import React, { useState, useEffect } from 'react';
import './styles.css';
import api from '../../services/api';
import Loading from '../../pages/Loading';
import { Context } from '../../Context/AuthContext';

function GerenciarWorkspace(props) {

    const { handleSwitch, selectedRow } = props
    const [carregando, setCarregando] = useState(false)
    const [data, setData] = useState([])
    const { aviso, workspaces, atualizarWorkspaces } = React.useContext(Context);
    const [change, setChange] = useState(false)
    const [originalData, setOriginalData] = useState([])

    useEffect(() => {
        let dat = []
        handleSwitch(parseInt(sessionStorage.getItem('workspaceId')))
        let dadosOrganizados = workspaces.sort((a, b) => {
            // Registros com amIOwner true devem vir antes
            if (a.amIOwner && !b.amIOwner) return -1;
            if (!a.amIOwner && b.amIOwner) return 1;

            // Se amIOwner for igual ou ambos são true ou false, compara pelo atributo id
            return a.id - b.id;
        });

        dadosOrganizados.forEach(item => {
            dat.push({ id: item.id, name: item.name, situacao: item.amIOwner ? 'Proprietário' : 'Convidado', owner_name: item.owner_name, default_workspace: item.default_workspace, associacao_id: item.associacao_id });
        })
        setData(dat)
        setOriginalData(dat)
    }, [workspaces])



    const handleCheckboxChange = async (associacao_id, id) => {

        const updatedData = data.map((item) => ({
            ...item,
            default_workspace: item.id === id ? item.default_workspace === 1 ? 0 : 1 : 0
        }));

         // Converte os objetos em strings JSON
        const updatedDataString = JSON.stringify(updatedData);
        const originalDataString = JSON.stringify(originalData);

        if (updatedDataString !== originalDataString) {
            setChange({ opcao: true, associacao_id })
        }else{
            setChange({ opcao: false, associacao_id })
        }

        setData(updatedData);

    };

    const handleRowClick = (id) => {
        handleSwitch(id);
    };

    const handleSalvarNovoPadrao = async () => {
        if (change.opcao) {
            try {
                setCarregando(true);
                await api.patch('workspace_user/' + change.associacao_id, { type: "default_workspace", value: 1 });
                aviso('success', 'Nova padrão atualizado');
                await atualizarWorkspaces()
                setChange({opcao: false})
            } catch (error) {
                aviso('error', error);
            } finally {
                setCarregando(false);
            }
        }
    }

    return (
        <div style={{maxHeight: '350px', overflowY: 'auto'}}>
            <Loading loading={carregando} message='Carregando...' />
            <table className='tableWorkspace'>
                <thead className='tableWorkspace'>
                    <tr>
                        <th>Nome</th>
                        <th>Situação</th>
                        <th>Nome do proprietário</th>
                        <th>Padrão</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={row.id} onClick={() => handleRowClick(row.id)} className={selectedRow === row.id ? 'selectedWorkspace' : 'notSelected'}>
                            <td>{row.name}</td>
                            <td>{row.situacao}</td>
                            <td>{row.owner_name}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    value={row.default_workspace}
                                    checked={row.default_workspace}
                                    onChange={(e) => handleCheckboxChange(row.associacao_id, row.id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                change.opcao && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button className='buttonWorkspace' onClick={() => handleSalvarNovoPadrao()} style={{ margin: '10px 0px 0px 0px' }}>Salvar novo padrão</button>
                    </div>
                )
            }

        </div>
    );
}


export default GerenciarWorkspace;