import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../services/api'
import Loading from '../Loading'
import { Context } from '../../Context/AuthContext';
import { Dropdown, Tab } from 'semantic-ui-react'
import { FiTrash2, FiPrinter } from 'react-icons/fi'
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';

import './styles.css';
import '../../global.css';

import { Menu } from '../../global.js'
import { GestaoTitulos } from './Titulo/index.js'
import { GestaoEstatisticas } from './Estatisticas/index.js'
import { GerenciarEmpresas } from './Empresas/index.js'


export default function GestãoAuditorias() {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [empresas, setEmpresas] = useState([])
  const [empresa, setEmpresa] = useState('');
  const [auditorias, setAuditorias] = useState([])
  const [tituloShow, setTituloShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const panes = [
    {
      menuItem: 'Auditoria',
      render: () => <Tab.Pane attached={false}>{Auditoria}</Tab.Pane>,
    },
    {
      menuItem: 'Empresas',
      render: () => <Tab.Pane attached={false}><GerenciarEmpresas /></Tab.Pane>,
    },
    {
      menuItem: 'Estatisticas',
      render: () => <Tab.Pane attached={false}><GestaoEstatisticas /></Tab.Pane>,
    }
  ]

  async function GerarRelatoriaAuditoria(auditoriaId, empresaId) {

    var url = window.location.href.split('://')
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const host = require('../../config.json');

    setCarregando(true)

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ token: localStorage.getItem('@jmv-token-key'), empresaId, auditoriaId })
    };

    let link
    if (url[0] === 'https') {
      link = 'https://' + host.api + '/v1/doc4'
    } else {
      link = 'http://' + host.api + '/v1/doc4'
    }

    setCarregando(true)
    await fetch(link, options)
      .then(res => {
        if (!res.ok) {
          throw new Error('Error: ' + res.status);
        }
        return res.blob();
      }).then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url
        a.download = "GRO";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });

    setCarregando(false)
  }

  async function inicial() {
    setCarregando(true)

    try {
      var op = []
      await Promise.all([
        api.get(`empresa`),
        api.get(`auditoria`)
      ]).then(response => {
        response[0].data.resultado.forEach((element, i) => {
          op.push({ key: i, value: element.id, text: element.razao_social })

        });
        response[1].data.resultado.forEach(item => {
          item.nome = op.filter(empresa => empresa.value === item.fk_empresa_id)[0]?.text
        })
        setEmpresas(op)

        // Ordena os dados por data
        response[1].data.resultado.sort((a, b) => new Date(a.data) - new Date(b.data));

        // Cria um objeto para rastrear a última numeração para cada empresa
        const ultimaNumeracaoPorEmpresa = {};

        // Adiciona o atributo 'numeracao' a cada objeto na lista
        const dadosComNumeracao = response[1].data.resultado.map((item) => {
          const { fk_empresa_id, data } = item;

          // Se não houver uma numeração para essa empresa, crie uma começando de 00
          if (ultimaNumeracaoPorEmpresa[fk_empresa_id] === undefined) {
            ultimaNumeracaoPorEmpresa[fk_empresa_id] = 1;
          }

          // Atualiza a numeração e formata como uma string com dois dígitos
          const numeracao = (ultimaNumeracaoPorEmpresa[fk_empresa_id]++).toString().padStart(2, '0');

          return {
            ...item,
            numeracao,
          };
        });

        setAuditorias(dadosComNumeracao)
      })

    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    if (activeTab === 0)
      inicial()
  }, [activeTab])

  const handleChangeEmpresa = async (e, { value }) => {
    setEmpresa(value)
  }

  const DropEmpresas = React.useMemo(() =>
  (
    <Dropdown
      className='dashboard-drop'
      options={empresas}
      placeholder="Selecione a empresa"
      search
      selection
      clearable
      fluid
      value={empresa}
      onChange={handleChangeEmpresa}
      noResultsMessage={"Nenhum capítulo pendente"}
    />
  ), [empresas, empresa])


  async function handleDeleteEmpresa(auditoria) {
    setCarregando(true)
    try {
      await api.delete('auditoria/' + auditoria);
      inicial()
      aviso('success', 'Auditoria removido com sucesso')
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  async function handleCreateAuditoria(empresa) {

    setCarregando(true)

    try {
      var valores = {
        fk_empresa_id: empresa,
      }
      await api.post('auditoria/', valores);
      inicial()
      setEmpresa('')
      aviso('success', 'Auditoria adicionado com sucesso')
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  const Auditoria = React.useMemo(() => {

    return (
      <div>
        {tituloShow ?
          <GestaoTitulos update={tituloShow} onHide={() => { sessionStorage.removeItem('auditoriaId'); setTituloShow(false); sessionStorage.removeItem('auditoriaEmpresaId'); sessionStorage.removeItem('auditoriaEmpresaNome'); sessionStorage.removeItem('auditoriaNumeracao') }}></GestaoTitulos>
          :
          <div>
            <div style={{ justifyContent: 'center', marginBottom: '10px', display: 'flex' }}>
              <label style={{ fontSize: '20px' }}>Empresas</label>
            </div>

            <div className='aud-select'>
              {DropEmpresas}
              {empresa !== '' && (
                <div className="reavaliacao-button">
                  <button onClick={() => { handleCreateAuditoria(empresa) }}>Criar nova auditoria</button>
                </div>
              )}
            </div>
            {auditorias.length ? auditorias.map((item, index) => (
              <div style={{ marginTop: '15px' }} key={item.id} className="ListaTrab-tarefa2">
                <ReactTooltip id={toString(item.id)} place="top" effect="solid" />
                <div>
                  <h3 className="auditoria-nome" onClick={() => {
                    sessionStorage.setItem('auditoriaEmpresaNome', item.nome)
                    sessionStorage.setItem('auditoriaNumeracao', item.numeracao)
                    sessionStorage.setItem('auditoriaEmpresaId', item.fk_empresa_id)
                    sessionStorage.setItem('auditoriaId', item.id)
                    setTituloShow(true)
                  }}>
                    {item.nome} - Auditoria {item.numeracao} - {format(item.data, 'dd/MM/yyyy HH:mm:ss', { locale: require('date-fns/locale/pt-BR') })}
                  </h3>
                </div>
                <div className="ListaTrab-icones2">
                  <i><FiPrinter data-tip={"Imprimir relatório"} data-for={toString(item.id)}
                    className="go-edit2"
                    onClick={() => GerarRelatoriaAuditoria(item.id, item.fk_empresa_id)} />
                  </i>
                  <i><FiTrash2 data-tip={"Excluir"} data-for={toString(item.id)}
                    className="go-trash2"
                    onClick={() => {
                      const r = window.confirm('Tem certeza que deseja deletar essa auditoria?')
                      if (r === true) {
                        handleDeleteEmpresa(item.id)
                      }
                    }} /> </i>
                </div>
              </div>
            )) : carregando ? null : <h4>Nenhuma auditoria adicionada</h4>}
            <Loading loading={carregando} message='Carregando...' />
          </div>}
      </div>
    )

  }, [DropEmpresas, auditorias, carregando, tituloShow])


  return (
    <div>
      <Menu />

      <div className="reavaliacao-retangulo1">
        <div className="reavaliacao-retangulo3">
          <div className="reavaliacao-intro">
            <h2>Auditoria</h2>
          </div>

          <div className="reavaliacao-login-box">

            <Tab menu={{ pointing: true }} panes={panes} activeIndex={activeTab} onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)} />

          </div>

        </div>
        <Loading loading={carregando} message='Carregando...' />
      </div>

    </div>


  );
}