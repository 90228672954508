import React, { useState, useContext } from 'react';
import { FiMenu, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import SideBarRight from './components/sideBarRight';
import SideBarLeft from './components/sideBarLeft';


import './global.css';

import logoImg from './assets/logo-jmvglobal.png';
import { Context } from './Context/AuthContext';


export function Menu() {

	const [menuLeftVisible, setMenuLeftVisible] = useState(false);
	const [menuRightVisible, setMenuRightVisible] = useState(false);
	const { Admin, FullLicensed, Licensed } = useContext(Context);

	return (
		<div>
			<div className="navbar">
				<div className="menuIcon">
					<i onClick={() => setMenuLeftVisible(true)}><FiMenu /></i>
				</div>

				<Link disabled={!Licensed && !Admin && !FullLicensed} to="/gerenciarempresas">
					<img src={logoImg} alt="JMV Global"/>	
                </Link>
				
				<div className="userIcon">
					<i onClick={() => setMenuRightVisible(true)}><FiUser /></i>
				</div>
				
			</div>

			<SideBarRight visible={menuRightVisible} handleClose={() => setMenuRightVisible(false)} />
			<SideBarLeft visible={menuLeftVisible} handleClose={() => setMenuLeftVisible(false)} />

		</div>
	);
}




