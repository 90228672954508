import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { Dropdown, Card, Statistic } from 'semantic-ui-react'
import CountUp from 'react-countup';
import { Chart } from "react-google-charts";

import '.././styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export function GestaoEstatisticas() {

    const { aviso } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = useState('');
    const [capitulos, setCapitulos] = useState([])
    const [capitulo, setCapitulo] = useState('')
    const [titulos, setTitulos] = useState([])
    const [titulo, setTitulo] = useState('')
    const [dadosEstatisticos, setDadosEstatisticos] = useState([])
    const [chartData, setChartData] = useState([])
    const [validador, setValidador] = useState(true)


    useEffect(() => {

        async function inicial() {

            setCarregando(true)
            try {
                var op = []
                await Promise.all([
                    api.get('empresa')
                ]).then(response => {
                    response[0].data.resultado.forEach((element, i) => {
                        op.push({ key: i, value: element.id, text: element.razao_social })
                    });
                    setEmpresas(op)
                })

            } catch (error) {
                console.log(error)
            } finally {
                setCarregando(false)
            }
        }

        inicial()

    }, [])



    const handleChangeEmpresa = async (e, { value }) => {

        setCarregando(true)
        setEmpresa(value);
        setCapitulo('')
        setTitulo('')
        setDadosEstatisticos([])

        var op = []

        await Promise.all([
            api.get(`auditoria_capitulo`),
        ]).then(response => {
            response[0].data.resultado.forEach((element, i) => {
                op.push({ key: i, value: element.id, text: element.capitulo })
            });
            setCapitulos(op)
            setTitulos([])
        })

        setCarregando(false)
    }

    const handleChangeCapitulo = async (e, { value }) => {
        setCarregando(true)
        setCapitulo(value);
        setTitulo('')
        setDadosEstatisticos([])
        var op = []
        await Promise.all([
            api.get(`auditoria_titulo`),
        ]).then(response => {
            response[0].data.resultado.filter(item => item.fk_cap_id === value).forEach((element, i) => {
                op.push({ key: i, value: element.id, text: element.titulo })
            });
            setTitulos(op)
        })
        setCarregando(false)
    }

    const handleChangeTitulo = async (e, { value }) => {
        setTitulo(value)
        setDadosEstatisticos([])
        AtualizarEstatisticas(value)
    }


    const AtualizarEstatisticas = async (titulo) => {
        try {
            setCarregando(true)
            var respostas;

            await Promise.all([
                api.get(`auditoria_resposta?empresa_id=${empresa}`),
            ]).then(response => {
                respostas = response[0].data.resultado;
            })

            respostas = respostas.filter(item => item.fk_cap_id === capitulo);

            // Função para encontrar registros mais recentes em um array de registros
            const encontrarMaisRecentes = registros => {
                const maiorAudId = registros.reduce((maior, registro) => Math.max(maior, registro.aud_id), -1);

                return registros.filter(registro => registro.aud_id === maiorAudId);
            };

            var valorFiltradoPorAuditoria = encontrarMaisRecentes(respostas)

            // Objeto para armazenar os registros agrupados por titulo_id
            const registrosPorTitulo = {};

            // Itera sobre os dados para agrupar por titulo_id
            valorFiltradoPorAuditoria.forEach(registro => {
                const { til_id } = registro;

                // Se ainda não houver um array para esse til_id, crie um
                if (!registrosPorTitulo[til_id]) {
                    registrosPorTitulo[til_id] = [];
                }

                // Adiciona o registro ao array
                registrosPorTitulo[til_id].push(registro);
            });

            // Encontra os registros mais recentes para cada titulo_id
            const registrosMaisRecentes = Object.values(registrosPorTitulo).flatMap(registros => encontrarMaisRecentes(registros));

            var dados_estatisticos = {}
            var multa_cap = 0
            var multa_titulo = 0
            var pontos_alcancados_cap = 0
            var pontos_possiveis_cap = 0
            var pontos_alcancados_titulo = 0
            var pontos_possiveis_titulo = 0

            registrosMaisRecentes.filter(item => item.fk_cap_id === capitulo).forEach(item => {
                multa_cap += item.multa
            })
            registrosMaisRecentes.filter(item => item.fk_cap_id === capitulo).forEach(item => {
                pontos_alcancados_cap += item.pontos_alcancados
            })
            registrosMaisRecentes.filter(item => item.fk_cap_id === capitulo).forEach(item => {
                pontos_possiveis_cap += item.pontos_possiveis
            })
            registrosMaisRecentes.filter(item => item.til_id === titulo).forEach(item => {
                multa_titulo += item.multa
            })
            registrosMaisRecentes.filter(item => item.til_id === titulo).forEach(item => {
                pontos_alcancados_titulo += item.pontos_alcancados
            })
            registrosMaisRecentes.filter(item => item.til_id === titulo).forEach(item => {
                pontos_possiveis_titulo += item.pontos_possiveis
            })

            dados_estatisticos.multa_cap = multa_cap
            dados_estatisticos.multa_titulo = multa_titulo
            dados_estatisticos.pontos_alcancados_cap = pontos_alcancados_cap
            dados_estatisticos.pontos_alcancados_titulo = pontos_alcancados_titulo
            dados_estatisticos.pontos_possiveis_cap = pontos_possiveis_cap
            dados_estatisticos.pontos_possiveis_titulo = pontos_possiveis_titulo

            setDadosEstatisticos(dados_estatisticos)

            const filteredData = respostas.filter(item => item.til_id === titulo);
            if(valorFiltradoPorAuditoria.filter(item => item.til_id === titulo).length > 0){
                setValidador(true)
            }else{
                setValidador(false)
            }

            const groupedData = filteredData.reduce((result, item) => {
                const key = item.aud_id;
                if (!result[key]) {
                    result[key] = {
                        data: item.data,
                        multa: 0,
                        pontos_alcancados: 0,
                    };
                }
                result[key].multa += item.multa;
                result[key].pontos_alcancados += item.pontos_alcancados;
                return result;
            }, {});

            // Organizar os dados agrupados por 'til_id'
            const organizedData = {};
            Object.values(groupedData).forEach(item => {
                const { data, multa, pontos_alcancados } = item;
                if (!organizedData[item.til_id]) {
                    organizedData[item.til_id] = [];
                }
                organizedData[item.til_id].push([new Date(data), parseFloat(multa), parseFloat(pontos_alcancados)]);
            });

            // Transformar os dados organizados em um formato que o gráfico aceita
            setChartData(Object.entries(organizedData).map(([til_id, values]) => {
                const rows = values.sort((a, b) => a[0] - b[0]);
                return [['Data', 'Multa', 'Pontos Alcançados'], ...rows];
            }).flat())

            setCarregando(true)
        } catch (error) {
            console.log(error)
            aviso('error', error);
        } finally {
            setCarregando(false)
        }
    }

    const DropEmpresas = React.useMemo(() =>
    (
        <Dropdown
            className='dashboard-drop'
            options={empresas}
            placeholder="Selecione a empresa"
            search
            selection
            clearable
            fluid
            value={empresa}
            onChange={handleChangeEmpresa}
            noResultsMessage={"Nenhum registro encontrado"}
        />
    ), [empresas, empresa])

    const DropCapitulo = React.useMemo(() =>
    (
        <Dropdown
            className='dashboard-drop'
            options={capitulos}
            placeholder="Selecione o capítulo"
            search
            selection
            disabled={empresa === ''}
            clearable
            fluid
            value={capitulo}
            onChange={handleChangeCapitulo}
            noResultsMessage={"Nenhum registro encontrado"}
        />
    ), [capitulos, capitulo, empresa])

    const DropTitulos = React.useMemo(() => (
        <Dropdown
            options={titulos}
            placeholder={"Selecione o título"}
            search
            selection
            disabled={empresa === '' || capitulo === ''}
            clearable
            fluid
            value={titulo}
            onChange={handleChangeTitulo}
            noResultsMessage={"Nenhum registro encontrado"}
        />
    ), [titulos, titulo, empresa, capitulo]);

    const LineChart = React.useMemo(() => (
        <Chart
            width={'100%'}
            height={'400px'}
            chartType="LineChart"
            loader={<div>Carregando gráfico</div>}
            data={chartData}
            options={{
                title: 'Mudanças ao longo do tempo',
                hAxis: {
                    title: 'Data',
                    format: 'MM/yyyy',
                },
                vAxes: {
                    0: { title: 'Multa (R$)' }, // Título para o eixo Y esquerdo
                    1: { title: 'Pontos Alcançados' }, // Título para o eixo Y direito
                },
                series: {
                    0: { targetAxisIndex: 0, color: 'red' },
                    1: { targetAxisIndex: 1, color: 'blue' },
                },
            }}
        />
    ), [chartData])


    return (
        <div>
            <div className="reavaliacao-nova">
                <div className="reavaliacao-p1">
                    <label>Empresa</label>
                    {DropEmpresas}
                </div>
                <div className="reavaliacao-p2">
                    <label>Capitulo</label>
                    {DropCapitulo}
                </div>
                <div className="reavaliacao-p3">
                    <label>Titulo</label>
                    {DropTitulos}
                </div>
            </div>
            {titulo !== "" && validador ? 
                <div>
                    <div className='estatisticas-auditoria'>
                        <label>Capitulo: {capitulos.filter(item => item.value === capitulo)[0]?.text}</label>
                        <Card.Group centered className='dashboard-estatistica'>
                            <Card className='dashboard-card3' color="black">
                                <Statistic size="small">
                                    <Statistic.Value><CountUp end={dadosEstatisticos.pontos_possiveis_cap} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Pontos possíveis</Statistic.Label>
                                </Statistic>
                            </Card>
                            <Card className='dashboard-card3' color="yellow">
                                <Statistic size="small" color="yellow">
                                    <Statistic.Value><CountUp decimal=',' decimals={2} separator='.' end={dadosEstatisticos.pontos_alcancados_cap} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Pontos alcançados</Statistic.Label>
                                </Statistic>
                            </Card>
                            <Card className='dashboard-card3' color="red">
                                <Statistic size="small" color="red">
                                    <Statistic.Value><span>R$ </span><CountUp decimal=',' decimals={2} separator='.' end={dadosEstatisticos.multa_cap} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Multa</Statistic.Label>
                                </Statistic>
                            </Card>
                        </Card.Group>
                    </div>
                    <div className='estatisticas-auditoria'>
                        <label>Titulo: {titulos.filter(item => item.value === titulo)[0]?.text}</label>
                        <Card.Group centered className='dashboard-estatistica'>
                            <Card className='dashboard-card3' color="black">
                                <Statistic size="small">
                                    <Statistic.Value><CountUp end={dadosEstatisticos.pontos_possiveis_titulo} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Pontos possíveis</Statistic.Label>
                                </Statistic>
                            </Card>
                            <Card className='dashboard-card3' color="yellow">
                                <Statistic size="small" color="yellow">
                                    <Statistic.Value><CountUp decimal=',' decimals={2} separator='.' end={dadosEstatisticos.pontos_alcancados_titulo} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Pontos alcançados</Statistic.Label>
                                </Statistic>
                            </Card>
                            <Card className='dashboard-card3' color="red">
                                <Statistic size="small" color="red">
                                    <Statistic.Value><span>R$ </span><CountUp decimal=',' decimals={2} separator='.' end={dadosEstatisticos.multa_titulo} duration={0.8} /></Statistic.Value>
                                    <Statistic.Label>Multa</Statistic.Label>
                                </Statistic>
                            </Card>
                        </Card.Group>
                    </div>
                    <div>
                        {LineChart}
                    </div>
                    <Loading loading={carregando} message='Carregando...' />
                </div>
            :
            <label>Não há registros</label>
            }

        </div>

    )

}
