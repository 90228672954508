import React, { useEffect, useState, useContext } from 'react';

import './styles.css';
import { Context } from '../../../Context/AuthContext';
import Loading from '../../Loading';
import CryptoJS from 'crypto-js'
import { Menu } from '../../../global.js'
import api from '../../../services/api';
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';



export default function LicencaAtiva() {

  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [dias_restantes, setDiasRestantes] = useState('');
  const [tipo, setTipo] = useState('');
  const [modalShow, setModalShow] = useState(false)
  const [hasLicense, setHasLicense] = useState(false)
  const [currentWorkspace, setCurrentWorkspace] = useState('')
  const [myWorkspace, setMyWorkspace] = useState('')
  const [carregando, setCarregando] = useState(false)
  const { Train, Aud } = React.useContext(Context);
  const [licenca, setLicenca] = useState('');
  const { aviso } = React.useContext(Context);

  const { handleLogin } = useContext(Context);

  useEffect(() => {

    async function inicial() {
      await api.get('verifica_login').then(response => {
        setEmail(response.data.user.user_email)
        setNome(response.data.user.display_name)
        let current = response.data.workspaces.filter(item => item.id === parseInt(sessionStorage.getItem('workspaceId')))[0]
        if(current !== undefined){
          setCurrentWorkspace(current)
        }
        let my_data = response.data.workspaces.filter(item => item.amIOwner === true)[0]
        if(my_data !== undefined){
          setMyWorkspace(my_data)
          setDiasRestantes(my_data.dias_restantes)
          setTipo(my_data.tipo)
        }
        setHasLicense(response.data.user.license.hasLicense)
      })
    } inicial();


  }, [])

  const jmv = () => {
    window.open('https://www.jmvglobal.com.br/pgrsmart/', '_blank')
  }

  const ativar = async (e) => {
    e.persist();
    setCarregando(true)
    const data = {
      license: licenca
    }
    try {
      await api.post('license/active', data)
      aviso('success', 'Chave ativada com sucesso')
      var user = CryptoJS.AES.decrypt(localStorage.getItem('login_enc'), '@#JMV');
      var login = user.toString(CryptoJS.enc.Utf8);
      var password = CryptoJS.AES.decrypt(localStorage.getItem('senha_enc'), '@#JMV');
      var senha = password.toString(CryptoJS.enc.Utf8);

      handleLogin(e, login, senha, false)


    } catch (error) {
      if (error.response.data.erro !== undefined)
        aviso('error', error.response.data.erro, true)
    } finally {
      setCarregando(false)

    }
  }

  const handleExport = async () => {
    setCarregando(true)
    await api.get('EEmpresa').then(resultado => {
      var data = resultado.data
      var blob = new Blob([JSON.stringify(data)], { type: "text/plain;charset=utf-8" });
      var date = new Date()
      var dia = ("00" + date.getDate()).slice(-2)
      var mes = ("00" + (date.getMonth() + 1)).slice(-2)
      var ano = ("0000" + date.getFullYear()).slice(-2)
      saveAs(blob, "pgr " + dia + "/" + mes + "/" + ano + ".json");
    })
    setCarregando(false)

  }

  return (
    <div>
      <header>
        <Menu />
      </header>

      <UserData show={modalShow} onHide={() => setModalShow(false)} />
      <Loading loading={carregando} message='Carregando...' />

      <div className="login-content-license">
        <div>
          <div className="intro-license">
            <h2>Informações da conta</h2>
          </div>

          <div className="retangulo-1-license">

            <div className="info-login-license">
              <h2>Nome: {nome}</h2>
            </div>

            <div className="info-login-license">
              <h2>E-mail: {email}</h2>
            </div>
            {currentWorkspace.amIOwner && (
              <div className="user-data">
                {!Train && !Aud ? <button onClick={() => setModalShow(true)} type="submit">Importar meus dados</button> : null}
                {!Train && !Aud ? <button onClick={handleExport} type="submit">Exportar meus dados</button> : null}
              </div>
            )}

          </div>

          {!hasLicense ?
            <div>
              <div className="intro-license">
                <h2>Usuário convidado</h2>
              </div>
              <div className="retangulo-2-license">

                <div className="info-login-license">
                  <h3>Seu usuário ainda não tem uma licença vinculada à conta. Adquira uma licença para desbloquear todos os recursos e insira os detalhes abaixo para acesso completo.</h3>
                </div>

                <div className="license">

                  <div className="license_ativa">
                    <input type="text" id="ativar-license" placeholder="Código da licença" onChange={e => setLicenca(e.target.value)} value={licenca} />
                    <button style={{ marginTop: "19px", marginLeft: "2px" }} onClick={ativar}> &nbsp;Ativar</button>
                  </div>

                </div>

              </div>
            </div>
            :
            <div>
              <div className="intro-license">
                <h2>Sua licença está ativa</h2>
              </div>

              <div className="retangulo-2-license">

                <div className="info-login-license">
                  <h2>Licença: {tipo}</h2>
                </div>
                <div className="info-login-license">
                  <h2>Tipo: {myWorkspace.workspace_permission === -2 ? "Auditoria" : myWorkspace.workspace_permission === -1 ? "Treinamento" : myWorkspace.workspace_permission === 2 ? "Administrador" : myWorkspace.workspace_permission === 0 ? "Business Premium" : "Business Basic"}</h2>
                </div>
                <div className="info-login-license">
                  <h2>Tempo Restante: {dias_restantes} dias</h2>
                </div>

                <div className="license">

                  <div className="license_ativa">
                    <button onClick={jmv} className="login-license" type="submit">Renovar</button>
                  </div>

                </div>

              </div>
            </div>
          }

        </div>
      </div>

    </div>

  );
}

function UserData(props) {

  const [carregando, setCarregando] = useState(false)
  const { aviso } = React.useContext(Context);
  const [files, setFiles] = useState("");
  const history = useHistory();

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }


  const handleControle = async () => {

    setCarregando(true)
    try {
      await api.post('IEmpresa', files)
      aviso('success', "Dados importados com sucesso")
      setCarregando(false)
      history.push('/GerenciarEmpresas')
    } catch (error) {
      aviso('error', error)
      setCarregando(false)
    }


  };

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      if (isJson(e.target.result)) {
        setFiles(JSON.parse(e.target.result));
      } else {
        aviso('error', 'Arquivo inválido', true)
      }
    };
  };

  return (

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Importar meus dados
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={carregando} message='Carregando...' />
        <div className="qual-relatorio-gerar2">
          <div className="selecionarempresa-tarefa2">
            <input type="file" onChange={handleChange} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={files == ""} onClick={handleControle}>Importar</Button>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}