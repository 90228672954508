import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiEdit, FiTrash2, FiChevronLeft } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CapTil } from '../Capitulo/index.js'
import { GestaoPerguntasAud } from '../Pergunta/index.js'

export function GestaoTituloAud(props) {

    const { aviso, Admin } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)
    const [titulos, setTitulos] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [perguntaShow, setPerguntaShow] = useState(false)

    async function inicializar() {
        setCarregando(true)
        try {
            await Promise.all([
                api.get('auditoria_titulo'),
            ]).then((response) => {
                setTitulos(response[0].data.resultado)
            })
        } catch (error) {
            aviso('error', error)
        } finally {
            setCarregando(false)
        }
    }

    useEffect(() => {
        if (props.update) {
            inicializar()
        }
    }, [props.update])

    async function handleDeleteTitulo(id) {
        try {
            setCarregando(true)
            await api.patch('auditoria_titulo/' + id, { enable: false })
            aviso('success', 'Deletado com sucesso')
        } catch (error) {
            aviso("error", "Erro ao deletar titulo, tente novamente", true)
        } finally {
            setCarregando(false)
        }
    }

    return (
        !perguntaShow ?
            <div className='teste'>
                <CapTil show={modalShow} titulos={titulos} admin={Admin.toString()} backdrop="static" tipo='titulo' onHide={() => { setModalShow(false); inicializar(); sessionStorage.removeItem('tituloId') }} />
                <div className="aud-titulo">
                    <i>
                        <ReactTooltip id="voltar" place="top" effect="solid"></ReactTooltip>
                        <FiChevronLeft data-tip={"Voltar"} data-for='voltar'
                            className="go-edit2"
                            style={{ margin: '0px 0px 10px 0px' }}
                            onClick={() => {
                                props.onHide()
                            }} />
                    </i>
                    <label style={{ fontSize: '20px' }}>Títulos</label>
                    <div>
                        <div />
                    </div>
                </div>
                <div className="reavaliacao-menu-pergunta">
                    <div className="reavaliacao-menu-pergunta2">
                        <label>&nbsp;</label>
                        <button onClick={() => {setModalShow(true); sessionStorage.removeItem('audTituloId')}}>Cadastrar novo</button>
                    </div>
                </div>
                <div>
                    <div style={{ marginTop: '30px' }}>
                        <h3>Títulos cadastrados:</h3>
                    </div>
                    {titulos.filter(item => item.fk_cap_id === parseInt(sessionStorage.getItem('audCapituloId'))).length < 1 ?
                        <div>
                            <label>Nenhum registro</label>
                        </div>
                        :
                        <div className="scroll-box">
                            {titulos.filter(item => item.fk_cap_id === parseInt(sessionStorage.getItem('audCapituloId'))).map(item => (
                                <div key={item.id} className="reavaliacao-list-box">
                                    <ReactTooltip id={toString(item.id)} place="top" effect="solid"></ReactTooltip>
                                    <div className="reavaliacao-name1">
                                        <h3 onClick={() => {
                                            setPerguntaShow(true)
                                            sessionStorage.setItem('audTituloId', item.id)
                                        }}>{item.titulo}</h3>
                                    </div>
                                    <div className="reavaliacao-icons">
                                        <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(item.id)} onClick={() => { sessionStorage.setItem('audTituloId', item.id); setModalShow(true) }} />  </i>
                                        <i className="reavaliacao-trash">
                                            <FiTrash2 data-tip="Excluir" data-for={toString(item.id)}
                                                onClick={async () => {
                                                    const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + item.titulo + ' :')
                                                    if (r === "excluir") {
                                                        await handleDeleteTitulo(item.id)
                                                        inicializar()
                                                    } else {
                                                        aviso("warning", "Nome incorreto, exclusão cancelada", true)
                                                    }
                                                }} /> </i>
                                    </div>

                                </div>
                            )
                            )}
                        </div>
                    }
                </div>
                <Loading loading={carregando} message='Carregando...' />
            </div>
            :
            <GestaoPerguntasAud update={perguntaShow} onHide={() => {
                sessionStorage.removeItem('audTituloId');
                setPerguntaShow(false)
            }} />

    )

}