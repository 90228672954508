import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiTrash2, FiChevronLeft } from 'react-icons/fi'
import { Dropdown } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'

import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export function GestaoCapitulos(props) {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [capitulo, setCapitulo] = useState([])
  const [capitulos, setCapitulos] = useState([])
  const [capituloOptions, setCapituloOptions] = useState([])
  const [tituloShow, setTituloShow] = useState(false);
  const [capituloSelecionado, setCapituloSelecionado] = useState('');

  async function inicial() {

    setCarregando(true)
    try {
      var op = []
      var valida = []
      await Promise.all([
        api.get(`auditoria_capitulo`),
        api.get(`aud_capitulo?auditoria_id=${sessionStorage.getItem('auditoriaId')}`)
      ]).then(response => {
        response[1].data.resultado.forEach((element) => {
          valida.push(element.fk_cap_id)
        })
        response[0].data.resultado.forEach((element, i) => {
          if (!valida.includes(element.id)) {
            op.push({ key: i, value: element.id, text: element.capitulo })
          }
        });
        setCapitulos(response[1].data.resultado);
        setCapituloOptions(op);
      })

    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {

    if (props.show)
      inicial()

  }, [props.show])

  const handleChangeCapitulo = async (e, { value }) => {
    setCapitulo(value)
  }

  const DropCapitulo = React.useMemo(() =>
  (
    <Dropdown
      className='dashboard-drop'
      options={capituloOptions}
      placeholder="Selecione um capítulo pendente para adicionar"
      search
      selection
      clearable
      fluid
      multiple
      value={capitulo}
      onChange={handleChangeCapitulo}
      noResultsMessage={"Nenhum capítulo pendente"}
    />
  ), [capituloOptions, capitulo])


  async function handleDeleteCapitulo(capitulo) {
    setCarregando(true)
    try {
      await api.delete('aud_capitulo/' + capitulo);
      inicial()
      aviso('success', 'Capitulo removido com sucesso')
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  async function handleAddCapitulo(capitulos) {

    setCarregando(true)

    try {
      let valores = []
      capitulos.forEach(capitulo => {
        valores.push({
          nome: capituloOptions.filter(item => item.value === capitulo)[0].text,
          fk_cap_id: capitulo,
          fk_aud_id: sessionStorage.getItem('auditoriaId')
        })
      })

      await api.post('aud_capitulo/', valores);
      inicial()
      setCapitulo([])

      aviso('success', 'Capitulo adicionado com sucesso')
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => { setTituloShow(false); props.onHide() }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Gerenciar capítulos e títulos
        </Modal.Title>
      </Modal.Header>


      <Modal.Body>
        {!tituloShow ?
          <div>
            <ReactTooltip id='voltar' place="top" effect="solid" />
            <div className="aud-titulo">
              <div />
              <label style={{ fontSize: '20px' }}>Capítulos</label>
              <div>
                <div />
              </div>
            </div>

            <div className='aud-select'>
              {DropCapitulo}
              {capitulo !== '' && (
                <div className="reavaliacao-button">
                  <button onClick={() => { handleAddCapitulo(capitulo) }}>Adicionar capítulo</button>
                </div>
              )}
            </div>
            <div className='scroll-box'>
              {capitulos.length ? capitulos.map(item => (
                <div key={item.id} style={{ marginTop: '15px' }} className="ListaTrab-tarefa2">
                  <ReactTooltip id={toString(item.id)} place="top" effect="solid" />
                  <div>
                    <h3 onClick={() => { setCapituloSelecionado(item); setTituloShow(true) }} className='auditoria-nome'>{item.nome}</h3>
                  </div>
                  <div className="ListaTrab-icones2">
                    <i>
                      <FiTrash2 data-tip={"Excluir"} data-for={toString(item.id)}
                        className="go-trash2"
                        onClick={() => {
                          const r = window.confirm('Tem certeza que deseja deletar esse capítulo?')
                          if (r === true) {
                            handleDeleteCapitulo(item.id)
                          }
                        }} /> </i>
                  </div>
                </div>
              )) : carregando ? null : <h4>Nenhum capítulo adicionado</h4>}
            </div>

            <Loading loading={carregando} message='Carregando...' />
          </div>
          :
          <GestaoTitulos update={tituloShow} capitulo={capituloSelecionado} onHide={() => {
            setCapituloSelecionado('')
            setTituloShow(false)
          }} />}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => { setTituloShow(false); props.onHide() }}>Fechar</Button>
      </Modal.Footer>

    </Modal>

  );
}

export function GestaoTitulos(props) {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [titulo, setTitulo] = useState([])
  const [titulos, setTitulos] = useState([])
  const [tituloOptions, setTituloOptions] = useState([])

  async function inicial() {

    setCarregando(true)

    try {
      var op = []
      var valida = []

      await Promise.all([
        api.get(`auditoria_titulo?all=true`),
        api.get(`aud_titulo?auditoria_id=${sessionStorage.getItem('auditoriaId')}&all=true`)
      ]).then(response => {
        response[1].data.resultado.filter(item => item.fk_cap_id === props.capitulo.fk_cap_id).forEach((element) => {
          valida.push(element.fk_titulo_id)
        })

        response[0].data.resultado.forEach((element, i) => {
          if (!valida.includes(element.id) && element.fk_cap_id === props.capitulo.fk_cap_id) {
            op.push({ key: i, value: element.id, text: element.titulo })
          }
        });

        setTituloOptions(op)
        setTitulos(response[1].data.resultado.filter(item => item.fk_cap_id === props.capitulo.fk_cap_id))
      })

    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    if (props.update)
      inicial()

  }, [props.update])

  const handleChangeTitulo = async (e, { value }) => {
    setTitulo(value)
  }

  const DropTitulo = React.useMemo(() =>
  (
    <Dropdown
      className='dashboard-drop'
      options={tituloOptions}
      placeholder="Selecione um titulo pendente para adicionar a auditoria"
      search
      selection
      clearable
      fluid
      multiple
      value={titulo}
      onChange={handleChangeTitulo}
      noResultsMessage={"Nenhum título pendente"}
    />
  ), [tituloOptions, titulo])


  async function handleDeleteTitulo(titulo) {
    setCarregando(true)
    try {
      await api.delete('aud_titulo/' + titulo);
      inicial()
      aviso('success', 'Titulo removido com sucesso')
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  async function handleAddTitulo(titulos) {

    setCarregando(true)

    try {
      let valores = []
      titulos.forEach(titulo => {
        valores.push({
          nome: tituloOptions.filter(item => item.value === titulo)[0].text,
          fk_titulo_id: titulo,
          fk_cap_id: props.capitulo.id
        })
      })
      await api.post('aud_titulo/', valores);
      inicial()

      aviso('success', 'Titulo adicionado com sucesso')
      setTitulo([])
    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  return (

    <div>
      <ReactTooltip id='voltar' place="top" effect="solid" />
      <div className='breadcumb'>
        <label>Capitulo: {props.capitulo.nome} &nbsp;</label>
      </div>
      <div className="aud-titulo">
        <i>
          <FiChevronLeft data-tip={"Voltar"} data-for='voltar'
            className="go-edit2"
            style={{ margin: '0px 0px 10px 0px' }}
            onClick={() => {
              props.onHide()
            }} />
        </i>
        <label style={{ fontSize: '20px' }}>Títulos</label>
        <div>
          <div />
        </div>
      </div>

      <div className='aud-select'>
        {DropTitulo}
        {titulo !== '' && (
          <div className="reavaliacao-button">
            <button onClick={() => { handleAddTitulo(titulo) }}>Adicionar título</button>
          </div>
        )}
      </div>
      <div className='scroll-box'>
        {titulos.length ? titulos.map(item => (
          <div key={item.titulo_id} style={{ marginTop: '15px' }} className="ListaTrab-tarefa2">
            <ReactTooltip id={toString(item.titulo_id)} place="top" effect="solid" />
            <div>
              <h3 style={{ fontSize: '15px', padding: '10px' }}>{item.nome}</h3>
            </div>
            <div className="ListaTrab-icones2">
              <i>
                <FiTrash2 data-tip={"Excluir"} data-for={toString(item.titulo_id)}
                  className="go-trash2"
                  onClick={() => {
                    const r = window.confirm('Tem certeza que deseja deletar esse capítulo?')
                    if (r === true) {
                      handleDeleteTitulo(item.titulo_id)
                    }
                  }} /> </i>
            </div>
          </div>
        )) : carregando ? null : <h4>Nenhum título adicionado</h4>}
      </div>
      <Loading loading={carregando} message='Carregando...' />
    </div>

  )

}

