import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';

import { Dropdown } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import { FiTrash2 } from 'react-icons/fi';
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';

import { useState, useEffect } from 'react';

export function Ocupacao({addOrUpdateOcupacao, ...props}) {

	const ocupacaoId = sessionStorage.getItem('ocupacaoId')
	const tarefaId = sessionStorage.getItem('atividadeId')

	const [identificacao, setIdentificacao] = useState("");

	const [carregando, setCarregando] = useState(false);
	const [currentValues6, setCurrentValues6] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [searchQuery2, setSearchQuery2] = useState('')
	const [cargos_cadastrados, setCargosCadastrados] = useState([])
	const [caracterizacao, setCaracterizacao] = useState([])
	const [opcoes_carac, setOpCarac] = useState([])
	const [modalCarac, setModalCarac] = useState(false)
	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setCurrentValues6([])
			setIdentificacao('')
			setSearchQuery2('')
			setCargosCadastrados([])

			async function initial() {
				var carac
				var c = await api.get('sugestoes/3')
				var carg = []

                if(!sessionStorage.getItem('atividadeNovo')){
                    var aux = await api.get('ocupacao?tarefa_id=' + tarefaId + '&empresa_id=' + sessionStorage.getItem('empresaId'))
                    aux.data.resultado.forEach(item => {
                        carg.push({ key: item.funcao, text: item.funcao, value: item.funcao })
                    })
                    setCargosCadastrados(carg)
                }

				setOpCarac(c.data.resultado)

				try {

					if (ocupacaoId !== null) {
                        setIdentificacao(props.ocupacoes.filter(ocupacao => ocupacao.id === parseInt(ocupacaoId))[0].funcao)
                        setSearchQuery2(props.ocupacoes.filter(ocupacao => ocupacao.id === parseInt(ocupacaoId))[0].funcao)

                        var carac = null;

                        if(!sessionStorage.getItem('atividadeNovo')){
                            await api.get(`oc_caracterizacao?ocupacao_id=${ocupacaoId}`).then(response => {
								const copy = response.data.resultado.map(caracterizacao => caracterizacao.caracterizacao_atividade);
								setCurrentValues6(copy);
								carac = copy;
                            })
                        }else{
                            let ocupacao = props.ocupacoes.filter(ocupacao => ocupacao.id === parseInt(ocupacaoId))[0];
							const copy = ocupacao.caracterizacoes.map(caracterizacao => caracterizacao.caracterizacao_atividade);
                            setCurrentValues6(copy);
							carac = copy;
                        }

						carac.forEach(item => {
							var verifica = false
							c.data.resultado.forEach(item2 => {
								if (item === item2.value) {
									verifica = true
								}
							})
							if (!verifica) {
								c.data.resultado.push({ key: item, text: item, value: item })
							}
						})

					} else {
						if (sessionStorage.getItem("AtividadeCargo") !== null) {
							setIdentificacao(sessionStorage.getItem("AtividadeCargo"))
							setSearchQuery2(sessionStorage.getItem("AtividadeCargo"))
						}
						if (sessionStorage.getItem('TarefaOcupacaoCaracterizacao') !== null) {
							aux = true
							carac = JSON.parse(sessionStorage.getItem('TarefaOcupacaoCaracterizacao'))
							setCurrentValues6(carac)
						}
					}
					setCaracterizacao(c.data.resultado)
				} catch (error) {

					aviso('error', error)
					console.log(error)

				} finally {

					setCarregando(false)
				}

			} initial()
		}

	}, [props.show])

	async function handleRegister(e) {
		e.preventDefault();

		const data = {
			funcao: identificacao
		};

		var verifica = []
		props.ocupacoes.forEach(ocupacao => {
            if(ocupacaoId === null)
			    verifica.push(ocupacao.funcao);
		})

		if (identificacao === '' || currentValues6.length < 1 || verifica.includes(data.funcao)) {
			if(verifica.includes(data.funcao)){
				aviso('warning', "Cargo já cadastrado!")
			}else{
				aviso('warning', "Preencha todos os campos!")
			}
			
		} else {
			try {
				setCarregando(true);
                if(!sessionStorage.getItem('atividadeNovo')){
                    if (ocupacaoId === null) {
                        var caracterizacoes = [];
                        currentValues6.forEach(caracterizacao => {
                            var v_carac = false
                            opcoes_carac.forEach(item => {
                                if (caracterizacao == item.value) {
                                    v_carac = true
                                }
                            })
                            if (!v_carac) {
    
                                var valor = {
                                    value: caracterizacao,
                                    text: caracterizacao
                                }
                                api.post("sugestoes/3", valor)
                            }
                            caracterizacoes.push({
                                caracterizacao_atividade: caracterizacao
                            })
                        })
                        data.caracterizacoes = caracterizacoes;
                        await cadastrarOcupacao(data, "post", tarefaId, null);
                        sessionStorage.setItem("TarefaOcupacaoCaracterizacao", JSON.stringify(currentValues6)) //Implementando sugestão dinâmica
                        sessionStorage.setItem("AtividadeCargo", identificacao)
                        aviso('success', "Cargo cadastrado com sucesso")
                    } else {
                        var caracterizacoes = [];
                        currentValues6.forEach(caracterizacao => {
                            var v_carac = false
                            opcoes_carac.forEach(item => {
                                if (caracterizacao == item.value) {
                                    v_carac = true
                                }
                            })
                            if (!v_carac) {
    
                                var valor = {
                                    value: caracterizacao,
                                    text: caracterizacao
                                }
                                api.post("sugestoes/3", valor)
                            }
                            caracterizacoes.push({
                                caracterizacao_atividade: caracterizacao
                            })
                        })
                        data.caracterizacoes = caracterizacoes;
                        await cadastrarOcupacao(data, "patch", null, ocupacaoId);
                        aviso('success', "Cargo salvo com sucesso")
                    }
                }else{
                    if (ocupacaoId === null) {
                        var caracterizacoes = [];
                        currentValues6.forEach(caracterizacao => {
    
                            var v_carac = false
                            opcoes_carac.forEach(item => {
                                if (caracterizacao == item.value) {
                                    v_carac = true
                                }
                            })
                            if (!v_carac) {
    
                                var valor = {
                                    value: caracterizacao,
                                    text: caracterizacao
                                }
                                api.post("sugestoes/3", valor)
                            }
    
                            caracterizacoes.push({
                                caracterizacao_atividade: caracterizacao
                            })
                            
                        })
                        data.caracterizacoes = caracterizacoes
                        addOrUpdateOcupacao(data, null)
                        aviso('success', "Cargo cadastrado com sucesso")
                    } else {
                        var caracterizacoes = [];
                        currentValues6.forEach(caracterizacao => {
    
                            var v_carac = false
                            opcoes_carac.forEach(item => {
                                if (caracterizacao == item.value) {
                                    v_carac = true
                                }
                            })
                            if (!v_carac) {
    
                                var valor = {
                                    value: caracterizacao,
                                    text: caracterizacao
                                }
                                api.post("sugestoes/3", valor)
                            }
    
                            caracterizacoes.push({
                                caracterizacao_atividade: caracterizacao
                            })
                            
                        })
                        data.caracterizacoes = caracterizacoes
                        addOrUpdateOcupacao(data, ocupacaoId)
                        aviso('success', "Cargo salvo com sucesso")
                    }
                }


			} catch (error) {
				aviso('error', error);

			} finally {
				setCarregando(false)
				props.onHide()
				setIdentificacao('')
				setSearchQuery2('')
				setCurrentValues6([])
			}
		}

	}

	const handleChange = (e, { value }) => {
		setCurrentValues6(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setCurrentValues6(currentValues6.filter((item) => item != value))
		setSearchQuery(value)
	}

	const handleAddition = (e, { value }) => {
		const copy = Array.from(caracterizacao);
		copy.push({ key: value, text: value, value: value })
		setCaracterizacao(copy);
	}

	const handleChange2 = (e, { value }) => {
		setIdentificacao(value);
		setSearchQuery2(value);
	}

	const handleSearchChange2 = (e, { searchQuery }) => {
		setSearchQuery2(searchQuery)
	}


	const handleAddition2 = (e, { value }) => {
		const copy = Array.from(cargos_cadastrados);
		copy.push({ key: value, text: value, value: value })
		setCargosCadastrados(copy);
	}

	const DropCaracteriza = React.useMemo(() => (
		<Dropdown
			options={caracterizacao}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			fluid
			multiple
			upward
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues6}
			searchQuery={searchQuery}
			onAddItem={handleAddition}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [caracterizacao, currentValues6, searchQuery]);

	const DropCargo = React.useMemo(() => (
		<Dropdown
			options={cargos_cadastrados}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			upward
			allowAdditions
			additionLabel="Adicionar "
			value={identificacao}
			searchQuery={searchQuery2}
			onAddItem={handleAddition2}
			onChange={handleChange2}
			onSearchChange={handleSearchChange2}
		/>
	), [cargos_cadastrados, identificacao, searchQuery2]);

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
			<RemoveCarac show={modalCarac} backdrop="static" onHide={() => { setModalCarac(false) }} />
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Cargos
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>


					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Cargo do trabalhor</label>
						{DropCargo}
					</div>

					<div className="atividade-caracterizacao">
						<label htmlFor="caracterizacao">Caracterização <FiTrash2 onClick={() => setModalCarac(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux" /></label>
						{DropCaracteriza}
					</div>


				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister} >{ocupacaoId === null ? 'Cadastrar' : 'Salvar'}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

export const cadastrarOcupacao = async (ocupacoes, tipo, tarefaId, ocupacaoId) => {
    try{
        if (tipo === "patch") {
            await api.delete('oc_caracterizacao/' + ocupacaoId)
            await api.patch("ocupacao/" + ocupacaoId, ocupacoes)
            console.log(ocupacoes)
            await Promise.all(
                ocupacoes.caracterizacoes.map(caracterizacao => 
                    api.post(`oc_caracterizacao?ocupacao_id=${ocupacaoId}`, caracterizacao)
                )
            );
        } else {
            const listaOcupacoes = Array.isArray(ocupacoes) ? ocupacoes : [ocupacoes];
            for (const ocupacao of listaOcupacoes) {
                const response = await api.post(`ocupacao?tarefa_id=${tarefaId}`, ocupacao);
                const ocupacaoId = response.data.id_ocupacao;
                await Promise.all(
                    ocupacao.caracterizacoes.map(caracterizacao => 
                        api.post(`oc_caracterizacao?ocupacao_id=${ocupacaoId}`, caracterizacao)
                    )
                );
            }
        }
    }catch(error){
        console.log(error)
    }

}


function RemoveCarac(props) {


	const [carregando, setCarregando] = useState(false);
	const [opCarac, setopCarac] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [carac, setCarac] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setopCarac([])
			setCarac([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/3')
					setopCarac(f.data.resultado.filter(item => item.fk_user_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (carac.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				carac.forEach(item => {
					lista.push(opCarac.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/3', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setCarac(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setCarac(carac.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropCarac = React.useMemo(() => (
		<Dropdown
			options={opCarac}
			placeholder={"Selecione múltiplos"}
			lazyLoad={true}
			search
			selection
			fluid
			multiple
			upward
			value={carac}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [carac, opCarac, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Caracterização</label>
						{DropCarac}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}