import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../../Context/AuthContext';
import Loading from '../../Loading';
import CryptoJS from 'crypto-js'
import './styles.css';

import { Menu } from '../../../global.js'
import api from '../../../services/api';



export default function LicencaAtiva() {

  const { handleLogin } = useContext(Context);

  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [licenca, setLicenca] = useState('');
  const [carregando, setCarregando] = useState('');
  const { aviso } = React.useContext(Context);

  useEffect(() => {
    async function inicial() {
      await api.get('verifica_login').then(response => {
        setEmail(response.data.user.user_email)
        setNome(response.data.user.display_name)
      }).catch(error => {
        error = error
      })
    } inicial();
  }, [])

  const ativar = async (e) => {
    e.persist();
    setCarregando(true)
    const data = {
      license: licenca
    }
    try {
      await api.post('license/active', data)
      aviso('success', 'Chave ativada com sucesso')
      var user = CryptoJS.AES.decrypt(localStorage.getItem('login_enc'), '@#JMV');
      var login = user.toString(CryptoJS.enc.Utf8);
      var password = CryptoJS.AES.decrypt(localStorage.getItem('senha_enc'), '@#JMV');
      var senha = password.toString(CryptoJS.enc.Utf8);

      handleLogin(e, login, senha, false)


    } catch (error) {
      if (error.response.data.erro !== undefined)
        aviso('error', error.response.data.erro, true)
    } finally {
      setCarregando(false)

    }
  }

  return (
    <div>
      <header>
        <Menu />
      </header>
      <Loading loading={carregando} message='Carregando...' />
      <div className="login-content-license">
        <div>
          <div className="intro-license">
            <h2>Meus dados</h2>
          </div>

          <div className="retangulo-1-license">

            <div className="info-login-license">
              <h3>Nome: {nome}</h3>
            </div>

            <div className="info-login-license">
              <h3>E-mail: {email}</h3>
            </div>

          </div>

          <div className="intro-license">
            <h2>Ativar licença</h2>
          </div>

          <div className="retangulo-2-license">

            <div className="info-login-license">
              <h3>Seu usuário ainda não tem permissão para a utilização do sistema, por favor, compre a licença e insira logo abaixo</h3>
            </div>

            <div className="license">

              <div className="license_ativa">
                <input type="text" id="ativar-license" placeholder="Código da licença" onChange={e => setLicenca(e.target.value)} value={licenca} />
                <button style={{marginTop:"19px",marginLeft:"2px"}} onClick={ativar}> &nbsp;Ativar</button>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  );
}