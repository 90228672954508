import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../services/api'
import Loading from '../Loading'
import { Context } from '../../Context/AuthContext';
import { Tab } from 'semantic-ui-react'
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';


import './styles.css';
import '../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from '../../global.js'


export default function LaudoSugestoes() {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [sugestoes, setSugestoes] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [modalShow2, setModalShow2] = useState(false)
  const [modalShow3, setModalShow3] = useState(false)
  const [modalShow4, setModalShow4] = useState(false)
  const [modalShow5, setModalShow5] = useState(false)
  const [modalShow6, setModalShow6] = useState(false)
  const [modalShow7, setModalShow7] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const location = useLocation();

  const panes = [
    {
      menuItem: 'Introdução',
      render: () => <Tab.Pane attached={false}>{GerenciarIntroducao}</Tab.Pane>,
    },
    {
      menuItem: 'Avaliação Preliminar do Risco',
      render: () => <Tab.Pane attached={false}>{GerenciarAvaliacao}</Tab.Pane>,
    },
    {
      menuItem: 'Conclusões para Insalubridade',
      render: () => <Tab.Pane attached={false}>{GerenciarConclusaoInsalubridade}</Tab.Pane>,
    },
    {
      menuItem: 'Conclusões LTCAT',
      render: () => <Tab.Pane attached={false}>{GerenciarConclusaoLcat}</Tab.Pane>,
    },
    {
      menuItem: 'Conclusões para Periculosidade',
      render: () => <Tab.Pane attached={false}>{GerenciarConclusaoPericulosidade}</Tab.Pane>,
    },
    {
      menuItem: 'Perguntas Qualitativas',
      render: () => <Tab.Pane attached={false}>{GerenciarPerguntas}</Tab.Pane>,
    },
    {
      menuItem: 'Equipamentos de medição',
      render: () => <Tab.Pane attached={false}>{GerenciarEquipamentos}</Tab.Pane>,
    },
  ]

  async function handleDeleteSugestao(id) {
    try {
      setCarregando(true)
      await api.post(`sugestoesDelete/9`, { lista: [id] })
      aviso('success', 'Deletado com sucesso')
    } catch (error) {
      aviso("error", "Erro ao deletar pergunta, tente novamente", true)
    } finally {
      setCarregando(false)
    }
  }

  async function inicializar() {
    setCarregando(true)
    try {
      const path = location.pathname;
      if(path === '/LaudoConfig/equipamentos'){
        setActiveTab(6)
      }
      const res = await api.get(`sugestoes/14`)
      setSugestoes(res.data.resultado)
    } catch (error) {
      aviso('error', error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {
    inicializar()
  }, [])

  const GerenciarIntroducao = React.useMemo(() => {

    return (
      <div className='teste'>

        <Introducao show={modalShow} backdrop="static" tipo='introducao' onHide={() => { setModalShow(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />
        <div className="reavaliacao-menu-pergunta">
          <div className="reavaliacao-menu-pergunta2">
            <label>&nbsp;</label>
            <button onClick={() => setModalShow(true)}>Cadastrar texto</button>
          </div>
        </div>
        <div>
          <div style={{ marginTop: '30px' }}>
            <h3>Textos cadastrados:</h3>
          </div>
          {sugestoes.filter(item => item.tipo === 'introducao').length < 1 ?
            <div>
              <label>Nenhum registro</label>
            </div>
            :
            <div className="scroll-box">
              {sugestoes.filter(item => item.tipo === 'introducao').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
          }
        </div>
      </div>
    )


  }, [sugestoes, modalShow])

  const GerenciarAvaliacao = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow2} tipo='avaliacao' backdrop="static" onHide={() => { setModalShow2(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />


        <div className="reavaliacao-menu-pergunta">
          <div className="reavaliacao-menu-pergunta2">
            <label>&nbsp;</label>
            <button onClick={() => setModalShow2(true)}>Cadastrar texto</button>
          </div>
        </div>
        {sugestoes.filter(item => item.tipo === 'avaliacao').length < 1 ?
          <div>
            <label>Nenhum registro</label>
          </div>
          :
          <div>
            <div style={{ marginTop: '30px' }}>
              <h3>Textos cadastrados:</h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.tipo === 'avaliacao').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow2(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow2])

  const GerenciarConclusaoLcat = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow3} tipo='conclusaoLcat' backdrop="static" onHide={() => { setModalShow3(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />

        {sugestoes.filter(item => item.conclusao_tipo === 'lcat').length === 0 ?
          <div className="reavaliacao-menu-pergunta">
            <div className="reavaliacao-menu-pergunta2">
              <label>&nbsp;</label>
              <label>Nenhum registro<br></br></label>
              <button onClick={() => setModalShow3(true)}>Cadastrar texto</button>
            </div>
          </div>
          :
          <div className="reavaliacao-menu-pergunta3">
            <div style={{ marginTop: '30px' }}>
              <h3>Conclusões cadastradas:</h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.conclusao_tipo === 'lcat').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow3(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>

              )
              )}
            </div>
            <br></br>
            <button onClick={() => setModalShow3(true)}>Cadastrar texto</button>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow3])

  const GerenciarConclusaoInsalubridade = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow4} tipo='conclusaoInsalubridade' backdrop="static" onHide={() => { setModalShow4(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />

        {sugestoes.filter(item => item.conclusao_tipo === 'insalubridade').length === 0 ?
          <div className="reavaliacao-menu-pergunta">
            <div className="reavaliacao-menu-pergunta2">
              <label>&nbsp;</label>
              <label>Nenhum registro<br></br></label>
              <button onClick={() => setModalShow4(true)}>Cadastrar texto</button>
            </div>
          </div>
          :
          <div className="reavaliacao-menu-pergunta3">
            <div style={{ marginTop: '30px' }}>
              <h3>Conclusões cadastradas:</h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.conclusao_tipo === 'insalubridade').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow4(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
            <br></br>
            <button onClick={() => setModalShow4(true)}>Cadastrar texto</button>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow4])

  const GerenciarConclusaoPericulosidade = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow5} tipo='conclusaoPericulosidade' backdrop="static" onHide={() => { setModalShow5(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />

        {sugestoes.filter(item => item.conclusao_tipo === 'periculosidade').length === 0 ?
          <div className="reavaliacao-menu-pergunta">
            <div className="reavaliacao-menu-pergunta2">
              <label>&nbsp;</label>
              <label>Nenhum registro<br></br></label>
              <button onClick={() => setModalShow5(true)}>Cadastrar texto</button>
            </div>
          </div>
          :
          <div className="reavaliacao-menu-pergunta3">
            <div style={{ marginTop: '30px' }}>
              <h3>Conclusões cadastradas:</h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.conclusao_tipo === 'periculosidade').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow5(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
            <br></br>
            <button onClick={() => setModalShow5(true)}>Cadastrar texto</button>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow5])

  const GerenciarPerguntas = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow6} tipo='perguntas' backdrop="static" onHide={() => { setModalShow6(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />

        {sugestoes.filter(item => item.tipo === 'perguntas').length === 0 ?
          <div className="reavaliacao-menu-pergunta">
            <div className="reavaliacao-menu-pergunta2">
              <label>&nbsp;</label>
              <label>Nenhum registro<br></br></label>
              <button onClick={() => setModalShow6(true)}>Cadastrar pergunta</button>
            </div>
          </div>
          :
          <div className="reavaliacao-menu-pergunta3">
            <div style={{ marginTop: '30px' }}>
              <h3>Perguntas cadastradas:</h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.tipo === 'perguntas').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow6(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
            <br></br>
            <button onClick={() => setModalShow6(true)}>Cadastrar pergunta</button>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow6])

  const GerenciarEquipamentos = React.useMemo(() => {

    return (
      <div>

        <Introducao show={modalShow7} tipo='equipamento' backdrop="static" onHide={() => { setModalShow7(false); sessionStorage.removeItem('sugestaoLaudoId'); inicializar() }} />

        {sugestoes.filter(item => item.tipo === 'equipamento').length === 0 ?
          <div className="reavaliacao-menu-pergunta">
            <div className="reavaliacao-menu-pergunta2">
              <label>&nbsp;</label>
              <label>Nenhum registro<br></br></label>
              <button onClick={() => setModalShow7(true)}>Cadastrar equipamento</button>
            </div>
          </div>
          :
          <div className="reavaliacao-menu-pergunta3">
            <div style={{ marginTop: '30px' }}>
              <h3>Equipamentos cadastrados: </h3>
            </div>
            <div className="scroll-box">
              {sugestoes.filter(item => item.tipo === 'equipamento').map(sugestao => (
                <div key={sugestao.id} className="reavaliacao-list-box">
                  <ReactTooltip id={toString(sugestao.id)} place="top" effect="solid"></ReactTooltip>
                  <div className="reavaliacao-name">
                    <h3>{sugestao.valor}</h3>
                  </div>
                  <div className="reavaliacao-icons">
                    <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(sugestao.id)} onClick={() => { sessionStorage.setItem('sugestaoLaudoId', sugestao.id); setModalShow7(true) }} />  </i>
                    <i className="reavaliacao-trash">
                      <FiTrash2 data-tip="Excluir" data-for={toString(sugestao.id)}
                        onClick={async () => {
                          const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + sugestao.valor + ' :')
                          if (r === "excluir") {
                            await handleDeleteSugestao(sugestao.id)
                            inicializar()
                          } else {
                            aviso("warning", "Nome incorreto, exclusão cancelada", true)
                          }
                        }} /> </i>
                  </div>

                </div>
              )
              )}
            </div>
            <br></br>
            <button onClick={() => setModalShow7(true)}>Cadastrar equipamento</button>
          </div>
        }
      </div>
    )


  }, [sugestoes, modalShow7])

  return (
    <div>
      <Menu />

      <div className="reavaliacao-retangulo1">
        <div className="reavaliacao-retangulo2">
          <div className="reavaliacao-intro">
            <h2>Configurações do laudo</h2>
          </div>

          <div className="laudo-login-box">

            <Tab menu={{ pointing: true }} panes={panes} activeIndex={activeTab} onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}/>

          </div>

        </div>
        <Loading loading={carregando} message='Carregando...' />
      </div>

    </div>


  );

}

function Introducao(props) {

  const sugestaoLaudoId = sessionStorage.getItem('sugestaoLaudoId')

  const [carregando, setCarregando] = useState(false);
  const [sugestao, setSugestao] = useState('')
  const [conclusaoParecer, setConclusaoParecer] = useState('Não')
  const [filtroPergunta, setFiltroPergunta] = useState('')
  const [certificado_equipamento, setCertificado] = useState('')
  const { aviso } = React.useContext(Context);

  useEffect(() => {

    async function inicial() {

      if (props.show) {

        setSugestao('')
        setCertificado('')

        if (sugestaoLaudoId !== undefined && sugestaoLaudoId !== null) {
          setCarregando(true)
          try {
            const response = await api.get('sugestoes/14')
            setSugestao(response.data.resultado.filter(item => item.id === parseInt(sugestaoLaudoId))[0].valor)
            setConclusaoParecer(response.data.resultado.filter(item => item.id === parseInt(sugestaoLaudoId))[0].conclusao_parecer)
            setFiltroPergunta(response.data.resultado.filter(item => item.id === parseInt(sugestaoLaudoId))[0].filtro_pergunta)
            setCertificado(response.data.resultado.filter(item => item.id === parseInt(sugestaoLaudoId))[0].certificado_equipamento)
          } catch (error) {
            console.log(error)
            aviso('error', error)
          } finally {
            setCarregando(false)
          }
        }

      }

    } inicial()

  }, [props.show, sessionStorage.getItem('sugestaoLaudoId')])

  const handleRegister = async (e) => {

    e.preventDefault()

    var data = {
      valor: sugestao,
    }

    if (props.tipo.match(/conclusao.*/)) {
      data.tipo = 'conclusao'
      data.conclusao_parecer = conclusaoParecer
      if (props.tipo === 'conclusaoInsalubridade') {
        data.conclusao_tipo = 'insalubridade'
      } else if (props.tipo === 'conclusaoPericulosidade') {
        data.conclusao_tipo = 'periculosidade'
      } else {
        data.conclusao_tipo = 'lcat'
      }
    } else {
      data.tipo = props.tipo
      if (props.tipo.match(/perguntas.*/)){
        data.filtro_pergunta = filtroPergunta
      }
      if (props.tipo.match(/equipamento.*/)){
        data.certificado_equipamento = certificado_equipamento
      }
    }

    if (sugestao === "") {
      aviso('warning', "Preencha todos os campos!")
    } else {

      try {

        var mensagem
        setCarregando(true)
        if (sugestaoLaudoId !== undefined && sugestaoLaudoId !== null) {
          await api.patch("sugestoes/" + sugestaoLaudoId + "?tipo=laudo", data)
          mensagem = "Texto atualizado com sucesso"
          aviso('success', mensagem)
        } else {
          await api.post("sugestoes/9", data)
          mensagem = "Texto criado com sucesso"
          aviso('success', mensagem)
        }

      } catch (error) {

        aviso('error', error)

      } finally {
        setCarregando(false)
        props.onHide()
      }
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {sugestaoLaudoId !== undefined && sugestaoLaudoId !== null ? "Editar texto" : "Cadastrar texto"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={carregando} message='Carregando...' />

        <div className="trabexp">

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label hidden={!props.tipo.match(/equipamento.*/)}>Equipamento de medição</label>
            <label hidden={props.tipo.match(/equipamento.*/)}>Texto</label>
            <textarea className="text-area-risco" type="longtext" aria-multiline="true" value={sugestao} onChange={e => setSugestao(e.target.value)} />
            <label hidden={!props.tipo.match(/equipamento.*/)}>Certificado de calibração</label>
            <textarea hidden={!props.tipo.match(/equipamento.*/)} className="text-area-risco" type="longtext" aria-multiline="true" value={certificado_equipamento} onChange={e => setCertificado(e.target.value)} />
            <label hidden={!props.tipo.match(/conclusao.*/)}>Tipo</label>
            <select hidden={!props.tipo.match(/conclusao.*/)} value={conclusaoParecer} onChange={e => { setConclusaoParecer(e.target.value) }}>
              <option value="Não">Conclusão parecer negativo</option>
              <option value="Sim">Conclusão parecer positivo</option>
            </select>
            <label hidden={!props.tipo.match(/perguntas.*/)}>Tipo</label>
            <select hidden={!props.tipo.match(/perguntas.*/)} value={filtroPergunta} onChange={e => { setFiltroPergunta(e.target.value) }}>
              <option value="">Geral</option>
              <option value="Ar comprimido">Ar comprimido</option>
              <option value="Biológico">Biológico</option>
              <option value="Calor">Calor</option>
              <option value="Frio">Frio</option>
              <option value="Periculoso">Periculoso</option>
              <option value="Químico">Químico</option>
              <option value="Radiações ionizante">Radiações ionizante</option>
              <option value="Radiações não ionizante">Radiações não ionizante</option>
              <option value="Ruído contínuo ou intermitente">Ruído contínuo ou intermitente</option>
              <option value="Ruído impulsivo ou de impacto">Ruído impulsivo ou de impacto</option>
              <option value="Umidade">Umidade</option>
              <option value="Vibração de corpo inteiro">Vibração de corpo inteiro</option>
              <option value="Vibração mãos e braços">Vibração mãos e braços</option>
            </select>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRegister}>{sugestaoLaudoId !== undefined && sugestaoLaudoId !== null ? "Salvar" : "Cadastrar"}</Button>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}
