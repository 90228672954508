import React from 'react';
import { FiEdit, FiTrash2, FiArrowLeftCircle, FiChevronLeft, FiArrowRightCircle, FiCopy } from 'react-icons/fi';
import { Modal, Button } from 'react-bootstrap'
import { Dropdown } from 'semantic-ui-react'
import './styles.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import InfiniteScroll from 'react-infinite-scroll-component';
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';
import { BiDetail } from 'react-icons/bi';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Input } from 'semantic-ui-react'

import { Menu } from '../../../global.js'
import Loading from '../../Loading';
import api from '../../../services/api';

export default function GerenciarRiscos() {

	const tarefa = sessionStorage.getItem('tarefa')
	const tarefaId = sessionStorage.getItem('atividadeId')
	const empresaId = sessionStorage.getItem('empresaId')
	const estabelecimentoModalidade = sessionStorage.getItem('estabelecimentoModalidade')
	const estabelecimentoId = sessionStorage.getItem('estabelecimentoId')
	const [mudanca, setMudanca] = useState(false)

	const [carregando, setCarregando] = useState(false);
	const [riscos, setRiscos] = useState([])
	const [troca, setTroca] = useState(false)
	const [atividades, setAtividades] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const { aviso } = React.useContext(Context);
	const host = require('../../../config.json')

	const [foundRiscos, setFoundRiscos] = useState([]);
	const [keyword, setKeyword] = useState('');
	const [risc, setRisc] = useState('');

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundRiscos.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundRiscos.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundRiscos.slice(count.prev + 10, count.next + 10)))
			}, 125)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}
	}

	const Sort = () => (
		<Dropdown
			text='Ordenar'
			icon='sort'
			floating
			labeled
			button
			className='icon'
		>
		  <Dropdown.Menu>
			<Dropdown.Item onClick={handleAsc}>A-Z</Dropdown.Item>
			<Dropdown.Item onClick={handleDesc}>Z-A</Dropdown.Item>
			<Dropdown.Item onClick={handleInverter}>Inverter ordem atual</Dropdown.Item>
		  </Dropdown.Menu>
		</Dropdown>
	);

	const sortArray = (obj) => {
		return obj.sort( (a,b) => {
			return a.nome.localeCompare(b.nome);
		})
	}

	const handleAsc = ( ) => {
		setRiscos(sortArray(riscos))
		lazyLoad()
		setMudanca(true)
	}

	const handleDesc = ( ) => {
		setRiscos(sortArray(riscos).reverse())
		lazyLoad()
		setMudanca(true)
	}

	const handleInverter = ( )=> {
		setRiscos(riscos.reverse())
		lazyLoad()
		setMudanca(true)
	}

	async function initial() {
		setCarregando(true)
		try {

			const response = await api.get(`risco?tarefa_id=${tarefaId}`)
			setRiscos(response.data.resultado.filter(risco => risco.nome !== ""))

			setFoundRiscos(response.data.resultado.filter(risco => risco.nome !== ""))
			setHasMore(true)
			setTimeout(() => {
				setCurrent(response.data.resultado.filter(risco => risco.nome !== "").slice(0, 10))
			}, 0)

			response.data.resultado.forEach(risco => {
				if (risco.nome === '') {
					api.delete(`risco/${risco.id}`)
				}
			})

			api.get(`tarefa?estabelecimento_id=${estabelecimentoId}`).then(async response => {
				setAtividades(response.data.resultado.filter(atividade => atividade.nome !== ""))
			})

		} catch (error) {

			aviso('error', error)

		} finally {

			setCarregando(false)
		}

	}

	function lazyLoad(){
		if(keyword !== ''){
			const result = riscos.filter((item) => {
				return (item.nome.toLowerCase().includes(keyword.toLowerCase()))
			})

			setFoundRiscos(result)
			setMudanca(false)
			setHasMore(true)

			setTimeout(() => {
				setCurrent(result.slice(0, 10))
			}, 0)

		}else{
			if(foundRiscos.length !== 0){
				setFoundRiscos(riscos)
				setTimeout(() => {
					setCurrent(riscos.slice(0, 10))
				}, 0)
				setHasMore(true)
			}
		}

		setCount({
			prev: 0,
			next: 10
		})
	}
	
	useEffect(() => {
		lazyLoad()
	},[keyword])

	useEffect(() => {
		initial()
	}, [sessionStorage.getItem('atividadeId'), troca]);



	async function handleDeleteRisco(id) {
		try {
			await api.delete(`risco/${id}`)
			setRiscos(riscos.filter(risco => risco.id !== id))
			UpdateRiscos()
			setCurrent(current.filter(risco => risco.id !== id))
			aviso('success', 'Risco deletado com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function UpdateRiscos() {
		try {
			const response = await api.get(`risco?tarefa_id=${tarefaId}`)
			setRiscos(response.data.resultado)
			setFoundRiscos(response.data.resultado)
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleLeftArrow(id) {

		try {
			var index = -1
			atividades.forEach((item, i) => {
				if (item.id == id) {
					index = i - 1
				}
			})
			var t = ""
			atividades.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('atividadeId', item.id)
					sessionStorage.setItem('tarefa', item.nome)
					sessionStorage.setItem('tarefaDescricao', item.descricao)
					t = item.nome
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', estabelecimentoModalidade === 'Tarefa' ? 'Tarefa ' + t : 'Atividade ' + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	const handleDoc = (risco) => {
		var url = window.location.href.split('://')
		if (url[0] === 'https') {
			window.open('https://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&riscoId=' + risco + '&pre=1' + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
		} else {
			window.open('http://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&riscoId=' + risco + '&pre=1' + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
		}
	}

	async function handleRightArrow(id) {

		try {
			var index = -1
			atividades.forEach((item, i) => {
				if (item.id == id) {
					index = i + 1
				}
			})
			var t = ""
			atividades.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('atividadeId', item.id)
					sessionStorage.setItem('tarefa', item.nome)
					sessionStorage.setItem('tarefaDescricao', item.descricao)
					t = item.nome
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', estabelecimentoModalidade === 'Tarefa' ? 'Tarefa ' + t : 'Atividade ' + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: '5px',
		borderBottom: '1px solid #5940A2',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "white",
		padding: '5px',
		width: 'auto',
		height: 'auto',
		maxHeight: '400px',
		overflowY: 'auto',
		overflowX: 'hidden'
	});

	function onDragEnd(result) {

		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			current,
			result.source.index,
			result.destination.index
		);

		if (JSON.stringify(current) !== JSON.stringify(items)) {
			setMudanca(true)
		}

		setCurrent(items)

	}

	function onSave() {

		var diff = current.length - riscos.length
		var ris
		if(diff < 0){
			ris = [].concat(current, riscos.slice(diff))
		}else{
			ris = current
		}

		setRiscos(ris)

		try {
			for (var index = 0; index < ris.length; index++) {
				ris[index].ordem = index
			}
			for (var index = 0; index < ris.length; index++) {
				api.patch(`risco/${ris[index].id}`, {ordem: ris[index].ordem});
			}
			aviso('success', 'Nova ordem salva com sucesso')
			setMudanca(false)
		} catch (error) {
			aviso('error', error)
		}

	}

	return (
		<div>
			<Menu />
			<ReactTooltip effect="solid"></ReactTooltip>
			<Loading loading={carregando} message='Carregando...' />
			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Organização: {sessionStorage.getItem('estabelecimentoName')}<br />{estabelecimentoModalidade === 'Tarefa' ? 'Tarefa' : 'Atividade'}: {tarefa}</h4></div>
			<Riscos show={modalShow} backdrop="static" risco={risc} onHide={() => { setModalShow(false); initial(); }} />
			<div className="gr-basic-content">

				<div className="titulo">
					<h1>Riscos</h1>
				</div>

				<div className="gr-box">
					<div className="ListaPerigoRisco-Cadastrar">
						<Link to='/GerenciarAtividades'><FiChevronLeft data-tip="Voltar" className="ListaPerigoRisco-arrow" /></Link>
						{!mudanca?<Link to="/cadastrarrisco">
							<button className="ListaPerigoRisco-Botao" onClick={() => { sessionStorage.setItem("riscoId", 0); sessionStorage.setItem('riscoNovo', true) }}>Cadastrar</button>
						</Link>: <button onClick={onSave} className='gr-salvar'>Salvar ordem</button>}
						<div>
							<i><FiArrowLeftCircle data-tip={estabelecimentoModalidade === 'Tarefa' ? 'Acessar tarefa anterior' : 'Acessar atividade anterior'} onClick={() => handleLeftArrow(tarefaId)} className="ListaPerigoRisco-arrow" /></i>
							<i><FiArrowRightCircle data-tip={estabelecimentoModalidade === 'Tarefa' ? 'Acessar tarefa seguinte' : 'Acessar atividade seguinte'} onClick={() => handleRightArrow(tarefaId)} className="ListaPerigoRisco-arrow" /></i>
						</div>
					</div>
					<div className="ga-subtitlle">
						<h2>Riscos cadastrados:</h2>
						<div className="searchbox">
							<Input fluid size='small' value={keyword} icon='search' placeholder='Pesquise...' onChange={e => { setKeyword(e.target.value) }} />
						</div>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									id="scrollableDiv"
								>
									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>
										{current.length ? current.map((risco, index) => (
											<Draggable isDragDisabled={keyword === ''? false: true} key={risco.id} draggableId={risco.id.toString()} index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<ReactTooltip id={toString(risco.id)} place="top" effect="solid"></ReactTooltip>
														<div className="gr-name">
															<Link to='/cadastrarrisco' onClick={() => { sessionStorage.setItem('riscoId', risco.id); sessionStorage.removeItem('riscoNovo'); sessionStorage.setItem('riscoName', risco.nome) }}>
																<h3><div className={
																	risco.tipologia == "Acidente" ?
																		"Acidente"
																		:
																		risco.tipologia == "Ergonômico" ?
																			"Ergonomico"
																			:
																			risco.tipologia == "Químico" ?
																				"Quimico"
																				:
																				risco.tipologia == "Físico" ?
																					"Fisico"
																					:
																					"Biologico"
																} />&nbsp;&nbsp;{risco.nome}</h3>
															</Link>
														</div>
														<div className="gr-icons">
															<i className="gr-edit">
																<FiCopy data-tip="Duplicar" data-for={toString(risco.id)}
																		onClick={() => { setModalShow(true); setRisc({ id: risco.id, nome: risco.nome }) }}
																/>
															</i>
															<Link to='/cadastrarrisco' ><i className="gr-edit"> <FiEdit data-tip="Editar" data-for={toString(risco.id)} onClick={() => { sessionStorage.setItem('riscoId', risco.id); sessionStorage.removeItem('riscoNovo'); sessionStorage.setItem('riscoName', risco.nome) }} />  </i> </Link>
															<i className="gr-trash">
																<FiTrash2 data-tip="Excluir" data-for={toString(risco.id)}
																	onClick={() => {
																		const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O RISCO SELECIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão do risco ' + risco.nome + ' :')
																		if (r === "excluir") {
																			handleDeleteRisco(risco.id);
																		} else {
																			aviso("warning", "Nome incorreto, exclusão cancelada", true)
																		}
																	}} /> </i>
															<i className="gr-edit">
																<BiDetail data-tip="Baixar pré-visualização" data-for={toString(risco.id)} onClick={() => handleDoc(risco.id)} />
															</i>
														</div>
													</div>
												)}
											</Draggable>
										)) : <h3>Nenhum registro</h3>}
										{provided.placeholder}
									</InfiniteScroll>
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className='dashboard-footer'>
						<div>Riscos encontrados: {foundRiscos.length}</div>
						<Sort/>
					</div>
				</div>
			</div>
		</div>
	);
}

function Riscos(props) {

	const [estabelecimentos, setEstabelecimentos] = useState([])
	const [empresas, setEmpresas] = useState([])
	const [tarefas, setTarefas] = useState([])
	const [estabelecimentoId, setEstabelecimentoId] = useState('')
	const [empresaId, setEmpresaId] = useState('')
	const [tarefaId, setTarefaId] = useState('')
	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false)

	useEffect(() => {

		if (props.show) {
			async function iniciar() {

				try {
					setCarregando(true)
					await api.get('empresa').then(response => {
						response.data.resultado.forEach(item => {
							item.key = item.id;
							item.value = item.id;
							item.text = item.razao_social;
						})
						setEmpresas(response.data.resultado)
					})

					setEmpresaId(parseInt(sessionStorage.getItem('empresaId')))
					setEstabelecimentoId(parseInt(sessionStorage.getItem('estabelecimentoId')))
					setTarefaId(parseInt(sessionStorage.getItem('atividadeId')))
					start(parseInt(sessionStorage.getItem('empresaId')))
					start2(parseInt(sessionStorage.getItem('estabelecimentoId')))
					
				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
				}

			}
			iniciar();
		}
	}, [props.show])

	function sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	const handleChangeEstabelecimento = (e, { value }) => {
		start2(value);
		setTarefaId('')
	}

	const handleChangeEmpresa =  async (e, { value }) => {
		start(value);
		setEstabelecimentoId('');
	}

	const handleChangeTarefa =  async (e, { value }) => {
		setTarefaId(value);
	}

	async function start(value){
		setEmpresaId(value);
		try {
			setCarregando(true);
			await api.get(`estabelecimento?empresa_id=${value}`).then(response => {
				response.data.resultado.forEach(item => {
					item.key = item.id;
					item.value = item.id;
					item.text = item.nome;
				})
				setEstabelecimentos(response.data.resultado)
			});
		} catch (error) {
			aviso('error', error);
		} finally {
			setCarregando(false);
		}		
	}

	async function start2(value){
		setEstabelecimentoId(value);
		try {
			setCarregando(true);
			await api.get(`tarefa?estabelecimento_id=${value}`).then(response => {
				response.data.resultado.forEach(item => {
					item.key = item.id;
					item.value = item.id;
					item.text = item.nome;
				})
				setTarefas(response.data.resultado)
			});
		} catch (error) {
			aviso('error', error);
		} finally {
			setCarregando(false);
		}		
	}

	const DropEstabelecimentos = React.useMemo(() => (
		<Dropdown
			options={estabelecimentos}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			noResultsMessage={'Não encontrado'}
			value={estabelecimentoId}
			onChange={handleChangeEstabelecimento}
		/>
	), [estabelecimentos, estabelecimentoId]);

	const DropTarefas = React.useMemo(() => (
		<Dropdown
			options={tarefas}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			noResultsMessage={'Não encontrado'}
			value={tarefaId}
			onChange={handleChangeTarefa}
		/>
	), [tarefas, tarefaId]);

	const DropEmpresas = React.useMemo(() => (
		<Dropdown
			options={empresas}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			noResultsMessage={'Não encontrado'}
			value={empresaId}
			onChange={handleChangeEmpresa}
		/>
	), [empresas, empresaId]);

	async function handleControle() {

		try {
			setCarregando(true)
			await api.get('dRisco/' + props.risco.id).then(async response => {
				if(tarefaId === parseInt(sessionStorage.getItem('atividadeId'))){
					response.data.trabalhador = true
					await api.post('dRisco?tarefa_id=' + tarefaId, response.data)
				}else{
					response.data.trabalhador = false
					await api.post('dRisco?tarefa_id=' + tarefaId, response.data)
				}
				
			})

			if (tarefaId === parseInt(sessionStorage.getItem('atividadeId'))) {
				aviso('success', sessionStorage.getItem('estabelecimentoModalidade') === 'Tarefa' ? "Tarefa duplicada com sucesso" : "Atividade duplicada com sucesso")
			} else {
				aviso('success', sessionStorage.getItem('estabelecimentoModalidade') === 'Tarefa' ? "Tarefa duplicada com sucesso" : "Atividade duplicada com sucesso")
				await sleep(1.5 * 1000)
				aviso('info', "Redirecionando para a organização " + tarefas.filter(item => item.id === parseInt(tarefaId))[0].nome)
				sessionStorage.setItem('atividadeId', tarefaId)
				sessionStorage.setItem('tarefa', tarefas.filter(item => item.id === parseInt(tarefaId))[0].nome)
				sessionStorage.setItem('estabelecimentoId', estabelecimentoId)
				sessionStorage.setItem('estabelecimentoName', estabelecimentos.filter(item => item.id === parseInt(estabelecimentoId))[0].nome)
				sessionStorage.setItem('empresaId', empresaId)
				sessionStorage.setItem('empresaName', empresas.filter(item => item.id === parseInt(empresaId))[0].razao_social)
			}

			setCarregando(false)
		} catch (error) {
			aviso('error', error)
		}
		props.onHide()
	}


	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Duplicar atividade
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div className="qual-relatorio-gerar2">

					<div className="selecionarempresa-tarefa2">
						<label htmlFor="selecionarempresa-tarefa2">Selecione a empresa que deseja duplicar a {sessionStorage.getItem('estabelecimentoModalidade').toLowerCase()} {props.risco.nome}</label>
						{DropEmpresas}
					</div>
					{empresaId !== '' &&
						<div className="selecionarempresa-tarefa2">
							<label htmlFor="selecionarempresa-tarefa2">Selecione a organização que deseja duplicar a {sessionStorage.getItem('estabelecimentoModalidade').toLowerCase()} {props.risco.nome}</label>
							{DropEstabelecimentos}
						</div>}
						{estabelecimentoId !== '' && empresaId !== '' &&
						<div className="selecionarempresa-tarefa2">
							<label htmlFor="selecionarempresa-tarefa2">Selecione a tarefa/atividade que deseja duplicar a {sessionStorage.getItem('estabelecimentoModalidade').toLowerCase()} {props.risco.nome}</label>
							{DropTarefas}
						</div>}


				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!estabelecimentoId} onClick={handleControle}>Duplicar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}
