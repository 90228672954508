import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash2, FiArrowLeftCircle, FiChevronLeft, FiArrowRightCircle, FiCopy } from 'react-icons/fi';
import { Dropdown } from 'semantic-ui-react'
import api from '../../../services/api';
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.css';
import Loading from '../../Loading';
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Input } from 'semantic-ui-react'

import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from '../../../global.js'

export default function GerenciarAtividades() {

	const empresaId = sessionStorage.getItem('empresaId')

	const estabelecimentoId = sessionStorage.getItem('estabelecimentoId')
	const estabelecimentoName = sessionStorage.getItem('estabelecimentoName')
	const estabelecimentoModalidade = sessionStorage.getItem('estabelecimentoModalidade')
	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [mudanca, setMudanca] = useState(false)
	const [troca, setTroca] = useState(false)

	const { aviso } = React.useContext(Context);

	const [atividades, setAtividades] = useState([])
	const [carregando, setCarregando] = useState(false);
	const [modalShow, setModalShow] = useState(false)
	const [atv, setAtv] = useState('')

	const [foundAtividades, setFoundAtividades] = useState([]);
	const [keyword, setKeyword] = useState('')

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundAtividades.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundAtividades.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundAtividades.slice(count.prev + 10, count.next + 10)))
			}, 125)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}
	}

	const Sort = () => (
		<Dropdown
			text='Ordenar'
			icon='sort'
			floating
			labeled
			button
			className='icon'
		>
			<Dropdown.Menu>
				<Dropdown.Item onClick={handleAsc}>A-Z</Dropdown.Item>
				<Dropdown.Item onClick={handleDesc}>Z-A</Dropdown.Item>
				<Dropdown.Item onClick={handleInverter}>Inverter ordem atual</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	const sortArray = (obj) => {
		return obj.sort((a, b) => {
			return a.nome.localeCompare(b.nome);
		})
	}

	const handleAsc = () => {
		setAtividades(sortArray(atividades))
		lazyLoad()
		setMudanca(true)
	}

	const handleDesc = () => {
		setAtividades(sortArray(atividades).reverse())
		lazyLoad()
		setMudanca(true)
	}

	const handleInverter = () => {
		setAtividades(atividades.reverse())
		lazyLoad()
		setMudanca(true)
	}

	function lazyLoad() {
		if (keyword !== '') {
			const result = atividades.filter((item) => {
				return (item.nome.toLowerCase().includes(keyword.toLowerCase()))
			})

			setFoundAtividades(result)
			setMudanca(false)
			setHasMore(true)

			setTimeout(() => {
				setCurrent(result.slice(0, 10))
			}, 0)

		} else {
			if (foundAtividades.length !== 0) {
				setFoundAtividades(atividades)
				setTimeout(() => {
					setCurrent(atividades.slice(0, 10))
				}, 0)
				setHasMore(true)
			}
		}

		setCount({
			prev: 0,
			next: 10
		})
	}

	useEffect(() => {
		lazyLoad()
	}, [keyword])

	async function initial() {
		try {
			const response = await api.get(`tarefa?estabelecimento_id=${sessionStorage.getItem('estabelecimentoId')}`)
			setAtividades(response.data.resultado.filter(atividade => atividade.nome !== ""))
			setFoundAtividades(response.data.resultado.filter(atividade => atividade.nome !== ""))
			setHasMore(true)
			setTimeout(() => {
				setCurrent(response.data.resultado.filter(atividade => atividade.nome !== '').slice(0, 10))
			}, 0)

			response.data.resultado.forEach(atividade => {
				if (atividade.nome === '') {
					api.delete(`tarefa/${atividade.id}`)
				}
			})

			api.get(`estabelecimento?empresa_id=${empresaId}`).then(response => {
				setEstabelecimentos(response.data.resultado.filter(estabelecimento => estabelecimento.nome !== ''));
			})

		} catch (error) {
			aviso('error', error)
		} finally {
			setCarregando(false)
		}
	}


	useEffect(() => {
		setCarregando(true)
		initial();
	}, [sessionStorage.getItem('estabelecimentoId'), troca]);


	async function handleDeleteAtividade(id) {
		try {
			await api.delete(`tarefa/${id}`)
			UpdateTarefas()
			setCurrent(current.filter(tarefa => tarefa.id !== id))
			aviso('success', estabelecimentoModalidade === 'Tarefa' ? "Tarefa" : "Atividade" + " removida com sucesso")
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleLeftArrow(id) {

		try {
			var index = -1
			estabelecimentos.forEach((item, i) => {
				if (item.id == id) {
					index = i - 1
				}
			})
			var t = ""
			estabelecimentos.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('estabelecimentoId', item.id)
					sessionStorage.setItem('estabelecimentoModalidade', item.modalidade)
					sessionStorage.setItem('estabelecimentoName', item.nome)
					t = item.nome
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', "Organização " + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleRightArrow(id) {

		try {
			var index = -1
			estabelecimentos.forEach((item, i) => {
				if (item.id == id) {
					index = i + 1
				}
			})
			var t = ""
			estabelecimentos.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('estabelecimentoId', item.id)
					sessionStorage.setItem('estabelecimentoModalidade', item.modalidade)
					sessionStorage.setItem('estabelecimentoName', item.nome)
					t = item.nome
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', "Organização " + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	async function UpdateTarefas() {
		try {
			const response = await api.get(`tarefa?estabelecimento_id=${sessionStorage.getItem('estabelecimentoId')}`)
			setAtividades(response.data.resultado)
			setFoundAtividades(response.data.resultado)
		} catch (error) {
			aviso('error', error)
		}
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: '5px',
		borderBottom: '1px solid #5940A2',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "white",
		padding: '5px',
		width: 'auto',
		height: 'auto',
		maxHeight: '400px',
		overflowY: 'auto',
		overflowX: 'hidden'
	});

	function onDragEnd(result) {

		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			current,
			result.source.index,
			result.destination.index
		);

		if (JSON.stringify(current) !== JSON.stringify(items)) {
			setMudanca(true)
		}

		setCurrent(items)

	}

	function onSave() {

		var diff = current.length - atividades.length
		var atv
		if (diff < 0) {
			atv = [].concat(current, atividades.slice(diff))
		} else {
			atv = current
		}

		setAtividades(atv)

		try {
			for (var index = 0; index < atv.length; index++) {
				atv[index].ordem = index
			}
			for (var index = 0; index < atv.length; index++) {
				api.patch(`tarefa/${atv[index].id}`, atv[index]);
			}
			aviso('success', 'Nova ordem salva com sucesso')
			setMudanca(false)
		} catch (error) {
			aviso('error', error)
		}

	}

	return (
		<div>
			<ReactTooltip effect="solid" />
			<Menu />
			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Empresa: {sessionStorage.getItem('empresaName')}<br />Organização: {estabelecimentoName}</h4></div>
			<Loading loading={carregando} message='Carregando...' />
			<Tarefas show={modalShow} backdrop="static" atividade={atv} onHide={() => { setModalShow(false); initial(); }} />

			<div className="ga-basic-content">
				<div className="titulo">
					<h1>{estabelecimentoModalidade === 'Tarefa' ? "Tarefas" : "Atividades"}</h1>
				</div>
				<div className="ga-box">

					<div className="ga-cadastrar">
						<Link to='/GerenciarEstabelecimentos'><i><FiChevronLeft data-tip="Voltar" className="ListaPerigoRisco-arrow" /></i></Link>
						{!mudanca ? <Link to="/cadastraratividades" onClick={() => { sessionStorage.removeItem('atividadeId'); sessionStorage.setItem('atividadeNovo', true) }}>
							<button>Cadastrar</button>
						</Link> : <button onClick={onSave} className='ga-salvar'>Salvar ordem</button>}
						<div>
							<i><FiArrowLeftCircle data-tip="Acessar organização anterior" onClick={() => handleLeftArrow(estabelecimentoId)} /></i>
							<i><FiArrowRightCircle data-tip="Acessar organização seguinte" onClick={() => handleRightArrow(estabelecimentoId)} /></i>
						</div>
					</div>

					<div className="ga-subtitlle">
						<h2>{estabelecimentoModalidade === 'Tarefa' ? "Tarefas cadastradas:" : "Atividades cadastradas:"}</h2>
						<div className="searchbox">
							<Input fluid size='small' value={keyword} icon='search' placeholder='Pesquise...' onChange={e => { setKeyword(e.target.value) }} />
						</div>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									id="scrollableDiv"
								>
									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>
										{current.length ? current.map((atividade, index) => (
											<Draggable isDragDisabled={keyword === '' ? false : true} key={atividade.id} draggableId={atividade.id.toString()} index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<ReactTooltip id={toString(atividade.id)} place="top" effect="solid" />
														<div className="ga-name">
															<Link to='/GerenciarRiscos' onClick={() => { sessionStorage.setItem('atividadeId', atividade.id); sessionStorage.setItem('tarefa', atividade.nome); sessionStorage.setItem('tarefaDescricao', atividade.descricao); sessionStorage.removeItem('atividadeNovo') }}><h3>{atividade.nome}</h3></Link>
														</div>
														<div className="ga-icons">
															<i className="ga-edit">
																<FiCopy data-tip="Duplicar" data-for={toString(atividade.id)}
																	onClick={() => { setModalShow(true); setAtv({ id: atividade.id, nome: atividade.nome }) }}
																/>
															</i>
															<Link to='/cadastraratividades' ><i className="ga-edit"> <FiEdit data-tip="Editar" data-for={toString(atividade.id)} onClick={() => { sessionStorage.removeItem('atividadeNovo'); sessionStorage.setItem('atividadeId', atividade.id) }} />  </i> </Link>
															<i className="ga-trash">
																<FiTrash2 data-tip="Excluir" data-for={toString(atividade.id)}
																	onClick={() => {
																		const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A ' + (estabelecimentoModalidade === "Tarefa" ? "TAREFA" : "ATIVIDADE") + ' SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + atividade.nome + ' :')
																		if (r === "excluir") {
																			handleDeleteAtividade(atividade.id);
																		} else {
																			aviso("warning", "Nome incorreto, exclusão cancelada", true)
																		}
																	}} /> </i>
														</div>
													</div>
												)}
											</Draggable>
										)) : <h3>Nenhum registro</h3>}
										{provided.placeholder}
									</InfiniteScroll>
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className='dashboard-footer'>
						<div>{estabelecimentoModalidade === 'Tarefa' ? "Tarefas" : "Atividades"} encontradas: {foundAtividades.length}</div>
						<Sort />
					</div>
				</div>
			</div>
		</div>
	);
}

function Tarefas(props) {

	const [estabelecimentos, setEstabelecimentos] = useState([])
	const [empresas, setEmpresas] = useState([])
	const [estabelecimentoId, setEstabelecimentoId] = useState('')
	const [empresaId, setEmpresaId] = useState('')
	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false)

	useEffect(() => {

		if (props.show) {
			async function iniciar() {

				try {
					setCarregando(true)
					await api.get('empresa').then(response => {
						response.data.resultado.forEach(item => {
							item.key = item.id;
							item.value = item.id;
							item.text = item.razao_social;
						})
						setEmpresas(response.data.resultado)
					})
					
					start(parseInt(sessionStorage.getItem('empresaId')))
					setEstabelecimentoId(parseInt(sessionStorage.getItem('estabelecimentoId')))
				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
				}

			}
			iniciar();
		}
	}, [props.show])

	function sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	const handleChangeEstabelecimento = (e, { value }) => {
		setEstabelecimentoId(value);
	}

	async function start(value){
		setEmpresaId(value);
		try {
			setCarregando(true);
			await api.get(`estabelecimento?empresa_id=${value}`).then(response => {
				response.data.resultado.forEach(item => {
					item.key = item.id;
					item.value = item.id;
					item.text = item.nome;
				})
				setEstabelecimentos(response.data.resultado)
			});
		} catch (error) {
			aviso('error', error);
		} finally {
			setCarregando(false);
		}

			
	}

	const handleChangeEmpresa =  async (e, { value }) => {
		start(value);
		setEstabelecimentoId('');
	}

	const DropEstabelecimentos = React.useMemo(() => (
		<Dropdown
			options={estabelecimentos}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			noResultsMessage={'Não encontrado'}
			value={estabelecimentoId}
			onChange={handleChangeEstabelecimento}
		/>
	), [estabelecimentos, estabelecimentoId]);

	const DropEmpresas = React.useMemo(() => (
		<Dropdown
			options={empresas}
			placeholder={"Selecione"}
			lazyLoad={true}
			search
			selection
			fluid
			noResultsMessage={'Não encontrado'}
			value={empresaId}
			onChange={handleChangeEmpresa}
		/>
	), [empresas, empresaId]);

	async function handleControle() {

		try {
			setCarregando(true)
			await api.get('dTarefa/' + props.atividade.id).then(async response => {
				await api.post('dTarefa?empresa_id=' + empresaId + '&estabelecimento_id=' + estabelecimentoId, response.data)
			})

			if (estabelecimentoId === parseInt(sessionStorage.getItem('estabelecimentoId'))) {
				aviso('success', sessionStorage.getItem('estabelecimentoModalidade') === 'Tarefa' ? "Tarefa duplicada com sucesso" : "Atividade duplicada com sucesso")
			} else {
				aviso('success', sessionStorage.getItem('estabelecimentoModalidade') === 'Tarefa' ? "Tarefa duplicada com sucesso" : "Atividade duplicada com sucesso")
				await sleep(1.5 * 1000)
				aviso('info', "Redirecionando para a organização " + estabelecimentos.filter(item => item.id === parseInt(estabelecimentoId))[0].nome)
				sessionStorage.setItem('estabelecimentoId', estabelecimentoId)
				sessionStorage.setItem('estabelecimentoName', estabelecimentos.filter(item => item.id === parseInt(estabelecimentoId))[0].nome)
				sessionStorage.setItem('empresaId', empresaId)
				sessionStorage.setItem('empresaName', empresas.filter(item => item.id === parseInt(empresaId))[0].razao_social)
			}

			setCarregando(false)
		} catch (error) {
			aviso('error', error)
		}
		props.onHide()
	}


	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Duplicar atividade
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div className="qual-relatorio-gerar2">

					<div className="selecionarempresa-tarefa2">
						<label htmlFor="selecionarempresa-tarefa2">Selecione a empresa que deseja duplicar a {sessionStorage.getItem('estabelecimentoModalidade').toLowerCase()} {props.atividade.nome}</label>
						{DropEmpresas}
					</div>
					{empresaId !== '' &&
						<div className="selecionarempresa-tarefa2">
							<label htmlFor="selecionarempresa-tarefa2">Selecione a organização que deseja duplicar a {sessionStorage.getItem('estabelecimentoModalidade').toLowerCase()} {props.atividade.nome}</label>
							{DropEstabelecimentos}
						</div>}


				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!estabelecimentoId} onClick={handleControle}>Duplicar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}