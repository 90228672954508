import React, { useState, useContext, useRef, useEffect } from 'react';
import { FaUser, FaCog, FaChevronRight, FaSlidersH } from 'react-icons/fa';
import { FiLogOut, FiArrowRight } from "react-icons/fi";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { IoIosHelpCircle } from "react-icons/io";
import { Link } from 'react-router-dom'; 
import { Context } from '../../Context/AuthContext';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../pages/Loading';
import { useHistory } from 'react-router-dom';
import './styles.css';
import GerenciarWorkspace from '../workspace'



const Sidebar = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggleDropdown = () => { setDropdownOpen(!dropdownOpen); setDropdownOpen2(false) };
    const toggleDropdown2 = () => { setDropdownOpen2(!dropdownOpen2); setDropdownOpen(false) };
    const toggleDropdown3 = () => { setDropdownOpen3(!dropdownOpen2); setDropdownOpen(false); setDropdownOpen2(false) };
    const [modalShow5, setModalShow5] = useState(false);
    const [modalShow4, setModalShow4] = useState(false);
    const history = useHistory();
    const sidebarRef = useRef(); // Ref para o elemento do sidebar

    const { visible, handleClose } = props
    const { Admin, FullLicensed, Licensed, Train, Aud, handleLogout, workspaces , BuyLicense, Observer } = useContext(Context);

    function fazerLogout() {
        handleLogout();
        history.push('/login')
    }

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`sidebar ${dropdownOpen ? 'show' : dropdownOpen2 ? 'show2' : dropdownOpen3? 'show3' : ''}`} style={{ width: visible ? '20%' : '0px', marginRight: visible ? '0px' : '-200%' }} ref={sidebarRef}>
            <Form show={modalShow5} onHide={() => setModalShow5(false)} />
            <Workspace buylicense={BuyLicense.toString()} show={modalShow4} workspaces={workspaces} onHide={() => setModalShow4(false)} />
            {visible && (
                <>
                    <div className="back-icon-user" >
                        <i onClick={handleClose}><FiArrowRight /></i>
                    </div>
                    {(Licensed || Admin || FullLicensed || Train) && (
                        <div className="sidebar-workspace">
                            Sua área de trabalho atual:
                            <div className="sidebar-workspace-atual" onClick={() => { setModalShow4(Licensed || Admin || FullLicensed || Train ? true : false); handleClose() }}>
                                <span style={{paddingRight: '10px'}}>{workspaces.filter(item => item.id === parseInt(sessionStorage.getItem('workspaceId')))[0]?.name}</span>
                                <HiOutlineSwitchHorizontal style={{fontSize: '27px'}}/>
                            </div>
                        </div>
                    )}

                    {Admin && (
                        <div className="drop-toggle" onClick={toggleDropdown}>
                            <div className="sidebar-item">
                                <FaCog />
                                <span style={{ paddingLeft: '5px' }}> Administrador </span>
                                <FaChevronRight className={`arrow ${dropdownOpen ? 'down' : ''}`} />
                            </div>
                        </div>)
                    }

                    <div className={"drop-menu"}>
                        {Admin && (
                            <Link to='/GestaoUsuarios' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="sidebar-item">
                                    Gestão de licença
                                </div>
                            </Link>)
                        }

                        {Admin && (
                            <Link to='/GestaoAuditoria' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="sidebar-item">
                                    Gestão de auditorias
                                </div>
                            </Link>)}
                    </div>

                    {(Licensed || Admin || FullLicensed) && (
                        <Link to='/licencaativa' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="sidebar-item">
                                <FaUser /><span style={{ paddingLeft: '5px' }}>Meus Dados</span>
                            </div>
                        </Link>
                    )}


                    {FullLicensed && (
                        <Link to="/LaudoConfig" disabled={!Licensed && !Admin && !FullLicensed} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="sidebar-item">
                                <FaSlidersH /><span style={{ paddingLeft: '5px' }}>Configurações do laudo</span>
                            </div>
                        </Link>
                    )}

                    {((Licensed || Admin || FullLicensed) && !Train && !Aud) && (
                        <div className="drop-toggle" onClick={toggleDropdown2}>
                            <div className="sidebar-item">
                                <IoIosHelpCircle />
                                <span style={{ paddingLeft: '5px' }}> Ajuda </span>
                                <FaChevronRight className={`arrow ${dropdownOpen2 ? 'down' : ''}`} />
                            </div>
                        </div>
                    )}

                    <div className={"drop-menu2"}>
                        {((Licensed || Admin || FullLicensed) && !Train && !Aud) && (
                            <div onClick={() => { setModalShow5(Licensed || Admin || FullLicensed ? true : false); handleClose() }} className="sidebar-item">
                                Formulários
                            </div>
                        )}

                        {((Licensed || Admin || FullLicensed) && !Train && !Aud && !Observer) && (
                            <Link to='/videos' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="sidebar-item">
                                    Vídeos
                                </div>
                            </Link>
                        )}
                    </div>


                    <div onClick={fazerLogout} className="sidebar-item">
                        <FiLogOut /> <span style={{ paddingLeft: '5px' }}>Sair</span>
                    </div>

                </>
            )}
        </div>
    );
};

function Form(props) {

    const [carregando, setCarregando] = useState(false)
    const { aviso } = React.useContext(Context);
    const options = ['Todos', 'Carta de apresentação e instrução do PGR SMART', 'Cartão de atividades FO03', 'Formulário do chefe do setor FO01', 'Formulario do trabalhador FO02',
        'Modelo de Formulário de Cenário de Emergência', 'Modelo de Formulário de Investigação de Acidente', 'Fluxo de Acidente', 'Modelo de Formulário de Inspeção',
        'Sugestão de Indicadores']
    const [op, SetOp] = useState('')

    const host = require('../../config.json')

    async function handleControle() {
        setCarregando(true)
        try {

            var url = window.location.href.split('://')
            if (url[0] === 'https') {
                window.open('https://' + host.api + '/v1/download/' + op + '?token=' + localStorage.getItem('@jmv-token-key'), '_blank')
            } else {
                window.open('http://' + host.api + '/v1/download/' + op + '?token=' + localStorage.getItem('@jmv-token-key'), '_blank')
            }

            aviso('success', 'Download efetuado com sucesso')
            props.onHide()
        } catch (error) {
            aviso('error', error)

        } finally {
            setCarregando(false)
        }
    }

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Organizações
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="qual-relatorio-gerar2">
                    <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecione o formulário que deseja baixar</label>
                        <select value={op} onChange={e => SetOp(e.target.value)}>
                            <option value='' disabled>Selecione</option>
                            {options.map((item, index) => (
                                <option key={index} value={index} >{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={op == ''} onClick={handleControle} >Download</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function Workspace(props) {

    const history = useHistory();

    const [selectedRow, setSelectedRow] = useState(sessionStorage.getItem('workspaceId'))

    const handleChangeWorkspace= () => {
        sessionStorage.setItem('workspaceId', selectedRow)
        window.open("/gerenciarempresas","_self")
    }

    const handleSwitch = (id) => {
        setSelectedRow(id)
    }

    return (

        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Áreas de trabalho
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GerenciarWorkspace selectedRow={selectedRow} handleSwitch={handleSwitch}/>
            </Modal.Body>
            <Modal.Footer>
                {selectedRow !== parseInt(sessionStorage.getItem('workspaceId')) && (<Button onClick={handleChangeWorkspace}>Acessar</Button>)}
                {props.buylicense === "true" && (<Button onClick={() => history.push('/gestaoWorkspace')}>Gerenciar áreas de trabalho</Button>)}
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Sidebar;
