import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiTrash2, FiChevronLeft, FiCheckCircle } from 'react-icons/fi'
import { Dropdown } from 'semantic-ui-react'
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';


import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { GestaoRespostas } from '../Resposta/index.js'
import { GestaoCapitulos } from '../Capitulo/index.js'

export function GestaoTitulos(props) {

    const { aviso } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)
    const [titulos, setTitulos] = useState([])
    const [respostaShow, setRespostaShow] = useState(false)
    const [capitulos, setCapitulos] = useState([])
    const [modalShow, setModalShow] = useState(false)

    async function inicial() {

        setCarregando(true)

        try {

            await Promise.all([
                api.get(`aud_titulo?auditoria_id=${sessionStorage.getItem('auditoriaId')}&all=true`),
            ]).then(response => {


                // Objeto para armazenar os pares únicos de cap_id e cap_nome
                const caps = [];

                // Mapeia os dados para construir o array de forma agrupada
                response[0].data.resultado.forEach(item => {
                    // Verifica se o cap_id já existe no array
                    const existe = caps.some(par => par.cap_id === item.cap_id);
                    // Se não existe, adiciona o par único
                    if (!existe) {
                        caps.push({ cap_id: item.cap_id, cap_nome: item.cap_nome });
                    }
                });

                caps.sort((a, b) => a.cap_nome.localeCompare(b.cap_nome));

                setCapitulos(caps)
                setTitulos(response[0].data.resultado)
            })

        } catch (error) {
            console.log(error)
        } finally {
            setCarregando(false)
        }
    }

    useEffect(() => {
        if (props.update)
            inicial()

    }, [props.update])


    async function handleDeleteTitulo(titulo) {
        setCarregando(true)
        try {
            await api.delete('aud_titulo/' + titulo);
            inicial()
            aviso('success', 'Titulo removido com sucesso')
        } catch (error) {
            console.log(error)
        } finally {
            setCarregando(false)
        }
    }

    return (
        <div>
            {respostaShow ?
                <GestaoRespostas update={respostaShow}
                    onHide={() => { sessionStorage.removeItem('tituloId'); setRespostaShow(false); sessionStorage.removeItem('perguntaTituloId'); sessionStorage.removeItem('tituloNome'); inicial() }}></GestaoRespostas>
                :
                <div>
                    <GestaoCapitulos show={modalShow} onHide={() => { setModalShow(false); inicial() }} />
                    <div>
                        <ReactTooltip id="valor" place="top" effect="solid" />
                        <div className='breadcumb'>
                            <label onClick={() => { props.onHide() }}>Auditoria {sessionStorage.getItem('auditoriaNumeracao')} - {sessionStorage.getItem('auditoriaEmpresaNome')} &nbsp;</label> /
                        </div>
                        <div className="aud-titulo">
                            <i>
                                <FiChevronLeft data-tip={"Voltar"} data-for='voltar'
                                    className="go-edit2"
                                    style={{ margin: '0px 0px 10px 0px' }}
                                    onClick={() => {
                                        props.onHide()
                                    }} />
                            </i>
                            <label style={{ fontSize: '20px' }}>Questionário</label>
                            <div>
                                <div className="reavaliacao-button" style={{ padding: '0px 0px 0px 10px' }}>
                                    <button onClick={() => { setModalShow(true) }}>Gerenciar capítulos e títulos</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    {capitulos.length > 0 ? capitulos.map(capitulo => (
                        <div key={capitulo.cap_id} style={{ marginTop: '15px' }} className="ListAud">
                            <h3 className="auditoria-nome">Capitulo: {capitulo.cap_nome}</h3>
                            <Grid container spacing={2}>
                                {titulos.filter(a => a.cap_id === capitulo.cap_id).map(item => (
                                    <Grid item xs={12} sm={6} md={4} key={item.titulo_id}>
                                        <div style={{backgroundColor:'white', marginTop: '15px'}} key={item.titulo_id} className="ListaTrab-tarefa2">
                                            <ReactTooltip id={toString(item.titulo_id)} place="top" effect="solid" />
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.concluido === 1 && (
                                                    <i>
                                                        <FiCheckCircle data-tip={"Auditoria realizada"} data-for={toString(item.titulo_id)} className="go-edit2" style={{color:'#21dc21'}} />
                                                    </i>
                                                )}
                                                <h3 className="auditoria-nome" onClick={() => {
                                                    sessionStorage.setItem('tituloId', item.titulo_id)
                                                    sessionStorage.setItem('perguntaTituloId', item.fk_titulo_id)
                                                    sessionStorage.setItem('tituloNome', item.nome)
                                                    sessionStorage.setItem('capituloNome', item.cap_nome)
                                                    setRespostaShow(true)
                                                }}>Título: {item.nome}</h3>
                                            </div>
                                            <div className="ListaTrab-icones2">
                                                <i>
                                                    <FiTrash2 data-tip={"Excluir"} data-for={toString(item.titulo_id)}
                                                        className="go-trash2"
                                                        onClick={() => {
                                                            const r = window.confirm('Tem certeza que deseja deletar esse título?')
                                                            if (r === true) {
                                                                handleDeleteTitulo(item.titulo_id)
                                                            }
                                                        }} /> </i>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>

                        </div>
                    )) : carregando ? null : <h4>Nenhum registro encontrado</h4>}

                    <Loading loading={carregando} message='Carregando...' />
                    {titulos.filter(item => item.concluido === 1).length > 0 && (
                        <div className="reavaliacao-button">
                            <button style={{ margin: '20px' }} onClick={() => { props.onHide() }}>Concluir</button>
                        </div>
                    )}

                </div>

            }
        </div>
    );
}
