import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash2, FiArrowLeftCircle, FiChevronLeft, FiArrowRightCircle, FiCopy } from 'react-icons/fi';
import api from '../../../services/api'
import { Modal, Button } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroll-component';

import { Input } from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.


import { Menu } from '../../../global.js'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';


export default function GerenciarEstabelecimentos() {

	const empresaId = sessionStorage.getItem('empresaId')
	const empresaName = sessionStorage.getItem('empresaName')

	const [empresas, setEmpresas] = useState([])
	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [foundEstabelecimentos, setFoundEstabelecimentos] = useState([]);
	const [carregando, setCarregando] = useState(false)
	const [troca, setTroca] = useState(false)
	const { aviso } = React.useContext(Context);

	const [modalShow, setModalShow] = useState(false)
	const [estab, setEstab] = useState('')
	const [mudanca, setMudanca] = useState(false)
	const [keyword, setKeyword] = useState('')
	const history = useHistory();

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundEstabelecimentos.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundEstabelecimentos.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundEstabelecimentos.slice(count.prev + 10, count.next + 10)))
			}, 125)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}
	}

	const Sort = () => (
		<Dropdown
			text='Ordenar'
			icon='sort'
			floating
			labeled
			button
			className='icon'
		>
		  <Dropdown.Menu>
			<Dropdown.Item onClick={handleAsc}>A-Z</Dropdown.Item>
			<Dropdown.Item onClick={handleDesc}>Z-A</Dropdown.Item>
			<Dropdown.Item onClick={handleInverter}>Inverter ordem atual</Dropdown.Item>
		  </Dropdown.Menu>
		</Dropdown>
	);

	const sortArray = (obj) => {
		return obj.sort( (a,b) => {
			return a.nome.localeCompare(b.nome);
		})
	}

	const handleAsc = ( ) => {
		setEstabelecimentos(sortArray(estabelecimentos))
		lazyLoad()
		setMudanca(true)
	}

	const handleDesc = ( ) => {
		setEstabelecimentos(sortArray(estabelecimentos).reverse())
		lazyLoad()
		setMudanca(true)
	}

	const handleInverter = ( )=> {
		setEstabelecimentos(estabelecimentos.reverse())
		lazyLoad()
		setMudanca(true)
	}

	function lazyLoad() {

		if(keyword !== ''){
			const result = estabelecimentos.filter((item) => {
				return (item.nome.toLowerCase().includes(keyword.toLowerCase()))
			})

			setFoundEstabelecimentos(result)
			setMudanca(false)
			
			setTimeout(() => {
				setCurrent(result.slice(0, 10))
			}, 0)

			setHasMore(true)

		}else{
			if(foundEstabelecimentos.length !== 0){
				setFoundEstabelecimentos(estabelecimentos)
				setTimeout(() => {
					setCurrent(estabelecimentos.slice(0, 10))
				}, 0)
				setHasMore(true)
			}
		}

		setCount({
			prev: 0,
			next: 10
		})
	}

	useEffect(() => {
		lazyLoad()
	},[keyword])

	async function inicial() {
		try {
			setCarregando(true)
			const response = await api.get(`estabelecimento?empresa_id=${sessionStorage.getItem('empresaId')}`)
			setEstabelecimentos(response.data.resultado.filter(estabelecimento => estabelecimento.nome !== ''));

			setFoundEstabelecimentos(response.data.resultado.filter(estabelecimento => estabelecimento.nome !== ''));
			setHasMore(true)
			setTimeout(() => {
				setCurrent(response.data.resultado.filter(estabelecimento => estabelecimento.nome !== '').slice(0, 10))
			}, 0)

			response.data.resultado.forEach(estabelecimento => {
				if (estabelecimento.nome == '') {
					api.delete(`estabelecimento/${estabelecimento.id}`)
				}
			})

			api.get('empresa').then(response => {
				setEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
			})

		} catch (error) {

			aviso('error', error)

		} finally {
			setCarregando(false)
		}

	} 

	useEffect(() => {

	inicial()

	}, [sessionStorage.getItem('empresaId'), troca]);

	async function handleDeleteEstabelecimento(id) {
		try {
			setCarregando(true)
			await api.delete(`estabelecimento/${id}`)
			UpdateEstabelecimentos()
			setCurrent(current.filter(estabelecimento => estabelecimento.id !== id));
			aviso('success', 'Estabelecimento removido com sucesso')
		} catch (error) {
			aviso('error', error)
		} finally {
			setCarregando(false)
		}
	}

	async function handleLeftArrow(id) {

		try {
			var index = -1
			empresas.forEach((item, i) => {
				if (item.id == id) {
					index = i - 1
				}
			})
			var t = ""
			empresas.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('empresaId', item.id)
					sessionStorage.setItem('empresaName', item.razao_social)
					t = item.razao_social
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', "Empresa " + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleRightArrow(id) {

		try {
			var index = -1
			empresas.forEach((item, i) => {
				if (item.id == id) {
					index = i + 1
				}
			})
			var t = ""
			empresas.forEach((item, i) => {
				if (index != -1 && index == i) {
					sessionStorage.setItem('empresaId', item.id)
					sessionStorage.setItem('empresaName', item.razao_social)
					t = item.razao_social
					setTroca(!troca)
				}
			})
			if (t != "") {
				aviso('info', "Empresa " + t)
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	async function UpdateEstabelecimentos() {
		try {
			await api.get(`estabelecimento?empresa_id=${sessionStorage.getItem('empresaId')}`).then(response => {
				setEstabelecimentos(response.data.resultado);
				setFoundEstabelecimentos(response.data.resultado);
			})
		} catch (error) {
			aviso('error', error)
		}
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: '5px',
		borderBottom: '1px solid #5940A2',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "white",
		padding: '5px',
		width: 'auto',
		height: 'auto',
		maxHeight: '400px',
		overflowY: 'auto',
		overflowX: 'hidden'
	});

	function onDragEnd(result) {

		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			current,
			result.source.index,
			result.destination.index
		);

		if(JSON.stringify(current) !== JSON.stringify(items)){
			setMudanca(true)
		}

		setCurrent(items)

	}

	function onSave() {

		var diff = current.length - estabelecimentos.length
		var est
		if(diff < 0){
			est = [].concat(current, estabelecimentos.slice(diff))
		}else{
			est = current
		}

		setEstabelecimentos(est)

		try {
			for(var index=0; index<est.length; index++){
				est[index].ordem = index
				est[index].reorder = 'true'	
			}
			for(var index=0; index<est.length; index++){
				api.patch(`estabelecimento/${est[index].id}`, est[index]);
			}
			aviso('success', 'Nova ordem salva com sucesso')
			setMudanca(false)
		} catch (error) {
			aviso('error', error)
		} 
		
	}

	return (
		<div>
			<ReactTooltip effect="solid" />
			<Estabelecimentos show={modalShow} backdrop="static" estabelecimento={estab} onHide={() => { setModalShow(false); inicial();}} />
			<Menu />
			<Loading loading={carregando} message='Carregando...' />
			<div className="ident"><h4>Empresa: {empresaName}</h4></div>
			<div className="ges-basic-content">

				<div className="titulo">
					<h1 >Organizações</h1>
				</div>
				<div className="ges-box">

					<div className="ges-cadastrar">
						<Link to='/GerenciarEmpresas'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
						{!mudanca?<Link to="/cadastrarestabelecimentos" onClick={() => { sessionStorage.removeItem('estabelecimentoId'); sessionStorage.setItem('estabelecimentoNovo', true)}}>
							<button>Cadastrar</button>
						</Link>:<button onClick={onSave} className='ges-salvar'>Salvar ordem</button>}
						<div>
							<i><FiArrowLeftCircle data-tip="Acessar empresa anterior" onClick={() => handleLeftArrow(empresaId)} /></i>
							<i><FiArrowRightCircle data-tip="Acessar empresa seguinte" onClick={() => handleRightArrow(empresaId)} /></i>
						</div>
					</div>

					<div className="ges-subtitle">
						<h2>Organizações cadastradas:</h2>
						<div className="searchbox">
							<Input fluid size='small' value={keyword} icon='search' placeholder='Pesquise...' onChange={e => { setKeyword(e.target.value) }} />
						</div>
					</div>

					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									id="scrollableDiv"
								>

									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>
										{current.length ? current.map((estabelecimento, index) => (
											<Draggable key={estabelecimento.id} draggableId={estabelecimento.id.toString()} index={index} isDragDisabled={keyword === ''? false: true}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<ReactTooltip id={toString(estabelecimento.id)} place="top" effect="solid" />
														<div className="ges-name">
															<Link to='/gerenciaratividades' onClick={() => { sessionStorage.setItem('estabelecimentoId', estabelecimento.id); sessionStorage.setItem('estabelecimentoModalidade', estabelecimento.modalidade); sessionStorage.setItem('estabelecimentoName', estabelecimento.nome) }}><h3>{estabelecimento.nome}</h3></Link>
														</div>
														<div className="ges-icons">
															<i className="ges-edit">
																<FiCopy data-tip="Duplicar" data-for={toString(estabelecimento.id)}
																	onClick={() => { setModalShow(true); setEstab({ id: estabelecimento.id, nome: estabelecimento.nome }) }}
																/>
															</i>
															<Link to='/cadastrarestabelecimentos' ><i className="ges-edit"> <FiEdit data-tip="Editar" data-for={toString(estabelecimento.id)} onClick={() => {sessionStorage.removeItem('estabelecimentoNovo'); sessionStorage.setItem('estabelecimentoId', estabelecimento.id) }} />  </i> </Link>
															<i className="ges-trash">
																<FiTrash2 data-tip="Excluir" data-for={toString(estabelecimento.id)}
																	onClick={() => {
																		const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A ORGANIZAÇÃO SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + estabelecimento.nome + ' :')
																		if (r === "excluir") {
																			handleDeleteEstabelecimento(estabelecimento.id);
																		} else {
																			aviso("warning", "Nome incorreto, exclusão cancelada", true)
																		}
																	}} /> </i>
														</div>
													</div>
												)}
											</Draggable>

										)) : <h3>Nenhum registro</h3>}
										{provided.placeholder}
									</InfiniteScroll>
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<div className='dashboard-footer'>
						<div>Organizações encontradas: {foundEstabelecimentos.length}</div>
						<Sort/>
					</div>
				</div>
			</div>
		</div>
	);
}

function Estabelecimentos(props) {

	const [empresas, setEmpresas] = useState([])
	const [empresaId, setEmpresaId] = useState('')
	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false)

	useEffect(() => {

		if (props.show) {
			async function iniciar() {

				try {
					setCarregando(true)
					await api.get('empresa').then(response => {
						setEmpresas(response.data.resultado)
					})
				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
				}

			} iniciar();
		}
	}, [props.show])

	function sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}


	async function handleControle() {

		try {
			setCarregando(true)
			await api.get('dEstabelecimento/' + props.estabelecimento.id).then(async response => {
				await api.post('dEstabelecimento?empresa_id=' + empresaId, response.data)
			})

			if (empresaId === sessionStorage.getItem('empresaId')) {
				aviso('success', "Organização duplicada com sucesso")
			} else {
				aviso('success', "Organização duplicada com sucesso")
				await sleep(1.5 * 1000)
				aviso('info', 'Redirecionando para a empresa ' + empresas.filter(item => item.id === parseInt(empresaId))[0].razao_social)
				sessionStorage.setItem('empresaId', empresaId)
				sessionStorage.setItem('empresaName', empresas.filter(item => item.id === parseInt(empresaId))[0].razao_social)
			}

			setCarregando(false)

		} catch (error) {
			console.log(error)
			aviso('error', error)
		}

		props.onHide()
	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Duplicar organização
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div className="qual-relatorio-gerar2">

					<div className="selecionarempresa-tarefa2">
						<label htmlFor="selecionarempresa-tarefa2">Selecione a empresa que deseja duplicar a organização {props.estabelecimento.nome}</label>
						<select value={empresaId} onChange={e => setEmpresaId(e.target.value)}>
							<option value="" disabled>Selecione</option>
							{empresas.map(empresa => (
								<option key={empresa.id} value={empresa.id} >{empresa.razao_social}</option>
							))}
						</select>
					</div>

				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!empresaId} onClick={handleControle}>Duplicar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}