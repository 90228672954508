import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../services/api'
import Loading from '../Loading'
import { Context } from '../../Context/AuthContext';
import { Dropdown } from 'semantic-ui-react'


import './styles.css';
import '../../global.css';

import { Menu } from '../../global.js'


export default function GerarRelatorio() {

  const [empresas, setEmpresas] = useState([])
  const [empresaId, setEmpresaId] = useState('')
  const { aviso, FullLicensed, Train, Aud } = React.useContext(Context);

  const [estabelecimentos, setEstabelecimentos] = useState([])
  const [estabelecimentoId, setEstabelecimentoId] = useState('')

  const [tarefas, setTarefas] = useState([])
  const [tarefaId, setTarefaId] = useState('')

  const [riscos, setRiscos] = useState([])
  const [riscoId, setRiscoId] = useState('')

  const [textosIntroducao, setTextosIntroducao] = useState([])
  const [textosAvaliacao, setTextosAvaliacao] = useState([])

  const [textoIntroducao, setTextoIntroducao] = useState('')
  const [textoAvaliacao, setTextoAvaliacao] = useState('')

  const [carregando, setCarregando] = useState(false)
  const [habilita, setHabilita] = useState(false)

  const [tipos, setTipos] = useState([])
  const [tipo, setTipo] = useState("")

  const host = require('../../config.json')

  const DropTipos = React.useMemo(() =>
  (
    <Dropdown
      options={tipos}
      placeholder="Selecione"
      selection
      fluid
      value={tipo}
      onChange={(e, { value }) => setTipo(value)}
    />
  ), [tipos, tipo])

  const DropIntroducao = React.useMemo(() =>
  (
    <Dropdown
      options={textosIntroducao}
      placeholder="Selecione"
      selection
      fluid
      value={textoIntroducao}
      onChange={(e, { value }) => setTextoIntroducao(value)}
    />
  ), [textoIntroducao, textosIntroducao])

  const DropAvaliacao = React.useMemo(() =>
  (
    <Dropdown
      options={textosAvaliacao}
      placeholder="Selecione"
      selection
      fluid
      value={textoAvaliacao}
      onChange={(e, { value }) => setTextoAvaliacao(value)}
    />
  ), [textoAvaliacao, textosAvaliacao])

  const DropRisco = React.useMemo(() =>
  (
    <Dropdown
      options={riscos}
      placeholder="Selecione"
      selection
      fluid
      search
      value={riscoId}
      onChange={(e, { value }) => setRiscoId(value)}
    />
  ), [riscos, riscoId])

  const DropTarefa = React.useMemo(() =>
  (
    <Dropdown
      options={tarefas}
      placeholder="Selecione"
      selection
      fluid
      search
      value={tarefaId}
      onChange={(e, { value }) => setTarefaId(value)}
    />
  ), [tarefas, tarefaId])

  const DropEstab = React.useMemo(() =>
  (
    <Dropdown
      options={estabelecimentos}
      placeholder="Selecione"
      selection
      fluid
      search
      value={estabelecimentoId}
      onChange={(e, { value }) => setEstabelecimentoId(value)}
    />
  ), [estabelecimentos, estabelecimentoId])

  const DropEmpresa = React.useMemo(() =>
  (
    <Dropdown
      options={empresas}
      placeholder="Selecione"
      selection
      fluid
      search
      value={empresaId}
      onChange={(e, { value }) => setEmpresaId(value)}
    />
  ), [empresas, empresaId])

  useEffect(() => {

    async function iniciar() {

      try {
        setCarregando(true)

        let options_tipo = []
        if(Aud){
          options_tipo.push({ key: '1', value: 'auditoria', text: 'Auditoria' })
        }else{
          options_tipo.push({ key: '1', value: 'pgr', text: 'PGR' })
          if (FullLicensed) {
            options_tipo.push({ key: '2', value: 'pcmso', text: 'PCMSO' })
          }
          options_tipo.push({ key: '3', value: 'os', text: 'Ordem de serviço' })
          options_tipo.push({ key: '4', value: 'laudo', text: 'Laudo' })
          if (FullLicensed) {
            options_tipo.push({ key: '5', value: 'auditoria', text: 'Auditoria' })
          }
        }
        setTipos(options_tipo)

        await api.get('empresa').then(response => {
          if (Train) {
            setEmpresas([response.data.resultado[0].map(({ id, razao_social }) => ({ key: id, text: razao_social, value: id }))])
          } else {
            setEmpresas(response.data.resultado.map(({ id, razao_social }) => ({ key: id, text: razao_social, value: id })))
          }
        })
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
      }

    }
    iniciar();

  }, []);



  useEffect(() => {

    async function estab() {
      setEstabelecimentoId('')
      setTarefaId('')
      setRiscoId('')

      try {
        setCarregando(true)
        if (empresaId !== '' && tipo !== 'auditoria') {
          await api.get(`estabelecimento?empresa_id=${empresaId}`).then(response => {
            if (Train) {
              setEstabelecimentos([response.data.resultado[0].map(({ id, nome }) => ({ key: id, value: id, text: nome }))])
            } else {
              let array = []
              array.push({ key: 0, value: 'all', text: 'Todos' })
              array = array.concat(response.data.resultado.map(({ id, nome }) => ({ key: id, value: id, text: nome })))
              setEstabelecimentos(array)
            }
          })
        }
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
      }

    }
    estab();

  }, [empresaId]);

  useEffect(() => {
    setEstabelecimentoId('')
    setEmpresaId('')
    setTarefaId('')
    setRiscoId('')
  }, [tipo]);

  useEffect(() => {

    async function estab() {
      setTarefaId('')
      setRiscoId('')
      try {
        setCarregando(true)
        if (estabelecimentoId !== '' && estabelecimentoId !== "all") {
          await api.get(`tarefa?estabelecimento_id=${estabelecimentoId}`).then(response => {
            if (Train) {
              setTarefas([response.data.resultado[0].map(({ id, nome }) => ({ key: id, value: id, text: nome }))])
            } else {
              let array = []
              array.push({ key: 0, value: 'all', text: 'Todos' })
              array = array.concat(response.data.resultado.map(({ id, nome }) => ({ key: id, value: id, text: nome })))
              setTarefas(array)
            }
          })
        } else {
          setTarefas([])
          setTarefaId('')
        }
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
      }

    }
    estab();

  }, [estabelecimentoId]);

  useEffect(() => {

    async function estab() {
      setRiscoId('')
      try {
        setCarregando(true)
        if (tarefaId !== '' && tarefaId !== "all") {
          await api.get(`laudo?tarefa_id=${tarefaId}`).then(response => {
            if (Train) {
              setRiscos([response.data.resultado[0].map(({ id, risco, fk_riscos_id}) => ({ key: id, value: fk_riscos_id, text: risco}))])
            } else {
              let array = []
              array.push({ key: 0, value: 'all', text: 'Todos' })
              array = array.concat(response.data.resultado.map(({ id, risco, fk_riscos_id}) => ({ key: id, value: fk_riscos_id, text: risco})))
              setRiscos(array)
            }
          })
        } else {
          setRiscos([])
          setRiscoId('')
        }
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
      }

    }
    estab();

  }, [tarefaId]);

  useEffect(() => {

    async function estab() {
      try {
        setCarregando(true)
          const res = await api.get(`sugestoes/14`)
          let array_introducao = []
          res.data.resultado.filter(item => item.tipo === 'introducao').forEach(item => {
            array_introducao.push({ key: item.id, text: item.valor, value: item.valor })
          })
          let array_avaliacao = []
          res.data.resultado.filter(item => item.tipo === 'avaliacao').forEach(item => {
            array_avaliacao.push({ key: item.id, text: item.valor, value: item.valor })
          })

          setTextosIntroducao(array_introducao)
          setTextosAvaliacao(array_avaliacao)
      } catch (error) {
        aviso('error', error)
      } finally {
        setCarregando(false)
      }

    }
    estab();

  }, []);

  const gerar = async (e) => {

    var laudovalido = true
    try {
      setCarregando(true)
      var url = window.location.href.split('://')
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');

      if (tipo === 'laudo') {

        const options = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ textoIntroducao, textoAvaliacao, token: localStorage.getItem('@jmv-token-key'), tarefaId, riscoId, empresaId, estabelecimentoId, workspace: sessionStorage.getItem('workspaceId') })
        };

        let link
        if (url[0] === 'https') {
          link = 'https://' + host.api + '/v1/doc3'
        } else {
          link = 'http://' + host.api + '/v1/doc3'
        }


        setCarregando(true)
        await fetch(link, options)
          .then(res =>{
            if (!res.ok){
              laudovalido = false
              throw new Error('Error: ' + res.status);
            } 
            return res.blob();
          }).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url
            a.download = "Laudo";
            document.body.appendChild(a);
            a.click();
            a.remove();
          });

        setCarregando(false)
      } else {

        if(tipo === 'auditoria'){

          const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ token: localStorage.getItem('@jmv-token-key'), empresaId})
          };
  
          let link
          if (url[0] === 'https') {
            link = 'https://' + host.api + '/v1/doc4'
          } else {
            link = 'http://' + host.api + '/v1/doc4'
          }
  
  
          setCarregando(true)
          await fetch(link, options)
            .then(res =>{
              if (!res.ok){
                laudovalido = false
                throw new Error('Error: ' + res.status);
              } 
              return res.blob();
            }).then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url
              a.download = "GRO";
              document.body.appendChild(a);
              a.click();
              a.remove();
            });
            
          setCarregando(false)

        }else{
        if (empresaId !== '' && estabelecimentoId !== 0) {
          if (tarefaId !== "") {
            if (url[0] === 'https') {
              if (tipo !== "os") {
                window.open('https://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              } else {
                window.open('https://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&os=sim&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              }
            } else {
              if (tipo !== "os") {
                window.open('http://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              } else {
                window.open('http://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&tarefaId=' + tarefaId + '&os=sim&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              }
            }

          } else {
            if (tipo === 'pgr' || tipo === 'os') {
              if (url[0] === 'https') {
                if (tipo === 'os') {
                  window.open('https://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&os=sim&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
                } else {
                  window.open('https://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
                }
              } else {
                if (tipo === 'os') {
                  window.open('http://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&os=sim&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
                } else {
                  window.open('http://' + host.api + '/v1/doc?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
                }
              }
            } else {
              if (url[0] === 'https') {
                window.open('https://' + host.api + '/v1/doc2?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              } else {
                window.open('http://' + host.api + '/v1/doc2?workspace='+ sessionStorage.getItem('workspaceId')+'&empresa=' + empresaId + '&estabelecimento=' + estabelecimentoId + '&token=' + localStorage.getItem('@jmv-token-key'), '_blank')
              }
            }
          }
          //await api.get(`doc?empresa=${empresaId}&estabelecimento=${estabelecimentoId}`)
        } else {
          aviso('warning', 'Empresa ou estabelecimento não selecionado')
        }
        }
      }

    } catch (error) {
      if(laudovalido){
        aviso('error', error)
      }else{
        aviso('warning', 'Nenhum laudo válido encontrado', true)
      }

    } finally {
      setCarregando(false)
    }
  }

  React.useMemo(() => {
    if (empresaId !== "") {
      if ((estabelecimentoId === "all" && tipo !== "laudo") || tipo === 'auditoria') {
        setHabilita(true)
      } else if (estabelecimentoId !== "") {
        if (tipo === "laudo") {
          if ((tarefaId !== "" && riscoId !== "" && textoAvaliacao !== "" && textoIntroducao !== "") || (estabelecimentoId === "all" && textoAvaliacao !== "" && textoIntroducao !== "") || (tarefaId === 'all' && textoAvaliacao !== "" && textoIntroducao !== "")) {
            setHabilita(true)
          } else {
            setHabilita(false)
          }
        } else {
          if (tipo === "pgr" || tipo === "os") {
            if (tarefaId === "") {
              setHabilita(false)
            } else {
              setHabilita(true)
            }
          } else {
            setHabilita(true)
          }
        }
      } else {
        setHabilita(false)
      }
    } else {
      setHabilita(false)
    }
  }, [empresaId, estabelecimentoId, tarefaId, tipo, riscoId, textoAvaliacao, textoIntroducao])

  return (
    <div>
      <Menu />

      <div className="gerar-retangulo1">
        <div className="gerar-retangulo2">
          <div className="gerar-intro">
            <h2>Relatório</h2>
          </div>

          <div className="gerar-login-box">

            <div className="qual-relatorio-gerar">
              <label htmlFor="selecionarempresa">Tipo de relatório</label>
              {DropTipos}
            </div>

            {tipo !== "" ? <div className="qual-relatorio-gerar">
              <label htmlFor="selecionarempresa">Selecione uma empresa</label>
              {DropEmpresa}
            </div> : null}

            {empresaId !== '' && tipo !== 'auditoria' ? <div className="selecionarempresa-tarefa">
              <label htmlFor="selecionarempresa-tarefa">Selecionar organização</label>
              {DropEstab}
            </div> : null}

            {estabelecimentoId !== '' && estabelecimentoId !== 'all' && (tipo === "pgr" || tipo === "os" || tipo === "laudo") ? <div className="selecionarempresa-tarefa">
              <label htmlFor="selecionarempresa-tarefa">Selecionar tarefa/atividade</label>
              {DropTarefa}
            </div> : null}

            {tarefaId !== ''&& tarefaId !== 'all' && tipo === "laudo" ? <div className="selecionarempresa-tarefa">
              <label htmlFor="selecionarempresa-tarefa">Selecionar risco</label>
              {DropRisco}
            </div> : null}

            {(riscoId !== '' || tarefaId === 'all' || estabelecimentoId === 'all') && (tipo === "laudo") ? <div className="selecionarempresa-tarefa">
              <label htmlFor="selecionarempresa-tarefa">Selecionar texto para introdução do laudo</label>
              {DropIntroducao}
            </div> : null}

            {(riscoId !== '' || tarefaId === 'all' || estabelecimentoId === 'all') && (tipo === "laudo") ? <div className="selecionarempresa-tarefa">
              <label>Selecionar texto para avaliação preliminar do risco</label>
              {DropAvaliacao}
            </div> : null}

            <div className="gerar-relatorio">
              <button type="submit" disabled={!habilita} onClick={gerar}>Gerar relatório</button>
            </div>

          </div>

        </div>
        <Loading loading={carregando} message='Carregando...' />
      </div>

    </div>


  );
}