import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';

import { Dropdown } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import { FiTrash2 } from 'react-icons/fi';
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';


import { useState, useEffect } from 'react';

const cadastrarMedidas = async (medidas, tipo, riscoId, controleId) => {
    if(tipo === "patch"){
        await api.patch("controles_existentes/" + controleId, medidas)
    }else{
		if(medidas.length !== undefined){
			await api.post("controles_existentes?risco_id=" + riscoId, medidas);
		}else{
			let valor = []
			valor.push(medidas)
			await api.post("controles_existentes?risco_id=" + riscoId, valor);
		}        
    }
}

function MedidasControle({ addOrUpdateMedida, ...props}) {

	
	const { aviso } = React.useContext(Context);

	const [tipo, setTipo] = useState('Controle')
	const [nomes, setNomes] = useState([]);
	const [nome, setNome] = useState(); //Medidas de controle
	const [carregando, setCarregando] = useState(false)
	const [controleId, setControleId] = useState(null)
	const [riscoId, setRiscoId] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [opcoes_medidas, setOpMedidas] = useState([])
	const [modalMedida, setModalMedida] = useState(false)

	useEffect(() => {

		if(props.show){

			async function initial() {

				setNome('')
				setControleId(sessionStorage.getItem('controle_existente_id'))
				setRiscoId(sessionStorage.getItem('riscoId'))

				var aux = false
				if (sessionStorage.getItem('controle_existente_id') !== null) {
					setCarregando(true)
					var n
					try {
						
						props.controles.filter(item => item.id == sessionStorage.getItem('controle_existente_id')).map(res => {
							n = res.nome
							setNome(res.nome)
							setSearchQuery(res.nome)
							setTipo(res.tipo)
						})

					} catch (error) {
						aviso('error',error)
					} finally {
						setCarregando(false)
					}
					
				}else{

					if(sessionStorage.getItem("TipoMedida") !== null){
						aux=true
						setTipo(sessionStorage.getItem("TipoMedida"))
						n=sessionStorage.getItem("TipoMedida")
					}
					if(sessionStorage.getItem("NomeMedida") !== null){
						aux=true
						setNome(sessionStorage.getItem("NomeMedida"))
						setSearchQuery(sessionStorage.getItem("NomeMedida"))
						n=sessionStorage.getItem("NomeMedida")
					}
				}

				await api.get('sugestoes/7').then(response => {
					var verifica = false
					setOpMedidas(response.data.resultado)

					if (sessionStorage.getItem('controle_existente_id') !== null || aux == true) {
						response.data.resultado.forEach(item => {
							if (n === item.value)
								verifica = true
						})

						if (!verifica) {
							response.data.resultado.push({ key: n, text: n, value: n })
						}
					}

					setNomes(response.data.resultado)
				})

			} initial()

		}

	}, [props.show])


	const handleChange = (e, { value }) => {
		setNome(value);
		setSearchQuery(value)
	}

	const handleSearchChange = (e , { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleAddition = (e, { value }) => {
		const copy = Array.from(nomes);
		copy.push({ key: value, text: value, value: value })
		setNomes(copy);
	}

	const DropMedidas = React.useMemo(() =>
		(
			<Dropdown
				options={nomes}
				placeholder="Selecione"
				clearable
				search
				selection
				lazyLoad={true}
				fluid
				upward
				allowAdditions
				additionLabel="Adicionar "
				value={nome}
				searchQuery={searchQuery}
				onAddItem={handleAddition}
				onChange={handleChange}
				onSearchChange={handleSearchChange}
			/>
		), [nome, nomes,searchQuery])

	async function handleControle() {

		const data = {
			nome,
			tipo
		};

		if (data.nome === '' || data.tipo === '') {
			aviso('warning', "Preencha todos os campos!")
		} else {

			try {

                if(sessionStorage.getItem('riscoNovo') !== "true"){
                    if (controleId !== null) {
                        await cadastrarMedidas(data, "patch", null, controleId);
                    } else {
                        await cadastrarMedidas(data, "post", riscoId, null);
                    }
                }else{
					if(sessionStorage.getItem('controle_existente_id') !== null){
                    	addOrUpdateMedida(data, sessionStorage.getItem('controle_existente_id'))
					}else{
						addOrUpdateMedida(data, null)
					}
                }

				var v_medida = false

				for(var i=0; i<opcoes_medidas.length; i++){
					if(nome === opcoes_medidas[i].value){
						v_medida = true
					}
				}

				if(!v_medida){

					var valor = {
						value: nome,
						text: nome
					}

					api.post('sugestoes/7', valor)
				}

				aviso('success', 'Controle salvo com sucesso')
				sessionStorage.setItem("TipoMedida",tipo)
				sessionStorage.setItem("NomeMedida",nome)
				props.onHide();
				setNome('');
				setSearchQuery('')
				setTipo('')

			} catch (error) {
				aviso('error', error)
			}
		}

	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Controles existentes
			</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
				<RemoveMedida show={modalMedida} backdrop="static" onHide={() => { setModalMedida(false) }} />

				<Loading loading={carregando} message='Carregando...' />
				<div className="empresa-modal">
					<div className="empresa-modal-box-nome">
						<label htmlFor="empresa-modal-nome">Tipo</label>
						<select value={tipo} onChange={e => setTipo(e.target.value)}>
							<option value="" disabled>Selecione</option>
							<option value="controle">Controle</option>
							<option value="física">Físicas</option>
							<option value="sistêmica">Sistemicas</option>
							<option value="comportamental">Comportamentais</option>
							<option value="eliminar o risco">Eliminar o risco</option>
							<option value="proteção coletiva">Proteção coletiva</option>
							<option value="medida administrativa">Medida administrativa</option>
							<option value="organização do trabalhalo">Organização do trabalhalo</option>
							<option value="medida administrativa">Medida administrativa</option>
						</select>
					</div>
					<div className="empresa-modal-box-cargo">
						<label>Medida de controle <FiTrash2 onClick={() => setModalMedida(true)} style={{cursor:"pointer"}} data-tip='Excluir sugestões' data-for="aux"/></label>
						{DropMedidas}
					</div>

				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleControle} >{controleId !== null ? "Salvar" : "Cadastrar"}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function RemoveMedida(props) {

	const [carregando, setCarregando] = useState(false);
	const [opMedida, setOpMedida] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [medida, setMedida] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setOpMedida([])
			setMedida([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/7')
					setOpMedida(f.data.resultado.filter(item => item.fk_workspace_id !== -1))
				} catch (error) {
					aviso('error',error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			} 
			initial()
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (medida.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				medida.forEach(item => {
					lista.push(opMedida.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/7', data)
				aviso('success','Removido com sucesso');

			} catch (error) {
				aviso('error',error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setMedida(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, {value}) => {
		setMedida(medida.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropMedida = React.useMemo(() => (
		<Dropdown
			options={opMedida}
			placeholder={"Selecione múltiplos"}
			search
			selection
			fluid
			lazyLoad={true}
			multiple
			upward
			value={medida}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [medida, opMedida, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
					</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Medida de controle</label>
						{DropMedida}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

export {MedidasControle, cadastrarMedidas};