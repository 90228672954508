import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Dropdown } from 'semantic-ui-react'
import Loading from '../../Loading'
import api from '../../../services/api'
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InfiniteScroll from 'react-infinite-scroll-component';

import { Input } from 'semantic-ui-react'

import '../styles.css';
import { CadastrarEmpresa } from './cadastro.js'

import { Context } from '../../../Context/AuthContext.js';

export function GerenciarEmpresas() {

    const [empresas, setEmpresas] = useState([]);
    const [carregando, setCarregando] = useState(false)
    const { aviso } = React.useContext(Context);
    const [mudanca, setMudanca] = useState(false);
    const [foundEmpresas, setFoundEmpresas] = useState([]);
    const [keyword, setKeyword] = useState('')
    const [showCadastro, setShowCadastro] = useState(false)

    const [count, setCount] = useState({
        prev: 0,
        next: 10
    })

    const [hasMore, setHasMore] = useState(true);
    const [current, setCurrent] = useState(foundEmpresas.slice(count.prev, count.next))

    const getMoreData = () => {

        if (current.length === foundEmpresas.length) {
            setHasMore(false);
            return;
        }

        if (current.length > 0) {
            setTimeout(() => {
                setCurrent(current.concat(foundEmpresas.slice(count.prev + 10, count.next + 10)))
            }, 125)
            setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
        }
    }

    function lazyLoad() {
        if (keyword !== '') {
            const result = empresas.filter((item) => {
                return (item.razao_social.toLowerCase().includes(keyword.toLowerCase()))
            })

            setFoundEmpresas(result)
            setMudanca(false)
            setHasMore(true)

            setTimeout(() => {
                setCurrent(result.slice(0, 10))
            }, 0)

        } else {
            if (foundEmpresas.length !== 0) {
                setFoundEmpresas(empresas)
                setTimeout(() => {
                    setCurrent(empresas.slice(0, 10))
                }, 0)
                setHasMore(true)
            }
        }

        setCount({
            prev: 0,
            next: 10
        })
    }

    useEffect(() => {
        lazyLoad()
    }, [keyword])

    const Sort = () => (
        <Dropdown
            text='Ordenar'
            icon='sort'
            floating
            labeled
            button
            className='icon'
        >
            <Dropdown.Menu>
                <Dropdown.Item onClick={handleAsc}>A-Z</Dropdown.Item>
                <Dropdown.Item onClick={handleDesc}>Z-A</Dropdown.Item>
                <Dropdown.Item onClick={handleInverter}>Inverter ordem atual</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    const sortArray = (obj) => {
        return obj.sort((a, b) => {
            return a.razao_social.localeCompare(b.razao_social);
        })
    }

    const handleAsc = () => {
        setEmpresas(sortArray(empresas))
        lazyLoad()
        setMudanca(true)
    }

    const handleDesc = () => {
        setEmpresas(sortArray(empresas).reverse())
        lazyLoad()
        setMudanca(true)
    }

    const handleInverter = () => {
        setEmpresas(empresas.reverse())
        lazyLoad()
        setMudanca(true)
    }

    async function atualizar() {
        setCarregando(true)
        try {
            const response = await api.get('empresa?all=true')
            setEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
            setFoundEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
            setHasMore(true)
            setTimeout(() => {
                setCurrent(response.data.resultado.filter(empresa => empresa.razao_social !== '').slice(0, 10))
            }, 0)

            response.data.resultado.forEach(empresa => {
                if (empresa.razao_social === '') {
                    api.delete(`empresa/${empresa.id}`)
                }
            })
        } catch (error) {
            aviso("error", error)
        } finally {
            setCarregando(false)
        }

    }

    async function iniciar() {
        setCarregando(true)

        try {
            await atualizar()
        } catch (error) {
            aviso("error", error)
        } finally {
            setCarregando(false)
        }

    }

    useEffect(() => {
        iniciar();
    }, []);

    async function handleDeleteEmpresa(id) {
        try {
            setCarregando(true)
            await api.delete(`empresa/${id}`)
            updateEmpresas()
            setCurrent(current.filter(empresa => empresa.id !== id))
            aviso('success', 'Empresa removida com sucesso')
        } catch (error) {
            aviso("error", "Erro ao deletar caso, tente novamente", true)
        } finally {
            setCarregando(false)
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: '5px',
        borderBottom: '1px solid #5940A2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "white",
        padding: '5px',
        width: 'auto',
        height: 'auto',
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden'
    });

    function onDragEnd(result) {

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            current,
            result.source.index,
            result.destination.index
        );

        if (JSON.stringify(current) !== JSON.stringify(items)) {
            setMudanca(true)
        }

        setCurrent(items)

    }

    function onSave() {

        var diff = current.length - empresas.length
        var emp
        if (diff < 0) {
            emp = [].concat(current, empresas.slice(diff))
        } else {
            emp = current
        }

        setEmpresas(emp)

        try {
            for (var index = 0; index < emp.length; index++) {
                emp[index].ordem = index
            }
            for (var index = 0; index < emp.length; index++) {
                api.patch(`empresa/${emp[index].id}`, emp[index]);
            }
            aviso('success', 'Nova ordem salva com sucesso')
            setMudanca(false)
        } catch (error) {
            aviso('error', error)
        }

    }

    async function updateEmpresas() {
        try {
            await api.get('empresa?all=true').then(response => {
                setEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
                setFoundEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
            })
        } catch (error) {
            aviso('error', error)
        }
    }

    return (
        showCadastro ?
            <CadastrarEmpresa onHide={() => { setShowCadastro(false); iniciar(); }} update={showCadastro} />
            :
            <div>
                <Loading loading={carregando} message='Carregando...' />


                <div className="ge-cadastrar">
                    {!mudanca ?
                        <button onClick={() => { sessionStorage.removeItem('empresaId'); sessionStorage.setItem('empresaNovo', true); setShowCadastro(true) }}>Cadastrar</button>
                        :
                        <button onClick={onSave} className='ge-salvar'>Salvar ordem</button>}
                </div>

                <div className="ges-subtitle">
                    <h2>Empresas cadastradas:</h2>
                    <div className="searchbox">
                        <Input fluid size='small' value={keyword} icon='search' placeholder='Pesquise...' onChange={e => { setKeyword(e.target.value) }} />
                    </div>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                id="scrollableDiv"
                            >
                                <InfiniteScroll
                                    dataLength={current.length}
                                    next={getMoreData}
                                    hasMore={hasMore}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {current.length ? current.map((empresa, index) => (
                                        <Draggable isDragDisabled={keyword === '' ? false : true} key={empresa.id} draggableId={empresa.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ReactTooltip id={toString(empresa.id)} place="top" effect="solid"></ReactTooltip>
                                                    <div className="ge-name">
                                                        <h3 onClick={() => { sessionStorage.removeItem('empresaNovo'); sessionStorage.setItem('empresaId', empresa.id); setShowCadastro(true) }}>{empresa.razao_social}</h3>
                                                    </div>
                                                    <div className='ge-icons'>
                                                        <i className="ge-edit"> <FiEdit data-tip="Editar" data-for={toString(empresa.id)} onClick={() => { sessionStorage.removeItem('empresaNovo'); sessionStorage.setItem('empresaId', empresa.id); setShowCadastro(true) }} />  </i>
                                                        <i className="ge-trash">
                                                            <FiTrash2 data-tip="Excluir" data-for={toString(empresa.id)}
                                                                onClick={() => {
                                                                    const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A EMPRESA SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + empresa.razao_social + ' :')
                                                                    if (r === "excluir") {
                                                                        handleDeleteEmpresa(empresa.id);
                                                                    } else {
                                                                        aviso("warning", "Nome incorreto, exclusão cancelada", true)
                                                                    }
                                                                }} />
                                                        </i>

                                                    </div>

                                                </div>
                                            )}
                                        </Draggable>

                                    )) : <h3>Nenhum registro</h3>
                                    }
                                    {provided.placeholder}
                                </InfiniteScroll>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className='dashboard-footer'>
                    <div>Empresas encontrados: {foundEmpresas.length}</div>
                    <Sort />
                </div>
            </div>
    )

}
