import React, { useState, createContext, useEffect } from 'react';
import { getData , logout_token, login_token } from '../services/auth';

import api from '../services/api'
import CryptoJS from 'crypto-js'


const Context = createContext();


function AuthProvider({ children }) {

    const [Authenticated, setAuthenticated] = useState(false);
    const [Licensed, setLicensed] = useState(false);
    const [Train, setTrain] = useState(false)
    const [Admin, setAdmin] = useState(false)
    const [Observer, setObserver] = useState(false)
    const [Aud, setAud] = useState(false)
    const [FullLicensed, setFullLicensed] = useState(false)
    const [Load, setLoading] = useState(true);
    const [Login, setLogin] = useState(false);
    const [message, setMessage] = useState('')
    const [typeMessage, setTypeMessage] = useState('')
    const [openMessage, setOpenMessage] = useState(false)
    const [troca, setTroca] = useState('')
    const [BuyLicense, setBuyLicense] = useState(false)
    const [workspaces, setWorkspaces] = useState([])

    const atualiza = async () => {

        try {
            const userData = await getData();
            if(typeof userData !== 'string'){

                const license = userData.user.license;
                const works = userData.workspaces;
                setWorkspaces(works);

                var observer, currentWorkspace ,admin, full, train, aud, buyLicense = false;

                if(userData.user.license.sucesso !== false && works.length > 0){
                    currentWorkspace = works.filter(item => item.id === parseInt(sessionStorage.getItem('workspaceId')))[0];
                    if(currentWorkspace !== undefined){
                        buyLicense = userData.user.license.hasLicense;
                        admin = (currentWorkspace.workspace_permission === 2 && currentWorkspace.amIOwner? true : false) || license.admin;
                        full = currentWorkspace.workspace_permission === 0 || currentWorkspace.workspace_permission === 2? true : false;
                        train = currentWorkspace.workspace_permission === -1? true : false;
                        aud = currentWorkspace.workspace_permission === -2? true : false;
                        observer = currentWorkspace.user_permission === 2? true : false;
                    }
                }
                
                const auth = userData.sucesso? true : false;
                const licensa = userData.user.license.sucesso? true : false;

                if (auth) {
                    setAuthenticated(true);
                }

                if (licensa) {
                    setLicensed(true);
                }

                if(admin){
                    setAdmin(true)
                }
 
                if(full && !observer){
                    setFullLicensed(true)
                }

                if(train){
                    setTrain(true)
                }

                if(aud){
                    setAud(true)
                }

                if(buyLicense){
                    setBuyLicense(true)
                }

                if(observer){
                    setObserver(true)
                }
            }

            setLoading(false);

        } catch (error) {
            console.log(error)
            aviso('error',error);
            setLoading(false);
        }
    };

    useEffect(() => {
        atualiza();
    }, []);

    async function atualizarWorkspaces() {
        const userData = await getData();
        setWorkspaces(userData.workspaces)
    }

    function handleLogout() {
        logout_token();
        localStorage.removeItem('senha_enc')
        localStorage.removeItem('login_enc')
        sessionStorage.removeItem('workspaceId')
        setLicensed(false);
        setAuthenticated(false);
    }

    function aviso(tipo, mensagem, literal) {
        
        if(mensagem?.response?.data?.permission){
            setTypeMessage("warning")
            setMessage(mensagem.response?.data?.mensagem) 
        }else{
            var texto = ""
            try {
                if(mensagem.response.data.mensagem !== undefined){
                    texto = mensagem.response.data.mensagem
                }else{
                    texto = ""
                }
            } catch (error) {
                error = error
            }
            
            if(tipo === 'error'){
                if(texto === 'Falha na autenticação' || texto === 'Usuário e/ou senha incorretos'){
                    setTypeMessage("warning")
                    setMessage(texto)
                    handleLogout()
                }else if(texto === 'Usuário desconectado'){
                    setTypeMessage("warning")
                    setMessage("Conta desconectada") 
                    handleLogout()
                }else if(literal === true){
                    setTypeMessage(tipo)
                    setMessage(mensagem) 
                }else{
                    setTypeMessage(tipo)
                    setMessage("Ocorreu um erro, tente novamente!") 
                }
            }else{
                setTypeMessage(tipo)
                setMessage(mensagem) 
            }
        }

        if (troca === '' || troca === false) {
            setTroca(true)
        } else {
            setTroca(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenMessage(false);
    };

    async function handleLogin(e, login, senha, verifica) {

        e.preventDefault();
        setLicensed(false);
        if (verifica)
            setAuthenticated(false);
        setLogin(true);

        var senha_enc = CryptoJS.AES.encrypt(senha, '@#JMV').toString();
        localStorage.setItem('senha_enc', senha_enc)
        var login_enc = CryptoJS.AES.encrypt(login, '@#JMV').toString();
        localStorage.setItem('login_enc', login_enc)

        const data = {
            login,
            senha
        }

        try {

            const resultado = await api.post('login', data);
            login_token(resultado.data.token);
            const userData = await getData();
            setWorkspaces(userData.workspaces)
            if(userData.workspaces.length > 0){
                sessionStorage.setItem('workspaceId', userData.workspaces[0].id)
            }

            if (verifica){
                aviso("success", "Login efetuado com sucesso");
                if(Aud){
                    window.open("/auditoria","_self")
                }else{
                    window.open("/gerenciarempresas","_self")
                }
            }else{
                atualiza()
            }

            // try {

            //     // const licensa = userData.user.license.sucesso? true : false;
            //     // if (licensa) {
            //     //     setLicensed(true);
            //     //     setAuthenticated(true);
            //     // }
            //     // setAuthenticated(true);
            // } catch (error) {
            //     console.log(error)
            //     aviso('error',error);
            // }

        } catch (error) {
            aviso('error',error);
            if (error.response.data === undefined || error.resultado === undefined) {
                aviso('error',error);
            } else {
                aviso('error', error.response.data.mensagem, true);
            }
        }
        setLogin(false);
       
    }

    return (

        <Context.Provider value={{ workspaces, troca, message, typeMessage, openMessage, Authenticated, Observer ,Licensed, Admin, Train, FullLicensed, BuyLicense, Aud, Load, Login, aviso, handleClose, handleLogout, handleLogin, setLoading, setLicensed, setAuthenticated, setOpenMessage, atualizarWorkspaces }}>
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider }