import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'

import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from '../../../global.js'
import { GestaoTituloAud } from '../Titulo/index.js'


export default function GestaoCapituloAud() {

    const { aviso, Admin } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)
    const [capitulos, setCapitulos] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [tituloShow, setTituloShow] = useState(false)

    async function handleDeleteCapitulo(id) {
        try {
            setCarregando(true)
            await api.patch('auditoria_capitulo/' + id, { enable: false })
            aviso('success', 'Deletado com sucesso')
        } catch (error) {
            aviso("error", "Erro ao deletar capitulo, tente novamente", true)
        } finally {
            setCarregando(false)
        }
    }

    async function inicializar() {
        setCarregando(true)
        try {
            await Promise.all([
                api.get('auditoria_capitulo'),
            ]).then((response) => {
                setCapitulos(response[0].data.resultado)
            })
        } catch (error) {
            aviso('error', error)
        } finally {
            setCarregando(false)
        }
    }

    useEffect(() => {
        inicializar()
    }, [])

    return (
        <div>
            <Menu />
            <div className="reavaliacao-retangulo1">
                <div className="reavaliacao-retangulo2">
                    <div className="reavaliacao-intro">
                        <h2>Gestão de auditorias</h2>
                    </div>
                    <div className="auditoria-login-box">
                        {!tituloShow ?
                            <div className='teste'>
                                <div style={{ justifyContent: 'center', marginBottom: '10px', display: 'flex' }}>
                                    <label style={{ fontSize: '20px' }}>Capítulos</label>
                                </div>
                                <CapTil show={modalShow} admin={Admin.toString()} backdrop="static" tipo='capitulo' capitulos={capitulos} onHide={() => { setModalShow(false); inicializar(); sessionStorage.removeItem('capituloId') }} />
                                <div className="reavaliacao-menu-pergunta">
                                    <div className="reavaliacao-menu-pergunta2">
                                        <label>&nbsp;</label>
                                        <button onClick={() => { setModalShow(true); sessionStorage.removeItem('audCapituloId') }}>Cadastrar novo</button>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ marginTop: '30px' }}>
                                        <h3>Capitulos cadastrados:</h3>
                                    </div>
                                    {capitulos.length < 1 ?
                                        <div>
                                            <label>Nenhum registro</label>
                                        </div>
                                        :
                                        <div className="scroll-box">
                                            {capitulos.map(item => (
                                                <div key={item.id} className="reavaliacao-list-box" style={{ cursor: 'default' }}>
                                                    <ReactTooltip id={toString(item.id)} place="top" effect="solid"></ReactTooltip>
                                                    <div className="reavaliacao-name">
                                                        <h3 style={{ cursor: 'pointer' }} onClick={() => {
                                                            sessionStorage.setItem('audCapituloId', item.id)
                                                            setTituloShow(true)
                                                        }}>{item.capitulo}</h3>
                                                    </div>
                                                    <div className="reavaliacao-icons">
                                                        <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(item.id)} onClick={() => { sessionStorage.setItem('audCapituloId', item.id); setModalShow(true) }} />  </i>
                                                        <i className="reavaliacao-trash">
                                                            <FiTrash2 data-tip="Excluir" data-for={toString(item.id)}
                                                                onClick={async () => {
                                                                    const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + item.capitulo + ' :')
                                                                    if (r === "excluir") {
                                                                        await handleDeleteCapitulo(item.id)
                                                                        inicializar()
                                                                    } else {
                                                                        aviso("warning", "Nome incorreto, exclusão cancelada", true)
                                                                    }
                                                                }} /> </i>
                                                    </div>

                                                </div>
                                            )
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <GestaoTituloAud update={tituloShow} onHide={() => {
                                sessionStorage.removeItem('audCapituloId');
                                setTituloShow(false)
                            }} />
                        }

                    </div>
                </div>
                <Loading loading={carregando} message='Carregando...' />
            </div>

        </div>


    );

}

export function CapTil(props) {

    const [carregando, setCarregando] = useState(false);
    const [capitulo, setCapitulo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [fullAply, setFullAply] = useState(false)

    const { aviso } = React.useContext(Context);

    useEffect(() => {

        async function inicial() {

            if (props.show) {

                setCapitulo('')
                setTitulo('')

                if (props.tipo === 'capitulo' && sessionStorage.getItem('audCapituloId') !== null) {
                    setCarregando(true)
                    try {
                        setCapitulo(props.capitulos.filter(item => item.id === parseInt(sessionStorage.getItem('audCapituloId')))[0].capitulo)
                        setFullAply(props.capitulos.filter(item => item.id === parseInt(sessionStorage.getItem('audCapituloId')))[0].user === 0? true : false)
                    } catch (error) {
                        console.log(error)
                        aviso('error', error)
                    } finally {
                        setCarregando(false)
                    }
                }

                if (props.tipo === 'titulo' && sessionStorage.getItem('audTituloId') !== null) {
                    setCarregando(true)
                    try {
                        setTitulo(props.titulos.filter(item => item.id === parseInt(sessionStorage.getItem('audTituloId')))[0].titulo)
                        setFullAply(props.titulos.filter(item => item.id === parseInt(sessionStorage.getItem('audTituloId')))[0].user === 0? true : false)
                    } catch (error) {
                        console.log(error)
                        aviso('error', error)
                    } finally {
                        setCarregando(false)
                    }
                }

            }

        } inicial()

    }, [props.show])

    const handleRegister = async (e) => {

        e.preventDefault()

        var data = {}

        if (props.tipo === 'capitulo') {
            data = {
                capitulo: capitulo,
                all: fullAply
            }
        }

        if (props.tipo === 'titulo') {
            data = {
                titulo: titulo,
                fk_cap_id: sessionStorage.getItem('audCapituloId'),
                all: fullAply
            }
        }

        if ((props.tipo === 'capitulo' && capitulo === '') || (props.tipo === 'titulo' && titulo === '')) {
            aviso('warning', "Preencha todos os campos!")
        } else {
            try {

                var mensagem
                setCarregando(true)
                if ((sessionStorage.getItem('audCapituloId') !== null && props.tipo === 'capitulo') || (sessionStorage.getItem('audTituloId') !== null && props.tipo === 'titulo')) {
                    if (props.tipo === 'capitulo') {
                        await api.patch("auditoria_capitulo/" + sessionStorage.getItem('audCapituloId'), data)
                        mensagem = "Capítulo atualizado com sucesso"
                    }
                    if (props.tipo === 'titulo') {
                        await api.patch("auditoria_titulo/" + sessionStorage.getItem('audTituloId'), data)
                        mensagem = "Título atualizado com sucesso"
                    }

                    aviso('success', mensagem)
                } else {
                    if (props.tipo === 'capitulo') {
                        await api.post("auditoria_capitulo/", data)
                        mensagem = "Capítulo atualizado com sucesso"
                    }
                    if (props.tipo === 'titulo') {
                        await api.post("auditoria_titulo/", data)
                        mensagem = "Título atualizado com sucesso"
                    }
                    mensagem = "Texto criado com sucesso"
                    aviso('success', mensagem)
                }

            } catch (error) {

                aviso('error', error)

            } finally {
                setCarregando(false)
                props.onHide()
            }
        }

    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {sessionStorage.getItem('audCapituloId') !== null && sessionStorage.getItem('audTituloId') !== null ? "Editar" : "Cadastrar"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                {props.admin === "true" && <div style={{display: 'inline-flex', alignItems: 'center'}}>
                    <input
                        type="checkbox"
                        checked={fullAply}
                        onChange={() => setFullAply(!fullAply)}
                    />
                    <label style={{paddingLeft: '5px'}}>Aplicar para todos os usuários</label>
                </div>}

                <div className="trabexp">

                    <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
                        <label hidden={props.tipo !== 'capitulo'}>Capítulo</label>
                        <textarea hidden={props.tipo !== 'capitulo'} className="text-area-risco" type="longtext" aria-multiline="true" value={capitulo} onChange={e => setCapitulo(e.target.value)} />
                        <label hidden={props.tipo !== 'titulo'}>Título</label>
                        <textarea hidden={props.tipo !== 'titulo'} className="text-area-risco" type="longtext" aria-multiline="true" value={titulo} onChange={e => setTitulo(e.target.value)} />
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleRegister}>{sessionStorage.getItem('audCapituloId') !== null && sessionStorage.getItem('audTituloId') !== null ? "Salvar" : "Cadastrar"}</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

