import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';
import { Dropdown, Checkbox } from 'semantic-ui-react'
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import { Modal, Button } from 'react-bootstrap'
import { FiInfo, FiEdit, FiTrash2, FiSearch, FiPlusCircle, FiChevronLeft} from 'react-icons/fi';
import { FaTable, FaInfo } from 'react-icons/fa';
import { useHistory } from 'react-router-dom'; //usado para linkar outras paginas.
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import br from 'date-fns/locale/pt-BR';

import './styles.css';
import './stylesPlano.css'

import sevAcidentes from '../../../assets/sevAcidentes.png';
import probAcidentes from '../../../assets/probAcidentes.png';
import sevHC from '../../../assets/sevHC.png';
import probHC from '../../../assets/probHC.png';
import sevBio from '../../../assets/sevBio.png';
import probBio from '../../../assets/probBio.png';
import sevErgo from '../../../assets/sevErgo.png';
import probErgo from '../../../assets/probErgo.png';
import imgResultado from '../../../assets/imgResultado.PNG'


import { Menu } from '../../../global.js';

import { useState, useEffect } from 'react';

import 'semantic-ui-css/semantic.min.css';

export default function AvlRisco() {

	const riscoId = sessionStorage.getItem('riscoId')
	const tarefaId = sessionStorage.getItem('atividadeId')
	const riscoName = sessionStorage.getItem('riscoName')
	const tarefaName = sessionStorage.getItem('tarefa')
	const estabelecimentoModalidade = sessionStorage.getItem('estabelecimentoModalidade')

	registerLocale('pt-BR', br)

	const { aviso, FullLicensed } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false);
	const [modalShow, setModalShow] = useState(false)
	const [modalShowAmostra, setModalShowAmostra] = useState(false)
	const [modalShowConclusao, setModalShowConclusao] = useState(false)
	const [modalShowPergunta, setModalShowPergunta] = useState(false)
	const history = useHistory();

	const [ambiental, setAmbiental] = useState('')
	const [ergo, setErgo] = useState('')
	const [tipologia, setTipologia] = useState('');
	const [risco, setRisco] = useState('')
	const [perigo, setPerigo] = useState('');

	const [planos, setPlanos] = useState([])

	const [utilizarMatriz, setUtiliza] = useState('')
	const [observ, setObserv] = useState('')

	const [fonte_externa, setFonteExterna] = useState('')
	const [severidade, setSeveridade] = useState('')
	const [probabilidade, setProbabilidade] = useState('')
	const [numTrabalhadores, setNumTrabalhadores] = useState('')
	const [resultado, setResultado] = useState('')
	const [cenarios, setCenarios] = useState('')
	const [abrirPlano, setAbrirPlano] = useState('')
	const [justificativa, setJustificativa] = useState('')
	const [ordem, setOrdem] = useState(null)

	const [naoApli, setNaoApli] = useState('')
	const [naoCont, setNaoCont] = useState('')

	const [modalShow2, setModalShow2] = useState(false)
	const [modalShow3, setModalShow3] = useState(false)
	const [modalShow4, setModalShow4] = useState(false)

	const [state5, setState5] = useState([]);
	const [currentValues5, setCurrentValues5] = useState(''); //Anexos

	const [state6, setState6] = useState([]);
	const [currentValues6, setCurrentValues6] = useState(''); //Risco Ocupacional

	const [state7, setState7] = useState([]);
	const [currentValues7, setCurrentValues7] = useState(''); //Critérios

	const [currentValues8, setCurrentValues8] = useState(''); //Risco laudo

	const [vin_pcmso, setVinPcmso] = useState('')
	const [pcmso_anexo, setPA] = useState('')
	const [pcmso_criterio, setPC] = useState('')
	const [pcmso_exames, setPE] = useState('')
	const [pcmso_periodicidade, setPP] = useState('')
	const [pcmso_risco, setPR] = useState('')
	const [pcmso_observ, setPO] = useState('')
	const [pcmso_cas, setPcas] = useState('')

	const [vin_laudo, setVinLaudo] = useState('')

	const [searchQuery8, setSearchQuery8] = useState('')
	const [searchQuery7, setSearchQuery7] = useState('')
	const [searchQuery6, setSearchQuery6] = useState('')
	const [searchQuery5, setSearchQuery5] = useState('')

	const [tipo_laudo, setTipoLaudo] = useState('')
	const [periodicidade, setPeriodicidade] = useState('')
	const [calor_ibutg_m, setCalorIbutgM] = useState('')
	const [calor_metabolismo_m, setCalorMetabolismoM] = useState('')
	const [calor_limite_lt, setCalorlimiteLt] = useState('')
	const [calor_limite_na, setCalorlimiteNa] = useState('')
	const [amostras, setAmostras] = useState([])
	const [somaTempo, setSomaTempo] = useState(0)
	const [optionsLaudo, setOptionsLaudo] = useState([])
	const [laudo_r, setLaudoR] = useState({})
	const [estatisticas, setEstatisticas] = useState('')
	const [limite, setLimite] = useState('')
	const [unidade_medida, setUnidadeMedida] = useState('')
	const [optionsUnidadeMedida, setOptionUnidadeMedida] = useState([])
	const [parametro, setParametro] = useState('ma')
	const [optionsParametros, setOptionParametro] = useState([])
	const [avaliacaoTrabalhista, setAvaliacaoTrabalhista] = useState('')

	var textVinpcmso = "Marque sim ou não ao observar se o risco "
		+ "avaliado possui critério para vincular ao "
		+ "PCMSO para execução de exame "
		+ "complementar no ASO.<br/><br/>"
		+ "Exemplos: Resultado de Ruido com "
		+ "intensidade acima de 80 dB(A), Riscos "
		+ "químicos acima do nível de Ação, Trabalho "
		+ "em Altura, etc..."

	var textRiscopcmso = "O risco classificado deve ser confirmado em "
		+ "um dos anexos da NR 07 ou verificar na lei se "
		+ "há critério legal."
	/*+ "Anexo I- Quadro I - NR 07 - Químicos<br/>"
	+ "Anexo I - Quadro II -NR 07 - Químicos<br/>"
	+ "Anexo II - Ruído<br/>"
	+ "Anexo III - Quadro I - NR 07 – Sílica e Asbesto<br/>"
	+ "Anexo III - Quadro III - NR 07 – PNOS ( Poeira)<br/>"
	+ "Anexo IV - Trabalho em pressões hiperbáricas<br/>"
	+ "Anexo V – Substancias cancerígenas e radiações ionizantes.<br/>"
	+ "Anexo V - Radiação Ionizante Riscos químicos da ACGIH<br/>"
	+ "NR 07 – Riscos classificados justificados pelo médico responsável.<br/>"
	+ "NR 01 – Levantamento Preliminar de Risco<br/>"
	+ "NR 01 – Risco identificado e classificado no PGR<br/>"
	+ "Calor - NR 09 – Anexo 3<br/>"
	+ "Vibração- NR 09 – Anexo 8<br/>"
	+ "NR 09 – Risco Qualitativo<br/>"
	+ "NR 10 - Trabalho com Eletricidade<br/>"
	+ "NR 11 - Movimentação de Cargas<br/>"
	+ "NR 17 - Ergonomia<br/>"
	+ "NR 20 - Inflamáveis e Combustíveis<br/>"
	+ "NR 33 - Trabalho em Espaço Confinado<br/>"
	+ "NR 35 - Trabalho em Altura"*/

	var textRiscoOcpcmso = "Clicar no risco ocupacional que está sendo "
		+ "avaliado e necessita vincular ao PCMSO para "
		+ "a execução de exame complementar no ASO."
	/*+ "Exemplo – Anexo I – Quadro II – NR 07 - "
	+ "Agente ambiental em estudo - Cádmio e seus "
	+ "compostos inorgânicos."*/

	var textCriteriopcmso = "Clicar no critério que exige a realização de exame "
		+ "complementar no ASO.<br/>"
		+ "No corpo ou nos anexos da NR 07 estão "
		+ "assinalados alguns critérios. Outros critérios estão "
		+ "nos itens das NRs."
	/*+ "Exemplos:<br/>"
	+ "> Nível de Ação<br/>"
	+ "> Limite de Exposição Ocupacional "
	+ "Risco qualitativo<br/>"
	+ "CLSC>10% LEO<br/>"
	+ "Sem avaliação quantitativa<br/>"
	+ "NR 07 – Riscos classificados justificados pelo "
	+ "médico responsável.<br/>"
	+ "NR 01 – Risco identificado e classificado no PGR.<br/>"
	+ "Exercer atividades em áreas previstas nas normas "
	+ "regulamentadoras NRs - 10,11,17,20,33,35"*/

	var textPeriodicidade = "Permanente – Durante a jornada de trabalho de forma habitual, indissociável da função.<br/>"
	+ "Ocasional – Quando a exposição ocorre sem previsibilidade ou que não faz da parte da rotina.<br/>"
	+ "Intermitente - Em atividades habituais programada, rotineiras ou não.<br/>"
	+ "Nota: Definições do PGRSmart. Elas devem ser personalizadas pelo profissional que assina o Laudo."


	const handleChange5 = (e5, { value }) => {
		setCarregando(true)
		setCurrentValues5(value);
		setCurrentValues6("")
		setCurrentValues7("")
		setPE("")
		setPP("")
		setSearchQuery6("")
		setSearchQuery7("")


		api.get("sugestoes/10?pcmso=" + value).then(response => {
			setState7(response.data.resultado)
		}).catch(error => {
			console.log(error)
			setState7([])
		})
		api.get("sugestoes/9?pcmso=" + value).then(response => {
			if (value == '11' || value == '12' || value == '15') {
				response.data.resultado.push({ key: risco, value: risco, text: risco })
				setCurrentValues6(risco)
				setPR(risco)
				setSearchQuery6(risco)
			}
			setState6(response.data.resultado)
			setPA(state5.filter(item => item.key == value)[0].text)
			setSearchQuery5(state5.filter(item => item.key == value)[0].text)
		}).catch(error => {
			setPA(value)
			setSearchQuery5(value)
			setState6([])
		})
		setCarregando(false)
	}//Anexos

	const handleSearchChange5 = (e, { searchQuery }) => {
		setSearchQuery5(searchQuery)
	}

	const handleSearchChange8 = (e, { searchQuery }) => {
		setSearchQuery8(searchQuery)
	}

	const handleChange6 = (e, { value }) => {
		setCarregando(true)
		setCurrentValues6(value);

		api.get("sugestoes/9?pcmso=" + value).then(response => {
			setPR(state6.filter(item => item.key == value)[0].text)
			setPE(state6.filter(item => item.key == value)[0].exames)
			setPcas(state6.filter(item => item.key == value)[0].cas)
			setSearchQuery6(state6.filter(item => item.key == value)[0].text)
		}).catch(error => {
			setPR(value)
			setSearchQuery6(value)
			setPE("")
			setPcas("")
			setCurrentValues7("")
			setSearchQuery7("")
			setState7([])
			setPC("")
			setPP("")
		})
		setCarregando(false)
	}//Risco Ocupacional

	const handleChange8 = async (e8, { value }) => {
		if (value !== '') {
			try {
				setCarregando(true)
				var id_laudo_aux = optionsLaudo.filter((item) => item.value === value)[0].key
				let am_aux = optionsLaudo.filter(item => item.key === id_laudo_aux)[0]
				let copy_laudo = laudo_r
				setAvaliacaoTrabalhista(am_aux.avaliacao_trabalhista)
				copy_laudo.fk_laudos_id = id_laudo_aux
				var data = {}
				data.fk_laudos_id = id_laudo_aux

				if (am_aux.unidade_medida !== "mg/m3 ou ppm" && am_aux.unidade_medida !== "Qualitativo") {
					setOptionUnidadeMedida([{ key: 1, value: am_aux.unidade_medida, text: am_aux.unidade_medida }])
					setUnidadeMedida(am_aux.unidade_medida)
					data.unidade_medida = am_aux.unidade_medida
					copy_laudo.unidade_medida = am_aux.unidade_medida
					setLimite(am_aux.limite_outro)
				} else if (am_aux.unidade_medida === "mg/m3 ou ppm") {
					setOptionUnidadeMedida([
						{ key: 1, value: 'ppm', text: 'ppm' },
						{ key: 2, value: 'mg/m3', text: 'mg/m3' }
					])

					if ((copy_laudo.unidade_medida === 'mg/m3' || copy_laudo.unidade_medida === 'ppm') && am_aux.limite_ppm !== 'Qualitativo') {
						setUnidadeMedida(copy_laudo.unidade_medida)
						data.unidade_medida = copy_laudo.unidade_medida
						if (copy_laudo.unidade_medida === 'ppm') {
							setLimite(am_aux.limite_ppm)
						} else {
							setLimite(am_aux.limite_mg_m3)
						}
					} else {
						setUnidadeMedida('ppm')
						data.unidade_medida = 'ppm'
						if (am_aux.limite_ppm === 'Qualitativo') {
							setLimite('Não aplicável')
						} else {
							setLimite(am_aux.limite_ppm)
						}

					}

				} else if (am_aux.unidade_medida === 'Qualitativo') {
					setOptionUnidadeMedida([
						{ key: 1, value: 'Não aplicável', text: 'Não aplicável' },
					])
					setUnidadeMedida('Não aplicável')
					setLimite('Não aplicável')
				}

				if (sessionStorage.getItem('laudoId') !== null && sessionStorage.getItem('laudoId') !== '') {
					setPeriodicidade('')
					data.periodicidade = ''
					await api.patch('laudo/' + sessionStorage.getItem('laudoId'), data)
					setLaudoR(copy_laudo)
				} else {
					setPeriodicidade('')
					data.periodicidade = ''
					const response = await api.post('laudo?risco_id=' + riscoId, data)
					sessionStorage.setItem('laudoId', response.data.id_laudo)
				}
				setTipoLaudo(optionsLaudo.filter((item) => item.value === value)[0]?.filtro)
				sessionStorage.setItem('tipo_laudo', optionsLaudo.filter((item) => item.value === value)[0]?.filtro)

			} catch (error) {
				console.log(error)
				aviso('error', error)
			} finally {
				setCarregando(false)
			}
		} else {
			setSomaTempo(0)
		}
		setCurrentValues8(value)
		setSearchQuery8(value)
		UpdateAmostras()
	}

	const handleChange9 = async (e8, { value }) => {
		setPeriodicidade(value)
	}

	const handleSearchChange6 = (e, { searchQuery }) => {
		setSearchQuery6(searchQuery)
	}

	const handleChange7 = (e7, { value }) => {
		setCarregando(true)
		setCurrentValues7(value);
		if (!isNaN(value) && value !== '') {
			setPC(state7.filter(item => item.key == value)[0].text)
			setSearchQuery7(state7.filter(item => item.key == value)[0].text)
			setPP(state7.filter(item => item.key == value)[0].periodicidade)
		} else {
			setPC(value)
			setSearchQuery7(value)
			setPP("")
		}
		setCarregando(false)
	}//Critérios

	const handleSearchChange7 = (e, { searchQuery }) => {
		setSearchQuery7(searchQuery)
	}

	const handleAddition1 = (e, { value }) => {
		const copy = Array.from(state5);
		copy.push({ key: value, text: value, value: value })
		setState5(copy);
	}

	const handleAddition2 = (e, { value }) => {
		const copy = Array.from(state6);
		copy.push({ key: value, text: value, value: value })
		setState6(copy);
	}

	const handleAddition3 = (e, { value }) => {
		const copy = Array.from(state7);
		copy.push({ key: value, text: value, value: value })
		setState7(copy);
	}

	const changeUnidadeMedida = (e, { value }) => {
		setUnidadeMedida(value)
		api.patch('laudo/' + sessionStorage.getItem('laudoId'), { unidade_medida: value })
		let copy_laudo = laudo_r
		copy_laudo.unidade_medida = value
		setLaudoR(copy_laudo)

		let am_aux = optionsLaudo.filter(item => item.key === copy_laudo.fk_laudos_id)[0]
		if (am_aux.unidade_medida !== "mg/m3 ou ppm" && am_aux.unidade_medida !== "Qualitativo") {
			setLimite(am_aux.limite_outro)
		} else if (am_aux.unidade_medida === "mg/m3 ou ppm" && am_aux.limite_ppm !== "Qualitativo") {
			if (value === 'ppm') {
				setLimite(am_aux.limite_ppm)
			} else {
				setLimite(am_aux.limite_mg_m3)
			}
		} else if (am_aux.unidade_medida === 'Qualitativo') {
			setLimite('Não aplicável')
		} else {
			setLimite('Não aplicável')
		}
	}

	const changeParametro = (e, { value }) => {
		setParametro(value)
		api.patch('laudo/' + sessionStorage.getItem('laudoId'), { parametro: value })
	}

	useEffect(() => {

		async function initial() {

			setCarregando(true)

			try {
				sessionStorage.removeItem('laudoId')
				sessionStorage.removeItem('controle_existente_id')
				if (sessionStorage.getItem('planoOn') == 'true') {
					setModalShow(true)
					sessionStorage.removeItem('planoOn')
				}
				await Promise.all([
					api.get('sugestoes/1'),
					api.get(`risco?tarefa_id=${tarefaId}`),
					api.get(`trabalhadores_expostos?risco_id=${sessionStorage.getItem('riscoId')}`),
					api.get(`plano_acao?risco_id=${sessionStorage.getItem('riscoId')}`),
					api.get(`controles_existentes?risco_id=${sessionStorage.getItem('riscoId')}`),
					api.get('laudo?risco_id=' + sessionStorage.getItem('riscoId')),
					api.get('laudo?risco_id=' + sessionStorage.getItem('riscoId') + '&type=all')
				]).then(response => {

					response[1].data.resultado.forEach(async risco => {

						if (risco.id === parseInt(riscoId)) {
							setPlanos(response[3].data.resultado)
							setAmbiental(risco.resultado_ambiental)
							setErgo(risco.resultado_ergonomico)
							setTipologia(risco.tipologia)
							setRisco(risco.nome)
							setPerigo(risco.perigo)
							setObserv(risco.observacao)
							if (risco.fontes_externas === "") {
								setFonteExterna("Não")
							} else {
								setFonteExterna(risco.fontes_externas)
							}
							setSeveridade(risco.severidade)
							setProbabilidade(risco.probabilidade)
							var qtd = 0
							await response[2].data.resultado.forEach(trabalhador => {
								qtd = qtd + trabalhador.qtd
							})
							setNumTrabalhadores(qtd)

							setResultado(risco.resultado)
							setCenarios(risco.cenario_emergencia === ''? 'Não' : risco.cenario_emergencia)
							setAbrirPlano(risco.abrir_plano_acao)
							setJustificativa(risco.justificativa)
							setUtiliza(risco.matriz_propria)
							setNaoApli(risco.nao_apli)
							setNaoCont(risco.nao_cont)
							setVinPcmso(risco.vin_pcmso === ""? "Não" : risco.vin_pcmso)
							setPA(risco.pcmso_anexo)
							setPC(risco.pcmso_criterio)
							setPE(risco.pcmso_exames)
							setPP(risco.pcmso_periodicidade)
							setPO(risco.pcmso_observ)
							setPcas(risco.pcmso_cas)
							setPR(risco.pcmso_risco)
							setCurrentValues5(risco.pcmso_anexo_ref)
							setCurrentValues6(risco.pcmso_risco_ref)
							setCurrentValues7(risco.pcmso_criterio_ref)
							setOrdem(risco.ordem)
							setVinLaudo(risco.vin_laudo === ''? 'Não': risco.vin_laudo)
							let options = response[6].data.resultado.map(({ id, risco, categoria, filtro, unidade_medida, limite_mg_m3, limite_ppm, limite_outro, avaliacao_trabalhista, avaliacao_previdenciaria }) => ({ key: id, value: risco, text: risco, categoria, filtro, unidade_medida, limite_mg_m3, limite_ppm, limite_outro, avaliacao_trabalhista, avaliacao_previdenciaria }))
							setOptionsLaudo(options)
							setOptionParametro([
								{ key: 1, value: 'ma', text: 'Média Aritmética' },
								{ key: 2, value: 'mg', text: 'Média Geométrica' },
								{ key: 4, value: 'mav', text: 'Maior Valor' },
								{ key: 5, value: 'mev', text: 'Menor Valor' },
							])

							if (response[5].data.resultado.length > 0) {

								setLaudoR(response[5].data.resultado[0])
								setUnidadeMedida(response[5].data.resultado[0].unidade_medida)
								setParametro(response[5].data.resultado[0].parametro)
								setPeriodicidade(response[5].data.resultado[0].periodicidade)

								let am_aux = options.filter(item => item.key === response[5].data.resultado[0].fk_laudos_id)[0]
								setAvaliacaoTrabalhista(am_aux.avaliacao_trabalhista)

								if (am_aux.unidade_medida !== "mg/m3 ou ppm" && am_aux.unidade_medida !== "Qualitativo") {
									setOptionUnidadeMedida([{ key: 1, value: response[5].data.resultado[0].unidade_medida, text: response[5].data.resultado[0].unidade_medida }])
									setLimite(am_aux.limite_outro)
								} else if (am_aux.unidade_medida === "mg/m3 ou ppm") {
									setOptionUnidadeMedida([
										{ key: 1, value: 'ppm', text: 'ppm' },
										{ key: 2, value: 'mg/m3', text: 'mg/m3' }
									])

									if (response[5].data.resultado[0].unidade_medida !== '' && am_aux.limite_ppm !== 'Qualitativo') {
										if (response[5].data.resultado[0].unidade_medida === 'ppm') {
											setLimite(am_aux.limite_ppm)
										} else {
											setLimite(am_aux.limite_mg_m3)
										}
									} else {
										if (am_aux.limite_ppm !== "Qualitativo") {
											setLimite(am_aux.limite_ppm)
										} else {
											setLimite('Não aplicável')
										}
									}

								} else if (am_aux.unidade_medida === 'Qualitativo') {
									setOptionUnidadeMedida(
										[{ key: 1, value: 'Não aplicável', text: 'Não aplicável' }],
									)
									setLimite('Não aplicável')
								}

								sessionStorage.setItem('laudoId', response[5].data.resultado[0].id)
								setCurrentValues8(options.filter(item => item.key === response[5].data.resultado[0].fk_laudos_id)[0].value)
								setTipoLaudo(options.filter(item => item.key === response[5].data.resultado[0].fk_laudos_id)[0].filtro)
								sessionStorage.setItem('tipo_laudo', options.filter(item => item.key === response[5].data.resultado[0].fk_laudos_id)[0].filtro)
								const am = await api.get('amostra?laudo_id=' + response[5].data.resultado[0].id)

								if (am.data.resultado.length > 0) {
									setAmostras(am.data.resultado)
									var somaExposicao = 0;
									am.data.resultado.forEach(item => {
										somaExposicao = somaExposicao + item.calor_tempo
									})

									if (!(am_aux.filtro.startsWith('Calor'))) {
										const estatisticas = await api.post('laudo_estatistica', { amostras: am.data.resultado, filtro: am_aux.filtro })
										setEstatisticas(estatisticas.data.resultado)
									}

									setSomaTempo(somaExposicao)
									ibutg_m(am.data.resultado)
									metabolismo_m(am.data.resultado)
								}

							} else {
								sessionStorage.removeItem('laudoId')
							}

							var verifica1 = ""
							var verifica2 = ""
							var verifica3 = ""

							var text = ""
							if (response[4].data.resultado.length < 1) {
								if (risco.nao_cont == '1') {
									text = "Não há controle"
								} else {
									text = "Não aplicável"
								}
							} else {
								response[4].data.resultado.forEach(item => {
									text = text.concat(item.nome + '/' + item.tipo + '; ')
								})
							}
							sessionStorage.setItem('medidasControleAvl', text)

							await Promise.all([
								api.get('sugestoes/8'),
								api.get('sugestoes/11'),
								api.get('sugestoes/12'),
							]).then(async response => {

								await Promise.all([
									response[0].data.resultado.forEach(anexo => {
										if (anexo.key == risco.pcmso_anexo_ref) {
											verifica1 = "Sim"
										}
									}),
									response[1].data.resultado.forEach(riscooc => {
										if (riscooc.key == risco.pcmso_risco_ref) {
											verifica2 = "Sim"
										}
									}),
									response[2].data.resultado.forEach(criterio => {
										if (criterio.key == risco.pcmso_criterio_ref) {
											verifica3 = "Sim"
										}
									})
								]
								)

								if (verifica1 !== "Sim") {
									response[0].data.resultado.push({ key: risco.pcmso_anexo, value: risco.pcmso_anexo, text: risco.pcmso_anexo })
								}
								setState5(response[0].data.resultado)

								api.get("sugestoes/9?pcmso=" + risco.pcmso_anexo_ref).then(response => {
									if (verifica2 !== "Sim") {
										response.data.resultado.push({ key: risco.pcmso_risco, value: risco.pcmso_risco, text: risco.pcmso_risco })
									}
									setState6(response.data.resultado)
								}).catch(error => {
									setState6([{ key: risco.pcmso_risco, value: risco.pcmso_risco, text: risco.pcmso_risco }])
								})

								api.get("sugestoes/10?pcmso=" + risco.pcmso_anexo_ref).then(response => {
									if (verifica3 !== "Sim") {
										response.data.resultado.push({ key: risco.cmso_criterio, value: risco.pcmso_criterio, text: risco.pcmso_criterio })
									}
									setState7(response.data.resultado)
								}).catch(error => {
									setState7([{ key: risco.pcmso_criterio, value: risco.pcmso_criterio, text: risco.pcmso_criterio }])
								})

							})

						}
					})

				});

				if (sessionStorage.getItem('dashboardPlano') === "true") {
					window.scrollTo(0, document.body.scrollHeight);
					setModalShow(true)
					setAbrirPlano('Sim')
				}

			} catch (error) {

				aviso('error', error)

			} finally {

				setCarregando(false)

			}

		} initial()

	}, [])

	const DropAnexos = React.useMemo(() =>
	(
		<Dropdown
			options={state5}
			placeholder="Selecione"
			search
			selection
			lazyLoad={true}
			clearable
			hidden={vin_pcmso == "Sim" ? false : true}
			fluid
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues5}
			searchQuery={searchQuery5}
			onAddItem={handleAddition1}
			onChange={handleChange5}
			onSearchChange={handleSearchChange5}
		/>
	), [currentValues5, state5, vin_pcmso, searchQuery5])

	const DropUnidadeMedida = React.useMemo(() =>
	(
		<Dropdown
			inline
			options={optionsUnidadeMedida}
			placeholder="Selecione"
			noResultsMessage="Sem resultados"
			value={unidade_medida}
			onChange={changeUnidadeMedida}
		/>
	), [optionsUnidadeMedida, unidade_medida])

	const DropParametros = React.useMemo(() =>
	(
		<Dropdown
			inline
			options={optionsParametros}
			placeholder="Selecione"
			noResultsMessage="Sem resultados"
			value={parametro}
			onChange={changeParametro}
		/>
	), [optionsParametros, parametro])

	const DropConfirmeLaudo = React.useMemo(() =>
	(
		<Dropdown
			options={optionsLaudo}
			placeholder="Selecione"
			search
			selection
			lazyLoad={true}
			clearable
			hidden={vin_laudo === "Sim" ? false : true}
			fluid
			value={currentValues8}
			searchQuery={searchQuery8}
			onChange={handleChange8}
			onSearchChange={handleSearchChange8}
		/>
	), [currentValues8, optionsLaudo, vin_laudo, searchQuery8])

	const DropPeriodicidade = React.useMemo(() =>
	(
		<Dropdown
			options={[{ key: 1, value: 'Permanente', text: 'Permanente' },
			{ key: 2, value: 'Ocasional', text: 'Ocasional' },
			{ key: 3, value: 'Intermitente', text: 'Intermitente' }]}
			placeholder="Selecione"
			search
			selection
			clearable
			hidden={vin_laudo === "Sim" ? false : true}
			fluid
			value={periodicidade}
			onChange={handleChange9}
		/>
	), [periodicidade, vin_laudo])

	const DropRiscoOcupacional = React.useMemo(() =>
	(
		<Dropdown
			options={state6}
			placeholder="Selecione"
			search
			selection
			clearable
			lazyLoad={true}
			hidden={currentValues5 != "" && vin_pcmso != "Não" ? false : true}
			fluid
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues6}
			searchQuery={searchQuery6}
			onAddItem={handleAddition2}
			onChange={handleChange6}
			onSearchChange={handleSearchChange6}
		/>
	), [currentValues6, state6, currentValues5, vin_pcmso, searchQuery6])

	const DropCriterio = React.useMemo(() =>
	(
		<Dropdown
			options={state7}
			placeholder="Selecione"
			search
			selection
			clearable
			lazyLoad={true}
			hidden={currentValues6 != "" && vin_pcmso != "Não" ? false : true}
			fluid
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues7}
			searchQuery={searchQuery7}
			onAddItem={handleAddition3}
			onChange={handleChange7}
			onSearchChange={handleSearchChange7}
		/>
	), [currentValues7, state7, currentValues6, vin_pcmso, searchQuery7])

	const handleRegister = async (e) => {

		e.preventDefault()
		var just = justificativa

		if (abrirPlano === "Sim") {
			just = ""
		}

		const data = {
			nome: risco,
			tipologia: tipologia,
			perigo: perigo,
			resultado_ergonomico: ergo,
			resultado_ambiental: ambiental,
			cenario_emergencia: cenarios,
			severidade: severidade,
			probabilidade: probabilidade,
			numero_trabalhadores: numTrabalhadores,
			resultado: resultado,
			observacao: observ,
			fontes_externas: fonte_externa,
			abrir_plano_acao: abrirPlano,
			justificativa: just,
			matriz_propria: utilizarMatriz,
			ordem: ordem,
			nao_apli: naoApli,
			nao_cont: naoCont,
			vin_pcmso: vin_pcmso,
			pcmso_anexo: pcmso_anexo,
			pcmso_anexo_ref: currentValues5,
			pcmso_risco: pcmso_risco,
			pcmso_risco_ref: currentValues6,
			pcmso_exames: pcmso_exames,
			pcmso_cas: pcmso_cas,
			pcmso_criterio: pcmso_criterio,
			pcmso_criterio_ref: currentValues7,
			pcmso_periodicidade: pcmso_periodicidade,
			pcmso_observ: pcmso_observ,
			vin_laudo: vin_laudo
		}

		var response
		if(vin_laudo === 'Sim'){
			response = await Promise.all([
				api.get('laudo_quiz?laudo_id=' + sessionStorage.getItem('laudoId')),
				api.get('laudo_conclusao?laudo_id=' + sessionStorage.getItem('laudoId'))
			])
		}else{
			response = []
			response[0] = {}
			response[0].data = {}
			response[0].data.resultado = {}
			response[1] = {}
			response[1].data = {}
			response[1].data.resultado = {}
		}
	
		var mensagem
		if ((vin_pcmso === "Sim" && FullLicensed && (pcmso_anexo === "" || pcmso_risco === "" || pcmso_criterio === "" || pcmso_exames === "" || pcmso_periodicidade === "")) || abrirPlano === "" || (utilizarMatriz === "Não" && abrirPlano !== "Sim" && resultado > 19 && justificativa === '') || severidade === '' || utilizarMatriz === '' || probabilidade === '' || (fonte_externa === '' && FullLicensed) || (abrirPlano === "Sim" && planos.length < 1) || (vin_pcmso === '' && FullLicensed) || (response[0]?.data?.resultado.length < 1 && avaliacaoTrabalhista === 'Qualitativo' && vin_laudo === 'Sim') || (amostras.length < 1 && vin_laudo === 'Sim') || (periodicidade === '' && vin_laudo === 'Sim') || (currentValues8 === '' && vin_laudo === 'Sim') || (response[1]?.data?.resultado < 1 && vin_laudo === 'Sim')) {

			if (utilizarMatriz === "Não" && abrirPlano !== "Sim" && resultado > 19 && justificativa === '') {
				aviso('warning', "Justificativa obrigatória!")
			} else if (vin_pcmso === "Sim" && pcmso_anexo == "") {
				aviso('warning', "Preencha a confirmação do risco no PCMSO!")
			} else if (vin_pcmso === "Sim" && pcmso_risco == "") {
				aviso('warning', "Preencha o risco ocupacional no PCMSO!")
			} else if (vin_pcmso === "Sim" && pcmso_criterio == "") {
				aviso('warning', "Preencha o critério no PCMSO!")
			} else if (vin_pcmso === "Sim" && pcmso_exames == "") {
				aviso('warning', "Preencha o exame no PCMSO!")
			} else if (abrirPlano === "Sim" && planos.length < 1) {
				aviso('warning', "Cadastre ao menos um plano de ação")
			} else if (response[0]?.data?.resultado?.length < 1 && avaliacaoTrabalhista === 'Qualitativo' && vin_laudo === 'Sim') {
				aviso('warning', "Obrigatório responder as perguntas!")
			} else if (amostras.length < 1 && vin_laudo === 'Sim') {
				aviso('warning', "Cadastre ao menos um registro de amostra!")
			} else if (response[1]?.data?.resultado < 1 && vin_laudo === 'Sim') {
				aviso('warning', "Defina uma conclusão para o laudo")
			}else{
				aviso('warning', "Preencha todos os campos!")
			}

		} else {
			setCarregando(true)

			try {

				await api.patch("risco/" + riscoId, data)
				if(vin_laudo === 'Sim'){
					await api.patch('laudo/' + sessionStorage.getItem('laudoId'), { periodicidade })
				}
				if ((sessionStorage.getItem('laudoId') !== null && sessionStorage.getItem('laudoId') !== '') && vin_laudo === 'Não') {
					await api.delete('laudo/' + sessionStorage.getItem('laudoId'))
					sessionStorage.removeItem('laudoId')
				}

				mensagem = "Risco atualizado com sucesso"

				aviso('success', mensagem)
				setCarregando(false)
				history.push('/GerenciarRiscos')


			} catch (error) {

				aviso('error', error)

			}
		}
	}

	const handleRegister2 = async (e) => {

		e.preventDefault()

		const data = {
			vin_laudo: vin_laudo
		}

		try {
			setCarregando(true)
			const response = await Promise.all([
				api.get('laudo_quiz?laudo_id=' + sessionStorage.getItem('laudoId')),
				api.get('laudo_conclusao?laudo_id=' + sessionStorage.getItem('laudoId'))
			])
			var mensagem
			if (currentValues8 === '' || periodicidade === '' || response[1].data.resultado.length < 1 || amostras.length < 1 || (response[0].data.resultado.length < 1 && (avaliacaoTrabalhista === 'Qualitativo' || avaliacaoTrabalhista === ''))) {
				if(amostras.length < 1){
					aviso('warning', "Cadastre ao menos um registro de amostra!")
				}else if(response[1].data.resultado.length < 1){
					aviso('warning', "Defina uma conclusão para o laudo")
				}else if((response[0].data.resultado.length < 1 && (avaliacaoTrabalhista === 'Qualitativo' || avaliacaoTrabalhista === ''))){
					aviso('warning', "Obrigatório responder as perguntas!")
				}else{
					aviso('warning', "Preencha todos os campos!")
				}
			} else {
				setCarregando(true)
				await api.patch("risco/" + riscoId, data)
				await api.patch('laudo/' + sessionStorage.getItem('laudoId'), { periodicidade })
				
				mensagem = "Laudo atualizado com sucesso"
				aviso('success', mensagem)
				setCarregando(false)
				window.scrollTo(0, document.body.scrollHeight);
			}
			
		} catch (error) {
			aviso('error', error)
		}finally{
			setCarregando(false)
		}
	}

	const MostrarResultado = React.useMemo(() => {


		if (severidade !== "" && probabilidade !== "" && utilizarMatriz === "Não") {
			setResultado(parseInt(severidade) * parseInt(probabilidade))
		}

		return (
			<div>

				{severidade !== "" && probabilidade !== "" && utilizarMatriz !== "Sim" ? <div >

					<div className="resultGroup">
						<h2>Resultado: {resultado}</h2>
						<ReactTooltip></ReactTooltip>
						<div className="buttonsImgs">
							<div className="imgButton">
								<button className="img2" data-tip='Mais informações' onClick={() => setModalShow4(true)} ><FaInfo /></button>
								{modalShow4 ?
									<Lightbox
										mainSrc={imgResultado}
										onCloseRequest={() => setModalShow4(false)}
									/> : null}

							</div>
						</div>
					</div>
					<div>
						{resultado < 5 ? <ul>
							<li>Não são requeridos controles adicionais, a organização deve garantir a manutenção das condições de trabalho e as medidas de controle existentes.</li>
						</ul> : resultado < 12 ? <ul>
							<li>Uma avaliação adicional da probabilidade deve ser realizada quando a severidade for alta ou altíssima.</li>
							<li>Implementar ações de controle para o nível de risco não sair dessa classificação.</li>
						</ul> : resultado < 20 ? <ul>
							<li>O trabalho deve ser observado continuamente até que sejam adotadas medidas que eliminem ou reduzam o risco, priorizando-se medidas de caráter coletivo.</li>
							<li>Implementar urgente as ferramentas preventivas de redução de riscos. </li>
						</ul> : <ul>
							<li>O trabalho não deve ser iniciado ou continuado, até que sejam adotadas medidas que eliminem ou reduzam o risco, priorizando-se medidas de caráter coletivo.</li>
							<li>Se não é possível eliminar, reduzir ou controlar o risco, o trabalho deve permanecer parado.</li>
							<li>Implementar urgente as ferramentas preventivas de redução de riscos. </li>
						</ul>
						}
					</div>
				</div> : null}
			</div>
		)

	}, [severidade, probabilidade, resultado, utilizarMatriz, modalShow4])

	async function handleDeletePlano(id) {
		try {
			await api.delete(`plano_acao/${id}`)
			setPlanos(planos.filter(plano => plano.id !== id))
			aviso('success', 'Plano removido com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleDeleteAmostra(id) {
		try {
			await api.delete(`amostra/${id}`)
			setAmostras(amostras.filter(amostra => amostra.id !== id))
			UpdateAmostras()
			aviso('success', 'Amostra removida com sucesso')
		} catch (error) {
			aviso('error', error)
		}
	}

	async function UpdatePlanos() {
		try {
			await api.get(`plano_acao?risco_id=${sessionStorage.getItem('riscoId')}`).then(response => {
				setPlanos(response.data.resultado)
			})
		} catch (error) {
			aviso('error', error)
		}
	}

	function ibutg_m(amostras) {
		var soma = 0.0
		amostras.forEach(amostra => {
			soma = soma + parseFloat(amostra.calor_ibutg) * parseFloat(amostra.calor_tempo)
		})
		setCalorIbutgM((soma / 60).toFixed(2))
	}

	function metabolismo_m(amostras) {
		var soma = 0.0
		amostras.forEach(amostra => {
			soma = soma + parseFloat(amostra.calor_metabolismo) * parseFloat(amostra.calor_tempo)
		})
		let metabolismo = (soma / 60).toFixed(2)
		setCalorMetabolismoM(metabolismo)
		if (metabolismo !== '' && metabolismo !== 0) {
			try {
				api.get(`laudo_calor_limite?metabolismo=${metabolismo}`).then(limites => {
					setCalorlimiteLt(limites.data.resultado.ibutg_leo)
					setCalorlimiteNa(limites.data.resultado.ibutg_na)
				})
			} catch (error) {
				aviso('error', error)
				console.log(error)
			}
		}
	}

	async function UpdateAmostras() {
		try {
			await api.get(`amostra?laudo_id=${sessionStorage.getItem('laudoId')}`).then(async response => {
				setAmostras(response.data.resultado)
				var somaExposicao = 0
				response.data.resultado.forEach(item => {
					somaExposicao = somaExposicao + item.calor_tempo
				})
				if (response.data.resultado.length === 1) {
					setParametro('mav')
					api.patch('laudo/' + sessionStorage.getItem('laudoId'), { parametro: 'mav' })
				}
				setSomaTempo(somaExposicao)
				ibutg_m(response.data.resultado)
				metabolismo_m(response.data.resultado)
				var estatisticas = await api.post('laudo_estatistica', { amostras: response.data.resultado, filtro: sessionStorage.getItem('tipo_laudo') })
				setEstatisticas(estatisticas.data.resultado)
			})
		} catch (error) {
			console.log(error)
			aviso('error', error)
		}
	}

	return (

		<div>
			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
			<Menu />
			<Amostra show={modalShowAmostra} tipo={optionsLaudo.filter((item) => item.value === currentValues8)[0]} soma={somaTempo} backdrop="static"
				onHide={() => {
					setModalShowAmostra(false)
					UpdateAmostras();
				}} />
			<Conclusao show={modalShowConclusao} backdrop="static" onHide={() => { setModalShowConclusao(false) }} />
			<Quiz show={modalShowPergunta} backdrop="static" tipo={tipo_laudo} onHide={() => { setModalShowPergunta(false) }} />

			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Organização: {sessionStorage.getItem('estabelecimentoName')}<br />{estabelecimentoModalidade === 'Tarefa' ? 'Tarefa' : 'Atividade'}: {tarefaName}<br /><div className={tipologia} /> Risco {tipologia == "Acidente" ? "de acidente" : tipologia}: {riscoName}
				<br />
				Medidas de controle: {sessionStorage.getItem('medidasControleAvl')}
			</h4></div>

			<div className="basic-content">

				<div className="titulo2">
					<h1>Avaliação do risco</h1>
				</div>
				<div className="avlRisco-box">

					<div className="back-button">
						<Link to='/CadastrarRisco'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
					</div>
					<div className="borda-avl">
						<div className="aux-99">
							<div className="matriz-propria-box">
								<label htmlFor="matriz">Utilizar matriz própria?</label>
								<select value={utilizarMatriz} onChange={e => { setUtiliza(e.target.value); setSeveridade("0"); setProbabilidade("0"); setResultado("") }}>
									<option value="" disabled>Selecione</option>
									<option value="Sim">Sim</option>
									<option value="Não">Não</option>
								</select>
							</div>

							<div className="severidade-box">
								<div className="group">
									<div className="selection">
										<label htmlFor="severidade">Severidade</label>
										{utilizarMatriz == "Não" ? <select value={severidade} onChange={e => setSeveridade(e.target.value)}>
											<option value="0" disabled>Selecione</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select> : <input type="text" value={severidade} onChange={e => { setSeveridade(e.target.value) }} />}
									</div>
									<div className="imgButton">
										{utilizarMatriz === "Não" ? <button data-tip="Matriz severidade" className="img" onClick={() => setModalShow2(true)} ><FaTable /></button> : null}
										{modalShow2 ?
											<Lightbox
												mainSrc={tipologia === 'Acidente' ? sevAcidentes : tipologia === 'Biológico' ? sevBio : tipologia === 'Ergonômico' ? sevErgo : sevHC}
												onCloseRequest={() => setModalShow2(false)}
											/> : null}
									</div>
								</div>
							</div>

							<div className="probabilidade-box">
								<div className="group">
									<div className="selection">
										<label htmlFor="probabilidade">Probabilidade</label>
										{utilizarMatriz == "Não" ? <select value={probabilidade} onChange={e => setProbabilidade(e.target.value)}>
											<option value="0" disabled>Selecione</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select> : <input type="text" value={probabilidade} onChange={e => { setProbabilidade(e.target.value) }} />}
									</div>
									<div className="imgButton">
										{utilizarMatriz == "Não" ? <button data-tip="Matriz probabilidade" className="img" onClick={() => setModalShow3(true)} ><FaTable /></button> : null}
										{modalShow3 ?
											<Lightbox
												mainSrc={tipologia === 'Acidente' ? probAcidentes : tipologia === 'Biológico' ? probBio : tipologia === 'Ergonômico' ? probErgo : probHC}
												onCloseRequest={() => setModalShow3(false)}
											/> : null}
									</div>
								</div>
							</div>

							<div hidden={utilizarMatriz == "Sim" ? false : true} className="numTrabalhadores-box">
								<label htmlFor="numTrabalhadores">Resultado</label>
								<input type="text" value={resultado} onChange={e => { setResultado(e.target.value) }} />
							</div>

							<div className="numTrabalhadores-box">
								<label htmlFor="numTrabalhadores">Número de trabalhadores</label>
								<input disabled type="number" value={numTrabalhadores} onChange={e => { setNumTrabalhadores(e.target.value) }} />
							</div>
						</div>
					</div>

					{resultado > 0 && utilizarMatriz == "Não" ? <div className={resultado < 5 ? "box-verde" : resultado < 12 ? "box-amarelo" : resultado < 20 ? "box-laranja" : "box-vermelho"}>
						{MostrarResultado}
					</div> : null}
					{FullLicensed ?
						<div className="labelpcmso">{tipologia == "Ergonômico" ? "Resultado Ergonômico: " : tipologia == "Acidente" ? "Risco de acidente: " : "Resultado Ambiental: "}<b>{tipologia == "Ergonômico" ? ergo : tipologia == "Acidente" ? riscoName : ambiental}</b></div>
						: null}

					{FullLicensed ?
						<div className="borda-avl">

							<div className="risco-linha2">

								<div className="risco-metade7">

									<div className="risco-selecione2">
										<label>Vincular ao PCMSO   <FiInfo style={{ cursor: "pointer" }} data-tip={textVinpcmso} data-for="aux"></FiInfo></label>
										<select value={vin_pcmso} onChange={e => setVinPcmso(e.target.value)}>
											<option value="" disabled>Selecione</option>
											<option value="Sim">Sim</option>
											<option value="Não">Não</option>
										</select>
									</div>
									<div className="risco-selecione2">
										<label hidden={vin_pcmso != "Sim" ? true : false}>Confirme o risco   <FiInfo style={{ cursor: "pointer" }} data-tip={textRiscopcmso} data-for="aux"></FiInfo></label>
										{DropAnexos}
									</div>
								</div>

								<div className="risco-metade7">
									<div className="risco-selecione2">
										<label hidden={currentValues5 == "" || vin_pcmso == "Não" ? true : false} style={{ paddingTop: "8px" }}>Risco ocupacional   <FiInfo style={{ cursor: "pointer" }} data-tip={textRiscoOcpcmso} data-for="aux" ></FiInfo></label>
										{DropRiscoOcupacional}
									</div>
									<div className="risco-selecione2">
										<label hidden={currentValues6 == "" || vin_pcmso == "Não" ? true : false} disabled={isNaN(currentValues6) ? false : true} style={{ paddingTop: "8px" }}>Exame</label>
										<textarea hidden={currentValues6 == "" || vin_pcmso == "Não" ? true : false} disabled={isNaN(currentValues6) ? false : true} className="observ-pcmso" aria-multiline="true" type="longtext" value={pcmso_exames} onChange={e => { setPE(e.target.value) }} />
									</div>
								</div>

								<div className="risco-metade7">

									<div className="risco-selecione2">
										<label hidden={currentValues6 == "" || vin_pcmso == "Não" ? true : false} style={{ paddingTop: "8px" }}>Critério   <FiInfo style={{ cursor: "pointer" }} data-tip={textCriteriopcmso} data-for="aux" ></FiInfo></label>
										{DropCriterio}
									</div>

									<div className="risco-selecione2">
										<label hidden={currentValues7 == "" || vin_pcmso == "Não" ? true : false} disabled={isNaN(currentValues7) ? false : true} style={{ paddingTop: "8px" }}>Periodicidade</label>
										<textarea hidden={currentValues7 == "" || vin_pcmso == "Não" ? true : false} disabled={isNaN(currentValues7) || (currentValues5 == '10' || currentValues5 == '11' || currentValues5 == '12') ? false : true} className="observ-pcmso" aria-multiline="true" type="longtext" value={pcmso_periodicidade} onChange={e => { setPP(e.target.value) }} />
									</div>

								</div>

								<label hidden={currentValues7 == "" || vin_pcmso == "Não" ? true : false} style={{ paddingTop: "8px" }}>Notas</label>
								<textarea hidden={currentValues7 == "" || vin_pcmso == "Não" ? true : false} className="observ-pcmso" aria-multiline="true" type="longtext" onChange={e => setPO(e.target.value)} value={pcmso_observ} placeholder={"Digite aqui"} />
							</div>
						</div>
						: null}
					{FullLicensed ?
						<div className="borda-avl">

							<div className="risco-linha2">
								<div className="risco-metade7">

									<div className="risco-selecione-laudo">
										<label>Realizar laudo?   <FiInfo style={{ cursor: "pointer" }} data-tip={"Marque “Sim” se necessita realizar um Laudo de Insalubridade, Aposentadoria Especial – LTCAT,Periculosidade"} data-for="aux"></FiInfo></label>
										<select value={vin_laudo} onChange={e => { setVinLaudo(e.target.value)}}>
											<option value="" disabled>Selecione</option>
											<option value="Sim">Sim</option>
											<option value="Não">Não</option>
										</select>
									</div>

									<div className="risco-selecione-laudo2">
										<label hidden={vin_laudo !== "Sim" ? true : false}>Confirme o risco   <FiInfo style={{ cursor: "pointer" }} data-tip={'Confirme o “Risco avaliado”, muito cuidado para não confirmar risco diferente do cadastrado no PGR.'} data-for="aux"></FiInfo></label>
										{DropConfirmeLaudo}
									</div>

									<div className="risco-selecione-laudo2">
										<label hidden={vin_laudo !== "Sim" ? true : false}>Periodicidade de exposição   <FiInfo style={{ cursor: "pointer" }} data-tip={textPeriodicidade} data-for="aux"></FiInfo></label>
										{DropPeriodicidade}
									</div>
								</div>
								{vin_laudo === "Sim" && currentValues8 !== '' && (
									<div className="dashboard-main">
										<div style={{ display: 'grid', margin: '5px' }}>
											{unidade_medida !== 'Não aplicável' && (
												<div className='filter-laudo'>
													<div className='laudo-unidade-medida'>
														<label style={{ marginRight: '10px' }}>Unidade de medida das amostras: </label>
														<div>
															{DropUnidadeMedida}
														</div>
													</div>
													{(estatisticas['ma'] !== null && estatisticas['ma'] !== undefined) ?
														<div className='laudo-unidade-medida'>
															<label style={{ marginRight: '10px' }}>Parâmetro estatístico: </label>
															<div>
																{DropParametros}
															</div>
														</div>
														: (tipo_laudo?.startsWith('Ruído')) && (
															(estatisticas['mav_dose'] !== null && estatisticas['mav_dose'] !== undefined) && (
																<div className='laudo-unidade-medida'>
																	<label style={{ marginRight: '25px' }}>Parâmetro estatístico: </label>
																	<div>
																		{DropParametros}
																	</div>
																</div>
															)
														)}
												</div>
											)}

											{tipo_laudo?.startsWith('Calor') ?
												<div style={{ display: 'grid' }}>
													<ReactTooltip />
													<label>Limite de exposição ocupacional: {calor_limite_lt?.toString().replace('.', ',')} °C</label>
													<label>Nível de ação: {calor_limite_na?.toString().replace('.', ',')} °C</label>
													<div style={{ display: 'flex' }}>
														{parseFloat(calor_limite_lt) > parseFloat(calor_ibutg_m)? <div data-tip="Abaixo do limite de tolerância" className='Físico' /> : parseFloat(limite) === parseFloat(estatisticas[parametro]) ? <div data-tip="No limite de tolerância" className='Ergonômico' /> : <div data-tip="Acima do limite de tolerância" className='Químico' />}
														<label>IBUTG médio ponderado: {calor_ibutg_m?.toString().replace('.', ',')} °C</label>
													</div>
													<label>Metabolismo médio ponderado: {calor_metabolismo_m?.toString().replace('.', ',')} W</label>
												</div>
												:
												!tipo_laudo?.startsWith('Ruído')?
													<div style={{ display: 'grid' }}>
														{limite !== 'Não aplicável' ?
															<label>Limite de tolerância NR 15: {limite} {unidade_medida}</label> : <label>Limite de tolerância NR 15: Não aplicável</label>
														}
														{optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria !== 'Quantitativo' ?
															limite === 'Não aplicável'?
																<div>
																	<label>Decreto 3.048/99: {optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria} </label>
																	<label style={{color: 'red', marginLeft:'5px'}}> (Clique em responder perguntas)</label>
																</div>
																:
																<div>
																	<label>Decreto 3.048/99: {optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria} </label>
																	{!optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria.startsWith('Sem previsão') && (
																		<label style={{color: 'orange', marginLeft:'5px'}}>(É desejável responder as perguntas)</label>
																	)}
																</div>
															:
															<div>
																<label>Decreto 3.048/99: {limite} {unidade_medida}</label>
															</div>
														}
														{(estatisticas[parametro] !== null && estatisticas[parametro] !== undefined) && (
															<div style={{ display: 'grid' }}>
																<ReactTooltip />
																{limite !== 'Não aplicável' ?
																	(!isNaN(parseFloat(estatisticas[parametro])) || amostras[0]?.resultado.startsWith('<'))?
																		<div style={{ display: 'flex' }}>
																			{parseFloat(limite) > parseFloat(estatisticas[parametro]?.toString().replace(',', '.')) || amostras[0]?.resultado.startsWith('<') ? <div data-tip="Abaixo do limite de tolerância" className='Físico' /> : parseFloat(limite) === parseFloat(estatisticas[parametro]?.toString().replace(',', '.')) ? <div data-tip="No limite de tolerância" className='Ergonômico' /> : <div data-tip="Acima do limite de tolerância" className='Químico' />}
																			<label>{optionsParametros.filter(item => item.value === parametro)[0]?.text}: {amostras[0]?.resultado.startsWith('<')? amostras[0]?.resultado : estatisticas[parametro]} {unidade_medida}</label>
																		</div>
																		:
																		null
																	:
																	!isNaN(parseFloat(estatisticas[parametro]))?
																		<label>{optionsParametros.filter(item => item.value === parametro)[0]?.text}: {estatisticas[parametro]} {unidade_medida}</label>
																		:
																		null
																}

																{amostras.length > 1 && !isNaN(parseFloat(estatisticas[parametro])) && (
																	<div className='estatistico'>
																		<label> P 95%: {estatisticas['p95']} {unidade_medida}</label>
																		<label> DPG: {estatisticas['dpg']}</label>
																		<label> LSC: {estatisticas['lsc']} {unidade_medida}</label>
																	</div>
																)}
															</div>
														)}
													</div>
													:
													(tipo_laudo?.startsWith('Ruído')) && (
														<div style={{ display: 'grid' }}>
															{limite !== 'Não aplicável' ?
																<label>Limite de tolerância NR 15: {limite} {unidade_medida}</label> : <label>Limite de tolerância NR 15: Não aplicável</label>
															}
															{optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria !== 'Quantitativo' ?
																<div>
																	<label>Decreto 3.048/99: {optionsLaudo.filter((item) => item.value === currentValues8)[0]?.avaliacao_previdenciaria} </label>
																</div>
																:
																<div>
																	<label>Decreto 3.048/99: {limite} {unidade_medida}</label>
																</div>
															}
															{(estatisticas[parametro + '_dose'] !== null && estatisticas[parametro] !== undefined) && (
																<div style={{ display: 'grid' }}>
																	<ReactTooltip />
																	{limite !== 'Não aplicável' ?
																		<div style={{ display: 'flex' }}>
																			<ReactTooltip />
																			{parseFloat(limite) > parseFloat(estatisticas[parametro + '_dose']?.b?.toString().replace(',', '.'))? <div data-tip="Abaixo do limite de tolerância" className='Físico' /> : parseFloat(limite) === parseFloat(estatisticas[parametro + '_dose']?.b?.toString().replace(',', '.')) ? <div data-tip="No limite de tolerância" className='Ergonômico' /> : <div data-tip="Acima do limite de tolerância" className='Químico' />}
																			<label>{optionsParametros.filter(item => item.value === parametro)[0]?.text}: {estatisticas[parametro + '_dose']?.b} {unidade_medida}</label>
																			<label style={{ marginLeft: '10px' }}>Dose: {estatisticas[parametro + '_dose']?.a}</label>
																		</div>
																		:
																		<label>{optionsParametros.filter(item => item.value === parametro)[0]?.text}: {estatisticas[parametro + '_dose']} {unidade_medida}</label>
																	}

																	{amostras.length > 1 && (
																		<div className='estatistico'>
																			<label> P 95%: {estatisticas['p95_dose']} {unidade_medida}</label>
																			<label> DPG: {estatisticas['dpg_dose']} </label>
																			<label> LSC: {estatisticas['lsc_dose']} {unidade_medida}</label>
																		</div>
																	)}
																</div>
															)}
														</div>
													)

											}
										</div>

										<div id="scrollableDiv" className="laudo-ListBox">

											{amostras.map((amostra) => (
												<div key={amostra.id} className="dashboard-ListItem">
													<div className="dashboard-item">
														<div className="dashboard-item-block">
															<div className="dashboard-item-block-p1">
																<div className="dashboard-item-p1">
																	<a><b>Data avaliação: </b>{new Date(amostra.data_avaliacao).toISOString().split('T')[0].split('-').reverse().join('/')}</a>
																	<a><b>Referência: </b>{amostra.referencia}</a>
																	{tipo_laudo?.startsWith('Calor') ?
																		<div style={{ display: 'grid' }}>
																			<a><b>IBUTG: </b>{amostra.calor_ibutg?.toString().replace('.', ',')}</a>
																			<a><b>Tempo: </b>{amostra.calor_tempo}</a>
																			<a><b>Metabolismo: </b>{amostra.calor_metabolismo} W</a>
																		</div>
																		:
																		tipo_laudo?.startsWith('Ruído con') ?
																			<div style={{ display: 'grid' }}>
																				<a><b>Dose {amostra.ruido_tipo}: </b>{amostra.ruido_dose?.toString().replace('.', ',')}</a>
																				<a><b>NeN: </b>{amostra.ruido_nen}</a>
																				<a><b>dB(A): </b>{amostra.ruido_dba}</a>
																				<a><b>Tempo de exposição: </b>{amostra.tempo_exposicao} min</a>
																			</div>
																			: tipo_laudo?.startsWith('Ruído impulsivo') ?
																				<div style={{ display: 'grid' }}>
																					<a><b>Dose {amostra.ruido_tipo}: </b>{amostra.ruido_dose?.toString().replace('.', ',')}</a>
																					<a><b>NeN: </b>{amostra.ruido_nen}</a>
																					<a><b>dB: </b>{amostra.ruido_dba}</a>
																					<a><b>Tempo de exposição: </b>{amostra.tempo_exposicao} min</a>
																				</div>
																				:
																				<a><b>Resultado: </b>{amostra.resultado} {unidade_medida !== 'Não aplicável' ? unidade_medida : ''}</a>
																	}

																</div>

																<div className="dashboard-icons">
																	<i className="dashboard-edit">
																		<FiEdit data-tip="Editar" data-for={toString(amostra.id)} onClick={e => { sessionStorage.setItem('AmostraId', amostra.id); setModalShowAmostra(true) }} />
																	</i>
																	<i className="dashboard-trash">
																		<FiTrash2 data-tip="Excluir" data-for={toString(amostra.id)}
																			onClick={() => {
																				const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A AMOSTRA SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão:')
																				if (r === "excluir") {
																					handleDeleteAmostra(amostra.id);
																				} else {
																					aviso("warning", "Nome incorreto, exclusão cancelada", true)
																				}
																			}} />
																	</i>

																</div>
															</div>
														</div>

													</div>

												</div>

											))}
										</div>

										<div className='laudo-footer'>
											Amostras registradas: {amostras.length}
											<div className='laudo-footer-buttons'>
												<button disabled={currentValues8 === ''} onClick={() => { setModalShowAmostra(true); sessionStorage.removeItem('AmostraId') }}> Cadastrar Amostra</button>
												<button disabled={currentValues8 === ''} onClick={() => { setModalShowPergunta(true); }}> Responder Perguntas</button>
												<button disabled={currentValues8 === ''} onClick={() => { setModalShowConclusao(true); }}> Definir Conclusão</button>
												<button disabled={currentValues8 === ''} onClick={handleRegister2}> Salvar</button>
											</div>
										</div>

									</div>
								)}

							</div>
						</div>
						: null}

					{FullLicensed ?
						<div className="risco-linha2">

							<div className="risco-metade6">

								<div className="risco-selecione2">
									<label>Cenário de emergência</label>
									<select value={cenarios} onChange={e => setCenarios(e.target.value)}>
										<option value="" disabled>Selecione</option>
										<option value="Sim">Sim</option>
										<option value="Não">Não</option>
										<option value="Não se aplica">Não se aplica</option>
									</select>

									<label>Fontes externas</label>
									<select value={fonte_externa} onChange={e => setFonteExterna(e.target.value)}>
										<option defaultValue="Não">Não</option>
										<option value="Sim">Sim</option>
									</select>
								</div>

								<div className="risco-selecione2">
									<label htmlFor="">Anotações</label>
									<textarea className="observ-pgr" aria-multiline="true" type="longtext" onChange={e => setObserv(e.target.value)} value={observ} placeholder={"Digite aqui"} />
								</div>
							</div>
						</div>
						: null}

					<div className="plano-propria-box">
						<label htmlFor="plano">Plano de ação?</label>
						<select value={abrirPlano} onChange={e => setAbrirPlano(e.target.value)}>
							<option value="" disabled>Selecione</option>
							<option value="Não">Não</option>
							<option value="Não se aplica">Não se aplica</option>
							<option value="Risco aceitável">Risco aceitável</option>
							<option value="Risco nível de ação">Risco nível de ação</option>
							<option value="Plano de ação geral">Plano de ação geral</option>
							<option value="Sim">Sim</option>
						</select>
					</div>

					{abrirPlano === 'Sim' ?

						<div className="medidas-1">
							<h3>Planos de ações</h3>
							<div>
								{planos.length ? planos.map(plano => (

									<div key={plano.id} className="ListaPlano-tarefa2">
										<ReactTooltip id={toString(plano.id)} place="top" effect="solid" />

										<div>
											<h3 className="ListaPlano-nome2">{plano.o_que}</h3>
										</div>
										<div className="ListaPlano-icones2">
											<i >
												<FiEdit data-tip={"Editar"} data-for={toString(plano.id)} className="go-edit2" onClick={() => {
													sessionStorage.setItem('planoId', plano.id)
													setModalShow(true)
												}} />
											</i>
											<i>
												<FiTrash2 data-tip={"Excluir"} data-for={toString(plano.id)}
													className="go-trash2"
													onClick={() => {
														const r = window.confirm('Tem certeza que deseja deletar esse plano?')
														if (r === true) {
															handleDeletePlano(plano.id);
														}
													}} />

											</i>
										</div>
									</div>
								)) : carregando ? null : <h3>Nenhum registro</h3>}
							</div>
							<div className="novo-elab">
								<button onClick={() => { sessionStorage.setItem("planoId", 0); setModalShow(true) }}> Cadastrar novo plano de ação</button>
								<PlanoAcao show={modalShow} backdrop="static" onHide={() => {
									setModalShow(false);
									UpdatePlanos();
									if (sessionStorage.getItem('moveTop') === 'true') {
										window.scrollTo(0, 0);
										sessionStorage.removeItem('moveTop')
									}
									if (sessionStorage.getItem('dashboardPlano') == 'true') {
										sessionStorage.removeItem('dashboardPlano')
										UpdatePlanos();
									}
								}
								}
								/>
							</div>
						</div>
						:
						null
					}

					{(abrirPlano !== "Sim" && resultado > 5) || utilizarMatriz === "Sim" ? <div className="justificativa-box">
						<label htmlFor="justificativa">Justificativa</label>
						<textarea type="text" value={justificativa} onChange={e => setJustificativa(e.target.value)} />
					</div> : null}

					<div className='cadastrar'>
						<button onClick={handleRegister}>Salvar risco</button>
					</div>
				</div>
			</div>
			<Loading loading={carregando} message='Carregando...' />
		</div>
	);
}

function PlanoAcao(props) {

	const [o_que, setO_que] = useState('')
	const [quem, setQuem] = useState('')
	const [quando, setQuando] = useState(new Date())
	const [inicio, setInicio] = useState(new Date())
	const [como, setComo] = useState('')
	const [onde, setOnde] = useState('')
	const [porque, setPorque] = useState('')
	const [quanto, setQuanto] = useState('')
	const [riscoId, setRiscoId] = useState('')
	const [tipologia, setTipologia] = useState('')
	const [currentValues, setCurrentValues] = useState('') //pesquisa
	const [options, setOptions] = useState([]) //opcoes de selecao
	const [searchQuery, setSearchQuery] = useState('')
	const [iniciado, setIniciado] = useState(false)
	const [concluido, setConcluido] = useState(false)
	const [prevConcluido, setPrevConcluido] = useState(false)

	const [habilita, setHabilita] = useState(false)

	const { aviso } = React.useContext(Context);

	const tarefaId = sessionStorage.getItem('atividadeId')

	const [carregando, setCarregando] = useState(false);

	var textOque = "Qual é a ação necessária/Qual a atividade a ser realizada"
	var textQuem = "Quem é a pessoa responsável por conduzir esse processo"
	var textQuando = "Quando termina o plano"
	var textInicio = "Quando inicia o plano"
	var textComo = "Detalhar como será feito para resolver a ação"
	var textOnde = "Detalhar onde essa ação deve ser realizada ( local, máquina,etc...)"
	var textPorque = "Geralmente é para resolver um problema, para reduzir ou "
		+ "eliminar um risco, aumentar a produtividade, para garantir a manutenção"
	var textQuanto = "Quanto vai custar, é para ter um rastreamento de quanto "
		+ "custou e qual é o payback (tempo de retorno do investimento inicial "
		+ "que foi aplicado no negócio ou projeto)"

	var textPesquisa = "Procure um plano de ação semelhante com base no campo 'O quê?' do 5W2H"
		+ " para agilizar o preenchimento (opcional)"

	const handleChange = (e, { value }) => {

		setCurrentValues(value);
		if (value != "") {
			setHabilita(true)
		} else {
			setHabilita(false)
		}

	}

	const handlePesquisa = () => {

		if ((searchQuery == undefined || searchQuery == "") && (currentValues == undefined || currentValues == "")) {

		} else {
			const r = window.confirm("Deseja puxar as informações do plano de ação " + searchQuery + " ?\n ATENÇÃO: Todos as informações já inseridas nesse plano de ação serão apagadas")

			if (r) {
				api.get("plano_acao?plano=consulta").then(response => {

					response.data.resultado.forEach(item => {

						if (currentValues == item.id) {

							var dat_fim = new Date(item.quando)
							var dat_ini = new Date(item.inicio)
							setO_que(item.o_que)
							setQuem(item.quem)
							setQuando(dat_fim)
							setInicio(dat_ini)
							setComo(item.como)
							setOnde(item.onde)
							setPorque(item.porque)
							setQuanto(item.quanto)
							setIniciado(Boolean(false))
							setConcluido(Boolean(false))
						}

					})
				})
			}
		}

	}

	const DropPesquisa = React.useMemo(() =>
	(
		<Dropdown
			options={options}
			placeholder="Procure por ações( O quê? ) já cadastradas em outros riscos"
			search
			selection
			lazyLoad={true}
			fluid
			clearable
			onChange={handleChange}
			value={currentValues}
		/>
	), [currentValues, options, searchQuery])

	useEffect(() => {

		if (props.show) {
			setCarregando(true)
			async function initial() {

				try {

					setRiscoId(sessionStorage.getItem('riscoId'))
					api.get(`risco?tarefa_id=${tarefaId}`).then(response => {
						response.data.resultado.forEach(risco => {
							if (risco.id == sessionStorage.getItem('riscoId')) {
								setTipologia(risco.tipologia)
							}
						});
					})

					api.get("plano_acao?plano=consulta").then(response => {
						var aux = []

						response.data.resultado.forEach(item => {
							aux.push({ key: item.id, text: item.o_que, value: item.id })
						})
						if (sessionStorage.getItem('planoId') != 0) {
							setOptions(aux.filter(item => item.value != sessionStorage.getItem('planoId')))
						} else {
							setOptions(aux)
						}

					})


					if (sessionStorage.getItem('planoId') != 0) {

						await api.get(`plano_acao?risco_id=${sessionStorage.getItem('riscoId')}`).then(response => {


							response.data.resultado.forEach(item => {

								if (sessionStorage.getItem('planoId') == item.id) {

									var dat_ini
									var dat_fim

									if (item.inicio == undefined) {
										dat_ini = new Date()
									} else {
										dat_ini = new Date(item.inicio)
									}

									if (item.quando == undefined) {
										var dat_fim = new Date()
									} else {
										var dat_fim = new Date(item.quando)
									}

									setO_que(item.o_que)
									setQuem(item.quem)
									setQuando(dat_fim)
									setInicio(dat_ini)
									setComo(item.como)
									setOnde(item.onde)
									setPorque(item.porque)
									setQuanto(item.quanto)
									setIniciado(Boolean(item.iniciado))
									setConcluido(Boolean(item.concluido))
									setPrevConcluido(Boolean(item.concluido))

								}

							})

						})
					}



				} catch (error) {

					aviso('error', error)

				} finally {

					setCarregando(false)
				}

			} initial()
		}


	}, [props.show, sessionStorage.getItem('planoId')])

	const handleRegister = async (e) => {

		e.preventDefault()

		const data = {
			o_que: o_que,
			quem: quem,
			quando: quando,
			inicio: inicio,
			como: como,
			onde: onde,
			porque: porque,
			quanto: quanto,
			iniciado: iniciado,
			concluido: concluido,
			fk_riscos_id: riscoId
		}

		setCarregando(true)

		try {


			if (o_que == "" || quem == "" || inicio == null || quando == null || como == "" || onde == "" || porque == "" || quanto == "" || quando == "") {
				aviso('warning', 'Preencha todos os campos!')
			} else {

				if (inicio > quando) {
					aviso('warning', 'Data de início superior à data de término!')
				} else {

					if (sessionStorage.getItem('planoId') == 0) {
						await api.post('plano_acao?risco_id=' + sessionStorage.getItem('riscoId'), data)
					} else {
						api.patch("plano_acao/" + sessionStorage.getItem('planoId'), data)
					}

					setCarregando(false)
					aviso('success', "Plano de ação salvo com sucesso")
					setQuando(new Date())
					setQuem('')
					setComo('')
					setOnde('')
					setPorque('')
					setQuanto('')
					setO_que('')
					setInicio(new Date())
					props.onHide()
				}

			}

		} catch (error) {

			aviso('error', error)

		} finally {
			setCarregando(false)

		}
	}

	const handleConcluido = () => {
		if (sessionStorage.getItem('dashboardPlano') === 'true' && prevConcluido == false && !concluido == true) {
			aviso('info', 'É conveniente alterar a severidade e probabilidade do risco')
			sessionStorage.setItem('moveTop', true)
		}
		setConcluido(!concluido)
		if (iniciado === false) {
			setIniciado(true)
		}
	}

	const handleIniciado = () => {
		setIniciado(!iniciado)
		if (concluido === true) {
			setConcluido(false)
		}
	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Plano de ação (5W2H)
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>

				<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
				<Loading loading={carregando} message='Carregando...' />

				<div className="plano-pesquisa1">
					<label>Procurar planos de ações semelhantes <FiInfo style={{ cursor: "pointer" }} data-tip={textPesquisa} data-for="aux"></FiInfo> </label>
					<div className="plano-pesquisa2">
						{DropPesquisa}
						<button disabled={!habilita} onClick={handlePesquisa}><FiSearch /></button>
					</div>
				</div>

				<div className="plano-retangulo1">
					<div className="plano-aux1">
						<label htmlFor="">O quê?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOque} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setO_que(e.target.value)} value={o_que} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Quem?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuem} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuem(e.target.value)} value={quem} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Onde?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOnde} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setOnde(e.target.value)} value={onde} />
					</div>
				</div>

				<div className="plano-retangulo2">
					<div className="plano-aux1">
						<label htmlFor="">Por quê?    <FiInfo style={{ cursor: "pointer" }} data-tip={textPorque} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setPorque(e.target.value)} value={porque} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Quanto?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuanto} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuanto(e.target.value)} value={quanto} />
					</div>

					<div className="plano-aux1">
						<label htmlFor="">Como?   <FiInfo style={{ cursor: "pointer" }} data-tip={textComo} data-for="aux"></FiInfo></label>
						<textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setComo(e.target.value)} value={como} />
					</div>
				</div>

				<div className="plano-retangulo3">

					<div className="plano-input">
						<label htmlFor="plano-input">Data Início   <FiInfo style={{ cursor: "pointer" }} data-tip={textInicio} data-for="aux"></FiInfo></label>
						<DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={inicio} onChange={date => setInicio(date)} />
					</div>
					<div className="plano-input">
						<label htmlFor="plano-input">Data Fim   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuando} data-for="aux"></FiInfo></label>
						<DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={quando} onChange={date => setQuando(date)} />
					</div>
					<div className="plano-check">
						<label style={{ marginRight: '5px' }}>Iniciado?</label>
						<Checkbox checked={iniciado} onChange={handleIniciado} />
						<label style={{ marginLeft: '10px', marginRight: '5px' }}>Concluído?</label>
						<Checkbox checked={concluido} onChange={handleConcluido} />
					</div>
				</div>

			</Modal.Body>

			<Modal.Footer>
				<Button onClick={handleRegister}>{sessionStorage.getItem('planoId') == 0 ? "Cadastrar" : "Salvar"}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>

		</Modal>

	)
}

function Amostra(props) {


	const [carregando, setCarregando] = useState(false);
	const [data_avaliacao, setDataAvaliacao] = useState('');
	const [horario, setHorarioMedicao] = useState('')
	const [tempo_exposicao, setTempoExposicao] = useState('')
	const [jornada_diaria, setJornadaDiaria] = useState(480)
	const [jornada_semanal, setJornadaSemanal] = useState('')
	const [equipamentos, setEquipamentos] = useState([])
	const [equipamento_id, setEquipamentoId] = useState(0)
	const [referencia, setRefenciaAmostra] = useState('')
	const [resultado, setResultado] = useState('')
	const [ruido_tipo, setRuidoTipo] = useState('')
	const [ruido_dose, setRuidoDose] = useState('')
	const [ruido_dba, setRuidoDba] = useState('')
	const [ruido_nen, setRuidoNen] = useState('')
	const [calor_tipo_ambiente, setCalorTipoAmbiente] = useState('')
	const [calor_descricao_atividade, setCalorDescricaoAtividade] = useState('')
	const [calor_tempo, setCalorTempo] = useState('')
	const [optionsMetabolismo, setOptionsMetabolismo] = useState([])
	const [calor_metabolismo, setCalorMetabolismo] = useState('')
	const [calor_tbs, setCalorTbs] = useState('')
	const [calor_tbn, setCalorTbn] = useState('')
	const [calor_tg, setCalorTg] = useState('')
	const [calor_ibutg, setCalorIbutg] = useState('')
	const [anotacao, setAnotacao] = useState('')

	const { aviso } = React.useContext(Context);

	var textTipoCalor = "O campo tipo de ambiente deve ser preenchido com a informação se a atividade foi em:<br/>"
	+ "a) Para ambientes internos ou para ambientes externos sem carga solar direto<br/>"
	+ "b) Para ambientes externos com carga solar direta1<br/>"
	+"1 Considera-se carga solar direta quando não há nenhuma interposição entre a radiação solar e o trabalhador exposto, por exemplo, a presença de barreiras como: nuvens, anteparos, telhas de vidro etc."

	function calculo_ibutg(tbs, tbn, tg, tipo) {
		if (tbs !== '' && tbn !== '' && tg !== '' && tipo !== '') {
			if (tipo === 'externo') {
				return parseFloat(tbn * 0.7 + tg * 0.2 + tbs * 0.1).toFixed(2)
			} else {
				return parseFloat(tbn * 0.7 + tg * 0.3).toFixed(2)
			}
		} else {
			return ''
		}
	}

	function calculo_dose(dose, te, tipo) {
		if (dose !== '' && te !== '' && tipo !== '') {
			var a = parseFloat(dose)
			var b = parseFloat(te)
			var dba
			if (tipo === 'q3') {
				dba = ((10 * Math.log10((a / 100))) + 85).toFixed(2)
				return parseFloat(dba)
			} else {
				dba = ((16.61 * Math.log10((a / 100))) + 85).toFixed(2)
				return parseFloat(dba)
			}
		} else {
			return ''
		}
	}

	function calculo_nen(dba, te) {
		if (dba !== '' && te !== '') {
			let a = parseFloat(dba)
			let b = parseFloat(te)
			let nen = (a + (16.61 * Math.log10(b / 480))).toFixed(2)
			return parseFloat(nen)
		} else {
			return ''
		}
	}

	const DropMetabolismo = React.useMemo(() =>
	(
		<Dropdown
			options={optionsMetabolismo}
			placeholder="Selecione"
			search
			selection
			lazyLoad={true}
			clearable
			fluid
			value={calor_metabolismo}
			onChange={(e, { value }) => setCalorMetabolismo(value)}
		/>
	), [calor_metabolismo, optionsMetabolismo])

	async function updateEquipamentos() {
		await api.get('sugestoes/14').then(response => {
			var equipamentos_aux = response.data.resultado.filter(item => item.tipo === 'equipamento').map(({ id, valor, certificado_equipamento, tipo }) => ({ key: id, text: valor, value: valor, certificado_equipamento, tipo }))
			if(props.tipo?.avaliacao_previdenciaria !== 'Quantitativo' && props.tipo?.avaliacao_trabalhista !== 'Quantitativo'){
				equipamentos_aux.push({key:-1, value: 'Atividade caracterizada pela avaliação qualitativa.', text:'Atividade caracterizada pela avaliação qualitativa.'})
				equipamentos_aux.push({key:-2, value: 'Atividade caracterizada em decorrência de inspeção realizada no local de trabalho.', text:'Atividade caracterizada em decorrência de inspeção realizada no local de trabalho.'})
				equipamentos_aux.push({key:-3, value: 'Atividade caracterizada de forma qualitativa.', text:'Atividade caracterizada de forma qualitativa.'})
			}
			setEquipamentos(equipamentos_aux)
		})
	}

	useEffect(() => {
		if (props.show) {
			setCarregando(true)
			setDataAvaliacao('')
			setHorarioMedicao('')
			setJornadaDiaria(480)
			setEquipamentoId(0)
			setRefenciaAmostra('')
			setResultado('')
			setRuidoTipo('')
			setRuidoDose('')
			setRuidoDba('')
			setRuidoNen('')
			setCalorTipoAmbiente('')
			setCalorDescricaoAtividade('')
			setCalorTempo('')
			setCalorMetabolismo('')
			setCalorTbs('')
			setCalorTbn('')
			setCalorTg('')
			setCalorIbutg('')
			setAnotacao('')

			if (sessionStorage.getItem('cache_tempo_exposicao') !== null) {
				setTempoExposicao(sessionStorage.getItem('cache_tempo_exposicao'))
			}

			if (sessionStorage.getItem('cache_jornada_semanal') !== null) {
				setJornadaSemanal(sessionStorage.getItem('cache_jornada_semanal'))
			}

			if (sessionStorage.getItem('amostra_referencia') !== null) {
				setRefenciaAmostra(sessionStorage.getItem('amostra_referencia'))
			}

			async function initial() {
				try {
					await Promise.all([
						api.get('sugestoes/14'),
						api.get('metabolismo')
					]).then(response => {
						var equipamentos_aux = response[0].data.resultado.filter(item => item.tipo === 'equipamento').map(({ id, valor, certificado_equipamento, tipo }) => ({ key: id, text: valor, value: valor, certificado_equipamento, tipo }))
						if(props.tipo?.avaliacao_previdenciaria !== 'Quantitativo' && props.tipo?.avaliacao_trabalhista !== 'Quantitativo'){
							equipamentos_aux.push({key:-1, value: 'Atividade caracterizada pela avaliação qualitativa.', text:'Atividade caracterizada pela avaliação qualitativa.'})
							equipamentos_aux.push({key:-2, value: 'Atividade caracterizada em decorrência de inspeção realizada no local de trabalho.', text:'Atividade caracterizada em decorrência de inspeção realizada no local de trabalho.'})
							equipamentos_aux.push({key:-3, value: 'Atividade caracterizada de forma qualitativa.', text:'Atividade caracterizada de forma qualitativa.'})
						}
						setEquipamentos(equipamentos_aux)
						var metabolismos_aux = response[1].data.resultado.map(({ id, referencia, metabolismo }) => ({ key: id, text: referencia + ' (' + metabolismo + ')', value: parseInt(metabolismo) }))
						setOptionsMetabolismo(metabolismos_aux)
					})

					if (sessionStorage.getItem('AmostraId') !== null) {
						var res = await api.get('amostra?laudo_id=' + sessionStorage.getItem('laudoId'))
						var amostra = res.data.resultado.filter(item => item.id === parseInt(sessionStorage.getItem('AmostraId')))[0]
						setDataAvaliacao(amostra.data_avaliacao)
						setHorarioMedicao(amostra.horario)
						setTempoExposicao(amostra.tempo_exposicao)
						setJornadaDiaria(amostra.jornada_diaria)
						setJornadaSemanal(amostra.jornada_semanal)
						setEquipamentoId(amostra.equipamento_id)
						setRefenciaAmostra(amostra.referencia)
						setResultado(amostra.resultado)
						setRuidoTipo(amostra.ruido_tipo)
						setRuidoDose(amostra.ruido_dose)
						setCalorTipoAmbiente(amostra.calor_tipo_ambiente)
						setCalorDescricaoAtividade(amostra.calor_descricao_atividade)
						setCalorTempo(amostra.calor_tempo)
						setCalorMetabolismo(amostra.calor_metabolismo)
						setCalorTbs(amostra.calor_tbs)
						setCalorTbn(amostra.calor_tbn)
						setCalorTg(amostra.calor_tg)
						setCalorIbutg(amostra.calor_ibutg)
						setRuidoNen(amostra.ruido_nen)
						setRuidoDba(amostra.ruido_dba)
						setAnotacao(amostra.anotacao)
					}

				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}

			initial()
			const handleFocus = async () => {
				// Função que será executada quando a aba ou janela receber o foco
				setCarregando(true);
				await updateEquipamentos();
				setCarregando(false);
			};

			window.addEventListener('focus', handleFocus);

			return () => {
				window.removeEventListener('focus', handleFocus);
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	const handleEquipamento = () => {
		const win = window.open("LaudoConfig/equipamentos", "_blank");
		win.focus();
	}

	const handleRegister = async (e) => {

		let validade = true

		if(data_avaliacao === '' || horario === '' || referencia === '' || jornada_diaria === '' || jornada_semanal === '' || (equipamento_id === 0 && props.tipo?.avaliacao_trabalhista === 'Quantitativo')){
			validade = false
		}

		if(props.tipo?.filtro.startsWith('Calor')){
			if(calor_tipo_ambiente === '' || calor_tg === '' || calor_tempo === '' || calor_tbs === '' || calor_tbn === '' || calor_metabolismo === '' || calor_ibutg === '' || calor_descricao_atividade === ''){
				validade = false
			}
		}else if(props.tipo?.filtro.startsWith('Ruído')){
			if(ruido_dba === '' || ruido_dose === '' || ruido_nen === '' || ruido_tipo === ''){
				validade = false
			}
		}else{
			if(resultado === ''){
				validade = false
			}
		}

		e.preventDefault()

		if(validade){

			const data = {
				data_avaliacao,
				horario,
				tempo_exposicao,
				jornada_diaria,
				jornada_semanal,
				equipamento_id,
				equipamento: equipamentos.filter(equipamento => equipamento.key === parseInt(equipamento_id))[0]?.value,
				certificado_equipamento: equipamentos.filter(equipamento => equipamento.key === parseInt(equipamento_id))[0]?.certificado_equipamento,
				referencia,
				anotacao
			}
	
			if (props.tipo?.filtro.startsWith('Calor')) {
				data.calor_descricao_atividade = calor_descricao_atividade
				data.calor_ibutg = calor_ibutg
				data.calor_metabolismo = calor_metabolismo
				data.calor_tbn = calor_tbn
				data.calor_tbs = calor_tbs
				data.calor_tempo = calor_tempo
				data.calor_tg = calor_tg
				data.calor_tipo_ambiente = calor_tipo_ambiente
			} else if (props.tipo?.filtro.startsWith('Ruído')) {
				data.ruido_dose = ruido_dose
				data.ruido_tipo = ruido_tipo
				data.ruido_dba = ruido_dba
				data.ruido_nen = ruido_nen
			} else {
				data.resultado = resultado
			}
	
			try {
				var mensagem
				setCarregando(true)
				if (sessionStorage.getItem('AmostraId') === null) {
					await api.post(`amostra?laudo_id=${sessionStorage.getItem('laudoId')}`, data)
					mensagem = "Amostra cadastrada com sucesso"
				} else {
					await api.patch("amostra/" + sessionStorage.getItem('AmostraId') + `?laudo_id=${sessionStorage.getItem('laudoId')}`, data)
					mensagem = "Amostra atualizada com sucesso"
				}
				sessionStorage.setItem('cache_tempo_exposicao', data.tempo_exposicao)
				sessionStorage.setItem('cache_jornada_semanal', data.jornada_semanal)
				sessionStorage.setItem('amostra_referencia', data.referencia)
				aviso('success', mensagem)
				props.onHide()
	
			} catch (error) {
				if (error.response.status === 406) {
					aviso('warning', error.response.data.mensagem)
				} else {
					aviso('error', error)
				}
			} finally {
				setCarregando(false)
			}
		}else{
			aviso('warning', "Preencha todos os campos!")
		}

	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Registro de amostra
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
				<Loading loading={carregando} message='Carregando...' />
				<div>
					<div className='amostra-linha'>
						<div className='amostra-c1'>
							<label >Referência da amostra<FiInfo style={{ cursor: "pointer" }} data-tip={'Nome, matrícula ou código do trabalhador amostrado.'} data-for="aux"></FiInfo></label>
							<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setRefenciaAmostra(e.target.value)} value={referencia} />
						</div>
						<div className='amostra-c2'>
							<label>Data da avaliação<FiInfo style={{ cursor: "pointer" }} data-tip={"Data do dia da avaliação, clique no calendário."} data-for="aux"></FiInfo></label>
							<input type="date" onChange={date => setDataAvaliacao(date.target.value)} value={data_avaliacao} />
						</div>
						<div className='amostra-c3'>
							<label >Horário de medição<FiInfo style={{ cursor: "pointer" }} data-tip={"Horário do início da medição"} data-for="aux"></FiInfo></label>
							<input type="time" onChange={time => setHorarioMedicao(time.target.value)} value={horario} />
						</div>
					</div>

					<div className='amostra-linha'>

						<div className='amostra-g2'>
							<label >Jornada semanal<FiInfo style={{ cursor: "pointer" }} data-tip={"Jornada semanal em minutos<br/>Ex: 2.640 minutos"} data-for="aux"></FiInfo></label>
							<input type="number" onChange={e => setJornadaSemanal(e.target.value)} value={jornada_semanal} />
						</div>

						<div className='amostra-g2'>
							<label >Jornada padrão (min)<FiInfo style={{ cursor: "pointer" }} data-tip={"Jornada padrão do Brasil em minutos<br/>Ex. 480 minutos"} data-for="aux"></FiInfo></label>
							<input type="number" onChange={e => setJornadaDiaria(e.target.value)} value={jornada_diaria} />
						</div>
						<div className='amostra-g2'>
							<label >Tempo de exposição (min)<FiInfo style={{ cursor: "pointer" }} data-tip={"Jornada diferente da jornada padrão ex. 720 minutos para quem trabalha 12 horas."} data-for="aux"></FiInfo></label>
							<input type="number" onChange={e => {
								setTempoExposicao(e.target.value);
								if (props.tipo?.filtro.startsWith('Ruído')) {
									let dba = calculo_dose(ruido_dose, e.target.value, ruido_tipo)
									setRuidoDba(dba)
									let nen = calculo_nen(dba, e.target.value)
									setRuidoNen(nen)
								}
							}} value={tempo_exposicao} />
						</div>
						<div className='amostra-g2'>
							<ReactTooltip/>
							<label >Equipamento de medição<FiInfo style={{ cursor: "pointer" }} data-tip={"Escolher os equipamentos utilizados na medição ambiental. Clique no ícone a direita para cadastrar um equipamento!"} data-for="aux"></FiInfo><FiPlusCircle onClick={handleEquipamento} data-tip={'Cadastrar equipamento'} style={{ cursor: "pointer" }}></FiPlusCircle></label>
							<select value={equipamento_id} onChange={e => setEquipamentoId(e.target.value)}>
								<option key='' value={0} disabled>Selecione</option>
								{equipamentos.map(equipamento => (
									<option key={equipamento.key} value={equipamento.key}>{equipamento.value}</option>
								))}
							</select>
						</div>
					</div>
					{!(props.tipo?.filtro.startsWith('Calor') || props.tipo?.filtro.startsWith('Ruído')) && (
						<div className='amostra-linha-2'>
							<div className='amostra-g2'>
								<label >Resultado<FiInfo style={{ cursor: "pointer" }} data-tip={"O campo resultado pode ser preenchido com letras, números, sinal<, e também inserir resultados com virgula."} data-for="aux"></FiInfo></label>
								<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setResultado(e.target.value)} value={resultado} />
							</div>
							<div className='amostra-g2'>
								<label>Anotação<FiInfo style={{ cursor: "pointer" }} data-tip={"O campo anotação é livre. Pode informar a temperatura, umidade, as atividades observadas, as máquinas e ferramentas envolvidas etc..."} data-for="aux"></FiInfo></label>
								<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setAnotacao(e.target.value)} value={anotacao} />
							</div>
						</div>
					)}
					{props.tipo?.filtro.startsWith('Ruído') && (
						<div>
							<div className='amostra-linha'>
								<div className='amostra-radio'>
									<label >Selecione o tipo de ruído<FiInfo style={{ cursor: "pointer" }} data-tip={"Taxa de troca que será utilizada no laudo."} data-for="aux"></FiInfo></label>
									<div className='amostra-radio-1'>
										<input
											onChange={e => {
												setRuidoTipo(e.target.value);
												let dba = calculo_dose(ruido_dose, tempo_exposicao, 'q3')
												setRuidoDba(dba)
												let nen = calculo_nen(dba, tempo_exposicao)
												setRuidoNen(nen)
											}}
											id="q3"
											value="q3"
											label="Q3"
											type="radio"
											checked={ruido_tipo === "q3"}
										/>
										<label htmlFor="q3">Q3</label>
										<input
											onChange={e => {
												setRuidoTipo(e.target.value);
												let dba = calculo_dose(ruido_dose, tempo_exposicao, 'q5')
												setRuidoDba(dba)
												let nen = calculo_nen(dba, tempo_exposicao)
												setRuidoNen(nen)
											}}
											id="q5"
											value="q5"
											label="Q5"
											type="radio"
											checked={ruido_tipo === "q5"}
										/>
										<label htmlFor="q5">Q5</label>
									</div>
								</div>
							</div>
							{ruido_tipo !== '' && ruido_tipo !== null &&
								<div className='amostra-linha'>
									<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
									<div className='amostra-c7'>
										<label >{ruido_tipo === 'q3' ? 'Dose Q3' : 'Dose Q5'} <FiInfo style={{ cursor: "pointer" }} data-tip={"Resultado da intensidade em dose de ruído."} data-for="aux"></FiInfo></label>
										<input type="number" onChange={e => {
											setRuidoDose(e.target.value)
											let dba = calculo_dose(e.target.value, tempo_exposicao, ruido_tipo)
											setRuidoDba(dba)
											let nen = calculo_nen(dba, tempo_exposicao)
											setRuidoNen(nen)
										}} value={ruido_dose} />
									</div>
									<div className='amostra-c7'>
										<label >dB(A) <FiInfo style={{ cursor: "pointer" }} data-tip={"Resultado em decibel na curva A."} data-for="aux"></FiInfo></label>
										<input disabled type="number" value={ruido_dba} />
									</div>
									<div className='amostra-c7'>
										<label >NEN <FiInfo style={{ cursor: "pointer" }} data-tip={"Resultado normalizado para uma jornada padrão brasileira de 8 horas (480 minutos)."} data-for="aux"></FiInfo></label>
										<input disabled type="number" value={ruido_nen} />
									</div>
									<div className='amostra-c6'>
										<label>Anotação<FiInfo style={{ cursor: "pointer" }} data-tip={"O campo anotação é livre. Pode informar a temperatura, umidade, as atividades observadas, as máquinas e ferramentas envolvidas etc..."} data-for="aux"></FiInfo></label>
										<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setAnotacao(e.target.value)} value={anotacao} />
									</div>
								</div>
							}
						</div>
					)}

					{props.tipo?.filtro.startsWith('Calor') && (
						<div>
							<div className='amostra-linha'>
								<div className='amostra-radio'>
									<label >Selecione o tipo de ambiente<FiInfo style={{ cursor: "pointer" }} data-tip={textTipoCalor} data-for="aux"></FiInfo></label>
									<div className='amostra-radio-1'>
										<input
											onChange={e => { setCalorTipoAmbiente(e.target.value); setCalorIbutg(calculo_ibutg(calor_tbs, calor_tbn, calor_tg, e.target.value)) }}
											value="interno"
											type="radio"
											checked={calor_tipo_ambiente === "interno"}
										/>
										<label>Interno</label>
										<input
											onChange={e => { setCalorTipoAmbiente(e.target.value); setCalorIbutg(calculo_ibutg(calor_tbs, calor_tbn, calor_tg, e.target.value)) }}
											value="externo"
											type="radio"
											checked={calor_tipo_ambiente === "externo"}
										/>
										<label>Externo</label>
									</div>
								</div>
							</div>
							<div className='amostra-linha'>
								<div className='amostra-c6'>
									<label >Descrição da atividade: <FiInfo style={{ cursor: "pointer" }} data-tip={"Descreva a atividade observada no estudo"} data-for="aux"></FiInfo></label>
									<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setCalorDescricaoAtividade(e.target.value)} value={calor_descricao_atividade} />
								</div>
								<div className='amostra-c5'>
									<label >Tempo:<FiInfo style={{ cursor: "pointer" }} data-tip={"Tempo de cada atividade observada com no máximo 60 minutos"} data-for="aux"></FiInfo></label>
									<input type="number" onChange={e => setCalorTempo(e.target.value)} value={calor_tempo} />
								</div>
								<div className='amostra-metabolismo'>
									<label >Metabolismo: <FiInfo style={{ cursor: "pointer" }} data-tip={"Escolha o metabolismo e clique na atividade observada no local da atividade."} data-for="aux"></FiInfo></label>
									{DropMetabolismo}
								</div>
							</div>
							<div className='amostra-linha'>
								<div className='amostra-c5'>
									<label >TBS: <FiInfo style={{ cursor: "pointer" }} data-tip={"Cadastre o bulbo seco - TBS"} data-for="aux"></FiInfo></label>
									<input type="number" onChange={e => {
										setCalorTbs(e.target.value)
										setCalorIbutg(calculo_ibutg(e.target.value, calor_tbn, calor_tg, calor_tipo_ambiente))
									}} value={calor_tbs} />
								</div>
								<div className='amostra-c5'>
									<label >TBN: <FiInfo style={{ cursor: "pointer" }} data-tip={"Cadastre o bulbo úmido - TBN"} data-for="aux"></FiInfo></label>
									<input type="number" onChange={e => {
										setCalorTbn(e.target.value)
										setCalorIbutg(calculo_ibutg(calor_tbs, e.target.value, calor_tg, calor_tipo_ambiente))
									}} value={calor_tbn} />
								</div>
								<div className='amostra-c5'>
									<label >TG: <FiInfo style={{ cursor: "pointer" }} data-tip={"Cadastre o termômetro de globo - TG"} data-for="aux"></FiInfo></label>
									<input type="number" onChange={e => {
										setCalorTg(e.target.value)
										setCalorIbutg(calculo_ibutg(calor_tbs, calor_tbn, e.target.value, calor_tipo_ambiente))
									}} value={calor_tg} />
								</div>
								<div className='amostra-c5'>
									<label >IBUTG: <FiInfo style={{ cursor: "pointer" }} data-tip={"Resultado do IBUTG – Será parcial no caso de mais de uma amostra ou total quando somente uma exposição de 60 minutos."} data-for="aux"></FiInfo></label>
									<input disabled type="number" value={calor_ibutg} />
								</div>
								<div className='amostra-g2'>
									<label>Anotação<FiInfo style={{ cursor: "pointer" }} data-tip={"O campo anotação é livre. Pode informar a temperatura, umidade, as atividades observadas, as máquinas e ferramentas envolvidas etc..."} data-for="aux"></FiInfo></label>
									<textarea className="text-area-risco" aria-multiline="true" type="text" onChange={e => setAnotacao(e.target.value)} value={anotacao} />
								</div>
							</div>
						</div>
					)}

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister} >Cadastrar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
				
			</Modal.Footer>
		</Modal>
	);
}

function Conclusao(props) {


	const [carregando, setCarregando] = useState(false);
	const [conclusao_ins, setConclusaoIns] = useState('');
	const [conclusao_lcat, setConclusaoLcat] = useState('')
	const [conclusao_pec, setConclusaoPec] = useState('')
	const [sugestoes, setSugestoes] = useState([])
	const [relacoes, setRelacoes] = useState([])
	const [laudo, setLaudo] = useState([])
	const [optionsPericuloso, setOptionPericuloso] = useState([])
	const [optionsInsalubre, setOptionInsalubre] = useState([])
	const [optionsLcat, setOptionLcat] = useState([])
	const [currentValues, setCurrentValues] = useState('') //periculosidade
	const [currentValues2, setCurrentValues2] = useState('') //insalubridade
	const [currentValues3, setCurrentValues3] = useState('') //lcat


	const { aviso } = React.useContext(Context);

	const handleChange = (e, { value }) => {
		setCarregando(true)
		setCurrentValues(value);
		setCarregando(false)
	}//Periculosidade

	const handleChange2 = (e, { value }) => {
		setCarregando(true)
		setCurrentValues2(value);
		setCarregando(false)
	}//Insalubridade

	const handleChange3 = (e, { value }) => {
		setCarregando(true)
		setCurrentValues3(value);
		setCarregando(false)
	}//Lcat

	const DropConclusaoPericulosa = React.useMemo(() =>
	(
		<Dropdown
			options={optionsPericuloso}
			placeholder="Selecione a conclusão para periculosidade"
			noResultsMessage="Nenhum resultado encontrado"
			search
			selection
			hidden={conclusao_pec === '' || conclusao_pec === undefined}
			lazyLoad={true}
			clearable
			fluid
			value={currentValues}
			onChange={handleChange}
		/>
	), [currentValues, optionsPericuloso, conclusao_pec])

	const DropConclusaoInsalubre = React.useMemo(() =>
	(
		<Dropdown
			options={optionsInsalubre}
			placeholder="Selecione a conclusão para insalubridade"
			noResultsMessage="Nenhum resultado encontrado"
			search
			selection
			lazyLoad={true}
			clearable
			hidden={conclusao_ins === '' || conclusao_ins === undefined}
			fluid
			value={currentValues2}
			onChange={handleChange2}
		/>
	), [currentValues2, optionsInsalubre, conclusao_ins])

	const DropConclusaoLcat = React.useMemo(() =>
	(
		<Dropdown
			options={optionsLcat}
			placeholder="Selecione a conclusão para LTCAT"
			noResultsMessage="Nenhum resultado encontrado"
			search
			selection
			hidden={conclusao_lcat === '' || conclusao_lcat === undefined}
			lazyLoad={true}
			clearable
			fluid
			value={currentValues3}
			onChange={handleChange3}
		/>
	), [currentValues3, optionsLcat, conclusao_lcat])

	useEffect(() => {
		if (props.show) {
			setCarregando(true)
			setConclusaoIns('')
			setConclusaoLcat('')
			setConclusaoPec('')
			setSugestoes([])
			setRelacoes([])

			async function initial() {
				try {
					await Promise.all([
						api.get('sugestoes/14'),
						api.get('laudo_conclusao?laudo_id=' + sessionStorage.getItem('laudoId')),
						api.get('laudo?risco_id=' + sessionStorage.getItem('riscoId'))
					]).then(response => {
						setRelacoes(response[1].data.resultado)
						let aux = response[0].data.resultado.filter(item => item.tipo === "conclusao")
						response[1].data.resultado.forEach(item => {
							if (item.tipo === 'periculosidade') {
								setCurrentValues(item.fk_conclusao_id)
							} else if (item.tipo === 'insalubridade') {
								setCurrentValues2(item.fk_conclusao_id)
							} else {
								setCurrentValues3(item.fk_conclusao_id)
							}
						})
						let laudo = response[2].data.resultado[0]
						setLaudo(laudo)
						setSugestoes(aux)
						setConclusaoIns(laudo.conclusao_ins)
						setConclusaoPec(laudo.conclusao_pec)
						setConclusaoLcat(laudo.conclusao_lcat)
						if (laudo.conclusao_lcat !== '') {
							let options = aux.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
							setOptionLcat(options.filter(item => item.conclusao_parecer === laudo.conclusao_lcat && item.conclusao_tipo === 'lcat'))

						}
						if (laudo.conclusao_pec !== '') {
							let options = aux.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
							setOptionPericuloso(options.filter(item => item.conclusao_parecer === laudo.conclusao_pec && item.conclusao_tipo === 'periculosidade'))
						}
						if (laudo.conclusao_ins !== '') {
							let options = aux.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
							setOptionInsalubre(options.filter(item => item.conclusao_parecer === laudo.conclusao_ins && item.conclusao_tipo === 'insalubridade'))
						}
					})

				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}
			}

			initial();

		}

	}, [props.show])

	const handleRegister = async (e) => {

		e.preventDefault()

		try {
			var mensagem

			setCarregando(true)
			if ((conclusao_pec !== '' && currentValues === '') || (conclusao_ins !== '' && currentValues2 === '') || (conclusao_lcat !== '' && currentValues3 === '')) {
				mensagem = "Todos os campos devem ser preenchidos"
				aviso('warning', mensagem)
			} else {
				if (relacoes.filter(item => item.tipo === "insalubridade").length < 1 && conclusao_ins !== '') {
					api.post(`laudo_conclusao?laudo_id=${sessionStorage.getItem('laudoId')}&conclusao_id=${currentValues2}`, { tipo: 'insalubridade' })
				} else {
					if (relacoes.filter(item => item.tipo === 'insalubridade' && item.fk_laudo_id === laudo.id)[0]?.id.toString() !== undefined) {
						if (conclusao_ins === '') {
							await api.delete('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'insalubridade')[0]?.id.toString())
						} else {
							await api.patch('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'insalubridade')[0]?.id.toString(), { fk_conclusao_id: currentValues2, fk_laudo_id: sessionStorage.getItem('laudoId'), tipo: 'insalubridade' })
						}
					}
				}

				if (laudo.conclusao_ins !== conclusao_ins) {
					await api.patch(`laudo/${sessionStorage.getItem('laudoId')}`, { conclusao_ins })
				}

				if (relacoes.filter(item => item.tipo === "lcat").length < 1 && conclusao_lcat !== '') {
					api.post(`laudo_conclusao?laudo_id=${sessionStorage.getItem('laudoId')}&conclusao_id=${currentValues3}`, { tipo: 'lcat' })
				} else {
					if (relacoes.filter(item => item.tipo === 'lcat' && item.fk_laudo_id === laudo.id)[0]?.id.toString() !== undefined) {
						if (conclusao_lcat === '') {
							await api.delete('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'lcat')[0]?.id.toString())
						} else {
							await api.patch('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'lcat')[0]?.id.toString(), { fk_conclusao_id: currentValues3, fk_laudo_id: sessionStorage.getItem('laudoId'), tipo: 'lcat' })
						}

					}
				}

				if (laudo.conclusao_lcat !== conclusao_lcat) {
					await api.patch(`laudo/${sessionStorage.getItem('laudoId')}`, { conclusao_lcat })
				}

				if (relacoes.filter(item => item.tipo === "periculosidade").length < 1 && conclusao_pec !== '') {
					api.post(`laudo_conclusao?laudo_id=${sessionStorage.getItem('laudoId')}&conclusao_id=${currentValues}`, { tipo: 'periculosidade' })
				} else {
					if (relacoes.filter(item => item.tipo === 'periculosidade' && item.fk_laudo_id === laudo.id)[0]?.id.toString() !== undefined) {
						if (conclusao_pec === '') {
							await api.delete('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'periculosidade')[0]?.id.toString())
						} else {
							await api.patch('laudo_conclusao/' + relacoes.filter(item => item.tipo === 'periculosidade')[0]?.id.toString(), { fk_conclusao_id: currentValues, fk_laudo_id: sessionStorage.getItem('laudoId'), tipo: 'periculosidade' })
						}
					}
				}

				if (laudo.conclusao_pec !== conclusao_pec) {
					await api.patch(`laudo/${sessionStorage.getItem('laudoId')}`, { conclusao_pec })
				}

				mensagem = "Conclusão definida com sucesso"
				aviso('success', mensagem)
				props.onHide()
			}

		} catch (error) {
			if (error.response?.status === 406) {
				aviso('warning', error.response.data.mensagem)
			} else {
				console.log(error)
				aviso('error', error)
			}
		} finally {
			setCarregando(false)
		}

	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Definir conclusão
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>
					<div className='amostra-linha'>
						<div className='laudo-c'>
							<label>Conclusão Insalubridade?<FiInfo style={{ cursor: "pointer" }}></FiInfo></label>
							<select value={conclusao_ins} onChange={e => {
								let options = sugestoes.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
								setOptionInsalubre(options.filter(item => item.conclusao_parecer === e.target.value && item.conclusao_tipo === 'insalubridade'))
								setConclusaoIns(e.target.value);
								setCurrentValues2('')
							}}>
								<option value="">Não aplicável</option>
								<option value="Sim">Sim</option>
								<option value="Não">Não</option>
							</select>
							{DropConclusaoInsalubre}
						</div>
					</div>
					<div className='amostra-linha'>
						<div className='laudo-c'>
							<label>Conclusão LTCAT?<FiInfo style={{ cursor: "pointer" }}></FiInfo></label>
							<select value={conclusao_lcat} onChange={e => {
								let options = sugestoes.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
								setOptionLcat(options.filter(item => item.conclusao_parecer === e.target.value && item.conclusao_tipo === 'lcat'))
								setConclusaoLcat(e.target.value);
								setCurrentValues3('')
							}}>
								<option value="">Não aplicável</option>
								<option value="Sim">Sim</option>
								<option value="Não">Não</option>
							</select>
							{DropConclusaoLcat}
						</div>
					</div>
					<div className='amostra-linha'>
						<div className='laudo-c'>
							<label>Conclusão Periculosidade?<FiInfo style={{ cursor: "pointer" }}></FiInfo></label>
							<select value={conclusao_pec} onChange={e => {
								let options = sugestoes.map(({ id, valor, tipo, conclusao_tipo, conclusao_parecer }) => ({ key: id, value: id, text: valor, tipo, conclusao_tipo, conclusao_parecer }))
								setOptionPericuloso(options.filter(item => item.conclusao_parecer === e.target.value && item.conclusao_tipo === 'periculosidade'))
								setConclusaoPec(e.target.value);
								setCurrentValues('')
							}}>
								<option value="">Não aplicável</option>
								<option value="Sim">Sim</option>
								<option value="Não">Não</option>
							</select>
							{DropConclusaoPericulosa}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister} >Concluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function Quiz(props) {


	const [carregando, setCarregando] = useState(false);
	const [perguntas, setPerguntas] = useState('')
	const [laudo, setLaudo] = useState('')
	const [lista, setLista] = useState([])
	const [relacoes, setRelacoes] = useState([])

	const { aviso } = React.useContext(Context);


	useEffect(() => {
		if (props.show) {
			setPerguntas([])
			async function initial() {
				try {
					await Promise.all([
						api.get('sugestoes/14'),
						api.get('laudo_quiz?laudo_id=' + sessionStorage.getItem('laudoId')),
						api.get('laudo?risco_id=' + sessionStorage.getItem('riscoId'))
					]).then(response => {
						let laudo_aux = response[2].data.resultado[0]
						setLaudo(laudo_aux)
						let aux_sug = response[0].data.resultado.filter(item => item.tipo === "perguntas")
						let sug = aux_sug.filter(item => item.filtro_pergunta === props.tipo || item.filtro_pergunta === '')
						let rel = response[1].data.resultado
						setRelacoes(rel)
						let ListaRelacionada = []
						rel.forEach(item => {
							ListaRelacionada.push(item.fk_pergunta_id)
						})
						setLista(ListaRelacionada)
						let array = []
						sug.forEach(item => {
							if (ListaRelacionada.includes(item.id)) {
								let i;
								for (i = 0; i < rel.length; i++) {
									if (rel[i].fk_pergunta_id === item.id) {
										item.justificativa = rel[i].justificativa
										if (rel[i].resposta === 1) {
											item.check = true
											item.check2 = false
										} else {
											item.check = false
											item.check2 = true
										}
									}
								}
							} else {
								item.justificativa = ''
								item.check = false
								item.check2 = false
							}
							array.push(item)
						})
						setPerguntas(array)
					})

				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}
			}
			setCarregando(true)
			initial();
		}

	}, [props.show])

	const handleRegister = async (e) => {

		e.preventDefault()

		try {
			var mensagem
			setCarregando(true)
			var verifica = true
			perguntas.forEach(pergunta => {
				if (pergunta.justificativa === '' && (pergunta.check === true)) {
					verifica = false
				}
			})

			if (!verifica) {
				aviso('warning', 'Informe uma justificativa para a pergunta')
			} else {
				for (let i = 0; i < perguntas.length; i++) {
					if ((!perguntas[i].check && !perguntas[i].check2) || perguntas[i].comentario === '') {
						if (!perguntas[i].check && !perguntas[i].check2 && lista.includes(perguntas[i].id)) {
							api.delete('laudo_quiz/' + relacoes.filter(item => item.fk_pergunta_id === perguntas[i].id)[0].id)
						}
					} else {
						if (lista.includes(perguntas[i].id)) {
							api.patch('laudo_quiz/' + relacoes.filter(item => item.fk_pergunta_id === perguntas[i].id)[0].id, { justificativa: perguntas[i].justificativa, resposta: perguntas[i].check ? 1 : 0 })
						} else {
							api.post('laudo_quiz?pergunta_id=' + perguntas[i].id + '&laudo_id=' + laudo.id, { justificativa: perguntas[i].justificativa, resposta: perguntas[i].check ? 1 : 0 })
						}
					}
				}
				mensagem = 'Cadastro realizado com sucesso'
				aviso('success', mensagem)
				props.onHide()
			}


		} catch (error) {
			if (error.response?.status === 406) {
				aviso('warning', error.response.data.mensagem)
			} else {
				console.log(error)
				aviso('error', error)
			}
		} finally {
			setCarregando(false)
		}

	}

	const handleChangeJustificativa = (index, e) => {
		let b = Array.from(perguntas)
		b[index].justificativa = e.target.value
		setPerguntas(b)
	}

	const CheckItem = (index) => {
		let b = Array.from(perguntas)
		b[index].check = !b[index].check
		if (b[index].check2) {
			b[index].check2 = !b[index].check
		}
		setPerguntas(b)
	}

	const CheckItem2 = (index) => {
		let b = Array.from(perguntas)
		b[index].check2 = !b[index].check2
		if (b[index].check) {
			b[index].check = !b[index].check2
		}
		setPerguntas(b)
	}

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Questionário
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>
					<div className="questionario-laudo-box">
						{perguntas.length !== 0 ? perguntas.map((pergunta, index) => (
							<div className="questionario-pergunta" key={pergunta.id}>
								<div className="questionario-comentario">
									<div className="questionario-pergunta">
										<a>{pergunta.valor}</a>
									</div>
									<div className='questionario-comentario2'>
										<Checkbox
											label='Sim'
											onClick={() => CheckItem(index)}
											checked={pergunta.check}
										/>
										<Checkbox
											label='Não'
											onClick={() => CheckItem2(index)}
											checked={pergunta.check2}
										/>
									</div>
								</div>
								<div className="questionario-comentario">

									{pergunta.check === true ?
										<div className='questionario-comentario1'>
											<label style={{ paddingRight: '10px', width: '30%' }}>Justificativa</label>
											<textarea
												value={pergunta.justificativa}
												onChange={(e) => handleChangeJustificativa(index, e)}
											/>
										</div> : null}
								</div>
							</div>)) : <a>Nenhum registro</a>}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister}>Concluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}
