import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiEdit, FiTrash2, FiChevronLeft } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'
import { Grid, GridColumn, Dropdown } from 'semantic-ui-react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export function GestaoPerguntasAud(props) {

    const { aviso, Admin } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)
    const [perguntas, setPerguntas] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [mudanca, setMudanca] = useState(false)

    async function inicializar() {
        setCarregando(true)
        try {
            await Promise.all([
                api.get('auditoria_pergunta?gestao=Sim')
            ]).then((response) => {
                setPerguntas(response[0].data.resultado.filter(item => item.fk_titulo_id === parseInt(sessionStorage.getItem('audTituloId'))))
            })
        } catch (error) {
            aviso('error', error)
        } finally {
            setCarregando(false)
        }
    }

    useEffect(() => {
        if (props.update) {
            inicializar()
        }
    }, [props.update])

    async function handleDeletePergunta(id) {
        try {
            setCarregando(true)
            await api.patch('auditoria_pergunta/' + id, { enable: false })
            aviso('success', 'Deletado com sucesso')
        } catch (error) {
            aviso("error", "Erro ao deletar pergunta, tente novamente", true)
        } finally {
            setCarregando(false)
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "white",
        padding: '5px',
        width: 'auto',
        height: 'auto',
        maxHeight: '400px',
    });

    function onDragEnd(result) {

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            perguntas,
            result.source.index,
            result.destination.index
        );

        if (JSON.stringify(perguntas) !== JSON.stringify(items)) {
            setMudanca(true)
        }

        setPerguntas(items)

    }

    function onSave() {

        var ris = perguntas;

        try {
            var index;

            for (index = 0; index < ris.length; index++) {
                ris[index].ordem = index
            }
            for (index = 0; index < ris.length; index++) {
                api.patch('auditoria_pergunta/' + ris[index].id, { ordem: ris[index].ordem })
            }

            aviso('success', 'Nova ordem salva com sucesso')
            setMudanca(false)
        } catch (error) {
            aviso('error', error)
        }

    }

    return (
        <div className='teste'>
            <Loading loading={carregando} message='Carregando...' />
            <Perg admin={Admin.toString()} show={modalShow} backdrop="static" capitulo={parseInt(sessionStorage.getItem('audCapituloId'))} titulo={parseInt(sessionStorage.getItem('audTituloId'))} perguntas={perguntas} onHide={() => { setModalShow(false); inicializar(); sessionStorage.removeItem('audPerguntaId') }} />
            <div className="aud-titulo">
                <i>
                    <ReactTooltip id="voltar" place="top" effect="solid"></ReactTooltip>
                    <FiChevronLeft data-tip={"Voltar"} data-for='voltar'
                        className="go-edit2"
                        style={{ margin: '0px 0px 10px 0px' }}
                        onClick={() => {
                            props.onHide()
                        }} />
                </i>
                <label style={{ fontSize: '20px' }}>Perguntas</label>
                <div>
                    <div />
                </div>
            </div>
            <div className="reavaliacao-menu-pergunta">
                <div className="reavaliacao-menu-pergunta2">
                    <label>&nbsp;</label>
                    {!mudanca ? <button onClick={() => setModalShow(true)}>Cadastrar nova</button> : <button onClick={onSave} className='gr-salvar'>Salvar ordem</button>}
                </div>
            </div>
            <div>
                <div style={{ marginTop: '30px' }}>
                    <h3>Perguntas cadastradas:</h3>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                id="scrollableDiv"
                                className="scroll-box"
                            >

                                {perguntas.length ?
                                    <div>
                                        {perguntas.map((item, index) => (

                                            <Draggable key={item.id} isDragDisabled={!Admin} draggableId={item.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div key={item.id} style={{width: '100%'}} className="reavaliacao-list-box">
                                                            <ReactTooltip id={toString(item.id)} place="top" effect="solid"></ReactTooltip>
                                                            <div className="reavaliacao-name1">
                                                                <h3 onClick={() => {
                                                                    setModalShow(true)
                                                                    sessionStorage.setItem('audPerguntaId', item.id)
                                                                }}>{item.o_que}</h3>
                                                            </div>
                                                            <div className="reavaliacao-icons">
                                                                <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(item.id)} onClick={() => { sessionStorage.setItem('audPerguntaId', item.id); setModalShow(true) }} />  </i>
                                                                <i className="reavaliacao-trash">
                                                                    <FiTrash2 data-tip="Excluir" data-for={toString(item.id)}
                                                                        onClick={async () => {
                                                                            const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE O ITEM SELCIONADO E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + item.o_que + ' :')
                                                                            if (r === "excluir") {
                                                                                await handleDeletePergunta(item.id)
                                                                                inicializar()
                                                                            } else {
                                                                                aviso("warning", "Nome incorreto, exclusão cancelada", true)
                                                                            }
                                                                        }} /> </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div> :
                                    <div>
                                        <label>Nenhum registro</label>
                                    </div>

                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )

}


function Perg(props) {

    const [carregando, setCarregando] = useState(false);
    const [nr, setNr] = useState('');
    const [item, setItem] = useState('');
    const [s_m, setSM] = useState('');
    const [g, setG] = useState('');
    const [o_que, setOQue] = useState('');
    const [como_atender, setComoAtender] = useState('');
    const [descricao_item, setDescricaoItem] = useState('');
    const [como_evidenciar, setComoEvidenciar] = useState('');
    const [fullAply, setFullAply] = useState(false);
    const [options, setOptions] = useState([]);

    const { aviso } = React.useContext(Context);

    const s_m_options = {
        s: 'Gestão de Segurança do Trabalho',
        m: 'Gestão de Medicina do Trabalho',
        ma: 'Gestão de Meio Ambiente',
        rs: 'Gestão de Responsabilidade Social',
        q: 'Gestão da Qualidade',
        si: 'Gestão de Sistema Integrado - SGI',
        c: 'Gestão Comportamental',
        so: 'Gestão da Segurança Operacional – ANP',
        co: 'Gestão de Contratadas - NR',
        ga: 'Gestão Auditiva - PCA',
        gr: 'Gestão Respiratória- PPR',
    }

    const handleAddition = (e, { value }) => {
        const copy = Array.from(options);
        copy.push({ key: value, text: value, value: value })
        setOptions(copy);
    }

    const handleChange = (e, { value }) => {
        setSM(value);
    }

    const DropSistema = React.useMemo(() => (
        <Dropdown
            options={options}
            placeholder={"Selecione"}
            search
            selection
            fluid
            upward
            allowAdditions
            additionLabel="Adicionar "
            value={s_m}
            onAddItem={handleAddition}
            onChange={handleChange}
        />
    ), [options, s_m]);

    useEffect(() => {

        async function inicial() {

            if (props.show) {

                setOptions(Object.entries(s_m_options).map(([key, value]) => ({
                    key,
                    text: value,
                    value: key,
                })))

                setNr('')
                setItem('')
                setSM('')
                setG('')
                setOQue('')
                setComoAtender('')
                setComoEvidenciar('')
                setFullAply(false)

                if (sessionStorage.getItem('audPerguntaId') !== null) {
                    setCarregando(true)
                    let perguntaAtual = props.perguntas.filter(item => item.id === parseInt(sessionStorage.getItem('audPerguntaId')))[0]
                    try {
                        if (!s_m_options.hasOwnProperty(perguntaAtual.s_m)) {
                            setOptions(prevOptions => [
                                ...prevOptions,
                                {
                                    key: perguntaAtual.s_m,
                                    value: perguntaAtual.s_m,
                                    text: perguntaAtual.s_m
                                }
                            ]);
                        }
                        setNr(perguntaAtual.nr)
                        setItem(perguntaAtual.item)
                        setSM(perguntaAtual.s_m)
                        setG(perguntaAtual.g)
                        setOQue(perguntaAtual.o_que)
                        setComoAtender(perguntaAtual.como_atender)
                        setDescricaoItem(perguntaAtual.descricao_item)
                        setComoEvidenciar(perguntaAtual.como_evidenciar)
                        setFullAply(perguntaAtual.user === 0 ? true : false)
                    } catch (error) {
                        console.log(error)
                        aviso('error', error)
                    } finally {
                        setCarregando(false)
                    }
                }

            }

        } inicial()

    }, [props.show])

    const handleRegister = async (e) => {

        e.preventDefault()

        var data = {
            nr,
            item,
            s_m,
            g,
            o_que,
            como_atender,
            descricao_item,
            como_evidenciar,
            fk_titulo_id: props.titulo,
            all: fullAply
        }

        var valida = false
        
        if (s_m !== 's' && s_m !== 'm') {
            valida = true
            setG(1)
        }

        if (nr === '' || item === '' || s_m === '' || o_que === '' || como_atender === '' || descricao_item === '' || como_evidenciar === '' || (g === '' && !valida) || ((g > 4 || g < 1) && !valida)) {
            if ((g > 4 || g < 1)) {
                aviso('warning', 'O valor de G deve estar entre 1 e 4')
            } else {
                aviso('warning', "Preencha todos os campos!")
            }

        } else {
            try {

                var mensagem
                setCarregando(true)
                if (sessionStorage.getItem('audPerguntaId') !== null) {
                    await api.patch("auditoria_pergunta/" + sessionStorage.getItem('audPerguntaId'), data)
                    mensagem = "Pergunta atualizado com sucesso"
                } else {
                    await api.post("auditoria_pergunta/", data)
                    mensagem = "Pergunta criada com sucesso"
                }

                aviso('success', mensagem)

            } catch (error) {

                aviso('error', error)

            } finally {
                setCarregando(false)
                props.onHide()
            }
        }
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {sessionStorage.getItem('audPerguntaId') !== null ? "Editar" : "Cadastrar"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />

                {props.admin === "true" && <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={fullAply}
                        onChange={() => setFullAply(!fullAply)}
                    />
                    <label style={{ paddingLeft: '5px' }}>Aplicar para todos os usuários</label>
                </div>}

                <Grid padded stackable doubling>
                    <Grid.Row columns={3} stretched>
                        <Grid.Column>
                            <div style={{ display: 'grid' }}>
                                <label>Norma</label>
                                <input value={nr} onChange={(e) => setNr(e.target.value)} />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div style={{ display: 'grid' }}>
                                <label>Item</label>
                                <input value={item} onChange={(e) => setItem(e.target.value)} />
                            </div>
                        </Grid.Column>
                        <GridColumn>
                            <div style={{ display: 'grid' }}>
                                <label>Descrição do item?</label>
                                <textarea className="text-area-risco" type="longtext" aria-multiline="true" value={descricao_item} onChange={e => setDescricaoItem(e.target.value)} />
                            </div>
                        </GridColumn>

                    </Grid.Row>
                    <Grid.Row columns={2} stretched>
                        <GridColumn>
                            <div style={{ display: 'grid' }}>
                                <label>Como atender o item da lei?</label>
                                <textarea className="text-area-risco" type="longtext" aria-multiline="true" value={como_atender} onChange={e => setComoAtender(e.target.value)} />
                            </div>
                        </GridColumn>
                        <GridColumn>
                            <div style={{ display: 'grid' }}>
                                <label>O que executar?</label>
                                <textarea className="text-area-risco" type="longtext" aria-multiline="true" value={o_que} onChange={e => setOQue(e.target.value)} />
                            </div>
                        </GridColumn>
                    </Grid.Row>
                    <Grid.Row columns={3} stretched>
                        <GridColumn>
                            <div style={{ display: 'grid' }}>
                                <label>Como evidenciar?</label>
                                <textarea className="text-area-risco" type="longtext" aria-multiline="true" value={como_evidenciar} onChange={e => setComoEvidenciar(e.target.value)} />
                            </div>
                        </GridColumn>
                        {(s_m === 'm' || s_m === 's') && (
                            <Grid.Column width={4} style={{ display: 'grid' }}>
                                <div style={{ display: 'grid' }}>
                                    <label>G</label>
                                    <input type='number' max={4} min={1} value={g} onChange={(e) => { setG(e.target.value) }} />
                                </div>
                            </Grid.Column>
                        )}
                        <Grid.Column style={{ display: 'grid' }}>
                            <div style={{ display: 'grid' }}>
                                <label>Sistema</label>
                                {DropSistema}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleRegister}>{sessionStorage.getItem('capituloId') !== null && sessionStorage.getItem('tituloId') !== null ? "Salvar" : "Cadastrar"}</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}


