import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';

import { Dropdown } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import { Context } from '../../../Context/AuthContext';



import { useState, useEffect } from 'react';

const cadastrarTrabalhador = async (trabalhadores, tipo, riscoId, trabalhadorId) => {
	if (tipo === "patch") {
		await api.patch("trabalhadores_expostos/" + trabalhadorId, trabalhadores)
	} else {
		if(trabalhadores.length !== undefined){
			await api.post(`trabalhadores_expostos?risco_id=${riscoId}`, trabalhadores)
		}else{
			let valor = []
			valor.push(trabalhadores)
			await api.post(`trabalhadores_expostos?risco_id=${riscoId}`, valor)
		}
	}
}

function TrabalhadoresExpostos({ addOrUpdateTrabalhador, ...props }) {

	const riscoId = sessionStorage.getItem('riscoId')
	const [trabId, setTrabId] = useState(0)

	const [carregando, setCarregando] = useState(false);
	const [cargo, setCargo] = useState('')
	const [quantidade, setQuantidade] = useState('')
	const { aviso } = React.useContext(Context);
	const [cargos, setCargos] = useState([])


	useEffect(() => {

		async function inicial() {

			if (props.show) {
				setTrabId(sessionStorage.getItem('trabId'))
				setCargo('')
				setCargos([])
				setQuantidade('')

				setCarregando(true)

				try {
					var copia = Array.from([])

					await api.get((`ocupacao?tarefa_id=${sessionStorage.getItem('atividadeId')}`)).then(response => {
						response.data.resultado.forEach(async cargo => {
							var passa = true

							props.trabalhadores.forEach(trabalhador => {
								if (trabalhador.nome === cargo.funcao && trabalhador.nome !== "")
									passa = false
							})
							
							if (passa) {
								copia.push({ key: cargo.funcao, text: cargo.funcao, value: cargo.funcao })
								setCargos(copia)
							}
						})
					})

					if (sessionStorage.getItem('trabId') !== '0') {
						props.trabalhadores.forEach(trabalhador => {
							if (trabalhador.id === parseInt(sessionStorage.getItem('trabId'))) {
								copia.push({ key: trabalhador.nome, text: trabalhador.nome, value: trabalhador.nome })
								setCargos(copia)
								setCargo(trabalhador.nome)
								setQuantidade(trabalhador.qtd)
							}
						})
					}

				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
				}
			}

		} inicial()

	}, [props.show, sessionStorage.getItem(trabId)])

	const handleRegister = async (e) => {

		e.preventDefault()

		const data = {
			nome: cargo,
			qtd: quantidade,
		}

		if (cargo === '' || quantidade === '') {
			aviso('warning', "Preencha todos os campos!")
		} else if (quantidade <= 0) {
			aviso('warning', "Quantidade mínima de trabalhadores é 1!")
		} else {

			try {
				var mensagem
				setCarregando(true)

				if (sessionStorage.getItem('riscoNovo') !== "true") {
					if (trabId == 0) {
						await cadastrarTrabalhador(data, "post", riscoId, null)
						mensagem = "Trabalhador cadastrado com sucesso"
					} else {
						await cadastrarTrabalhador(data, "patch", null, trabId)
						mensagem = "Trabalhador atualizado com sucesso"
					}
				} else {
					if (sessionStorage.getItem('trabId') !== '0') {
						addOrUpdateTrabalhador(data, sessionStorage.getItem('trabId'));
						mensagem = "Trabalhador atualizado com sucesso"
					}else{
						addOrUpdateTrabalhador(data, null);
						mensagem = "Trabalhador cadastrado com sucesso"
					}
				}

				aviso('success', mensagem)

			} catch (error) {

				aviso('error', error)

			} finally {
				setCarregando(false)
				props.onHide()
			}
		}
	}

	const handleChange = (e, { value }) => {
		setCargo(value);
	}

	const DropCargos = React.useMemo(() =>
	(
		<Dropdown
			options={cargos}
			clearable
			placeholder="Selecione"
			search
			lazyLoad={true}
			selection
			fluid
			upward
			value={cargo}
			onChange={handleChange}
		/>
	), [cargo, cargos])

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Trabalhador exposto
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />

				<div className="trabexp">

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Cargo do trabalhador exposto</label>
						{DropCargos}
					</div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Quantidade</label>
						<input type="number" min={1} onChange={e => setQuantidade(e.target.value)} value={quantidade} />
					</div>
				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleRegister}>{trabId == 0 ? "Cadastrar" : "Salvar"}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

export  {TrabalhadoresExpostos, cadastrarTrabalhador};