import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import CadastrarEmpresa from './pages/Empresa/CadastrarEmpresa';
import LicencaAtiva from './pages/Licenca/LicencaAtiva';
import AtivarLicenca from './pages/Licenca/AtivarLicenca';
import CadastrarEstabelecimentos from './pages/Estabelecimento/CadastrarEstabelecimentos';
import CadastrarAtividades from './pages/Atividade/CadastrarAtividades';
import GerarRelatorio from './pages/GerarRelatorio';
import CadastrarRisco from './pages/Risco/CadastrarRisco';
import GerenciarEmpresas from './pages/Empresa/GerenciarEmpresas';
import GerenciarEstabelecimentos from './pages/Estabelecimento/GerenciarEstabelecimentos';
import GerenciarAtividades from './pages/Atividade/GerenciarAtividades';
import GerenciarRiscos from './pages/Risco/GerenciarRiscos';
import AvlRisco from './pages/Risco/AvlRisco';
import DashRisco from './pages/Dashboards/GestaoRisco'
import DashPlano from './pages/Dashboards/GestaoPlano'
import GestUsers from './pages/GestaoAdmin'
import ReavaliacaoPGR from './pages/ReavaliacaoPGR'
import Auditoria from './pages/Auditoria'
import LaudoSugestoes from './pages/LaudoSugestoes'
import Videos from './pages/Videos'
import GestAud from './pages/GestaoAuditoria/Capitulo'
import GestaoWorkspaces from './pages/GestaoWorkspaces';


import { Context } from './Context/AuthContext';


function CustomRoute({ isFullLicensed, isObserver, isAdmin, isPrivate, isLicensed, isAud, isPublic, ...rest }) {

  const { Authenticated, Observer, Licensed, Admin, FullLicensed, Load, Aud } = useContext(Context);


  if (Load) {
    return null;
  }

  if(!isPublic && !Authenticated){
    return <Redirect to='/login' />
  }

  //Verifica se a rota é Pública e o usuário está autenticado porém sem a licença
  if (isPublic && !Licensed && Authenticated) {
    return <Redirect to='/AtivarLicenca' />
  }

  //Verifica se a rota precisa de Licença e o usuário está autenticado mas não tem licença
  if (isLicensed && !Licensed && Authenticated) {
    return <Redirect to='/AtivarLicenca' />
  }

  if(isAdmin && !Admin && !Licensed && Authenticated){
    return <Redirect to='/AtivarLicenca' />
  }

  if(isAud && !FullLicensed && !Aud && !Licensed && Authenticated){
    return <Redirect to='/AtivarLicenca' />
  }

  if(isFullLicensed && !FullLicensed && !Licensed && Authenticated){
    return <Redirect to='/AtivarLicenca' />
  }

  if(isObserver && !Observer && !FullLicensed && !Licensed && Authenticated){
    return <Redirect to='/AtivarLicenca' />
  }

  //Verifica se a rota é privada e se o usuário tem licença
  if (isPrivate && Licensed) {
    if(Observer){
      return <Redirect to='/DashBoardRisco' />
    }else if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }

  if(isLicensed && !isAud && Aud){
    return <Redirect to='/auditoria' />
  }

  if(isLicensed && !isObserver && Observer){
    return <Redirect to='/DashBoardRisco' />
  }

  //Verifica se a rota é Pública e se o usuário está com licença
  if (isPublic && Licensed) {
    if(Observer){
      return <Redirect to='/DashBoardRisco' />
    }else if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }

  if(isAdmin && !Admin && Licensed){
    if(Observer){
      return <Redirect to='/DashBoardRisco' />
    }else if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }

  if(isAud && !isLicensed && !Aud && !FullLicensed && Licensed){
    if(Observer){
      return <Redirect to='/DashBoardRisco' />
    }else if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }

  if(isObserver && !Observer && !FullLicensed && Licensed){
    if(Observer){
      return <Redirect to='/DashBoardRisco' />
    }else if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }


  if(isFullLicensed && !FullLicensed && Licensed){
    if(Aud){
      return <Redirect to='/auditoria' />
    }else{
      return <Redirect to='/gerenciarempresas' />
    }
  }



  return <Route {...rest} />

}

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>

        <CustomRoute isPublic exact path="/" component={Login} />
        <CustomRoute isPublic path="/login" component={Login} />
        <CustomRoute isPrivate path="/AtivarLicenca" component={AtivarLicenca} />
        <CustomRoute isLicensed path="/CadastrarEmpresa" component={CadastrarEmpresa} />
        <CustomRoute isLicensed isAud isObserver path="/LicencaAtiva" component={LicencaAtiva} />
        <CustomRoute isLicensed path="/CadastrarEstabelecimentos" component={CadastrarEstabelecimentos} />
        <CustomRoute isLicensed path="/CadastrarAtividades" component={CadastrarAtividades} />
        <CustomRoute isLicensed isAud isObserver path="/gerarRelatorio" component={GerarRelatorio} />
        <CustomRoute isLicensed path="/CadastrarRisco" component={CadastrarRisco} />
        <CustomRoute isLicensed path="/GerenciarEmpresas" component={GerenciarEmpresas} />
        <CustomRoute isLicensed path="/GerenciarEstabelecimentos" component={GerenciarEstabelecimentos} />
        <CustomRoute isLicensed path="/GerenciarAtividades" component={GerenciarAtividades} />
        <CustomRoute isLicensed path="/AvlRisco" component={AvlRisco} />
        <CustomRoute isLicensed path="/GerenciarRiscos" component={GerenciarRiscos} />
        <CustomRoute isFullLicensed path="/ReavaliacaoPGR" component={ReavaliacaoPGR} />
        <CustomRoute isAud path="/Auditoria" component={Auditoria} />
        <CustomRoute isFullLicensed path="/LaudoConfig" component={LaudoSugestoes} />
        <CustomRoute isLicensed path="/Videos" component={Videos} />
        <CustomRoute isObserver path="/DashBoardRisco" component={DashRisco} />
        <CustomRoute isObserver path="/DashBoardPlano" component={DashPlano} />
        <CustomRoute isAdmin path="/GestaoUsuarios" component={GestUsers} />
        <CustomRoute isAdmin path="/GestaoAuditoria" component={GestAud} />
        <CustomRoute isFullLicensed path="/GestaoWorkspace" component={GestaoWorkspaces} />

      </Switch>
    </BrowserRouter>
  );
}