import api from "./api";

export const TOKEN_KEY = "@jmv-token-key";

export const getData = async () => {
    try {
        const res = await api.get('verifica_login');
        const valor = res.data;
        return valor
    } catch (error) {
        return ''
    }
}

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login_token = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout_token = () => {
    localStorage.removeItem(TOKEN_KEY);
};
