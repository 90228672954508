import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';
import { Modal, Button } from 'react-bootstrap'
import { Context } from '../../../Context/AuthContext';
import { useState, useEffect } from 'react';


export function Elaboradores({ addOrUpdateElaboradores, ...props }) {

    const [nome, setNome] = useState('');
    const [cargo, setCargo] = useState('');
    const [orgao_classe, setOrgao_classe] = useState('');
    const [numero_inscricao, setNumero_inscricao] = useState('');
    const [elabId, setElabId] = useState('');
    const [carregando, setCarregando] = useState(false)
    const { aviso } = React.useContext(Context);

    useEffect(() => {

        async function incial() {
            try {
                setNome('');
                setCargo('');
                setOrgao_classe('');
                setNumero_inscricao('');

                const elabId = sessionStorage.getItem('elab_id');
                setElabId(elabId);

                const elaborador = props.elaboradores.find(elaborador => elaborador.id === parseInt(elabId));

                if (elaborador) {
                    setNome(elaborador.nome);
                    setCargo(elaborador.cargo);
                    setOrgao_classe(elaborador.orgao_classe);
                    setNumero_inscricao(elaborador.numero_inscricao);
                }

            } catch (error) {
                aviso('error', error)
            }
        }

        if (props.show) {
            incial();
        }

    }, [props.show]);


    async function handleElaborador() {

        const data = {
            nome,
            cargo,
            orgao_classe,
            numero_inscricao
        };

        setCarregando(true);

        if (data.nome === '' || data.cargo === '' || data.orgao_classe === '' || data.numero_inscricao === '') {
            aviso('warning', "Preencha todos os campos!")
        } else {

            try {
                if (!sessionStorage.getItem('empresaNovo')) {
                    if (elabId !== null) {
                        await cadastrarElaborador(data, "patch", null, elabId);
                        aviso('success', 'Elaborador atualizado com sucesso');
                    } else {
                        await cadastrarElaborador(data, "post", sessionStorage.getItem('empresaId'), null);
                        aviso('success', 'Elaborador cadastrado com sucesso');
                    }
                } else {
                    if (elabId !== null) {
                        addOrUpdateElaboradores(data, elabId);
                        aviso('success', 'Elaborador atualizado com sucesso');
                    } else {
                        addOrUpdateElaboradores(data, null);
                        aviso('success', 'Elaborador cadastrado com sucesso');
                    }
                }
            } catch (error) {
                aviso('error', error)
            } finally {
                setCarregando(false);
                props.onHide();
            }
        }

    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastro de elaborador
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="empresa-modal">
                    <div className="empresa-modal-box-nome">
                        <label htmlFor="e-empresa-modal-nome">Nome</label>
                        <input
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                            type="text"
                            id="e-empresa-modal-nome"
                            placeholder="Digite aqui" />
                    </div>
                    <div className="empresa-modal-box-cargo">
                        <label htmlFor="e-empresa-modal-cargo">Cargo</label>
                        <input
                            value={cargo}
                            onChange={e => setCargo(e.target.value)}
                            type="text"
                            id="e-empresa-modal-cargo"
                            placeholder="Digite aqui" />
                    </div>
                    <div className="empresa-modal-box-orgao">
                        <label htmlFor="e-empresa-modal-orgao">Orgão</label>
                        <input
                            value={orgao_classe}
                            onChange={e => setOrgao_classe(e.target.value)}
                            type="text"
                            id="e-empresa-modal-orgao"
                            placeholder="Digite aqui" />
                    </div>
                    <div className="empresa-modal-box-num-orgao">
                        <label htmlFor="e-empresa-modal-num-orgao">Nº de inscrição no orgão de classe</label>
                        <input
                            value={numero_inscricao}
                            onChange={e => setNumero_inscricao(e.target.value)}
                            type="text"
                            id="e-empresa-modal-num-orgao"
                            placeholder="Digite aqui" />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleElaborador} >Salvar</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const cadastrarElaborador = async (elaboradores, tipo, empresaId, elaboradorId) => {
    try {
        if (tipo === "patch") {
            await api.patch(`elaborador/${elaboradorId}`, elaboradores);
        } else {
            const listaElaboradores = Array.isArray(elaboradores) ? elaboradores : [elaboradores];
            await Promise.all(
                listaElaboradores.map(elaborador =>
                    api.post(`elaborador?empresa_id=${empresaId}`, elaborador)
                )
            );
        }
    } catch (error) {
        console.log(error)
    }
}
