import React, { useState, useEffect } from 'react';
//import { Context} from '../../Context/AuthContext';
import { useHistory } from 'react-router-dom'
import {Link} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { FiChevronLeft } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import './styles.css';

import { Menu } from '../../../global.js'


export default function CadastrarEstabelecimentos() {

	const history = useHistory();
	const [carregando, setCarregando] = useState(false)
	const { aviso } = React.useContext(Context);


	const estabelecimentoId = sessionStorage.getItem('estabelecimentoId')
	const empresaId = sessionStorage.getItem('empresaId')
	const empresaName = sessionStorage.getItem('empresaName')

	const [tipo0, setTipo] = useState('')
	const [nome0, setNome] = useState('')
	const [nivel0, setNivel] = useState(0)
	const [modalidade0, setModalidade] = useState('')
	const [caracterizacao_ambiente0, setAmbiente] = useState('')
	const [caracterizacao_processo0, setProcesso] = useState('')
	const [ordem, setOrdem] = useState(null)

	const [tipo1, setTipo1] = useState('')
	const [nome1, setNome1] = useState('')
	const [nivel1, setNivel1] = useState(0)

	const [tipo2, setTipo2] = useState('')
	const [nome2, setNome2] = useState('')
	const [nivel2, setNivel2] = useState(0)

	const [tipo3, setTipo3] = useState('')
	const [nome3, setNome3] = useState('')
	const [nivel3, setNivel3] = useState(0)

	const [tipo4, setTipo4] = useState('')
	const [nome4, setNome4] = useState('')
	const [nivel4, setNivel4] = useState(0)



	useEffect(() => {
		async function inicial() {
			if(sessionStorage.getItem('estabelecimentoNovo') === null)
			{
				try {
					if(sessionStorage.getItem('estabelecimentoNovo') !== undefined)
					setCarregando(true)
					await api.get(`estabelecimento?empresa_id=${empresaId}&nivel=all`).then(response => {

						var nivelSuperior;
						var nivelP

						let est = (response.data.resultado.filter(estabelecimento => estabelecimento.id === parseInt(estabelecimentoId)))
						est.forEach(item => {
							setTipo(item.tipo)
							setNome(item.nome)
							setNivel(item.nivel)
							nivelP = item.nivel
							nivelSuperior = item.id_nivel_superior
							setModalidade(item.modalidade)
							setAmbiente(item.caracterizacao_ambiente)
							setProcesso(item.caracterizacao_processo)
							setOrdem(item.ordem)
						})


						if (nivelP >= 2) {
							let est2 = (response.data.resultado.filter(estabelecimento => estabelecimento.id === parseInt(nivelSuperior)))
							est2.forEach(item => {
								setTipo1(item.tipo)
								setNome1(item.nome)
								setNivel1(item.nivel)
								nivelSuperior = item.id_nivel_superior
							})
						}

						if (nivelP >= 3) {
							let est3 = (response.data.resultado.filter(estabelecimento => estabelecimento.id === parseInt(nivelSuperior)))
							est3.forEach(item => {
								setTipo2(item.tipo)
								setNome2(item.nome)
								setNivel2(item.nivel)
								nivelSuperior = item.id_nivel_superior
							})
						}

						if (nivelP >= 4) {
							let est2 = (response.data.resultado.filter(estabelecimento => estabelecimento.id === parseInt(nivelSuperior)))
							est2.forEach(item => {
								setTipo3(item.tipo)
								setNome3(item.nome)
								setNivel3(item.nivel)
								nivelSuperior = item.id_nivel_superior
							})
						}

						if (nivelP >= 5) {
							let est3 = (response.data.resultado.filter(estabelecimento => estabelecimento.id === parseInt(nivelSuperior)))
							est3.forEach(item => {
								setTipo4(item.tipo)
								setNome4(item.nome)
								setNivel4(item.nivel)
								nivelSuperior = item.id_nivel_superior
							})
						}

					})

				} catch (error) {

				} finally {
					setCarregando(false)
				}
			}

		} inicial()

	}, [empresaId, estabelecimentoId]);


	function setData1() {

		var tipo = tipo1
		var nome = nome1
		var nivel = nivel1
		var modalidade = ''
		var caracterizacao_ambiente = ''
		var caracterizacao_processo = ''

		var data = {
			tipo,
			nome,
			nivel,
			modalidade,
			caracterizacao_ambiente,
			caracterizacao_processo
		};
		return data;
	}

	function setData2() {

		var tipo = tipo2
		var nome = nome2
		var nivel = nivel2
		var modalidade = ''
		var caracterizacao_ambiente = ''
		var caracterizacao_processo = ''

		var data = {
			tipo,
			nome,
			nivel,
			modalidade,
			caracterizacao_ambiente,
			caracterizacao_processo
		};
		return data;
	}

	function setData3() {

		var tipo = tipo3
		var nome = nome3
		var nivel = nivel3
		var modalidade = ''
		var caracterizacao_ambiente = ''
		var caracterizacao_processo = ''

		var data = {
			tipo,
			nome,
			nivel,
			modalidade,
			caracterizacao_ambiente,
			caracterizacao_processo
		};
		return data;
	}

	function setData4() {

		var tipo = tipo4
		var nome = nome4
		var nivel = nivel4
		var modalidade = ''
		var caracterizacao_ambiente = ''
		var caracterizacao_processo = ''

		var data = {
			tipo,
			nome,
			nivel,
			modalidade,
			caracterizacao_ambiente,
			caracterizacao_processo
		};

		return data;
	}


	async function handleRegister(e) {

		e.preventDefault();

		let body = [];

		var tipo = tipo0
		var nome = nome0
		var nivel = nivel0
		var modalidade = modalidade0
		var caracterizacao_ambiente = caracterizacao_ambiente0
		var caracterizacao_processo = caracterizacao_processo0

		var data = {
			tipo,
			nome,
			nivel,
			modalidade,
			caracterizacao_ambiente,
			caracterizacao_processo,
			ordem
		};
		body[0] = data;

		if (nivel == "2") {
			body[0] = setData1();
			body[1] = data
		} else if (nivel == "3") {
			body[0] = setData2()
			body[1] = setData1()
			body[2] = data
		} else if (nivel == "4") {
			body[0] = setData3()
			body[1] = setData2()
			body[2] = setData1()
			body[3] = data
		} else if (nivel == "5") {
			body[0] = setData4()
			body[1] = setData3()
			body[2] = setData2()
			body[3] = setData1()
			body[4] = data
		}

		if (data.tipo === '' || data.nome === '' || data.nivel === '' || data.modalidade === '' || data.caracterizacao_ambiente === '' || data.caracterizacao_processo === '') {

			aviso('warning', "Preencha todos os campos!")

		} else {

			if(sessionStorage.getItem('estabelecimentoNovo') === null){
				try {
					setCarregando(true)
					await api.patch(`estabelecimento/${estabelecimentoId}`, body);
					aviso('success', 'Estabelecimento atualizado com sucesso')
				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
					history.push('Gerenciarestabelecimentos')
				}
			}else{
				try {
					setCarregando(true)
					await api.post(`estabelecimento?empresa_id=${empresaId}`, body);
					aviso('success', 'Estabelecimento cadastrado com sucesso')
				} catch (error) {
					aviso('error', error)
				} finally {
					setCarregando(false)
					history.push('Gerenciarestabelecimentos')
				}
			}
			
		}

	}


	return (
		<div>
			<Menu />
			<ReactTooltip effect="solid" />
			<Loading loading={carregando} message='Carregando...' />
			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Empresa: {empresaName}</h4></div>
			<div className="basic-content">

				<div className="titulo">
					<h1 className="titulo">{sessionStorage.getItem('estabelecimentoNovo') === null? "Editar organização" : "Cadastrar organização"}</h1>
				</div>

				<div className="cadastrar-box">

					<div className="back-button">
						<Link to='/GerenciarEstabelecimentos'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
					</div>

					<div className="linha">
						<div className="nomeEstabelecimento">
							<label htmlFor="nome-estabelecimento">Nome da organização</label>
							<input value={nome0} onChange={e => setNome(e.target.value)} type="text" placeholder="Digite aqui" />
						</div>
						<div className="atividadetarefa">
							<label htmlFor="atividadetarefa">Atividade ou tarefa</label>
							<select onChange={e => setModalidade(e.target.value)} value={modalidade0} name="select">
								<option value="" disabled>Selecione</option>
								<option value="Atividade">Atividade</option>
								<option value="Tarefa">Tarefa</option>
							</select>
						</div>
					</div>
					<div className="linha">
						<div className="nivelestabelecimento">
							<label htmlFor="nivelestabelecimento">Nível da organização</label>
							<select id="nivelestabelecimento" value={nivel0} onChange={e => setNivel(e.target.value)} name="select">
								<option value="1">Nível 1</option>
								<option value="2">Nível 2</option>
								<option value="3">Nível 3</option>
								<option value="4">Nível 4</option>
								<option value="5">Nível 5</option>
							</select>
						</div>
						<div className="tipoestabelecimento">
							<label htmlFor="tipoestabelecimento">Tipo da organização</label>
							<select onChange={e => setTipo(e.target.value)} value={tipo0}>
								<option value="" disabled>Selecione</option>
								<option value="Unidade">Unidade</option>
								<option value="Setor">Setor</option>
								<option value="Área">Área</option>
								<option value="GHE">GHE</option>
								<option value="GSE">GSE</option>
								<option value="Célula">Célula</option>
								<option value="Gerência">Gerência</option>
								<option value="Atividade">Atividade</option>
								<option value="Outros">Outros</option>
							</select>
						</div>
					</div>

					<div className="linha">
						<div className="metade">
							<label htmlFor="">Caracterização do processo</label>
							<textarea value={caracterizacao_processo0} onChange={e => setProcesso(e.target.value)} className="caracterizacaoprocesso" aria-multiline="true" type="longtext" placeholder="Digite aqui" />
						</div>
						<div className="metade">
							<label htmlFor="">Caracterização do ambiente de trabalho</label>
							<textarea value={caracterizacao_ambiente0} onChange={e => setAmbiente(e.target.value)} className="caracterizacaoambiente" aria-multiline="true" type="longtext" placeholder="Digite aqui" />
						</div>
					</div>

					<div className="niveisList">
						{nivel0 >= 5 ?
							<div className="linha2">
								<div className="pertence2">
									<div className="horizontal">
										<h2>Pertence à</h2>
										<h2>Nível 4</h2>
									</div>
									<div className="linhaespecial">
										<div className="nomepertence">
											<label htmlFor="nomepertence">Nome da organização</label>
											<input type="text" value={nome4} onChange={e => setNome4(e.target.value)} />
										</div>
										<div className="tipopertence">
											<label htmlFor="tipopertence">Tipo da organização</label>
											<select onChange={e => setTipo4(e.target.value)} value={tipo4}>
												<option value="" disabled>Selecione</option>
												<option value="Unidade">Unidade</option>
												<option value="Setor">Setor</option>
												<option value="Área">Área</option>
												<option value="GHE">GHE</option>
												<option value="GSE">GSE</option>
												<option value="Célula">Célula</option>
												<option value="Gerência">Gerência</option>
												<option value="Atividade">Atividade</option>
												<option value="Outros">Outros</option>
											</select>
										</div>
									</div>
								</div>
							</div> : null}
						{nivel0 >= 4 ?
							<div className="linha2">
								<div className="pertence2">
									<div className="horizontal">
										<h2>Pertence à</h2>
										<h2>Nível 3</h2>
									</div>
									<div className="linhaespecial">
										<div className="nomepertence">
											<label htmlFor="nomepertence">Nome da organização</label>
											<input type="text" value={nome3} onChange={e => setNome3(e.target.value)} />
										</div>
										<div className="tipopertence">
											<label htmlFor="tipopertence">Tipo da organização</label>
											<select onChange={e => setTipo3(e.target.value)} value={tipo3}>
												<option value="" disabled>Selecione</option>
												<option value="Unidade">Unidade</option>
												<option value="Setor">Setor</option>
												<option value="Área">Área</option>
												<option value="GHE">GHE</option>
												<option value="GSE">GSE</option>
												<option value="Célula">Célula</option>
												<option value="Gerência">Gerência</option>
												<option value="Atividade">Atividade</option>
												<option value="Outros">Outros</option>
											</select>
										</div>
									</div>
								</div>
							</div> : null}
						{nivel0 >= 3 ?
							<div className="linha2">
								<div className="pertence2">
									<div className="horizontal">
										<h2>Pertence à</h2>
										<h2>Nível 2</h2>
									</div>
									<div className="linhaespecial">
										<div className="nomepertence">
											<label htmlFor="nomepertence">Nome da organização</label>
											<input type="text" value={nome2} onChange={e => setNome2(e.target.value)} />
										</div>
										<div className="tipopertence">
											<label htmlFor="tipopertence">Tipo da organização</label>
											<select onChange={e => setTipo2(e.target.value)} value={tipo2}>
												<option value="" disabled>Selecione</option>
												<option value="Unidade">Unidade</option>
												<option value="Setor">Setor</option>
												<option value="Área">Área</option>
												<option value="GHE">GHE</option>
												<option value="GSE">GSE</option>
												<option value="Célula">Célula</option>
												<option value="Gerência">Gerência</option>
												<option value="Atividade">Atividade</option>
												<option value="Outros">Outros</option>
											</select>
										</div>
									</div>
								</div>
							</div> : null}
						{nivel0 >= 2 ?
							<div className="linha2">
								<div className="pertence2">
									<div className="horizontal">
										<h2>Pertence à</h2>
										<h2>Nível 1</h2>
									</div>
									<div className="linhaespecial">
										<div className="nomepertence">
											<label htmlFor="nomepertence">Nome da organização</label>
											<input type="text" value={nome1} onChange={e => setNome1(e.target.value)} />
										</div>
										<div className="tipopertence">
											<label htmlFor="tipopertence">Tipo da organização</label>
											<select onChange={e => setTipo1(e.target.value)} value={tipo1}>
												<option value="" disabled>Selecione</option>
												<option value="Unidade">Unidade</option>
												<option value="Setor">Setor</option>
												<option value="Área">Área</option>
												<option value="GHE">GHE</option>
												<option value="GSE">GSE</option>
												<option value="Célula">Célula</option>
												<option value="Gerência">Gerência</option>
												<option value="Atividade">Atividade</option>
												<option value="Outros">Outros</option>
											</select>
										</div>
									</div>
								</div>
							</div> : null}

					</div>



					<div className="cadastrar">
						<button onClick={handleRegister}>{sessionStorage.getItem('estabelecimentoNovo') === null? "Salvar" : "Cadastrar"}</button>
					</div>
				</div>


			</div>
		</div>
	);
}