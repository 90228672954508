import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../services/api'
import Loading from '../Loading'
import { Context } from '../../Context/AuthContext';
import { Dropdown, Tab, Checkbox } from 'semantic-ui-react'
import { FiEdit, FiTrash2, FiInfo } from 'react-icons/fi';
import { BiDetail } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'


import './styles.css';
import '../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from '../../global.js'


export default function Reavaliacao() {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [empresa, setEmpresa] = useState('');
  const [emp, setEmp] = useState()
  const [estabelecimento, setEstabelecimento] = useState('');
  const [estab, setEstab] = useState();
  const [atividades, setAtividades] = useState([]);
  const [revisores, setRevisores] = useState([]);
  const [rev, setRev] = useState([])
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])
  const [perguntas, setPerguntas] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [modalShow2, setModalShow2] = useState(false)
  const [modalShow3, setModalShow3] = useState(false)
  const [showTarefas, setShowTarefas] = useState(false)
  const [estabelecimentoModalidade, setEstabelecimentoModalidade] = useState()
  const [per, setPer] = useState([])
  const [reavaliacoes, setReavaliacoes] = useState([])
  const [revAux, SetRevAux] = useState('')

  var textPergunta = "1.5.4.4.6 A avaliação de riscos deve constituir um processo contínuo e ser revista a cada dois anos ou"
    + "quando da ocorrência das seguintes situações:<br/><br/>"
    + "a) após implementação das medidas de prevenção, para avaliação de riscos residuais;"
    + "<br/>b) após inovações e modificações nas tecnologias, ambientes, processos, condições, procedimentos e"
    + "organização do trabalho que impliquem em novos riscos ou modifiquem os riscos existentes;"
    + "<br/>c) quando identificadas inadequações, insuficiências ou ineficácias das medidas de prevenção;"
    + "<br/>d) na ocorrência de acidentes ou doenças relacionadas ao trabalho;"
    + "<br/>e) quando houver mudança nos requisitos legais aplicáveis."

  const onfocus = () => {
    setGatilho(!ref.current)
    sessionStorage.setItem("Gatilho", "true")
  }

  const [gatilho, _setGatilho] = useState(true)

  const ref = React.useRef(gatilho);
  const setGatilho = data => {
    ref.current = data;
    _setGatilho(data);
  };

  const panes = [
    {
      menuItem: 'Nova reavaliação',
      render: () => <Tab.Pane attached={false}>{NovaReavaliacao}</Tab.Pane>,
    },
    {
      menuItem: 'Gerenciar perguntas',
      render: () => <Tab.Pane attached={false}>{GerenciarPerguntas}</Tab.Pane>,
    },
    {
      menuItem: 'Gerenciar revisores',
      render: () => <Tab.Pane attached={false}>{GerenciarRevisores}</Tab.Pane>,
    },
    {
      menuItem: 'Histórico de reavaliações',
      render: () => <Tab.Pane attached={false}>{GerenciarReavaliacoes}</Tab.Pane>,
    }
  ]

  useEffect(() => {

    async function inicial() {

      setCarregando(true)

      try {

        var op = []
        await Promise.all([
          api.get('empresa')
        ]).then(response => {
          response[0].data.resultado.forEach((element, i) => {
            op.push({ key: i, value: element.id, text: element.razao_social })
          });
          setEmp(response[0].data.resultado)
          setOptions(op)
        })

      } catch (error) {
        console.log(error)
      } finally {
        setCarregando(false)
      }
    }

    inicial()
    window.addEventListener('focus', onfocus);

    // cleanup this component
    return () => {
      window.removeEventListener('focus', onfocus);
    };

  }, [])

  useEffect(() => {
    UpdateRevisores()
    UpdatePerguntas()
  }, [empresa])

  useEffect(() => {
    UpdateTarefas()
    UpdatePerguntas()
  }, [gatilho])


  const handleChange = async (e, { value }) => {

    setCarregando(true)
    setEmpresa(value);

    var op = []

    await Promise.all([
      api.get(`estabelecimento?empresa_id=${value}`),
      api.get('revisor?empresa_id=' + value)
    ]).then(response => {
      response[0].data.resultado.forEach((element, i) => {
        op.push({ key: i, value: element.id, text: element.nome })
      });
      setEstab(response[0].data.resultado)
      setOptions2(op)
      setReavaliacoes([])
    })

    setCarregando(false)
  }

  const UpdateHistorico = async (estabelecimento) => {

    var a = await api.get('reavaliacao?estabelecimento_id='+estabelecimento)
    var b = a.data.resultado

    for (var i=0; i<b.length; i++){
      b[i].perguntas = []
      b[i].revisores = []
    }

    for (var i=0; i<b.length; i++){
      var c = await api.get('reavaliacao_revisor?reavaliacao_id='+b[i].id)
      c.data.resultado.forEach(item => {
        b[i].revisores.push(item.nome)
      })
      var d = await api.get('reavaliacao_quiz?reavaliacao_id='+b[i].id)
      d.data.resultado.forEach(item => {
        b[i].perguntas.push({pergunta:item.pergunta,comentario:item.comentario})
      })
    }
    setReavaliacoes(b)
    setCarregando(false)
  }

  const handleChange2 = async (e, { value }) => {
    setCarregando(true)
    const response = await api.get(`tarefa?estabelecimento_id=${value}`)
    setAtividades(response.data.resultado.filter(atividade => atividade.nome !== ""))
    setEstabelecimentoModalidade(estab.filter(item => item.id == value)[0].modalidade)
    setEstabelecimento(value);
    await UpdateHistorico(value)
  }

  const handleChange3 = (e2, { value }) => {
    setRevisores(value);
  }

  async function handleDeletePergunta(id) {
    try {
      setCarregando(true)
      await api.post(`sugestoesDelete/8`,{lista:[id]})
      setPerguntas(perguntas.filter(pergunta => pergunta.id !== id))
      aviso('success', 'Pergunta removida com sucesso')
    } catch (error) {
      aviso("error", "Erro ao deletar pergunta, tente novamente", true)
    } finally {
      setCarregando(false)
    }
  }

  async function handleDeleteRevisor(id) {
    try {
      setCarregando(true)
      await api.delete(`revisor/${id}`)
      setRevisores(revisores.filter(revisor => revisor.id !== id))
      aviso('success', 'Revisor removida com sucesso')
    } catch (error) {
      aviso("error", "Erro ao deletar revisor, tente novamente", true)
    } finally {
      setCarregando(false)
    }
  }

  async function handleDeleteReavaliacao(id) {
    try {
      setCarregando(true)
      await api.delete(`reavaliacao/${id}`)
      setReavaliacoes(reavaliacoes.filter(reavaliacao => reavaliacao.id !== id))
      aviso('success', 'Reavaliação removida com sucesso')
    } catch (error) {
      aviso("error", "Erro ao deletar reavaliação, tente novamente", true)
    } finally {
      setCarregando(false)
    }
  }

  async function handleDeleteAtividade(id) {
    try {
      setCarregando(true)
      await api.delete(`tarefa/${id}`)
      setAtividades(atividades.filter(atividade => atividade.id !== id))
      aviso('success', estabelecimentoModalidade === 'Tarefa' ? "Tarefa" : "Atividade" + " removida com sucesso")
    } catch (error) {
      aviso('error', error)
    } finally {
      setCarregando(false)
    }
  }

  async function UpdateTarefas() {
    if (estabelecimento !== '') {
      setCarregando(true)
      const response = await api.get(`tarefa?estabelecimento_id=${estabelecimento}`)
      setAtividades(response.data.resultado.filter(atividade => atividade.nome !== ""))
      setCarregando(false)
    }
  }

  async function UpdatePerguntas() {
    if (empresa !== '') {
      await api.get('sugestoes/13?empresa_id=' + empresa).then(response => {
        setPerguntas(response.data.resultado)
        var array = []
        response.data.resultado.forEach(item => {
          item.check = false
          item.comentario = ''
          array.push(item)
        })
        setPer(array)
      })
    }
    
  }

  async function UpdateRevisores() {
    
    if (empresa !== '') {
      setCarregando(true)
      setRevisores([])
      let copy = []
      await api.get('revisor?empresa_id=' + empresa).then(response => {
        response.data.resultado.forEach(revisor => {
          copy.push({ key: revisor.id, text: revisor.nome, value: revisor.id });
        })
        setOptions3(copy)
        setRev(response.data.resultado)
      })
      setCarregando(false)
    }
    
  }


  const DropEmpresas = React.useMemo(() =>
  (
    <Dropdown
      className='dashboard-drop'
      options={options}
      placeholder="Selecione a empresa"
      search
      selection
      value={empresa}
      onChange={handleChange}
    />
  ), [options, empresa])

  const DropEstabelecimentos = React.useMemo(() =>
  (
    <Dropdown
      className='dashboard-drop'
      options={options2}
      placeholder="Selecione a organização"
      search
      selection
      value={estabelecimento}
      onChange={handleChange2}
    />
  ), [options2, estabelecimento])

  const DropRevisores = React.useMemo(() => (
    <Dropdown
      options={options3}
      placeholder={"Selecione um ou mais revisores"}
      search
      selection
      clearable
      fluid
      multiple
      value={revisores}
      onChange={handleChange3}
    />
  ), [options3, revisores]);

  const CheckItem = (index) => {
    let b = Array.from(per)
    b[index].check = !b[index].check
    var teste = false
    b.forEach(item => {
      if (item.check) {
        teste = true
      }
    })
    if (teste) {
      setShowTarefas(true)
    } else {
      setShowTarefas(false)
    }
    setPer(b)
  }

  const handleChangeComentario = (index, e) => {
    let b = Array.from(per)
    b[index].comentario = e.target.value
    setPer(b)
  }

  const handleEditAtividade = (id) => {
    sessionStorage.removeItem('atividadeNovo');
    sessionStorage.setItem('atividadeId', id)
    sessionStorage.setItem('empresaId', empresa)
    sessionStorage.setItem('empresaName', emp.filter(item => item.id == empresa)[0].razao_social)
    sessionStorage.setItem('estabelecimentoId', estabelecimento)
    sessionStorage.setItem('estabelecimentoModalidade', estab.filter(item => item.id == estabelecimento)[0].modalidade)
    sessionStorage.setItem('estabelecimentoName', estab.filter(item => item.id == estabelecimento)[0].nome)
    const win = window.open("cadastraratividades", "_blank");
    win.focus();
  }

  const handleAccessRisco = (atividade) => {
    sessionStorage.removeItem('atividadeNovo');
    sessionStorage.setItem('atividadeId', atividade.id)
    sessionStorage.setItem('empresaId', empresa)
    sessionStorage.setItem('empresaName', emp.filter(item => item.id == empresa)[0].razao_social)
    sessionStorage.setItem('estabelecimentoId', estabelecimento)
    sessionStorage.setItem('estabelecimentoModalidade', estab.filter(item => item.id == estabelecimento)[0].modalidade)
    sessionStorage.setItem('estabelecimentoName', estab.filter(item => item.id == estabelecimento)[0].nome)
    sessionStorage.setItem('tarefa', atividade.nome)
    const win = window.open("Gerenciarriscos", "_blank");
    win.focus();
  }

  async function handleSalvar() {
    if (revisores.length < 1) {
      aviso('warning', 'Informe pelo menos um revisor')
    } else {
      if (estabelecimento == '') {
        aviso('warning', 'Selecione o estabelecimento primeiro')
      } else {

        var verifica = false
        var verifica2 = true
        per.forEach(pergunta => {
          if (pergunta.check) {
            verifica = true
            if (pergunta.comentario == '') {
              verifica2 = false
            }
          }
        })
        if (verifica) {
          if (!verifica2) {
            aviso('warning', 'Informe um comentário para a pergunta')
          } else {
            try {
              setCarregando(true)
              const response = await api.post('reavaliacao?estabelecimento_id=' + estabelecimento)
              if(response.data.sucesso){
                var id = response.data.id_reavaliacao
                per.forEach(pergunta => {
                  if (pergunta.check) {
                    if (pergunta.comentario !== '') {
                      api.post('reavaliacao_quiz?pergunta_id=' + pergunta.id + '&reavaliacao_id=' + id, { comentario: pergunta.comentario })
                    }
                  }
                })
                revisores.forEach(revisor => {
                  api.post('reavaliacao_revisor?reavaliacao_id='+id+'&revisor_id='+revisor)
                })
                aviso('success', 'Reavaliação salva com sucesso')
                setPer([])
                setEstabelecimento('')
                setAtividades([])
                setRevisores([])
              }else{
                aviso('warning', response.data.mensagem)
              }
            } catch (error) {
              aviso('error', error)
            } finally {
              setCarregando(false)
            }
          }
        } else {
          aviso('warning', 'Marque sim em pelo menos uma pergunta')
        }

      }
    }
  }

  const NovaReavaliacao = React.useMemo(() => {

    return (
      <div>
        <div className="reavaliacao-nova">
          <div className="reavaliacao-p1">
            <label>Empresa</label>
            {DropEmpresas}
          </div>
          <div className="reavaliacao-p2">
            <label>Organização</label>
            {DropEstabelecimentos}
          </div>
          <div className="reavaliacao-p3">
            <label>Revisores</label>
            {DropRevisores}
          </div>
        </div>
        <div>
          < ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
          {revisores.length > 0 ?
            <div className="reavaliacao-box">
              <div className="reavaliacao-box1">
                <h3 style={{ textAlign: 'center' }}>Perguntas e Respostas <FiInfo style={{ cursor: "pointer" }} data-tip={textPergunta} data-for="aux"></FiInfo></h3>
                {per.length !== 0 ? per.map((pergunta, index) => (
                  <div className="reavaliacao-pergunta" key={pergunta.id}>
                    <ReactTooltip id={toString(pergunta.id)} place="top" effect="solid"></ReactTooltip>
                    <div className="reavaliacao-name">
                      <div className="reavaliacao-pergunta">
                        <a>{pergunta.pergunta}</a>
                      </div>
                    </div>
                    <div className="reavaliacao-comentario">
                      <div className='reavaliacao-comentario2'>
                        <Checkbox
                          label='Marque para sim'
                          onClick={() => CheckItem(index)}
                          checked={pergunta.check}
                        />
                      </div>
                      {pergunta.check == true ?
                        <div className='reavaliacao-comentario1'>
                          <label style={{ paddingRight: '10px', width: '30%' }}>Comentário</label>
                          <textarea
                            value={pergunta.comentario}
                            onChange={(e) => handleChangeComentario(index, e)}
                          />
                        </div> : null}
                    </div>
                  </div>)) : <a>Nenhum registro</a>}
              </div>
              {showTarefas ?
                <div className="reavaliacao-box2">
                  <h3 style={{ textAlign: 'center' }}>{estabelecimentoModalidade === "Tarefa" ? "Tarefas" : "Atividades"} disponíveis para reavaliação</h3>
                  {atividades.length ? atividades.map(atividade => (
                    <div key={atividade.id} className="reavaliacao-list-box">
                      <ReactTooltip id={toString(atividade.id)} place="top" effect="solid" />
                      <div className="reavaliacao-name">
                        <div onClick={() => handleAccessRisco(atividade)}><h3>{atividade.nome}</h3></div>
                      </div>
                      <div className="reavaliacao-icons">
                        <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(atividade.id)} onClick={() => handleEditAtividade(atividade.id)} />  </i>
                        <i className="reavaliacao-trash">
                          <FiTrash2 data-tip="Excluir" data-for={toString(atividade.id)}
                            onClick={() => {
                              const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A ' + (estabelecimentoModalidade === "Tarefa" ? "TAREFA" : "ATIVIDADE") + ' SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + atividade.nome + ' :')
                              if (r === "excluir") {
                                handleDeleteAtividade(atividade.id);
                              } else {
                                aviso("warning", "Nome incorreto, exclusão cancelada", true)
                              }
                            }} /> </i>
                      </div>
                    </div>
                  )) : carregando ? null : <a>&nbsp;</a>}
                </div> : <div className="reavaliacao-box2">&nbsp;</div>}
            </div> : null}
          {revisores.length > 0 ?
            <div className="reavaliacao-button">
              <button onClick={() => handleSalvar()}>Salvar reavaliação</button>
            </div> : null}
        </div>
      </div>
    )

  }, [DropEmpresas, DropEstabelecimentos, DropRevisores, per, atividades])

  const GerenciarPerguntas = React.useMemo(() => {

    return (
      <div>

        <Perguntas show={modalShow} backdrop="static" empresa={empresa} onHide={() => { setModalShow(false); sessionStorage.removeItem('perguntaId'); UpdatePerguntas() }} />

        <div className="reavaliacao-menu-pergunta">
          <div className="reavaliacao-menu-pergunta1">
            <div style={{ width: '100%' }}>
              <label>Empresa</label>
              {DropEmpresas}
            </div>
          </div>
          <div className="reavaliacao-menu-pergunta2">
            <label>&nbsp;</label>
            <button onClick={() => setModalShow(true)}>Cadastrar pergunta</button>
          </div>

        </div>

        <div style={{marginTop:'30px'}}>
          <h3>Perguntas cadastradas:</h3>
        </div>
        <div className="scroll-box">
          {perguntas.length !== 0 ? perguntas.map(pergunta => (
            <div key={pergunta.id} className="reavaliacao-list-box">
              <ReactTooltip id={toString(pergunta.id)} place="top" effect="solid"></ReactTooltip>
              <div className="reavaliacao-name">
                <div className="reavaliacao-nav-link" to="/gerenciarestabelecimentos">
                  <h3>{pergunta.pergunta}</h3>
                </div>
              </div>
              <div className="reavaliacao-icons">
                <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(pergunta.id)} onClick={() => { sessionStorage.setItem('perguntaId', pergunta.id); setModalShow(true) }} />  </i>
                <i className="reavaliacao-trash">
                  <FiTrash2 data-tip="Excluir" data-for={toString(pergunta.id)}
                    onClick={() => {
                      const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A PERGUNTA SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + pergunta.pergunta + ' :')
                      if (r === "excluir") {
                        handleDeletePergunta(pergunta.id);
                        UpdatePerguntas();
                      } else {
                        aviso("warning", "Nome incorreto, exclusão cancelada", true)
                      }
                    }} /> </i>
              </div>

            </div>
          )) : <a>Nenhum Registro</a>}
        </div>


      </div>
    )


  }, [perguntas, modalShow, DropEmpresas])

  const GerenciarRevisores = React.useMemo(() => {

    return (
      <div>

        <Revisores show={modalShow2} backdrop="static" empresa={empresa} onHide={() => { setModalShow2(false); sessionStorage.removeItem('revisorId'); UpdateRevisores() }} />

        <div className="reavaliacao-menu-pergunta">
          <div className="reavaliacao-menu-pergunta1">
            <div style={{ width: '100%' }}>
              <label>Empresa</label>
              {DropEmpresas}
            </div>
          </div>
          <div className="reavaliacao-menu-pergunta2">
            <label>&nbsp;</label>
            <button onClick={() => setModalShow2(true)}>Cadastrar revisor</button>
          </div>

        </div>

        <div style={{marginTop:'30px'}}>
          <h3>Revisores cadastrados:</h3>
        </div>
        <div className="scroll-box">
          {rev.length !== 0 ? rev.map(revisor => (
            <div key={revisor.id} className="reavaliacao-list-box">
              <ReactTooltip id={toString(revisor.id)} place="top" effect="solid"></ReactTooltip>
              <div className="reavaliacao-name">
                <div className="reavaliacao-nav-link" to="/gerenciarestabelecimentos">
                  <h3>{revisor.nome}</h3>
                </div>
              </div>
              <div className="reavaliacao-icons">
                <i className="reavaliacao-edit"> <FiEdit data-tip="Editar" data-for={toString(revisor.id)} onClick={() => { sessionStorage.setItem('revisorId', revisor.id); setModalShow2(true) }} />  </i>
                <i className="reavaliacao-trash">
                  <FiTrash2 data-tip="Excluir" data-for={toString(revisor.id)}
                    onClick={() => {
                      const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A PERGUNTA SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + revisor.text + ' :')
                      if (r === "excluir") {
                        handleDeleteRevisor(revisor.id);
                      } else {
                        aviso("warning", "Nome incorreto, exclusão cancelada", true)
                      }
                    }} /> </i>
              </div>

            </div>
          )) : <a>Nenhum Registro</a>}
        </div>


      </div>
    )


  }, [modalShow2, DropEmpresas, rev])

  const GerenciarReavaliacoes = React.useMemo(() => {

    function handleClickItem(item){
      SetRevAux(item)
      setModalShow3(true)
    }

    return (
      <div>
        <Revisar show={modalShow3} backdrop="static" reavaliacao={revAux} onHide={() => setModalShow3(false)} />
        <div className="reavaliacao-nova">
          <div className="reavaliacao-p1">
            <label>Empresa</label>
            {DropEmpresas}
          </div>
          <div className="reavaliacao-p2">
            <label>Organização</label>
            {DropEstabelecimentos}
          </div>
        </div>
        <div className="reavaliacao-list">
          <h3 style={{ textAlign: 'center' }}>Histórico de reavaliações</h3>
          {reavaliacoes.length ? reavaliacoes.map(reavaliacao => (
            <div key={reavaliacao.id} className="reavaliacao-list-box2">
              <ReactTooltip id={toString(reavaliacao.id)} place="top" effect="solid" />
              <div className="reavaliacao-name">
                <p style={{width:'100%'}}><b>Data da reavaliacao: </b> {new Date(reavaliacao.data).toLocaleDateString('pt-BR')} - {new Date(reavaliacao.data).toLocaleTimeString('pt-BR')}</p>
                <p style={{width:'100%'}}><b>Revisor(es): </b> {reavaliacao.revisores.join(', ')}</p>
              </div>
              <div className="reavaliacao-icons">
                <i className="reavaliacao-edit"> <BiDetail data-tip="Mais detalhes" data-for={toString(reavaliacao.id)} onClick={() => handleClickItem(reavaliacao)} />  </i>
                <i className="reavaliacao-trash">
                  <FiTrash2 data-tip="Excluir" data-for={toString(reavaliacao.id)}
                    onClick={() => {
                      const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A REAVALIAÇÃO SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de reavaliação data: ' + new Date(reavaliacao.data).toLocaleDateString('pt-BR') + ' :')
                      if (r === "excluir") {
                        handleDeleteReavaliacao(reavaliacao.id);
                      } else {
                        aviso("warning", "Valor incorreto, exclusão cancelada", true)
                      }
                    }} /> </i>
              </div>
            </div>
          )) : carregando ? null : <a>&nbsp;</a>}
        </div>
      </div>
    )


  }, [DropEstabelecimentos, DropEmpresas, per, reavaliacoes, modalShow3])

  return (
    <div>
      <Menu />

      <div className="reavaliacao-retangulo1">
        <div className="reavaliacao-retangulo2">
          <div className="reavaliacao-intro">
            <h2>Reavaliação PGR</h2>
          </div>

          <div className="reavaliacao-login-box">

            <Tab menu={{ pointing: true }} panes={panes} />

          </div>

        </div>
        <Loading loading={carregando} message='Carregando...' />
      </div>

    </div>


  );
}

function Perguntas(props) {

  const perguntaId = sessionStorage.getItem('perguntaId')

  const [carregando, setCarregando] = useState(false);
  const [pergunta, setPergunta] = useState('')
  const { aviso } = React.useContext(Context);

  useEffect(() => {

    async function inicial() {

      if (props.show) {

        setPergunta('')

        if (perguntaId !== undefined && perguntaId !== null) {
          setCarregando(true)
          try {
            await Promise.all([
              api.get('sugestoes/13?empresa_id=' + props.empresa)
            ]).then(response => {
              setPergunta(response[0].data.resultado.filter(item => item.id == perguntaId)[0].pergunta)
            })
          } catch (error) {
            console.log(error)
            aviso('error', error)
          } finally {
            setCarregando(false)
          }
        }

      }

    } inicial()

  }, [props.show, sessionStorage.getItem('perguntaId')])

  const handleRegister = async (e) => {

    e.preventDefault()

    var data = {
      pergunta: pergunta,
    }

    if (pergunta == "") {
      aviso('warning', "Preencha todos os campos!")
    } else {

      try {

        var mensagem
        setCarregando(true)
        if (perguntaId !== undefined && perguntaId !== null) {
          await api.patch("sugestoes/" + perguntaId, data)
          mensagem = "Pergunta atualizada com sucesso"
          aviso('success', mensagem)
        } else {
          data.fk_empresa_id = props.empresa
          await api.post("sugestoes/8", data)
          mensagem = "Pergunta criada com sucesso"
          aviso('success', mensagem)
        }

      } catch (error) {

        aviso('error', error)

      } finally {
        setCarregando(false)
        props.onHide()
      }
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {perguntaId !== undefined && perguntaId !== null ? "Editar pergunta" : "Cadastrar pergunta"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={carregando} message='Carregando...' />

        <div className="trabexp">

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Pergunta</label>
            <input value={pergunta} onChange={e => setPergunta(e.target.value)}></input>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRegister}>{perguntaId !== undefined && perguntaId !== null ? "Salvar" : "Cadastrar"}</Button>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Revisores(props) {

  const revisorId = sessionStorage.getItem('revisorId')

  const [carregando, setCarregando] = useState(false);
  const [nome, setNome] = useState('')
  const [cargo, setCargo] = useState('')
  const [orgao_classe, setOrgaoClasse] = useState('')
  const [numero_inscricao, setNumeroInscricao] = useState('')
  const { aviso } = React.useContext(Context);

  useEffect(() => {

    async function inicial() {

      if (props.show) {

        setNome('')
        setNumeroInscricao('')
        setCargo('')
        setOrgaoClasse('')

        if (revisorId !== undefined && revisorId !== null) {
          setCarregando(true)
          try {
            await Promise.all([
              api.get('revisor?empresa_id=' + props.empresa)
            ]).then(response => {
              setNome(response[0].data.resultado.filter(item => item.id == revisorId)[0].nome)
              setOrgaoClasse(response[0].data.resultado.filter(item => item.id == revisorId)[0].orgao_classe)
              setCargo(response[0].data.resultado.filter(item => item.id == revisorId)[0].cargo)
              setNumeroInscricao(response[0].data.resultado.filter(item => item.id == revisorId)[0].numero_inscricao)
            })
          } catch (error) {
            aviso('error', error)
          } finally {
            setCarregando(false)
          }
        }

      }

    } inicial()

  }, [props.show, sessionStorage.getItem('revisorId')])

  const handleRegister = async (e) => {

    e.preventDefault()

    var data = {
      nome: nome,
      orgao_classe: orgao_classe,
      cargo: cargo,
      numero_inscricao: numero_inscricao
    }

    if (nome == '' || orgao_classe == '' || cargo == '' || numero_inscricao == '') {
      aviso('warning', "Preencha todos os campos!")
    } else {

      try {

        var mensagem
        setCarregando(true)
        if (revisorId !== undefined && revisorId !== null) {
          await api.patch("revisor/" + revisorId, data)
          mensagem = "Revisor atualizado com sucesso"
          aviso('success', mensagem)
        } else {
          await api.post("revisor?empresa_id=" + props.empresa, data)
          mensagem = "Revisor criada com sucesso"
          aviso('success', mensagem)
        }

      } catch (error) {

        aviso('error', error)

      } finally {
        setCarregando(false)
        props.onHide()
      }
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {revisorId !== undefined && revisorId !== null ? "Editar revisor" : "Cadastrar revisor"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={carregando} message='Carregando...' />

        <div className="trabexp">

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Nome</label>
            <input value={nome} onChange={e => setNome(e.target.value)}></input>
          </div>

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Cargo</label>
            <input value={cargo} onChange={e => setCargo(e.target.value)}></input>
          </div>

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Orgão classe</label>
            <input value={orgao_classe} onChange={e => setOrgaoClasse(e.target.value)}></input>
          </div>

          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
            <label>Número de inscrição</label>
            <input value={numero_inscricao} onChange={e => setNumeroInscricao(e.target.value)}></input>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRegister}>{revisorId !== undefined && revisorId !== null ? "Salvar" : "Cadastrar"}</Button>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Revisar(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Detalhes da reavaliação
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        {props.show?
          <div className="trabexp">

            <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
              <div className='reavaliacao-pergunta-comentario1'>
                <a><b>Empresa: </b>{props.reavaliacao.empresa}</a>
                <a><b>Organização: </b>{props.reavaliacao.estabelecimento}</a>
                <a><b>Data: </b>{new Date(props.reavaliacao.data).toLocaleDateString('pt-BR')} - {new Date(props.reavaliacao.data).toLocaleTimeString('pt-BR')}</a>
                <a><b>Revisor(es): </b>{props.reavaliacao.revisores.join(', ')}</a>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
              {props.reavaliacao.perguntas.map(item => (
                <div key={item.pergunta} className='reavaliacao-pergunta-comentario'>
                  <div className='reavaliacao-pergunta-comentario1'><b>Pergunta:</b> {item.pergunta} &ensp; <b>(Sim)</b></div>
                  <div className='reavaliacao-pergunta-comentario2'><b>Comentário:</b> {item.comentario}</div>
                </div>
              ))}
              
            </div>

          </div>
          :
          null}

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}