import React, { useState, useContext, useEffect } from 'react';
import { FiEye, FiEyeOff, FiMail, FiLock, FiLogIn } from 'react-icons/fi';
import { Context } from '../../Context/AuthContext';

import './styles.css';

import logoImg from '../../assets/logo-jmvglobal.png';

import Loading from '../Loading'

export default function Login() {

  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [hidden, setHidden] = useState(true);

  const { handleLogin, Login } = useContext(Context);

  function switchHidden() {
    setHidden(!hidden);
  }

  function fazerLogin(e) {
    e.persist();
    handleLogin(e, login, senha, true);
  }

  return (
    <div>
      <header>
        <div className="navbar-login">
          <img src={logoImg} alt="JMV Global" />
        </div>
      </header>

      <div className="login-content">
        <div>
          <div className="intro">
            <h2>Entre com seu cadastro</h2>
          </div>

          <div className="login-box">

            <div className="info-login">
              <h3>Suas credenciais são as mesmas usadas no site principal da JMV - Global</h3>
            </div>

            <div className="form-box">

              <form onSubmit={e => fazerLogin(e)}>

                <div className="inputWithIcon">

                  <input
                    type="text"
                    placeholder="E-mail ou usuário"
                    value={login}
                    onChange={e => setLogin(e.target.value)}
                  /> <i><FiMail /></i>

                </div>

                <div className="input-group2">
                  <div className="inputWithIcon">
                    <input
                      type={hidden ? "password" : "text"}
                      placeholder="Senha"
                      value={senha}
                      onChange={e => setSenha(e.target.value)}
                    /> <i><FiLock /></i>
                  </div>

                  <button type="button" onClick={switchHidden} >{hidden ? <FiEye /> : <FiEyeOff />}</button>
                </div>

                <div className="entrar">
                  <button className="login" type="submit"><FiLogIn /> &nbsp; Entrar</button>
                </div>

              </form>
            </div>

            <div className="back">
              <a className="back-link" href="https://www.jmvglobal.com.br/login/?action=lostpassword" target="_blank">Não consegue entrar?</a>
            </div>

          </div>

        </div>
      </div>
      <Loading loading={Login} message='Carregando...' />

    </div>

  );
}