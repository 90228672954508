import React, { useEffect, useState } from 'react';
//import {Link, useHistory} from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import Loading from '../../Loading'
import { Context } from '../../../Context/AuthContext';
import { Checkbox } from 'semantic-ui-react'
import DatePicker from 'react-datepicker';
import { FiInfo, FiEdit, FiChevronLeft } from 'react-icons/fi'
import { Modal, Button } from 'react-bootstrap'
import { Table, TableCell, TableHead, TableBody, TableRow, TableContainer } from '@material-ui/core'
import ReactTooltip from 'react-tooltip';
import Paper from '@material-ui/core/Paper';
import { registerLocale } from "react-datepicker";
import br from 'date-fns/locale/pt-BR';

import '../styles.css';
import '../../../global.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export function GestaoRespostas(props) {

  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false)
  const [respostas, setRespostas] = useState([])
  const [respostasOriginal, setRespostasOriginal] = useState([])
  const [itemsResposta, setItemsResposta] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [btn, setBTN] = useState('')
  const [sistemaOptions, setSistemaOptions] = useState([])
  const [sistema, setSistema] = useState('')


  const optionsResposta = ["Não", "Sim", "Não aplicável"]
  const optionsPlanoAcao = ["Não", "Sim", "Não aplicável", "Planejar no orçamento", "Plano de ação geral"]

  const s_m_options = {
    s: 'Gestão de Segurança do Trabalho',
    m: 'Gestão de Medicina do Trabalho',
    ma: 'Gestão de Meio Ambiente',
    rs: 'Gestão de Responsabilidade Social',
    q: 'Gestão da Qualidade',
    si: 'Gestão de Sistema Integrado - SGI',
    c: 'Gestão Comportamental',
    so: 'Gestão da Segurança Operacional - ANP',
    co: 'Gestão de Contratadas - NR',
    ga: 'Gestão Auditiva - PCA',
    gr: 'Gestão Respiratória- PPR',
  }

  useEffect(() => {
    if (sistema !== '') {
      let respostas_filtradas = [];
      if(sistema === 'todos'){
        respostas_filtradas = respostasOriginal;
      }else{
        respostas_filtradas = respostasOriginal.filter(item => item.s_m === sistema);
      }
      let valorAtual = itemsResposta.filter(resposta => respostas_filtradas.some(item => item.id === resposta.fk_id_pergunta))
      
      if (valorAtual.length > 0) {
        respostas_filtradas.forEach((item) => {
          const valorEncontrado = valorAtual.find(valor => valor.fk_id_pergunta === item.id) || {};

          if(valorEncontrado.id === undefined){
            item.novo = true;
          }else{
            item.novo = false;
          }

          item.julgamento = valorEncontrado.julgamento ?? "";
          item.opcao = valorEncontrado.opcao ?? "Não";
          item.evidencia = valorEncontrado.evidencia ?? "";
          item.plano_acao = valorEncontrado.plano_acao ?? "Não";
          item.resposta_id = valorEncontrado.id ?? "";
          item.pontos_alcancados = valorEncontrado.pontos_alcancados ?? 0;
          item.pontos_possiveis = valorEncontrado.pontos_possiveis ?? 0;
          item.multa = valorEncontrado.multa ?? 0;
          item.pts = valorEncontrado.pts ?? 0;
        });
      }

      setRespostas(respostas_filtradas)
    }
  }, [sistema, respostasOriginal, itemsResposta])

  async function inicial() {

    setCarregando(true)

    try {

      var respostas, perguntas;

      await Promise.all([
        api.get(`auditoria_resposta?empresa_id=${sessionStorage.getItem('auditoriaEmpresaId')}`),
        api.get('auditoria_pergunta?empresa_id=' + sessionStorage.getItem('auditoriaEmpresaId'))
      ]).then(response => {
        respostas = response[0].data.resultado
        setItemsResposta(respostas)
        perguntas = response[1].data
      })

      var tabela = perguntas.tabela
      setBTN(perguntas.btn)
      var n_funcionarios = perguntas.n_funcionarios
      var btnAux = perguntas.btn
      perguntas = perguntas.resultado.filter(item => item.fk_titulo_id === parseInt(sessionStorage.getItem('perguntaTituloId')))

      perguntas.forEach(item => {

        item.julgamento = 0;
        item.opcao = "Não";
        item.evidencia = "";
        item.plano_acao = "Não";
        item.plano_acao_aud = "";
        item.novo = true;

        let resultado_pontos_possiveis = tabela
          .filter(item2 => item2.num_trabalhadores >= n_funcionarios && item2.tipo === item.s_m)
          .sort((a, b) => a.num_trabalhadores - b.num_trabalhadores)
          .slice(0, 1);

        if ((item.opcao === "Sim" || item.opcao === "Não") && (item.s_m === 's' || item.s_m === 'm')) {
          item.pts = parseFloat(resultado_pontos_possiveis[0][item.g]);
          item.pontos_possiveis = item.pts;
        } else {
          item.pontos_possiveis = 0;
        }

        if (item.opcao === "Sim") {
          item.pontos_alcancados = item.pontos_possiveis;
          item.multa = 0
        } else {
          item.pontos_alcancados = item.pontos_possiveis * parseFloat(item.julgamento) / 100;
          item.multa = item?.pontos_possiveis * parseFloat(btnAux);
        }
      })

      const uniqueS_M = [...new Set(perguntas.map(option => option.s_m))];

      let sistemaOptionsAux = [{
        key: 0,
        text: 'Todos',
        value: 'todos'
      }];

      sistemaOptionsAux = sistemaOptionsAux.concat(
        uniqueS_M.map((item, index) => ({
          key: index + 1,
          text: s_m_options.hasOwnProperty(item) ? s_m_options[item] : item,
          value: item
        }))
      );

      setSistemaOptions(sistemaOptionsAux)

      setSistema(sistemaOptionsAux[0].value)

      setRespostasOriginal(perguntas)

    } catch (error) {
      console.log(error)
    } finally {
      setCarregando(false)
    }
  }

  useEffect(() => {

    if (props.update) {
      inicial()
    }

  }, [props.update])

  const handleChangeOpcao = (index, e) => {
    let b = Array.from(respostas)
    b[index].opcao = e.target.value
    if ((b[index].opcao === "Sim" || b[index].opcao === "Não") && (b[index].s_m === 's' || b[index].s_m === 'm')) {
      b[index].pontos_possiveis = b[index].pts;
    } else {
      b[index].pontos_possiveis = 0;
    }

    if (b[index].opcao === "Sim") {
      b[index].pontos_alcancados = b[index].pontos_possiveis;
      b[index].multa = 0
    } else {
      b[index].pontos_alcancados = b[index].pontos_possiveis * parseFloat(b[index].julgamento) / 100;
      b[index].multa = b[index]?.pontos_possiveis * parseFloat(btn);
    }
    setRespostas(b)
  }

  const handleChangeJulgamento = (index, e) => {
    let b = Array.from(respostas)
    b[index].julgamento = e.target.value
    b[index].julgamento_error = false
    if (e.target.value !== "") {
      if ((b[index].opcao === "Sim" || b[index].opcao === "Não") && (b[index].s_m === 's' || b[index].s_m === 'm')) {
        b[index].pontos_possiveis = b[index].pts;
      } else {
        b[index].pontos_possiveis = 0;
      }

      if (b[index].opcao === "Sim") {
        b[index].pontos_alcancados = b[index].pontos_possiveis;
        b[index].multa = 0
      } else {
        if (b[index].julgamento === '') {
          b[index].pontos_alcancados = '';
        } else {
          b[index].pontos_alcancados = b[index].pontos_possiveis * parseFloat(b[index].julgamento) / 100;
        }
        b[index].multa = b[index]?.pontos_possiveis * parseFloat(btn);
      }
    }
    setRespostas(b)
  }

  const handleChangeEvidencia = (index, e) => {
    let b = Array.from(respostas)
    b[index].evidencia = e.target.value
    b[index].evidencia_error = false
    setRespostas(b)
  }

  const handleChangePlanoAcao = (index, e) => {
    let b = Array.from(respostas)
    b[index].plano_acao = e.target.value
    setRespostas(b)
  }

  function handleReceberPlanoAcao(plano) {
    let b = Array.from(respostas)
    b.forEach((item, index) => {
      if (index === parseInt(sessionStorage.getItem('respostaId'))) {
        item.plano_acao_aud = plano
        item.plano_acao_aud_error = false
        item.novo_plano = true
      }
    })
    setRespostas(b);
  }

  const handleSalvar = async () => {
    setCarregando(true)
    var b = Array.from(respostas)
    var valido = true
    b.forEach((item) => {
      if (item.opcao === '') {
        valido = false
        item.opcao_error = true
      }
      if (item.opcao === "Não" || item.opcao === "Não aplicável") {
        if (item.julgamento === '') {
          valido = false
          item.julgamento_error = true
        }
      }
      if (item.opcao === "Não" || item.opcao === "Sim") {
        if (item.evidencia === '') {
          valido = false
          item.evidencia_error = true
        }
      }
      if (item.plano_acao === "Sim") {
        if (item.plano_acao_aud === '' &&
          itemsResposta.filter(item2 => item2.id === item.resposta_id)[0]?.plano_acao !== "Sim") {
          valido = false
          item.plano_acao_aud_error = true
        }
      }
    })

    setRespostas(b)

    if (valido) {
      try {

        var valoresPut = []
        var valoresPost = []
        b.forEach(item => {

          if (item.novo)
            valoresPost.push({
              opcao: item.opcao,
              julgamento: item.julgamento,
              evidencia: item.evidencia,
              plano_acao: item.plano_acao,
              fk_id_pergunta: item.id,
              fk_titulo_id: sessionStorage.getItem('tituloId'),
            })
          else
            valoresPut.push({
              opcao: item.opcao,
              julgamento: item.julgamento,
              evidencia: item.evidencia,
              plano_acao: item.plano_acao,
              fk_id_pergunta: item.id,
              fk_titulo_id: sessionStorage.getItem('tituloId'),
              id: item.resposta_id
            })
        })

        var response2;

        if(valoresPost.length > 0){
          response2 = await api.post('auditoria_resposta?empresa_id=' + sessionStorage.getItem('auditoriaEmpresaId'), valoresPost)
        }
        if(valoresPut.length > 0){
          response2 = await api.patch('auditoria_resposta?empresa_id=' + sessionStorage.getItem('auditoriaEmpresaId'), valoresPut)
        }

        b.forEach((item, index) => {
          if (item.novo)
            item.id_resposta = response2.data.ids_respostas[index];
        })

        setRespostas(b)

        var valores_plano = []
        var temPlanoAcao = false
        b.filter(valor => valor.novo_plano === true).forEach(item => {
          valores_plano.push({
            o_que: item.plano_acao_aud.o_que,
            quem: item.plano_acao_aud.quem,
            quando: item.plano_acao_aud.quando,
            inicio: item.plano_acao_aud.inicio,
            como: item.plano_acao_aud.como,
            onde: item.plano_acao_aud.onde,
            porque: item.plano_acao_aud.porque,
            quanto: item.plano_acao_aud.quanto,
            iniciado: item.plano_acao_aud.iniciado,
            concluido: item.plano_acao_aud.concluido,
            data_concluido: item.plano_acao_aud.concluido ? new Date() : null,
            fk_resposta_id: item.id_resposta
          })
          temPlanoAcao = true
        })

        if (temPlanoAcao) {
          await api.patch('plano_acao_auditoria', valores_plano)
        }

        aviso('success', "Auditoria realizada com sucesso")
        setCarregando(false)
        props.onHide()
      } catch (error) {
        console.log(error)
        aviso('error', error)
        setCarregando(false)
      }
    } else {
      aviso('warning', 'Preencha todos os campos em vermelho')
      setCarregando(false)
    }
  }

  function Somar(resposta, tipo) {
    var soma = 0
    for (var i = 0; i < resposta.length; i++) {
      if (tipo === 'possiveis') {
        soma = soma + resposta[i].pontos_possiveis
      }
      if (tipo === 'alcancados') {
        soma = soma + resposta[i].pontos_alcancados
      }
      if (tipo === 'multa') {
        soma = soma + resposta[i].multa
      }
    }
    return parseFloat(soma);
  }

  function CalcularPorcentagem(resposta) {
    if (Somar(resposta, 'possiveis') === 0) {
      return 0
    } else {
      return parseFloat(Somar(resposta, 'alcancados') / Somar(resposta, 'possiveis')).toFixed(4);
    }
  }

  return (
    <div>
      <PlanoAcao
        enviarplanoacao={handleReceberPlanoAcao}
        perguntas={respostas}
        show={modalShow}
        onHide={() => { setModalShow(false); sessionStorage.removeItem('respostaId'); }}
      />
      <div>
        {respostas.map((row, index) => (
          <ReactTooltip key={index} backgroundColor="#006ab4" classstionName="tip-auditoria" id={toString(index)} effect="solid" event='click' globalEventOff='click' clickable></ReactTooltip>
        ))}
        <div className='breadcumb'>
          <label onClick={() => props.onHide2()}>Auditoria {sessionStorage.getItem('auditoriaNumeracao')} - {sessionStorage.getItem('auditoriaEmpresaNome')} &nbsp;</label> /
        </div>
        <div>
          <ReactTooltip id="valor" place="top" effect="solid" />
          <div className="aud-titulo">
            <i>
              <FiChevronLeft data-tip={"Voltar"} data-for='voltar'
                className="go-edit2"
                style={{ margin: '0px 0px 10px 0px' }}
                onClick={() => {
                  props.onHide()
                }} />
            </i>
            <label style={{ fontSize: '20px', marginTop: '10px' }}>Questionário</label>
            <div>
              <select value={sistema} name="opcao" onChange={(e) => setSistema(e.target.value)}>
                {sistemaOptions.map((item, index) => (
                  <option key={index} value={item.value}>{item.text}</option>
                )
                )}
              </select>
            </div>
          </div>
          <TableContainer component={Paper} style={{ maxHeight: '580px' }}>
            <Table size="medium" aria-label="a dense table" className='table-mui'>
              <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow className='table-head'>
                  <TableCell align="left" colSpan={12} style={{ backgroundColor: '#919191', padding: '5px 16px 5px 16px', fontSize: '1.1rem' }}>Capítulo {sessionStorage.getItem('capituloNome')}</TableCell>
                </TableRow>
                <TableRow className='table-head'>
                  <TableCell align="left" colSpan={12} style={{ backgroundColor: '#919191', padding: '5px 16px 5px 16px' }}>Título {sessionStorage.getItem('tituloNome')}</TableCell>
                </TableRow>
                <TableRow className='table-head'>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Norma</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px', maxWidth: 50 }} align="center">Item</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px', minWidth: 300 }} align="center">O que executar?</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Reposta</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Julgamento profissional</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px', minWidth: 270 }} align="center">Evidência</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Plano de ação</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">5W2H</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Pts possíveis</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Pts alcançados</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Multa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {respostas.map((row, index) => (

                  <TableRow className={index % 2 === 0 ? 'table-par' : 'table-impar'} key={row.id}>
                    <TableCell align="center">{row.nr}</TableCell>
                    <TableCell align="center">
                      {row.item + " "}
                      <FiInfo style={{ cursor: "pointer" }} data-html={true} data-tip={row.descricao_item.replace(/\n/g, '</br>')} data-for={toString(index)}></FiInfo>
                    </TableCell>
                    <TableCell align="left" width={"25%"}>
                      {row.o_que.split('\n').map((line, index, array) => (
                        <React.Fragment key={index}>
                          <span style={{ textAlign: 'justify' }}>{line + ' '}</span>
                          {index !== array.length - 1 ? <br /> : <FiInfo style={{ cursor: "pointer" }} data-tip={"Sugestão de como atender: " + row.como_atender} data-for={toString(index)}></FiInfo>}
                        </React.Fragment>
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      <select style={{ width: '80px' }} value={row.opcao} name="opcao" id={row.id} onChange={e => handleChangeOpcao(index, e)}>
                        {optionsResposta.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        )
                        )}
                      </select>
                    </TableCell>
                    <TableCell align="center">{row.opcao === "Não" &&
                      <div style={{ display: 'inline-flex' }}>
                        <input style={{ width: '55px' }} value={row.julgamento} className={row.julgamento_error ? "input-error" : ""} type="number" max={99} min={0} onChange={e => handleChangeJulgamento(index, e)}></input>
                        <div style={{ padding: '9px 5px 5px 5px', fontSize: '16px' }}>%</div>
                      </div>
                    }
                    </TableCell>
                    <TableCell align="center">{row.opcao !== "Não aplicável" &&
                      <textarea value={row.evidencia} className={row.evidencia_error ? "text-area-ident input-error" : "text-area-ident"} data-tip={"Sugestão de como evidenciar: " + row.como_evidenciar} data-for={toString(index)} type="longtext" aria-multiline="true" onChange={e => handleChangeEvidencia(index, e)}></textarea>
                    }
                    </TableCell>
                    <TableCell align="center">
                      <select style={{ width: '90px' }} value={row.plano_acao} name="planoacao" id={row.id} onChange={e => handleChangePlanoAcao(index, e)}>
                        {optionsPlanoAcao.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        )
                        )}
                      </select>
                    </TableCell>
                    <TableCell align="center">
                      {row.plano_acao === "Sim" && (
                        <i className={row.plano_acao_aud_error ? "icon-plano-error" : "icon-plano"}>
                          <ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" multiline={true}></ReactTooltip>
                          <FiEdit data-tip="Abrir 5W2H" data-for="aux"
                            onClick={() => { sessionStorage.setItem('respostaId', index); setModalShow(true) }}
                          />
                        </i>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.pontos_possiveis}</TableCell>
                    <TableCell align="center">{row.pontos_alcancados?.toFixed(0)}</TableCell>
                    <TableCell align="center">R$ {row.multa?.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer className='table-resume' component={Paper}>
            <Table size="medium" aria-label="a dense table" className='table-mui'>
              <TableHead>
                <TableRow className='table-head'>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Total pontos possíveis</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Total pontos alcançados</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Porcentagem</TableCell>
                  <TableCell style={{ padding: '5px 16px 5px 16px' }} align="center">Valor total da multa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={'table-par'}>
                  <TableCell align="center">{Somar(respostas, 'possiveis')}</TableCell>
                  <TableCell align="center">{Somar(respostas, 'alcancados').toFixed(0)}</TableCell>
                  <TableCell align="center">{(CalcularPorcentagem(respostas) * 100).toFixed(2)} %</TableCell>
                  <TableCell align="center">R$ {Somar(respostas, 'multa').toFixed(2)}</TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
          <div className="reavaliacao-button">
            <button style={{ margin: '20px' }} onClick={() => props.onHide()}>Cancelar</button>
            <button style={{ margin: '20px' }} onClick={() => handleSalvar()}>Salvar</button>
          </div>
        </div>


      </div>
      <Loading loading={carregando} message='Carregando...' />
    </div>
  );
}

function PlanoAcao({ enviarplanoacao, perguntas, show, onHide }) {

  const [o_que, setO_que] = useState('')
  const [quem, setQuem] = useState('')
  const [quando, setQuando] = useState(new Date())
  const [inicio, setInicio] = useState(new Date())
  const [como, setComo] = useState('')
  const [onde, setOnde] = useState('')
  const [porque, setPorque] = useState('')
  const [quanto, setQuanto] = useState('')
  const [iniciado, setIniciado] = useState(false)
  const [concluido, setConcluido] = useState(false)
  const { aviso } = React.useContext(Context);

  const [carregando, setCarregando] = useState(false);

  var textOque = "Qual é a ação necessária/Qual a atividade a ser realizada"
  var textQuem = "Quem é a pessoa responsável por conduzir esse processo"
  var textQuando = "Quando termina o plano"
  var textInicio = "Quando inicia o plano"
  var textComo = "Detalhar como será feito para resolver a ação"
  var textOnde = "Detalhar onde essa ação deve ser realizada ( local, máquina,etc...)"
  var textPorque = "Geralmente é para resolver um problema, para reduzir ou "
    + "eliminar um risco, aumentar a produtividade, para garantir a manutenção"
  var textQuanto = "Quanto vai custar, é para ter um rastreamento de quanto "
    + "custou e qual é o payback (tempo de retorno do investimento inicial "
    + "que foi aplicado no negócio ou projeto)"

  useEffect(() => {

    async function inicial() {

      if (show) {

        setO_que('')
        setQuem('')
        setQuando(null)
        setInicio(null)
        setComo('')
        setOnde('')
        setPorque('')
        setQuanto('')
        setIniciado(false)
        setConcluido(false)

        if (sessionStorage.getItem('respostaId') !== null) {
          const response = await api.get('plano_acao_auditoria?resposta_id=' + perguntas[parseInt(sessionStorage.getItem('respostaId'))].resposta_id)
          if (response.data.resultado.length > 0) {
            setO_que(response.data.resultado[0].o_que)
            setQuem(response.data.resultado[0].quem)
            setQuando(new Date(response.data.resultado[0].quando))
            setInicio(new Date(response.data.resultado[0].inicio))
            setComo(response.data.resultado[0].como)
            setOnde(response.data.resultado[0].onde)
            setPorque(response.data.resultado[0].porque)
            setQuanto(response.data.resultado[0].quanto)
            setIniciado(Boolean(response.data.resultado[0].iniciado))
            setConcluido(Boolean(response.data.resultado[0].concluido))
          } else {
            if (perguntas[parseInt(sessionStorage.getItem('respostaId'))].plano_acao_aud !== "") {
              let data = perguntas[parseInt(sessionStorage.getItem('respostaId'))].plano_acao_aud
              setO_que(data.o_que)
              setQuem(data.quem)
              setQuando(data.quando)
              setInicio(data.inicio)
              setComo(data.como)
              setOnde(data.onde)
              setPorque(data.porque)
              setQuanto(data.quanto)
              setIniciado(data.iniciado)
              setConcluido(data.concluido)
            }
          }

        } else {
          if (perguntas[parseInt(sessionStorage.getItem('respostaId'))].plano_acao_aud !== "") {
            let data = perguntas[parseInt(sessionStorage.getItem('respostaId'))].plano_acao_aud
            setO_que(data.o_que)
            setQuem(data.quem)
            setQuando(data.quando)
            setInicio(data.inicio)
            setComo(data.como)
            setOnde(data.onde)
            setPorque(data.porque)
            setQuanto(data.quanto)
            setIniciado(data.iniciado)
            setConcluido(data.concluido)
          }
        }

      }

    } inicial()

  }, [show])


  const handleRegister = async (e) => {

    e.preventDefault()

    const data = {
      o_que: o_que,
      quem: quem,
      quando: quando,
      inicio: inicio,
      como: como,
      onde: onde,
      porque: porque,
      quanto: quanto,
      iniciado: iniciado,
      concluido: concluido,
    }

    if (o_que === "" || quem === "" || inicio == null || quando == null || como === "" || onde === "" || porque === "" || quanto === "" || quando === "") {
      aviso('warning', 'Preencha todos os campos!')
    } else {

      if (inicio > quando) {
        aviso('warning', 'Data de início superior à data de término!')
      } else {
        enviarplanoacao(data);
        aviso('success', 'Plano de ação salvo com sucesso')
        setCarregando(false)
        onHide()
      }

    }
  }

  const handleConcluido = () => {
    setConcluido(!concluido)
    if (iniciado === false) {
      setIniciado(true)
    }
  }

  const handleIniciado = () => {
    setIniciado(!iniciado)
    if (concluido === true) {
      setConcluido(false)
    }
  }

  registerLocale('pt-BR', br)

  return (

    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Plano de ação (5W2H)
        </Modal.Title>
      </Modal.Header>


      <Modal.Body>

        <ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
        <Loading loading={carregando} message='Carregando...' />

        <div className="plano-retangulo1">
          <div className="plano-aux1">
            <label htmlFor="">O quê?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOque} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setO_que(e.target.value)} value={o_que} />
          </div>

          <div className="plano-aux1">
            <label htmlFor="">Quem?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuem} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuem(e.target.value)} value={quem} />
          </div>

          <div className="plano-aux1">
            <label htmlFor="">Onde?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOnde} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setOnde(e.target.value)} value={onde} />
          </div>
        </div>

        <div className="plano-retangulo2">
          <div className="plano-aux1">
            <label htmlFor="">Por quê?    <FiInfo style={{ cursor: "pointer" }} data-tip={textPorque} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setPorque(e.target.value)} value={porque} />
          </div>

          <div className="plano-aux1">
            <label htmlFor="">Quanto?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuanto} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuanto(e.target.value)} value={quanto} />
          </div>

          <div className="plano-aux1">
            <label htmlFor="">Como?   <FiInfo style={{ cursor: "pointer" }} data-tip={textComo} data-for="aux"></FiInfo></label>
            <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setComo(e.target.value)} value={como} />
          </div>
        </div>

        <div className="plano-retangulo3">

          <div className="plano-input">
            <label htmlFor="plano-input">Data Início   <FiInfo style={{ cursor: "pointer" }} data-tip={textInicio} data-for="aux"></FiInfo></label>
            <DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={inicio} onChange={date => setInicio(date)} />
          </div>
          <div className="plano-input">
            <label htmlFor="plano-input">Data Fim   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuando} data-for="aux"></FiInfo></label>
            <DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={quando} onChange={date => setQuando(date)} />
          </div>
          <div className="plano-check">
            <label style={{ marginRight: '5px' }}>Iniciado?</label>
            <Checkbox checked={iniciado} onChange={handleIniciado} />
            <label style={{ marginLeft: '10px', marginRight: '5px' }}>Concluído?</label>
            <Checkbox checked={concluido} onChange={handleConcluido} />
          </div>
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleRegister}>Salvar</Button>
        <Button onClick={onHide}>Fechar</Button>
      </Modal.Footer>

    </Modal>

  )
}
