import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash2, FiCopy, FiLock, FiUnlock} from 'react-icons/fi';
import { RiFolderTransferLine } from "react-icons/ri";
import { Dropdown } from 'semantic-ui-react'
import Loading from '../../Loading'
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import api from '../../../services/api'
import ReactTooltip from 'react-tooltip';
import { Modal, Button } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Checkbox } from 'semantic-ui-react'

import { Input } from 'semantic-ui-react'

import './styles.css';

import { Menu } from '../../../global.js'
import { Context } from '../../../Context/AuthContext';

export default function GerenciarEmpresas() {

	const [empresas, setEmpresas] = useState([]);
	const [emp, setEmp] = useState('');
	const [carregando, setCarregando] = useState(false)
	const { aviso, workspaces } = React.useContext(Context);
	const [mudanca, setMudanca] = useState(false);
	const [modalLock, setModalLock] = useState(false)
	const [modalUnlock, setModalUnlock] = useState(false)
	const [modalShow, setModalShow] = useState(false)
	const [modalShow2, setModalShow2] = useState(false)
	const [foundEmpresas, setFoundEmpresas] = useState([]);
	const [keyword, setKeyword] = useState('')

	const [count, setCount] = useState({
		prev: 0,
		next: 10
	})

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState(foundEmpresas.slice(count.prev, count.next))

	const getMoreData = () => {

		if (current.length === foundEmpresas.length) {
			setHasMore(false);
			return;
		}

		if (current.length > 0) {
			setTimeout(() => {
				setCurrent(current.concat(foundEmpresas.slice(count.prev + 10, count.next + 10)))
			}, 125)
			setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
		}
	}

	function lazyLoad() {
		if (keyword !== '') {
			const result = empresas.filter((item) => {
				return (item.razao_social.toLowerCase().includes(keyword.toLowerCase()))
			})

			setFoundEmpresas(result)
			setMudanca(false)
			setHasMore(true)

			setTimeout(() => {
				setCurrent(result.slice(0, 10))
			}, 0)

		} else {
			if (foundEmpresas.length !== 0) {
				setFoundEmpresas(empresas)
				setTimeout(() => {
					setCurrent(empresas.slice(0, 10))
				}, 0)
				setHasMore(true)
			}
		}

		setCount({
			prev: 0,
			next: 10
		})
	}

	useEffect(() => {
		lazyLoad()
	}, [keyword])

	const Sort = () => (
		<Dropdown
			text='Ordenar'
			icon='sort'
			floating
			labeled
			button
			className='icon'
		>
			<Dropdown.Menu>
				<Dropdown.Item onClick={handleAsc}>A-Z</Dropdown.Item>
				<Dropdown.Item onClick={handleDesc}>Z-A</Dropdown.Item>
				<Dropdown.Item onClick={handleInverter}>Inverter ordem atual</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	const sortArray = (obj) => {
		return obj.sort((a, b) => {
			return a.razao_social.localeCompare(b.razao_social);
		})
	}

	const handleAsc = () => {
		setEmpresas(sortArray(empresas))
		lazyLoad()
		setMudanca(true)
	}

	const handleDesc = () => {
		setEmpresas(sortArray(empresas).reverse())
		lazyLoad()
		setMudanca(true)
	}

	const handleInverter = () => {
		setEmpresas(empresas.reverse())
		lazyLoad()
		setMudanca(true)
	}

	async function atualizar() {
		setCarregando(true)
		try {
			const response = await api.get('empresa?all=true')
			setEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
			setFoundEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
			setHasMore(true)
			setTimeout(() => {
				setCurrent(response.data.resultado.filter(empresa => empresa.razao_social !== '').slice(0, 10))
			}, 0)

			response.data.resultado.forEach(empresa => {
				if (empresa.razao_social == '') {
					api.delete(`empresa/${empresa.id}`)
				}
			})
		} catch (error) {
			console.log(error)
			aviso("error", error)
		} finally {
			setCarregando(false)
		}

	}

	useEffect(() => {
		atualizar();
	}, []);

	async function handleDeleteEmpresa(id) {
		try {
			setCarregando(true)
			await api.delete(`empresa/${id}`)
			updateEmpresas()
			setCurrent(current.filter(empresa => empresa.id !== id))
			aviso('success', 'Empresa removida com sucesso')
		} catch (error) {
			aviso("error", "Erro ao deletar caso, tente novamente", true)
		} finally {
			setCarregando(false)
		}
	}

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: '5px',
		borderBottom: '1px solid #5940A2',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "white",
		padding: '5px',
		width: 'auto',
		height: 'auto',
		maxHeight: '400px',
		overflowY: 'auto',
		overflowX: 'hidden'
	});

	function onDragEnd(result) {

		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			current,
			result.source.index,
			result.destination.index
		);

		if (JSON.stringify(current) !== JSON.stringify(items)) {
			setMudanca(true)
		}

		setCurrent(items)

	}

	function onSave() {

		var diff = current.length - empresas.length
		var emp
		if (diff < 0) {
			emp = [].concat(current, empresas.slice(diff))
		} else {
			emp = current
		}

		setEmpresas(emp)

		try {
			for (var index = 0; index < emp.length; index++) {
				emp[index].ordem = index
			}
			for (var index = 0; index < emp.length; index++) {
				api.patch(`empresa/${emp[index].id}`, {ordem: emp[index].ordem});
			}
			aviso('success', 'Nova ordem salva com sucesso')
			setMudanca(false)
		} catch (error) {
			aviso('error', error)
		}

	}

	async function updateEmpresas() {
		try {
			await api.get('empresa?all=true&workspace=1').then(response => {
				setEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
				setFoundEmpresas(response.data.resultado.filter(empresa => empresa.razao_social !== ''));
			})
		} catch (error) {
			aviso('error', error)
		}
	}

	return (
		<div>
			<Menu />
			<Loading loading={carregando} message='Carregando...' />

			<div className="ge-basic-content">

				<div className="titulo">
					<h1 >Empresas</h1>
				</div>
				<div className="ge-box">

					<div className="ge-cadastrar">
						{!mudanca ? <Link to="/cadastrarempresa" onClick={() => { sessionStorage.removeItem('empresaId'); sessionStorage.setItem('empresaNovo', true) }}>
							<button>Cadastrar</button>
						</Link> : <button onClick={onSave} className='ge-salvar'>Salvar ordem</button>}
					</div>

					<div className="ges-subtitle">
						<h2>Empresas cadastradas:</h2>
						<div className="searchbox">
							<Input fluid size='small' value={keyword} icon='search' placeholder='Pesquise...' onChange={e => { setKeyword(e.target.value) }} />
						</div>
					</div>

					<LockEmpresa show={modalLock} backdrop="static" onHide={() => { setModalLock(false); atualizar(); }} />
					<UnockEmpresa show={modalUnlock} backdrop="static" onHide={() => { setModalUnlock(false); atualizar(); }} />
					<Duplicar show={modalShow} backdrop="static" empresa={emp} onHide={() => { setModalShow(false); atualizar(); }} />
					<Mover show={modalShow2} backdrop="static" empresa={emp} onHide={() => { setModalShow2(false); atualizar(); }} />

					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									id="scrollableDiv"
								>
									<InfiniteScroll
										dataLength={current.length}
										next={getMoreData}
										hasMore={hasMore}
										scrollableTarget="scrollableDiv"
									>
										{current.length ? current.map((empresa, index) => (
											<Draggable isDragDisabled={keyword === '' ? false : true} key={empresa.id} draggableId={empresa.id.toString()} index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<ReactTooltip id={toString(empresa.id)} place="top" effect="solid"></ReactTooltip>
														{Boolean(empresa.block) !== true ?
															<div className="ge-name">
																<Link className="ge-nav-link" to="/gerenciarestabelecimentos">
																	<h3 onClick={() => { sessionStorage.setItem('empresaId', empresa.id); sessionStorage.setItem('empresaName', empresa.razao_social) }}>{empresa.razao_social}</h3>
																</Link>
															</div>
															:
															<div className="ge-name-disabled">
																<h3>{empresa.razao_social}</h3>
															</div>
														}

														{Boolean(empresa.block) ?
															<div className="ge-icons">
																<i className="ge-edit">
																	<FiLock data-tip='Desbloquear' data-for={toString(empresa.id)}
																		onClick={async () => {
																			sessionStorage.setItem('lockId', empresa.id)
																			setModalUnlock(true)
																		}}
																	/>
																</i>
															</div>
															:
															<div className='ge-icons'>

																<i className="ge-edit">
																	<FiUnlock data-tip='Bloquear' data-for={toString(empresa.id)}
																		onClick={() => {
																			if (empresa.password !== null) {
																				sessionStorage.setItem('lockPassword', empresa.password)
																			} else {
																				sessionStorage.removeItem('lockPassword')
																			}
																			sessionStorage.setItem('lockId', empresa.id)
																			setModalLock(true);
																		}}
																	/>
																</i>
																{}
																{workspaces.filter((item) => item.id === parseInt(sessionStorage.getItem('workspaceId')))[0]?.amIOwner && (
																	<i className="ge-edit">

																		<RiFolderTransferLine data-tip="Mover" data-for={toString(empresa.id)}
																			onClick={() => { setModalShow2(true); setEmp({ id: empresa.id, razao_social: empresa.razao_social }) }}
																		/>

																	</i>
																)}
																<i className="ge-edit">

																	<FiCopy data-tip="Duplicar" data-for={toString(empresa.id)}
																		onClick={() => { setModalShow(true); setEmp({ id: empresa.id, razao_social: empresa.razao_social }) }}
																	/>

																</i>
																<Link to='/CadastrarEmpresa'><i className="ge-edit"> <FiEdit data-tip="Editar" data-for={toString(empresa.id)} onClick={() => { sessionStorage.removeItem('empresaNovo'); sessionStorage.setItem('empresaId', empresa.id) }} />  </i> </Link>
																<i className="ge-trash">
																	<FiTrash2 data-tip="Excluir" data-for={toString(empresa.id)}
																		onClick={() => {
																			const r = window.prompt('ATENÇÃO: ESTE PROCEDIMENTO IRÁ EXCLUIR PERMANENTEMENE A EMPRESA SELECIONADA E TODAS AS SUAS DEPÊNDENCIAS\n\nEscreva abaixo a palavra "excluir" para confirmar a exclusão de ' + empresa.razao_social + ' :')
																			if (r === "excluir") {
																				handleDeleteEmpresa(empresa.id);
																			} else {
																				aviso("warning", "Nome incorreto, exclusão cancelada", true)
																			}
																		}} />
																</i>

															</div>
														}
													</div>
												)}
											</Draggable>

										)) : <h3>Nenhum registro</h3>
										}
										{provided.placeholder}
									</InfiniteScroll>
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className='dashboard-footer'>
						<div>Empresas encontrados: {foundEmpresas.length}</div>
						<Sort />
					</div>
				</div>


			</div>
		</div>
	);



}

function LockEmpresa(props) {

	const [senha, setSenha] = useState([])
	const [email, setEmail] = useState('')
	const [senhaAux, setSenhaAux] = useState('')
	const [verifica, setVerifica] = useState(false)
	const [verifica2, setVerifica2] = useState('nao')
	const { aviso } = React.useContext(Context);
	const [carregando, setCarregando] = useState(false)

	useEffect(() => {

		if (props.show) {
			setSenha('');
			setEmail('');
			setSenhaAux('');
			const password = sessionStorage.getItem('lockPassword')
			if (password !== null && password !== '') {
				setVerifica(true);
				setVerifica2('sim');
			} else {
				setVerifica(false);
				setVerifica2('nao');
			}
		}

	}, [props.show])

	function validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	async function handleControle() {
		if (verifica && verifica2 === 'sim') {
			try {
				setCarregando(true)
				await api.post('lockEmpresa/' + sessionStorage.getItem('lockId'), { password: senha, verifica: true, email })
				aviso('success', "Empresa bloqueada com sucesso")
			} catch (error) {
				aviso('error', error)
			} finally {
				sessionStorage.removeItem('lockId')
				sessionStorage.removeItem('lockPassword')
				setCarregando(false)
				props.onHide()
			}
		} else {
			if (senha === senhaAux && senha !== '') {
				if (validateEmail(email)) {
					try {
						setCarregando(true);
						await api.post('lockEmpresa/' + sessionStorage.getItem('lockId'), { password: senha, verifica: false, email })
						aviso('success', "Empresa bloqueada com sucesso")
					} catch (error) {
						aviso('error', error)
					} finally {
						sessionStorage.removeItem('lockId')
						sessionStorage.removeItem('lockPassword')
						setCarregando(false);
						props.onHide();
					}
				} else {
					aviso('warning', "Email inválido", true)
				}
			} else {
				aviso('warning', "As senhas não conferem", true)
			}
		}

	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Bloquear empresa
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					{verifica ?

						<div className="lockEmpresa-div">
							<div>
								<label>Deseja utilizar o padrão utilizado anteriormente?</label>
								<select style={{ width: '100%' }} autoFocus value={verifica2} onChange={e => setVerifica2(e.target.value)}>
									<option value={'nao'}>Não</option>
									<option value={'sim'}>Sim</option>
								</select>
							</div>
						</div>
						:

						<div className="lockEmpresa-div">
							<div className='lockEmpresa-block'>
								<div className='lockEmpresa-item'>
									<label>Informe a senha:</label>
									<input autoFocus type='password' value={senha} onChange={e => setSenha(e.target.value)} />
								</div>
								<div className='lockEmpresa-item'>
									<label>Confirme a senha:</label>
									<input type='password' value={senhaAux} onChange={e => setSenhaAux(e.target.value)} />
								</div>
							</div>

							<div className='lockEmpresa-block'>
								<div className='lockEmpresa-item'>
									<label>Informe um email para recuperação:</label>
									<input type='text' value={email} onChange={e => setEmail(e.target.value)} />
								</div>
							</div>
						</div>
					}

					{verifica2 === 'nao' && verifica ?
						<div className="lockEmpresa-div">
							<div className='lockEmpresa-block'>
								<div className='lockEmpresa-item'>
									<label>Informe a senha:</label>
									<input autoFocus type='password' value={senha} onChange={e => setSenha(e.target.value)} />
								</div>
								<div className='lockEmpresa-item'>
									<label>Confirme a senha:</label>
									<input type='password' value={senhaAux} onChange={e => setSenhaAux(e.target.value)} />
								</div>
							</div>

							<div className='lockEmpresa-block'>
								<div className='lockEmpresa-item'>
									<label>Informe um email para recuperação:</label>
									<input type='text' value={email} onChange={e => setEmail(e.target.value)} />
								</div>
							</div>
						</div>
						:
						null
					}


				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button type='submit' onClick={handleControle}>Bloquear</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function UnockEmpresa(props) {

	const [senha, setSenha] = useState('')
	const [email, setEmail] = useState('')
	const [forget, setForget] = useState(false)
	const { aviso } = React.useContext(Context);
	const [carregando, setCarregando] = useState(false)

	useEffect(() => {

		if (props.show) {
			setSenha('');
			setEmail('');
			setForget(false)
		}

	}, [props.show])

	function validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	async function handleControle() {
		if (forget) {
			try {
				setCarregando(true)
				if (validateEmail(email)) {
					await api.get(`recoverEmpresa?email=${email}&id=${sessionStorage.getItem('lockId')}`).then(response => {
						aviso('success', 'Um email foi enviado para ' + email)
						sessionStorage.removeItem('lockId')
						props.onHide()
					})
				} else {
					aviso('warning', "Email inválido", true)
				}
			} catch (error) {
				if (error.response.data.mensagem === 'Email errado') {
					aviso('warning', 'Email incorreto', true)
				} else {
					aviso('error', error)
				}
			} finally {
				setCarregando(false)
			}
		} else {
			try {
				setCarregando(true);
				await api.post(`unlockEmpresa/${sessionStorage.getItem('lockId')}`, { password: senha }).then(response => {
					aviso('success', 'Empresa desbloqueada com sucesso!')
					sessionStorage.removeItem('lockId')
					props.onHide()
				})
			} catch (error) {
				if (error.response.data.mensagem === 'Senha errada') {
					aviso('warning', 'Senha incorreta!', true)
				} else {
					aviso('error', error.response.data.mensagem, true)
				}
			} finally {
				setCarregando(false);
			}
		}
	}

	return (

		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Desbloquear empresa
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>
					<div className="lockEmpresa-block">
						<div className='lockEmpresa-item'>
							<label>Esqueci a senha</label>
							<Checkbox autoFocus onChange={(e, value) => setForget(value.checked)} toggle />
						</div>
						{!forget ?
							<div className='lockEmpresa-item'>
								<label>Informe a senha:</label>
								<input type='password' value={senha} onChange={e => setSenha(e.target.value)} />
							</div>
							:
							<div className='lockEmpresa-item'>
								<label>Informe o email cadastrado para recuperação:</label>
								<input type='text' value={email} onChange={e => setEmail(e.target.value)} />
							</div>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleControle}>{forget ? "Enviar" : "Desbloquear"}</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function Duplicar(props) {

	const [workspaceId, setWorkspaceId] = useState('')
	const { aviso, workspaces } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false)

	async function inicial() {
		setWorkspaceId('');
	}

	useEffect(() => {
		if (props.show)
			inicial()
	}, [props.show])

	function sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}


	async function handleControle() {

		try {
			setCarregando(true)
			await api.get('dEmpresa/' + props.empresa.id).then(async response => {
				await api.post('dEmpresa?workspace_id=' + workspaceId, response.data)
			})
			if (workspaceId === sessionStorage.getItem('workspaceId')) {
				aviso('success', "Empresa duplicada com sucesso")
			} else {
				aviso('success', "Empresa duplicada com sucesso")
				await sleep(1.5 * 1000)
				aviso('info', 'Redirecionando para a área de trabalho ' + workspaces.filter(item => item.id === parseInt(workspaceId))[0].name)
				sessionStorage.setItem('workspaceId', workspaceId)
			}
			setCarregando(false)
		} catch (error) {
			aviso('error', error)
		}

		props.onHide()
	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Duplicar empresa
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div className="qual-relatorio-gerar2">

					<div className="selecionarempresa-tarefa2">
						<label htmlFor="selecionarempresa-tarefa2">Selecione a área de trabalho para a qual deseja duplicar a empresa {props.empresa.razao_social}</label>
						<select value={workspaceId} onChange={e => setWorkspaceId(e.target.value)}>
							<option value="" disabled>Selecione</option>
							{workspaces.map(work => (
								<option key={work.id} value={work.id} >{work.name + ' - ' + work.owner_name}</option>
							))}
						</select>
					</div>

				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!workspaceId} onClick={handleControle}>Duplicar</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}


function Mover(props) {

	const [workspaces, setWorkspaces] = useState([])
	const [workspaceId, setWorkspaceId] = useState('')
	const { aviso } = React.useContext(Context);

	const [carregando, setCarregando] = useState(false)

	async function inicial() {

		setCarregando(true)
		try {

			await Promise.all([
				api.get('workspace')
			]).then(response => {
				setWorkspaces(response[0].data.resultado.filter(item => item.id !== parseInt(sessionStorage.getItem('workspaceId'))))
			})

		} catch (error) {
			console.log(error)
			aviso('error', error)
		} finally {
			setCarregando(false)
		}
	}

	useEffect(() => {
		if (props.show)
			inicial()
	}, [props.show])


	async function handleControle() {

		try {
			setCarregando(true)
			await api.patch('empresa/' + props.empresa.id, { fk_workspace_id: workspaceId })
			aviso('success', 'Empresa movida com sucesso para a área de trabalho ' + workspaces.filter(item => item.id === parseInt(workspaceId))[0].name)
			setCarregando(false)
		} catch (error) {
			aviso('error', error)
		}

		props.onHide()
	}

	return (

		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Mover empresa
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div className="qual-relatorio-gerar2">

					<div className="selecionarempresa-tarefa2">
						<label htmlFor="selecionarempresa-tarefa2">Selecione a área de trabalho para a qual deseja mover a empresa {props.empresa.razao_social}</label>
						<select value={workspaceId} onChange={e => setWorkspaceId(e.target.value)}>
							<option value="" disabled>Selecione</option>
							{workspaces.map(work => (
								<option key={work.id} value={work.id} >{work.name}</option>
							))}
						</select>
					</div>

				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!workspaceId} onClick={handleControle}>Mover</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}


