import React from 'react';
import api from '../../../services/api';
import { FiInfo, FiSearch } from 'react-icons/fi';
import Loading from '../../Loading';
import { Modal, Button } from 'react-bootstrap'
import { Context } from '../../../Context/AuthContext';
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import { registerLocale } from "react-datepicker";
import br from 'date-fns/locale/pt-BR';
import ReactTooltip from 'react-tooltip';

export function PlanoAcao({addOrUpdatePlanoAcao , ...props}) {

    registerLocale('pt-BR', br)

    const [o_que, setO_que] = useState('')
    const [quem, setQuem] = useState('')
    const [quando, setQuando] = useState(new Date())
    const [inicio, setInicio] = useState(new Date())
    const [como, setComo] = useState('')
    const [onde, setOnde] = useState('')
    const [porque, setPorque] = useState('')
    const [quanto, setQuanto] = useState('')
    const [currentValues, setCurrentValues] = useState('') //pesquisa
    const [options, setOptions] = useState([]) //opcoes de selecao
    const [searchQuery, setSearchQuery] = useState('')
    const [iniciado, setIniciado] = useState(false)
    const [concluido, setConcluido] = useState(false)

    const [habilita, setHabilita] = useState(false)

    const { aviso } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false);

    var textOque = "Qual é a ação necessária/Qual a atividade a ser realizada"
    var textQuem = "Quem é a pessoa responsável por conduzir esse processo"
    var textQuando = "Quando termina o plano"
    var textInicio = "Quando inicia o plano"
    var textComo = "Detalhar como será feito para resolver a ação"
    var textOnde = "Detalhar onde essa ação deve ser realizada ( local, máquina,etc...)"
    var textPorque = "Geralmente é para resolver um problema, para reduzir ou "
        + "eliminar um risco, aumentar a produtividade, para garantir a manutenção"
    var textQuanto = "Quanto vai custar, é para ter um rastreamento de quanto "
        + "custou e qual é o payback (tempo de retorno do investimento inicial "
        + "que foi aplicado no negócio ou projeto)"

    var textPesquisa = "Procure um plano de ação semelhante com base no campo 'O quê?' do 5W2H"
        + " para agilizar o preenchimento (opcional)"

    const handleChange = (e, { value }) => {

        setCurrentValues(value);
        if (value != "") {
            setHabilita(true)
        } else {
            setHabilita(false)
        }

    }

    const handlePesquisa = () => {

        if ((searchQuery == undefined || searchQuery == "") && (currentValues == undefined || currentValues == "")) {

        } else {
            const r = window.confirm("Deseja puxar as informações do plano de ação " + searchQuery + " ?\n ATENÇÃO: Todos as informações já inseridas nesse plano de ação serão apagadas")

            if (r) {
                api.get("plano_acao_geral?plano=consulta").then(response => {

                    response.data.resultado.forEach(item => {

                        if (currentValues == item.id) {

                            var dat_fim = new Date(item.quando)
                            var dat_ini = new Date(item.inicio)
                            setO_que(item.o_que)
                            setQuem(item.quem)
                            setQuando(dat_fim)
                            setInicio(dat_ini)
                            setComo(item.como)
                            setOnde(item.onde)
                            setPorque(item.porque)
                            setQuanto(item.quanto)
                            setIniciado(Boolean(false))
                            setConcluido(Boolean(false))
                        }

                    })
                })
            }
        }

    }

    const DropPesquisa = React.useMemo(() =>
    (
        <Dropdown
            options={options}
            placeholder="Procure por ações( O quê? ) já cadastradas em outros riscos"
            search
            lazyLoad={true}
            selection
            fluid
            clearable
            onChange={handleChange}
            value={currentValues}
        />
    ), [currentValues, options, searchQuery])

    useEffect(() => {

        if (props.show) {
            setCarregando(true)
            async function initial() {

                setQuando(new Date())
                setQuem('')
                setComo('')
                setOnde('')
                setPorque('')
                setQuanto('')
                setO_que('')
                setInicio(new Date())

                try {
                    api.get("plano_acao_geral?plano=consulta").then(response => {
                        var aux = []

                        response.data.resultado.forEach(item => {
                            aux.push({ key: item.id, text: item.o_que, value: item.id })
                        })

                        if (sessionStorage.getItem('planoId') != 0) {
                            setOptions(aux.filter(item => item.value != sessionStorage.getItem('planoId')))
                        } else {
                            setOptions(aux)
                        }

                    })

                    if (sessionStorage.getItem('planoId') !== '0') {

                        props.planos.forEach(item => {

                            if (parseInt(sessionStorage.getItem('planoId')) === item.id) {

                                var dat_ini
                                var dat_fim

                                if (item.inicio == undefined) {
                                    dat_ini = new Date()
                                } else {
                                    dat_ini = new Date(item.inicio)
                                }

                                if (item.quando == undefined) {
                                    var dat_fim = new Date()
                                } else {
                                    var dat_fim = new Date(item.quando)
                                }

                                setO_que(item.o_que)
                                setQuem(item.quem)
                                setQuando(dat_fim)
                                setInicio(dat_ini)
                                setComo(item.como)
                                setOnde(item.onde)
                                setPorque(item.porque)
                                setQuanto(item.quanto)
                                setIniciado(Boolean(item.iniciado))
                                setConcluido(Boolean(item.concluido))

                            }

                        })
                    } else {
                        setO_que('')
                        setQuem('')
                        setQuando(new Date())
                        setInicio(new Date)
                        setComo('')
                        setOnde('')
                        setPorque('')
                        setQuanto('')
                        setIniciado(false)
                        setConcluido(false)
                    }
                } catch (error) {
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }
            } initial()
        }
    }, [props.show])

    const handleRegister = async (e) => {

        e.preventDefault()

        const data = {
            o_que: o_que,
            quem: quem,
            quando: quando,
            inicio: inicio,
            como: como,
            onde: onde,
            porque: porque,
            quanto: quanto,
            iniciado: iniciado,
            concluido: concluido,
        }

        setCarregando(true)

        try {

            if (o_que == "" || quem == "" || inicio == null || quando == null || como == "" || onde == "" || porque == "" || quanto == "" || quando == "") {
                aviso('warning', 'Preencha todos os campos!')
            } else {

                if (inicio > quando) {
                    aviso('warning', 'Data de início superior à data de término!')
                } else {
                    if(!sessionStorage.getItem('empresaNovo')){
                        if (parseInt(sessionStorage.getItem('planoId')) === 0) {
                            await cadastrarPlano(data, "post", sessionStorage.getItem('empresaId'), null);
                        } else {
                            await cadastrarPlano(data, "patch", null, sessionStorage.getItem('planoId'));
                        }
                    }else{
                        if (parseInt(sessionStorage.getItem('planoId')) === 0) {
                            addOrUpdatePlanoAcao(data, null)
                        } else {
                            addOrUpdatePlanoAcao(data, sessionStorage.getItem('planoId'))
                        }
                    }

                    setCarregando(false)
                    aviso('success', "Plano de ação salvo com sucesso")
                }
            }

        } catch (error) {
            console.log(error)
            aviso('error', error);
        } finally {
            setCarregando(false);
            props.onHide();
        }
    }

    const handleConcluido = () => {
        setConcluido(!concluido)
        if (iniciado === false) {
            setIniciado(true)
        }
    }

    const handleIniciado = () => {
        setIniciado(!iniciado)
        if (concluido === true) {
            setConcluido(false)
        }
    }

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Plano de ação (5W2H)
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
                <Loading loading={carregando} message='Carregando...' />


                <div className="plano-pesquisa1">
                    <label>Procurar planos de ações semelhantes <FiInfo style={{ cursor: "pointer" }} data-tip={textPesquisa} data-for="aux"></FiInfo> </label>
                    <div className="plano-pesquisa2">
                        {DropPesquisa}
                        <button disabled={!habilita} onClick={handlePesquisa}><FiSearch /></button>
                    </div>
                </div>

                <div className="plano-retangulo1">
                    <div className="plano-aux1">
                        <label htmlFor="">O quê?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOque} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setO_que(e.target.value)} value={o_que} />
                    </div>

                    <div className="plano-aux1">
                        <label htmlFor="">Quem?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuem} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuem(e.target.value)} value={quem} />
                    </div>

                    <div className="plano-aux1">
                        <label htmlFor="">Onde?   <FiInfo style={{ cursor: "pointer" }} data-tip={textOnde} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setOnde(e.target.value)} value={onde} />
                    </div>
                </div>

                <div className="plano-retangulo2">
                    <div className="plano-aux1">
                        <label htmlFor="">Por quê?    <FiInfo style={{ cursor: "pointer" }} data-tip={textPorque} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setPorque(e.target.value)} value={porque} />
                    </div>

                    <div className="plano-aux1">
                        <label htmlFor="">Quanto?   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuanto} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setQuanto(e.target.value)} value={quanto} />
                    </div>

                    <div className="plano-aux1">
                        <label htmlFor="">Como?   <FiInfo style={{ cursor: "pointer" }} data-tip={textComo} data-for="aux"></FiInfo></label>
                        <textarea className="plano-aux2" aria-multiline="true" type="longtext" onChange={(e) => setComo(e.target.value)} value={como} />
                    </div>
                </div>

                <div className="plano-retangulo3">

                    <div className="plano-input">
                        <label htmlFor="plano-input">Data Início   <FiInfo style={{ cursor: "pointer" }} data-tip={textInicio} data-for="aux"></FiInfo></label>
                        <DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={inicio} onChange={date => setInicio(date)} />
                    </div>
                    <div className="plano-input">
                        <label htmlFor="plano-input">Data Fim   <FiInfo style={{ cursor: "pointer" }} data-tip={textQuando} data-for="aux"></FiInfo></label>
                        <DatePicker locale="pt-BR" peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" isClearable className="data-picker" dateFormat="dd/MM/yyyy" selected={quando} onChange={date => setQuando(date)} />
                    </div>
                    <div className="plano-check">
                        <label style={{ marginRight: '5px' }}>Iniciado?</label>
                        <Checkbox checked={iniciado} onChange={handleIniciado} />
                        <label style={{ marginLeft: '10px', marginRight: '5px' }}>Concluído?</label>
                        <Checkbox checked={concluido} onChange={handleConcluido} />
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleRegister}>{parseInt(sessionStorage.getItem('planoId')) === 0 ? "Cadastrar" : "Salvar"}</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

        </Modal>

    )
}

export const cadastrarPlano = async (planos, tipo, empresaId, planoId) => {
    try {
        if (tipo === "patch") {
            await api.patch(`plano_acao_geral/${planoId}`, planos);
        } else {
            const listaPlanos = Array.isArray(planos) ? planos : [planos];
            await Promise.all(
                listaPlanos.map(plano =>
                    api.post('plano_acao_geral?empresa_id=' + empresaId, plano)
                )
            );
        }
    } catch (error) {
        console.log(error)
    }
}